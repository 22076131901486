import React from 'react';
import TswDialog from 'components/v2/FormElements/Dialog';
import DialogButtons from 'components/v2/FormElements/Dialog/Buttons';

// MUI
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    padding: '2rem 0',
  },
});

export const ConfirmModal = ({
  onClose,
  onConfirm,
  open,
  isOpen,
  title,
  confirmText,
  children,
  message,
  disabled,
}) => {
  // isOpen is from v1 confirm, was easier to keep the same name
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    if (!onConfirm) return onClose();
    setLoading(true);
    const success = await onConfirm();
    setLoading(false);
    if (onClose && success) onClose();
  };

  return (
    <TswDialog onClose={onClose} open={isOpen || open} title={title} maxWidth="sm">
      <Box className={classes.container}>{message || children}</Box>
      <DialogButtons
        onCancel={onClose}
        onClick={handleSubmit}
        clickText={confirmText}
        loading={loading}
        disabled={disabled}
      />
    </TswDialog>
  );
};

export default ConfirmModal;
