import { pathBuilder, peopleRoot } from 'config/paths/index';

export const basePath = (id) => {
  return `${peopleRoot}/${id}/travels`;
};

export const userPathsBuilder = (id) => {
  return pathBuilder(basePath(id));
};

const fullPathsConfig = userPathsBuilder(':id');
export default fullPathsConfig;
