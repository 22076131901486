import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';

const FeedLoaderAnimation = keyframes`
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const StyledItem = styled('div')({
  animationDuration: '1s',
  animationDirection: 'alternate',
  animationIterationCount: 'infinite',
  animationName: FeedLoaderAnimation,
  animationTimingFunction: 'ease-in-out',
  backgroundColor: 'rgba(238, 238, 243, 0.59)',
  height: '4.75rem',
  marginBottom: '1rem',
  opacity: 0.5,
});

const StyledItemTwo = styled(StyledItem)({
  animationDelay: '0.33s',
});

const StyledItemThree = styled(StyledItem)({
  animationDelay: '0.66s',
});

const FeedLoader = () => (
  <>
    <StyledItem />
    <StyledItemTwo />
    <StyledItemThree />
  </>
);

export default FeedLoader;
