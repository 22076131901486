import React, { useState } from 'react';

// Components
import Button from 'components/v2/FormElements/Button';
import InboxActivity from 'components/v2/InboxV2/InboxActivity';
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import ReminderList from './ReminderList';

// MUI
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import List from '@mui/material/List';
import { Typography } from '@mui/material';

import { withFeatureState } from '../state';

const StyledBadge = styled(Badge)(({ theme }) => ({
  marginLeft: 0,
  marginRight: 18,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: 36,
  padding: '5px 0px',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: '1.5rem',
  width: '30rem',
}));

const ActivityDrawer = ({
  getInbox,
  inbox,
  loading,
  profile,
  users,
  preferences,
  getDrawerActivities,
  activities,
}) => {
  const { reminders } = inbox;
  const array = Array.isArray(reminders) ? reminders : [];
  const [open, setOpen] = useState(false);
  const onClick = () => setOpen((prev) => !prev);

  const numReminders = array.reduce((acc, { count }) => acc + count, 0);

  const getData = React.useCallback(() => {
    // getInbox();
    getDrawerActivities();
  }, []); // eslint-disable-line

  React.useEffect(() => {
    if (open) getData();
  }, [getData, open]);

  const badgeProps = {
    color: 'primary',
    // variant: 'dot',
    badgeContent: numReminders,
  };

  const buttonProps = {
    onClick,
    variant: 'outlined',
  };

  const drawerProps = {
    anchor: 'right',
    onClose: onClick,
    open,
  };

  const reduxData = ['inboxFeed'];

  return (
    <ReduxManager data={reduxData} showLoading={false}>
      <StyledBadge {...badgeProps}>
        <StyledButton {...buttonProps}>
          <FormatListBulletedIcon />
        </StyledButton>
      </StyledBadge>

      <Drawer {...drawerProps}>
        <StyledBox>
          <Typography variant="h4" sx={{ pb: 0 }}>
            Reminders
          </Typography>
          <Typography variant="caption" sx={{ pt: 0 }}>
            My next 30 days
          </Typography>
          <List>
            <ReminderList array={array} closeDrawer={() => setOpen(false)} />
          </List>
          <InboxActivity activities={activities} users={users} skipLoad={true} loading={loading} />
        </StyledBox>
      </Drawer>
    </ReduxManager>
  );
};

export default withFeatureState(ActivityDrawer);
