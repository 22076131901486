import React from 'react';
import { connect } from 'react-redux';

import { Link as RouterLink } from 'react-router-dom';

// MUI
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { styled } from '@mui/material/styles';

// Components
import DocumentAvatar from './Avatar';
import SidebarListItem from 'components/v2/PageElements/Sidebar/ListItem';

// Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

import { userFormsPath } from 'config/paths/userDigitalForms';

import format from 'lib/v1/format';

const StyledListItem = styled(SidebarListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  alignItems: 'center',
  '& p': {
    padding: 0,
  },
}));

const DeleteIcon = styled(IconButton)(({ theme }) => ({
  right: -5,
  marginTop: -1,
}));

const StyledLink = styled(ListItemText)(({ theme }) => ({
  '& > div': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const FormItem = ({
  attachment = {},
  destroy,
  show,
  permissions = {},
  isReviewer,
  icon,
  isDialog = false,
  profile,
}) => {
  const {
    id,
    completed_at,
    submitted_at,
    due_date,
    status,
    title,
    user,
    attached_digital_form_id,
    user_id,
  } = attachment;

  let date;
  if (status === 'Incomplete') {
    date = due_date ? `Due: ${format.relativeTime(due_date)}` : 'No Due Date';
  }
  if (status === 'Complete') {
    date = format.relativeTime(completed_at);
  }
  if (status === 'Submitted') {
    date = format.relativeTime(submitted_at);
  }
  const description = `${status} - ${date}`;
  const text = `${title} - ${user ? user.name : 'Unknown'}`;

  const canDelete = permissions.digitalFormRequests.destroy || isReviewer;
  const canView = profile.id === user_id || profile.role === 'admin';

  const Link = ({ link, text, path }) => {
    return link ? (
      <>
        <RouterLink {...{ to: path }}>{text}</RouterLink>
      </>
    ) : (
      <>{text}</>
    );
  };

  const linkProps = {
    link: canView,
    path: userFormsPath(user_id, id),
    text,
  };

  return (
    <>
      <StyledListItem>
        <ListItemAvatar sx={{ display: 'flex', flexDirection: 'row', marginTop: 0 }}>
          <DocumentAvatar md icon={icon} />
        </ListItemAvatar>

        <StyledLink dense primary={<Link {...linkProps} />} secondary={description} />

        {canDelete && (
          <ListItemSecondaryAction>
            <DeleteIcon aria-label="delete" onClick={() => destroy(id, attached_digital_form_id)}>
              <DeleteOutlineOutlinedIcon />
            </DeleteIcon>
          </ListItemSecondaryAction>
        )}
      </StyledListItem>
    </>
  );
};

const mapStateToProps = ({ profile }) => ({
  profile,
  ...mapPermissionsToProps({ profile }),
});
export default connect(mapStateToProps)(FormItem);
