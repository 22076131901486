import React from 'react';
import { connect } from 'react-redux';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import BaseThemeV1 from './v1/Base';
import LightTheme from './v2/Light';
import DarkTheme from './v2/Dark';

const themeMapper = {
  original: BaseThemeV1,
  light: LightTheme,
  dark: DarkTheme,
};

const Theme = ({ themes, children }) => {
  const activeTheme = themeMapper[themes.mode];

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={activeTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const mapStateToProps = ({ themes }) => {
  return { themes };
};

export default connect(mapStateToProps)(Theme);
