// common data manipulation tools for adapters
export const flipObject = (obj) => {
  return Object.entries(obj).reduce((obj, [key, value]) => {
    if (value) {
      return { ...obj, [value]: key };
    } else {
      return obj;
    }
  }, {});
};

export const adaptSelectToRequest = (field, { title = 'title', label = 'label' } = {}) => {
  return field && field[label] ? field[label] : null;
};

export const adaptSingleSelectFieldsToRequest = ({ formData = {}, singleSelectFields = [] }) => {
  const adapted = {};

  singleSelectFields.forEach((key) => {
    const exists = formData[key];
    if (!exists) return;

    adapted[key] = adaptSelectToRequest(exists);
  });

  return adapted;
};

export const adaptTagsToForm = (adapted) => {
  const { tag_names } = adapted;

  if (tag_names && Array.isArray(tag_names)) {
    adapted.tags = tag_names.map((obj) => ({ title: obj.name, label: obj.id }));
  }

  return adapted;
};

export const adaptSingleSelectToForm = ({ resource, fieldNames = [] }) => {
  const out = {};

  fieldNames.forEach((name) => {
    const value = resource[name];
    if (value) out[name] = { title: value, label: value };
  });

  return out;
};

const adaptCustomFieldToDisplayName = ({ value, model, instance } = {}) =>
  value
    ?.replace(`custom_field_${model}_`, '')
    ?.split('_')
    ?.slice(instance ? 1 : 0) // Slice :id if instance
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');

export const adaptCustomFieldsToLabels = ({ fields, model = 'user', instance } = {}) => {
  const labels = fields?.map((value) => ({
    label: `Custom Field: ${adaptCustomFieldToDisplayName({ value, model, instance })}`, // Todo Group these options instead of labeling individually
    value,
  }));

  return labels;
};
