// HOC
import { withFeatureState } from './state';
import { Delete, edit } from 'components/v2/FlexTable/RowActionItem';

// adapters
import { adaptToDetail } from 'adapters/v1/documents/formData';
import { adaptToForm, adaptDocumentTagsToRequest } from 'adapters/v1/documents/formData';
import TagRowAction from 'components/v2/FlexTable/TagRowAction';

// components
import EditDialog from './Edit';

export const ConnectedDelete = withFeatureState(Delete);
export const ConnectedEdit = withFeatureState(
  edit({ adapter: adaptToDetail, DialogComponent: EditDialog })
);
export const ConnectedTags = withFeatureState((rest) => {
  const actionProps = {
    adaptToForm,
    adaptToRequest: adaptDocumentTagsToRequest,
    taggableType: 'Document',
  };
  return <TagRowAction {...actionProps} {...rest} />;
});
