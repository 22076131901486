import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import Route from 'components/v2/PageElements/Routes';

import ReduxManager from 'components/v2/PageElements/ReduxManager';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths from 'config/paths/requirementControls';
import requirementsPaths from 'config/paths/requirements';
import controlsPaths from 'config/paths/controls';

// Components
const Index = lazy(() => import('./index'));
const ResourceLayout = lazy(() => import('./ResourceLayout'));
const RequirementsDetail = lazy(() => import('./Requirements/Detail'));
const ControlsDetail = lazy(() => import('./Controls/Detail'));

/*
 * Important thing to note about Resourceful layouts.
 *
 * If you want sublayouts such as those defined in "Detail" to successfully render
 * you cannot use exact paths, you are partial matching on the parent to ensure
 * the top level layout always gets loaded so that any child pages also get loaded.
 */

export const Routes = ({ paths, permissions }) => (
  <ReduxManager
    data={[
      'requirementsDropdown',
      'controlsDropdown',
      'documentsDropdown',
      'contractsDropdown',
      'peopleDropdown',
    ]}
  >
    <Suspense fallback={<></>}>
      <Switch>
        <Route
          permission={permissions.requirements.list}
          exact
          path={requirementsPaths.resource.detail(':id')}
          component={RequirementsDetail}
        />
        <Route
          permission={permissions.requirements.list}
          exact
          path={controlsPaths.resource.detail(':id')}
          component={ControlsDetail}
        />
        <Route permission={permissions.requirements.list} path={paths.root} component={Index} />

        {/* Take Note of the missing exact, and the fact that ResourceLayout goes to resource root */}
        <Route
          permission={permissions.requirements.list}
          path={paths.resource.root}
          component={ResourceLayout}
        />
      </Switch>
    </Suspense>
  </ReduxManager>
);

export default connect(mapPermissionsToProps, null)((props) => <Routes paths={paths} {...props} />);
