import { importTypeToDetail, importStatus } from 'adapters/v1/imports/formData';

const SELECT_OPTIONS = {
  container_types: [
    'Class 5 - Legal Size Filing Cabinet, AA-F-358',
    'Class 5 - Map and Plan Container, AA-F-363',
    'Class 5 - General Purpose Container, AA-F-363',
    'Class 5W - Weapons Container, AA-F-358',
    'Class 5W - Weapons Container, AA-C-2859',
    'Class 6 - Letter Size, AA-F-358',
    'Class 6 - Legal Size, AA-F-358',
    'Class 6 - Special Size, AA-F-358',
    'Class 6 - General Purpose Container, AA-F-363',
    'Class 6S - Shipboard Filing Cabinets, AA-F-358',
  ].map((type) => {
    return { label: type, value: type };
  }),
  admin_roles: [
    { label: 'Administrator', value: 'admin' },
    { label: 'Security Manager', value: 'sec-mngr' },
  ],
  boolean: [{ label: 'No', value: false, default: true }, { label: 'Yes', value: true }],
  cage: [
    { label: 'No, for all employees', value: 'negatory', default: true },
    { label: 'Yes, for employees in their CAGE', value: 'facility_scope' },
    { label: 'Yes, for all users', value: 'company_scope' },
  ],

  import_kind: Object.keys(importTypeToDetail).map((key) => {
    return { label: importTypeToDetail[key], value: key };
  }),
  import_status: importStatus.map((str) => {
    return { label: str, value: str };
  }),
  functions: [
    { label: 'Security Manager / FSO', value: 'sec-mngr' },
    { label: 'Insider Threat Working Group', value: 'insider-threat-working-group' },
    { label: 'Personnel Analyst', value: 'personnel-analyst' },
    { label: 'HR', value: 'human-resources' },
    { label: 'Contract Management', value: 'contract-management' },
  ],

  roles: [
    { label: 'Administrator', value: 'admin' },
    { label: 'Security Manager', value: 'sec-mngr' },
    { label: 'Assistant Security Manager', value: 'asst-sec-mngr' },
    { label: 'Employee', value: 'employee' },
  ],

  form_status: [
    { checked: true, label: 'Incomplete', value: false },
    { checked: false, label: 'Complete', value: true },
  ],

  active_status: [
    { label: 'Active Users', value: 'active' },
    { label: 'Inactive Users', value: 'inactive' },
  ],

  viewed: [
    { checked: true, label: 'Unviewed', value: false },
    { checked: false, label: 'Viewed', value: true },
  ],

  lodging_types: [
    { label: 'Hotel', value: 'Hotel' },
    { label: 'Rental/Homeshare', value: 'Rental/Homeshare' },
    { label: 'Guest of contact', value: 'Guest of contact' },
  ],

  continuous_evaluation: [
    { checked: true, label: 'True', value: true },
    { checked: false, label: 'False', value: false },
  ],

  clearance_level: [
    { label: 'None', value: 'None' },
    { label: 'Confidential', value: 'Confidential' },
    { label: 'Secret', value: 'Secret' },
    { label: 'Top Secret', value: 'Top Secret' },
  ],

  safeguarding_level: [
    { label: 'None', value: 'None' },
    { label: 'Confidential', value: 'Confidential' },
    { label: 'Secret', value: 'Secret' },
    { label: 'Top Secret', value: 'Top Secret' },
    { label: 'TS/SCI', value: 'TS/SCI' },
  ],
  visit_clearance_level: [
    { label: 'Unclassified', value: 'Unclassified' },
    { label: 'Confidential', value: 'Confidential' },
    { label: 'Secret', value: 'Secret' },
    { label: 'Top Secret', value: 'Top Secret' },
    { label: 'TS/SCI', value: 'TS/SCI' },
  ],
  contract_types: [
    { label: 'Prime', value: 'prime', active: true },
    { label: 'Subcontract', value: 'subcontract', active: false },
    { label: 'Subcontract Generated', value: 'subcontract_generated', active: true },
    { label: 'Subcontract Received', value: 'subcontract_Received', active: true },
  ],

  visits: [{ label: 'Incoming', value: 0 }, { label: 'Outgoing', value: 1 }],

  training_status: [{ label: 'Incomplete', value: 0 }, { label: 'Complete', value: 1 }],

  assignment_status: [{ label: 'Incomplete', value: 0 }, { label: 'Complete', value: 1 }],

  visit_status: [
    { label: 'Employee Requested', value: 'employee' },
    { label: 'Submitted to DISS/Customer', value: 'jpas' },
    { label: 'Active', value: 'active' },
    { label: 'Expired', value: 'expired' },
  ],

  classifications: [
    { label: 'Confidential', value: 'confidential' },
    { label: 'Secret', value: 'secret' },
    { label: 'Top Secret', value: 'topsecret' },
    { label: 'TS/SCI', value: 'TS/SCI' },
  ],

  agencies: [
    { value: 1, label: 'DoD - Department of Defense' },
    { value: 2, label: 'NSA - National Security Agency' },
    { value: 3, label: 'NGA - National Geospatial-Intelligence Agency' },
    { value: 4, label: 'DIA - Defense Intellgence Agency' },
    { value: 5, label: 'CIA - Central Intelligence Agency' },
    { value: 6, label: 'AIRFORCE - Department of Air Force' },
    { value: 7, label: 'ARMY - Department of Army' },
    { value: 8, label: 'DEA - Drug Enforcement Agency' },
    { value: 9, label: 'DHS - Department of Homeland Security' },
    { value: 10, label: 'DLA - Defense Logistics Agency' },
    { value: 11, label: 'DSS - Defense Security Service' },
    { value: 12, label: 'DOE - Department of Energy' },
    { value: 13, label: 'DOJ - Department of Justice' },
    { value: 14, label: 'DOS - Department of State' },
    { value: 15, label: 'DOT - Department of Transportation' },
    { value: 16, label: 'FBI - Federal Bureau of Investigation' },
    { value: 17, label: 'NAVY - Department of Navy' },
    { value: 18, label: 'NRO - National Reconnaissance Office' },
    { value: 19, label: 'ODNI - Office of the Director of National Intelligence' },
    { value: 20, label: 'TREASURY - Department of Treasury' },
    { value: 21, label: 'USCG - United States Coast Guard' },
    { value: 22, label: 'USMC - United States Marine Corps' },
    { value: 23, label: 'CLASSIFIED - Classified' },
  ],

  asset_types: [
    { label: 'Document', value: 'document' },
    { label: 'Laptop', value: 'laptop' },
    { label: 'Hard drive', value: 'hard_drive' },
    { label: 'Thumb drive', value: 'thumb_drive' },
    { label: 'Key', value: 'key' },
    { label: 'CD, Media', value: 'cd_media' },
    { label: 'STU/STE', value: 'stu_ste' },
    { label: 'Widget', value: 'widget' },
    { label: 'Tape', value: 'tape' },
    { label: 'Microfiche', value: 'microfiche' },
    { label: 'Floppy Disk', value: 'floppy_disk' },
    { label: 'Hardware', value: 'hardware' },
    { label: 'Other', value: 'other' },
  ],

  badge_status: [
    { label: 'In Progress', value: 0 },
    { label: 'Issued', value: 1 },
    { label: 'Terminated', value: 2 },
  ],

  certificate_status: [
    { label: 'Received', value: 'received' },
    { label: 'Generated', value: 'generated' },
    { label: 'Signed Out', value: 'signed_out' },
    { label: 'Transferred', value: 'transferred' },
    { label: 'Destroyed', value: 'destroyed' },
  ],

  clearance: [['none', 'None'], ['secret', 'Secret'], ['top_secret', 'Top Secret']],

  kind: [['training', 'Training'], ['program', 'Program'], ['inspection', 'Inspection']],

  topic: [
    ['employee_initial', 'Employee Initial'],
    ['employee_refresher', 'Employee Refresher'],
    ['program_initial', 'Program Refresher'],
    ['program_refresher', 'Program Refresher'],
  ],

  source: [['internal', 'Internal'], ['external', 'External']],

  travel_status: [
    { value: 'pre', label: 'Pre-travel reports' },
    { value: 'post', label: 'Post-travel reports' },
  ],

  role: [['admin', 'Administrator'], ['employee', 'Employee']],

  status: ['closed', 'assigned', 'open'],

  state: [
    ['AL', 'Alabama'],
    ['AK', 'Alaska'],
    ['AZ', 'Arizona'],
    ['AR', 'Arkansas'],
    ['CA', 'California'],
    ['CO', 'Colorado'],
    ['CT', 'Connecticut'],
    ['DE', 'Delaware'],
    ['DC', 'District Of Columbia'],
    ['FL', 'Florida'],
    ['GA', 'Georgia'],
    ['HI', 'Hawaii'],
    ['ID', 'Idaho'],
    ['IL', 'Illinois'],
    ['IN', 'Indiana'],
    ['IA', 'Iowa'],
    ['KS', 'Kansas'],
    ['KY', 'Kentucky'],
    ['LA', 'Louisiana'],
    ['ME', 'Maine'],
    ['MD', 'Maryland'],
    ['MA', 'Massachusetts'],
    ['MI', 'Michigan'],
    ['MN', 'Minnesota'],
    ['MS', 'Mississippi'],
    ['MO', 'Missouri'],
    ['MT', 'Montana'],
    ['NE', 'Nebraska'],
    ['NV', 'Nevada'],
    ['NH', 'New Hampshire'],
    ['NJ', 'New Jersey'],
    ['NM', 'New Mexico'],
    ['NY', 'New York'],
    ['NC', 'North Carolina'],
    ['ND', 'North Dakota'],
    ['OH', 'Ohio'],
    ['OK', 'Oklahoma'],
    ['OR', 'Oregon'],
    ['PA', 'Pennsylvania'],
    ['RI', 'Rhode Island'],
    ['SC', 'South Carolina'],
    ['SD', 'South Dakota'],
    ['TN', 'Tennessee'],
    ['TX', 'Texas'],
    ['UT', 'Utah'],
    ['VT', 'Vermont'],
    ['VA', 'Virginia'],
    ['WA', 'Washington'],
    ['WV', 'West Virginia'],
    ['WI', 'Wisconsin'],
    ['WY', 'Wyoming'],
  ],
  complete: [{ label: 'Show Complete', value: true }, { label: 'Show Incomplete', value: false }],
};

export default SELECT_OPTIONS;
