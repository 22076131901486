// legacy colors
export const twilight = '#205077';
export const twilight3 = '#8FA7BB';
export const blue = '#1C91CE';
export const lightBlue = '#f6fbfd';
export const grey = '#686767';
export const green = '#54835f';
export const red = '#b35050';
export const sage = '#679282';
export const charcoal = '#3F464B';
export const lightGray = '#d9d9d9';
export const lighterGray = '#ECF0EF';
export const ash = '#818C8D';

// SIS Logo Colors
export const logoCyan = '#58E3D0';
export const logoGreen = '#70D955';
export const logoGreenDark = '#52C41A';

// SIS Main Colors
export const sisGreen5 = '#73d13d';
export const sisGreen6 = '#52C41A';
export const sisCyan4 = '#5cdbd3';
export const sisGray1 = '#f4f4f4';
export const sisGray9 = '#3b3b3b';

// SIS Secondary Colors
export const sisGreen9 = '#135200';
export const sisGreen2 = '#d9f7be';
export const sisCyan9 = '#00474f';
export const sisCyan2 = '#b5f5ec';
export const sisBlue9 = '#003a8c';
export const sisBlue5 = '#40a9ff';

// SIS Extended Colors
export const sisGray6 = '#7f7f7f';

// Color Schemes
export const blueLight = '#edf2f4';
export const blueMed = '#005295';
export const blueDark = '#2A2E42';

export const redMed = '#ed2842';
export const redDark = '#d70e30';

//warmer
export const goldLight = '#E8DCC3';
export const goldMed = '#A39B89';
//mid
export const greenLight = '#6DD2B8';
export const greenMed = '#348899';
export const greenDark = '#245D69';
//cooler
export const tealLight = '#5DB4DD';
export const tealMed = '#1374a1';

// Color Shades
export const redShade = '#fbeaec';
export const blueShade = '#f3f9ff';
export const purpleShade = '#f5f7ff';
export const blueDarkShade = '#00064b';

// from designer
export const tableActionColor = sisBlue9;
export const navbarColor = sisGray1;
export const activeIconColor = sisGreen5;
export const ticketsColor = '#704998';
export const travelColor = logoCyan;
export const visitsColor = logoCyan;
export const notifyColor = sisGreen5;

// Divider, lol
export const blank = '';

// App
// export const primary = sisBlue5;
// export const primary = twilight;
// export const secondary = sisBlue9;
// export const secondary = grey;
// export const tertiary = sisCyan4;

export const action = tealLight;
export const info = sisCyan4;

export const alert = redDark;
export const warning = redMed;
export const success = greenMed;

export const textPrimary = blueDark;
export const textLight = blueMed;

export const borderPrimary = blueMed;
export const borderLight = blueLight;

export const highlight = blueLight;
export const accent = redShade;

export const buttonPrimary = blueDark;
export const buttonSecondary = blueDarkShade;
export const buttonDisabled = lightGray;
export const buttonGhost = redMed;

// https://css-tricks.com/converting-color-spaces-in-javascript/
export const hexToHsla = (H, alpha = 1) => {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length === 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H.length === 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return `hsl(${h}, ${s}%, ${l}%, ${alpha})`;
};
