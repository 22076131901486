import React from 'react';

// MUI
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import Button from 'components/v2/FormElements/Button';

const PlaceholderBox = styled(Box)(({ theme }) => ({
  fontSize: '1rem',
  border: `1px dashed ${theme.palette.secondary.main}`,
  borderRadius: 10,
  padding: '1rem 0.625rem',
  color: theme.palette.secondary.main,
  textAlign: 'center',
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(6),
}));

const EmptyStatePlaceholder = ({
  children,
  clickText = 'Click Me',
  onClick,
  sx,
  buttonColor,
  buttonVariant = 'outlined',
}) => {
  const buttonProps = {
    onClick,
    variant: buttonVariant,
  };

  if (buttonColor) buttonProps.color = buttonColor;

  return (
    <PlaceholderBox sx={sx}>
      {children}
      {onClick && <ActionButton {...buttonProps}>{clickText}</ActionButton>}
    </PlaceholderBox>
  );
};

export default EmptyStatePlaceholder;
