import React from 'react';

// material-core
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';

// components
import Button from '../Button';
import Buttons from '../Buttons';
import ConfirmModal from 'components/v2/FormElements/ConfirmModal';
import { useDialog } from 'components/v2/FormElements/Dialog';

const ButtonsContainer = styled(DialogActions)(({ theme }) => ({
  padding: '24px 0',
}));

export const StyledDeleteButton = styled(Button)(({ theme }) => ({
  marginRight: 'auto',
}));

const DialogButtons = (props) => {
  const { onClick, onClose, open } = useDialog();

  const modalProps = {
    title: 'Delete Item',
    isOpen: open,
    onConfirm: props.onDelete,
    confirmText: 'Delete',
    onClose,
  };

  return (
    <ButtonsContainer>
      {props.onDelete && (
        <>
          <ConfirmModal {...modalProps}>
            {props.deleteConfirm || 'Are you sure you want to permanently delete this item?'}
          </ConfirmModal>
          <StyledDeleteButton variant="text" color="error" onClick={onClick}>
            Delete
          </StyledDeleteButton>
        </>
      )}
      <Buttons {...props} />
    </ButtonsContainer>
  );
};

export default DialogButtons;
