import { makeEnum, ui } from 'adapters/v1/tsform';
import { titleizeSnake } from 'lib/v1/format';
import { cellAdapterFactory } from 'adapters/v1/factories';
import { flipObject } from 'adapters/v1';
import { adaptToDisplay } from 'adapters/v1/dates';

const dateFields = ['created_at'];

export const importTypeToDetail = {
  Badge: 'Badges',
  Certificate: 'Assets',
  Contract: 'Contracts',
  Facility: 'Facilities',
  Requirement: 'Requirements',
  User: 'Personnel',
  UserAccess: 'Accesses',
  UserEligibility: 'Eligibilities',
};

export const fieldAliases = {
  UserAccess: {
    type_access_id: 'access_code',
    user_id: 'user',
  },
  UserEligibility: {
    type_eligibility_id: 'eligibility_code',
    type_investigation_id: 'investigation_code',
    user_id: 'user',
  },
};

export const importStatus = ['Draft', 'Complete', 'Uploaded'];

export const adaptToRequest = (formData, columnMapping) => {
  if (!formData || !columnMapping) return {};

  const inverseMapping = flipObject(columnMapping);

  Object.entries(formData).forEach(([key, value]) => {
    inverseMapping[key] = value;
  });

  const flipped = flipObject(inverseMapping);
  return flipped;
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};

  const { properties, column_names, column_mapping } = resource;

  if (!properties || !column_names || !column_mapping) return {};

  const requiredFromTsw = properties.filter((obj) => obj.required).map((obj) => obj.key);

  const requiredColumnsFromImport = requiredFromTsw.map((field) => resource.column_mapping[field]);

  const filtered = column_names.filter((name) => !requiredColumnsFromImport.includes(name));

  const out = {};

  filtered.forEach((name) => {
    out[name] = flipObject(column_mapping)[name];
  });

  return out;
};

export const adaptToTable = (resource) => {
  if (!resource) return {};

  const adapted = adaptToDisplay({ ...resource }, dateFields);
  const { created_by } = resource;

  if (adapted.kind) {
    adapted.kind = importTypeToDetail[resource.kind];
  }

  if (adapted.user_id) {
    adapted.user_id = created_by ? created_by.name : null;
    if (adapted.origin === 'api') adapted.user_id += ' - API';
  }

  adapted.createdDate = new Date(adapted.created_at);
  adapted.updatedDate = new Date(adapted.updated_at);

  adapted.createdBy = adapted.created_by?.name;

  return adapted;
};

export const adaptToFinalizeTable = (resource) => {
  if (!resource) return {};
  const adapted = { ...resource };

  // For any date fields, use `data` value instead of `raw_data` value
  // This gives the user a preview of invalid values if they use incorrect date format
  const dateFields = resource.properties.filter((p) => ['date', 'datetime'].includes(p.type));
  dateFields.forEach(({ key }) => {
    // Translate field names (e.g., "brief_date" to "Brief Date")
    const rawFieldName = resource.mapping?.[key];

    // Show the Ruby parsed date (e.g., "0092-09-05" instead of "9/5/92")
    adapted.raw_data[rawFieldName] = adapted.data?.[key];
  });

  return adapted;
};

export const adaptToRequiredFields = (resource) => {
  if (!resource) return [];

  const { properties, column_names } = resource;

  if (!properties || !column_names) return [];

  const titleContent = (field) => {
    field = fieldAliases[resource?.kind]?.[field] ?? field;
    const titleized = titleizeSnake(field);
    return `Select the column from your file that contains: '${titleized}'`;
  };

  return properties
    .filter((obj) => obj.required && obj.key)
    .map((obj) => {
      const values = column_names.map((str) => {
        return { label: str, value: str };
      });
      return {
        schema: makeEnum(titleContent(obj.key), values, 'string'),
        ui: ui('Select', { innerWidth: '60%', innerAlign: 'center' }),
        view: true,
        edit: true,
        key: obj.key,
        required: obj.required,
      };
    });
};

// FIXME, this should use adatapers/v1/tsForm' and not 'lib/v1/tsForm'
export const adaptToOptionalFields = (resource) => {
  if (!resource) return [];

  const { properties, column_names, column_mapping } = resource;

  if (!properties || !column_names || !column_mapping) return [];

  const requiredFromTsw = properties.filter((obj) => obj.required).map((obj) => obj.key);

  const requiredColumnsFromImport = requiredFromTsw.map((field) => column_mapping[field]);
  const filtered = column_names.filter((name) => !requiredColumnsFromImport.includes(name));

  const values = properties
    .filter((obj) => !obj.required)
    .map((obj) => {
      return { label: titleizeSnake(obj.key), value: obj.key };
    });

  values.unshift({ default: true, label: 'None', value: undefined });

  return filtered.map((str) => {
    return {
      key: str,
      schema: makeEnum(str, values, 'string'),
      ui: ui('Select'),
      view: true,
      edit: true,
    };
  });
};

const adaptToNested = (resource) => {
  const { raw_data, associated } = resource;

  if (!raw_data) return {};

  if (associated) {
    associated.forEach((object) => {
      const { field, record } = object;

      if (field && record && record.display_value) {
        raw_data[resource.mapping[field]] = record.display_value;
      }
    });
  }

  return raw_data;
};

// JSONB fields for import units
export const nestedCellAdapter = cellAdapterFactory(adaptToNested);

// Non JSONB fields for import units
export const finalizeCellAdapter = cellAdapterFactory(adaptToFinalizeTable);

// Fields for import table itself
export const cellAdapter = cellAdapterFactory(adaptToTable);
