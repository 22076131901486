import { isObject } from 'lib/v1/types';

const selectable = (
  dataFromStore,
  { id = 'id', value = 'name', key = 'data', arrayData = false } = {}
) => {
  if (!dataFromStore) return [];
  if (!dataFromStore[key]) return [];
  if (arrayData && !isObject(dataFromStore[key])) return [];

  const arr = arrayData ? dataFromStore[key] : Object.values(dataFromStore[key]);

  return arr.map((obj) => {
    const jsonApi = obj.attributes;

    return {
      value: obj[id] || (jsonApi && obj.attributes[id]),
      label: jsonApi ? obj.attributes[value] : obj[value],
    };
  });
};

export default selectable;
