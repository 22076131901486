import React from 'react';

import { useDialog } from 'components/v2/FormElements/Dialog';
import ConfirmModal from 'components/v2/FormElements/ConfirmModal';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

import Avatar from 'components/v2/PageElements/Avatar';

const CellBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const CellButton = styled(Button)(({ theme }) => ({
  justifyContent: 'flex-start',
  minWidth: '100%',
}));

export const avatarCellRender = ({ row }) => {
  return <Avatar profile={row} />;
};

export const comboCellRender = (prop) => {
  return ({ row }) => {
    return (
      <CellBoxContainer>
        {row[prop]?.map((item, index) => {
          return <Box key={index}>{item}</Box>;
        })}
      </CellBoxContainer>
    );
  };
};

export const buttonCellRender = (prop, { onClick }) => {
  return ({ row }) => {
    const handleClick = () => {
      onClick(row);
    };

    return (
      <CellButton onClick={handleClick} variant="text">
        {row[prop]}
      </CellButton>
    );
  };
};

const DeleteButton = styled(DeleteIcon)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.error.main,
  },
}));

const DeleteModal = ({
  action = 'Delete',
  afterDelete,
  confirmText,
  destroy,
  list,
  nameProp,
  row,
  type = 'item',
  ...rest
}) => {
  const { onClick, onClose, open } = useDialog();

  const onDelete = async () => {
    if (!row || !row.id) return;
    const resp = await destroy(row.id);
    if (!resp.success) return;
    afterDelete && afterDelete();
    onClose();
  };

  const name = nameProp && row ? `${row[nameProp]} ${type}` : 'row';
  const message = `Are you sure you want to ${action.toLowerCase()} the ${name}?`;

  const modalProps = {
    title: name ? `${action} ${name}` : action,
    isOpen: open,
    onConfirm: onDelete,
    confirmText,
    onClose,
    message,
  };

  return (
    <>
      <ConfirmModal {...modalProps} />
      <IconButton onClick={onClick}>
        <DeleteButton />
      </IconButton>
    </>
  );
};

export const deleteCell = (props) => {
  return ({ row }) => <DeleteModal {...props} row={row} />;
};
