import { pathBuilder, peopleRoot } from 'config/paths/index';

export const basePath = (id) => {
  return `${peopleRoot}/${id}/forms`;
};

export const userPathsBuilder = (id) => {
  return pathBuilder(basePath(id));
};

export const userFormsPath = (id, form_id) => {
  return userPathsBuilder(id).resource.detail(form_id);
};

const fullPathsConfig = userPathsBuilder(':id');

export default fullPathsConfig;
