import React from 'react';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow classes={{ popper: className }} {...props} />
))(({ theme, background, color }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 14,
    background: background || theme.palette.info.main,
    color: color || theme.palette.info.contrastText,
    boxShadow: theme.shadows[1],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
  },
}));

const Index = (props) => {
  return <StyledTooltip {...props} />;
};

export default Index;
