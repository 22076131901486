import CONST from './constants';

export const initialState = {
  data: {},
  result: [],
  loading: false,
  byUser: {},
  meta: {},
};

const index = (state = initialState, { type, payload = {}, error }) => {
  const { id, data, meta } = payload;
  switch (type) {
    case CONST.SUBMIT_SIGNATURE_REQUEST_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.SUBMIT_SIGNATURE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CONST.GET_SIGNATURE_REQUESTS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.ALL_SIGNATURE_REQUESTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...data.reduce((obj, form) => {
            const id = form.signature_request_id;
            obj[id] = form;

            // Allow client-side to override status
            // This is needed due to HelloSign's inconsistent API
            obj[id].is_complete = state.data[id] ? state.data[id].is_complete : obj[id].is_complete;

            return obj;
          }, {}),
        },
        meta,
        loading: false,
        result: data.map((d) => d.signature_request_id),
      };
    case CONST.GET_SIGNATURE_REQUESTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...data.reduce((obj, form) => {
            const id = form.signature_request_id;
            obj[id] = form;

            // Allow client-side to override status
            // This is needed due to HelloSign's inconsistent API
            obj[id].is_complete = state.data[id] ? state.data[id].is_complete : obj[id].is_complete;

            return obj;
          }, {}),
        },
        meta,
        loading: false,
        byUser: {
          ...state.byUser,
          [id]: data.reduce(
            (filtered, form) => {
              // Remove any forms that have been marked for deletion
              const original = state.data[form.signature_request_id] || {};
              if (!original.deleted) {
                filtered.push(form.signature_request_id);
              }
              return filtered;
            },
            (state.byUser[id] || []).filter((id) => {
              // Preserve any forms that have been marked as pending and do not yet
              // appear in the new data array
              const original = state.data[id];
              return !data.find((d) => d.signature_request_id === id) && original.pending;
            })
          ),
        },
      };
    case CONST.GET_SIGNATURE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          [data.signature_request_id]: data,
        },
        byUser: {
          [id]: [...new Set([data.signature_request_id].concat(state.byUser[id] || []))],
        },
      };
    case CONST.CREATE_SIGNATURE_REQUEST_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.CREATE_SIGNATURE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [data.signature_request_id]: data,
        },
        byUser: {
          [id]: [...new Set([data.signature_request_id].concat(state.byUser[id] || []))],
        },
      };
    case CONST.REASSIGN_SIGNATURE_REQUEST_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.REASSIGN_SIGNATURE_REQUEST_SUCCESS:
      // Remove old entry from data
      const { previous_id } = data;
      const newData = { ...state.data };
      delete newData[previous_id];

      return {
        ...state,
        data: {
          ...state.data,
          [data.signature_request_id]: data,
        },
        byUser: {
          [id]: [...new Set([data.signature_request_id].concat(state.byUser[id] || []))],
        },
        loading: false,
      };
    case CONST.DELETE_SIGNATURE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [data.signature_request_id]: {
            deleted: true,
            ...state.data[data.signature_request_id],
          },
        },
        byUser: {
          [id]: (state.byUser[id] || []).filter((id) => id !== data.signature_request_id),
        },
      };
    case CONST.MARK_SIGNATURE_REQUEST_COMPLETE_SUCCESS:
      return {
        ...state,
        data: {
          [data.id]: data,
          ...state.data,
        },
      };
    default:
      return state;
  }
};

export default index;
