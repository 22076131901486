import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import AppContext from 'components/v2/AppContext';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch } from 'components/v2/PageElements/Routes';

import {
  basePathV2 as basePath,
  adminBasePath,
  defaultPathV2 as defaultPath,
  logoutPath,
} from 'config/paths';
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

// Components
import AppLayout from './index';
import MySidebar, { MySidebarContent } from './MySidebar';

// Routes
import PeopleRoutes from 'components/v2/People/Routes';
import ActivityRoutes from 'components/v2/Activity/Routes';
import RolesRoutes from 'components/v2/Roles/Routes';
import CustomFieldsRoutes from 'components/v2/CustomFields/Routes';
import CompanyRoutes from 'components/v2/Company/Routes';

import { usePaths } from 'config/paths';

// actions
import { logout } from 'store/v1/authentication/actions';

// LazyComponents
const Sidebar = lazy(() => import('components/v2/PageElements/Sidebar'));

export const Logout = connect(null, { logout })(({ logout, onLogout }) => {
  // FIX HOOK
  React.useEffect(() => {
    logout();
    if (onLogout) onLogout();
  }, []); // eslint-disable-line
  return <></>;
});

const redirectPaths = ['/visits', '/inbox', '/admin', '/workflow', '/login'];

export const Routes = ({ history, preferences, permissions, location = {}, onLogout, profile }) => {
  const [showSidebar, setShowSidebar] = React.useState(false);
  const { pathname } = location;

  // Core context definitions
  const isAdminContext = pathname && pathname.startsWith(adminBasePath);
  const isMyContext = !isAdminContext;

  const paths = usePaths({ adminBasePath, basePath, isAdminContext, profile });

  React.useEffect(() => {
    setShowSidebar(isMyContext && !pathname.endsWith('details'));
  }, [isMyContext, pathname]);

  const context = {
    v2: true,
    isAdminContext,
    isMyContext,
    permissions,
    preferences,
    profile,
    paths: {
      ...paths,
      myProfilePath: paths.my.profile.resource.detail(profile.id),
    },
  };

  return (
    <AppContext.Provider value={context}>
      <ReduxManager data={[]}>
        <AppLayout>
          <Switch>
            <Route permission={true} exact path={logoutPath}>
              <Logout {...{ onLogout }} />
            </Route>
            <Route path={basePath} permission={true}>
              <Switch>
                <Route permission={true} path={adminBasePath}>
                  <Suspense fallback={<></>}>
                    <Switch>
                      <Route exact permission={true} path={adminBasePath}>
                        <MySidebar isAdminContext />
                      </Route>
                      <Route permission={true} path={paths.admin.people.root}>
                        <PeopleRoutes paths={paths.admin.people} />
                      </Route>
                      <Route permission={true} path={paths.admin.activity.root}>
                        <ActivityRoutes paths={paths.admin.activity} />
                      </Route>
                      <Route permission={true} path={paths.admin.roles.root}>
                        <RolesRoutes paths={paths.admin.roles} />
                      </Route>
                      <Route permission={true} path={paths.admin['custom-fields'].root}>
                        <CustomFieldsRoutes paths={paths.admin['custom-fields']} />
                      </Route>
                      <Route permission={true} path={paths.admin.account.root}>
                        <CompanyRoutes
                          v2={true}
                          paths={paths.admin.account}
                          accessesPaths={paths.admin.accesses}
                          eligibilitiesPaths={paths.admin.eligibility}
                          polygraphsPaths={paths.admin.polygraphs}
                        />
                      </Route>
                    </Switch>
                  </Suspense>
                </Route>
                <Sidebar sidebarContent={showSidebar && <MySidebarContent />}>
                  <Switch>
                    <Route permission={true} path={paths.my.profile.root}>
                      <Switch>
                        <PeopleRoutes paths={paths.my.profile} />
                      </Switch>
                    </Route>
                    <Route exact permission={true} path={defaultPath}>
                      <MySidebar />
                    </Route>
                  </Switch>
                </Sidebar>
              </Switch>
            </Route>
            <Route path={redirectPaths} redirect={defaultPath} />
            <Route exact path="/" redirect={defaultPath} />
          </Switch>
        </AppLayout>
      </ReduxManager>
    </AppContext.Provider>
  );
};

const mapStateToProps = ({ profile }) => {
  return { ...mapPreferencesToProps({ profile }), ...mapPermissionsToProps({ profile }), profile };
};

export default withRouter(connect(mapStateToProps)((props) => <Routes {...props} />));
