import CONST from './constants';
import format from 'lib/v1/format';
import { denull } from 'lib/v1';

export const initialState = {
  data: {},
  result: [],
  meta: {},
  loading: false,
};

const index = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case CONST.ALL_USERS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...denull(payload).reduce((all, user) => {
            all[user.id] = {
              ...user,
              training_assignments: (user.training_assignments || []).map((t) => t.id),
              name: format.first_last(user),
            };
            return all;
          }, {}),
        },
        meta,
        result: [...new Set(state.result.concat(payload.map((user) => user.id)))],
      };
    case 'ASSIGN_SUCCESS':
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.reduce((all, assignment) => {
            all[assignment.user.id] = {
              ...state.data[assignment.user.id],
              training_assignments: [
                ...(state.data[assignment.user.id].training_assignments || []),
                assignment.id,
              ],
            };
            return all;
          }, {}),
        },
      };
    case CONST.ALL_USERS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONST.GET_USER_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.id]: {
            ...payload,
            name: format.first_last(payload),
          },
        },
        result: [...new Set(state.result.concat(payload.id))],
      };
    case CONST.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONST.GET_USER_ACCESSES_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.GET_USER_ACCESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.id]: {
            ...payload,
            ...state.data[payload.id],
          },
        },
      };
    case CONST.GET_USER_ACCESSES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONST.CREATE_USER_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.CREATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.id]: {
            ...payload,
            ssn: payload.ssn
              ? payload.ssn
                  .split('')
                  .filter((s) => s !== '-')
                  .join('')
              : payload.ssn,
          },
        },
        result: [...new Set([payload.id].concat(state.result))],
      };
    case CONST.CREATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONST.DELETE_USER_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        meta: {
          ...state.meta,
          total_count: --state.meta.total_count,
        },
        result: state.result.filter((id) => !payload.includes(id)),
      };
    case CONST.DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONST.UPDATE_USER_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.id]: {
            ...state.data[payload.id],
            ...payload,
            ssn: payload.ssn
              ? payload.ssn
                  .split('')
                  .filter((s) => s !== '-')
                  .join('')
              : payload.ssn,
          },
        },
      };
    case CONST.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONST.USERS_RESET_PAGING:
      return {
        ...state,
        meta: {},
      };
    case CONST.GET_USERS_SLIM_PENDING:
      return {
        ...state,
        loading: false,
      };
    case CONST.GET_USERS_SLIM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...denull(payload).reduce((all, user) => {
            const existing = state.data[user.id] || {};
            all[user.id] = { ...existing, ...user, name: format.first_last(user) };
            return all;
          }, {}),
        },
        result: payload.map((user) => user.id),
      };
    case CONST.GET_USERS_SLIM_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONST.CREATE_USER_COMMENT_SUCCESS:
      const comments = [payload.comment].concat(state.data[payload.id].comments || []);
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.id]: { ...state.data[payload.id], comments },
        },
      };
    case CONST.GET_USER_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.id]: { ...state.data[payload.id], comments: payload.comment },
        },
      };
    default:
      return state;
  }
};

export default index;
