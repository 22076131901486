import React from 'react';
import { Route } from 'react-router-dom';
import TabView from 'components/v1/TabView';
import { isArray } from 'lib/v1/types';

const formatChild = (child) => {
  if (!child || !child.props) return [];
  return [child.props];
};

const formatChildren = (children) => {
  return children
    .filter(({ props }) => props && props.title) // Allow us to pass unnamed routes to the tabs for nested routing
    .map(({ props }) => ({ title: props.title, path: props.path, exact: props.exact }));
};

const TabLayout = ({ children }) => {
  const pages = isArray(children) ? formatChildren(children) : formatChild(children);

  return (
    <>
      <TabView pages={pages} />
      {children}
    </>
  );
};

export const TabLayoutFactory = (Component) => {
  return ({ children }) => {
    const pages = isArray(children) ? formatChildren(children) : formatChild(children);

    return (
      <>
        <Component pages={pages} />
        {children}
      </>
    );
  };
};

export const Tab = ({ title, path, component }) => (
  <Route exact path={path} component={component} />
);

export const ResourceTab = ({ title, route, component }) => (
  <Route exact path={route} component={component} />
);

export default TabLayout;
