import React from 'react';
import TswDialog from 'components/v2/FormElements/Dialog';
import Form from './Form';

export const Dialog = ({ onClose, open, title, ...rest }) => {
  return (
    <TswDialog onClose={onClose} open={open} title={title}>
      <Form afterCancel={onClose} {...rest} />
    </TswDialog>
  );
};

export default Dialog;
