import actionBuilder from 'store/v2/actions';
import actionType, { bulkActionType, apiPath } from './constants';

const baseActions = actionBuilder({ apiPath, bulkActionType, actionType });

const getDrawerActivities = () => {
  return baseActions.list({
    per_page: 30,
    sort: 'created_at',
    dir: 'desc',
    include: 'user',
  });
};

const actions = {
  ...baseActions,
  getDrawerActivities,
};

export default actions;
