import React from 'react';
import { connect } from 'react-redux';

// Mui
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

// Components
import Buttons from './Dialog/Buttons';
import TextField from './TextField';

import documentActions from 'store/v2/documents/actions';
import { validURL } from 'lib/v1/validation-rules';

const DEFAULT_LABEL = 'Add Link';

const AddLink = ({
  loading,
  create,
  resourceScope,
  documentable_type,
  documentable_id,
  label = DEFAULT_LABEL,
  callback,
  onClose = () => {},
} = {}) => {
  const [name, setName] = React.useState('');
  const [link, setLink] = React.useState('');
  const [linkError, setLinkError] = React.useState(false);
  const [nameError, setNameError] = React.useState(false);

  const handleLinkChange = (event) => {
    const newLink = event.target.value;
    setLink(newLink);

    if (linkError && validURL(link)) {
      setLinkError(false);
    }
  };

  const handleNameChange = (event) => {
    const newName = event.target.value;
    setName(event.target.value);

    if (nameError && newName.trim() !== '') {
      setNameError(false);
    }
  };

  const validate = () => {
    const linkValid = validURL(link);
    setLinkError(!linkValid);

    const nameValid = name.trim() !== '';
    setNameError(!nameValid);

    return linkValid && nameValid;
  };

  const handleSubmit = async () => {
    const valid = validate();
    if (!valid) return;

    const resp = await create({
      name,
      link,
      resource_scope: resourceScope,
      documentable_type,
      documentable_id,
    });
    if (!resp || !resp.id) return;
    callback && callback(resp);

    setName('');
    setLink('');
    onClose();
  };

  return (
    <Box>
      <List>
        <ListItem>
          <TextField
            onChange={handleNameChange}
            label="Link Name"
            required
            value={name === null ? link : name}
            error={nameError}
            helperText={nameError && 'Please enter a link name'}
          />
        </ListItem>

        <ListItem>
          <TextField
            onChange={handleLinkChange}
            label="Link URL"
            required
            value={link}
            error={linkError}
            helperText={linkError && 'Please enter a valid URL including http'}
          />
        </ListItem>
      </List>
      <Buttons clickText="Add" onClick={handleSubmit} onCancel={onClose} loading={loading} />
    </Box>
  );
};

export default connect(({ documentsV2: { loading } }) => ({ loading }), {
  create: documentActions.create,
})(AddLink);
