import React from 'react';

// components
import MultiSelectFilter from 'components/v2/Filters/MultiSelect';
import DateRangeFilter from 'components/v2/Filters/DateRange';

const MultiSelect = ({ updateQuery }) => {
  const name = 'Template';
  const displayName = 'MultiSelect Template';

  const mapStateToSelect = () => {
    return {
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      isLoading: false,
      disabled: false,
    };
  };

  const multiSelectProps = {
    name,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelectFilter {...multiSelectProps} {...props} />,
    label: displayName,
    value: name,
    icon: 'calendar',
  };
};

const DateRange = ({ updateQuery }) => {
  const name = 'Template';
  const displayName = 'DateRange Template';

  const dateRangeProps = {
    name,
    displayName,
    updateQuery,
  };

  return {
    Component: (props) => <DateRangeFilter {...dateRangeProps} {...props} />,
    label: displayName,
    value: name,
    icon: 'calendar',
  };
};

const filters = [MultiSelect, DateRange];

export default filters;
