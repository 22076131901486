import { pathBuilder, peopleRoot } from 'config/paths/index';

export const root = '/travels';
const basePaths = pathBuilder(root);

export const peopleBasePath = (id) => {
  return `${peopleRoot}/${id}/travels`;
};

export const userPathsBuilder = (id) => {
  return pathBuilder(peopleBasePath(id));
};

const paths = {
  ...basePaths,
  create: root + '?create',
  settings: root + '/settings',
};

export default paths;
