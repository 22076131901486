let styles = {
  column: {
    margin: '1.25rem auto',
    maxWidth: '700px',
  },
};

const ErrorPage = () => {
  return (
    <div className="segment" style={styles.column}>
      <h2 className="header">We're Sorry</h2>
      <p>The feature you are looking for is not enabled.</p>
      <p>
        If you believe this is an error or would like to enable this feature, please contact
        Customer Support at <a href="mailto:support@threatswitch.com">support@threatswitch.com</a>.
      </p>
    </div>
  );
};

export default ErrorPage;
