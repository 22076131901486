import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from 'store/v1/authentication/actions';
// import store from 'store';

// import { useInterval } from 'lib/v1/hooks';

// import request from 'lib/v2/request';
import { styled } from '@mui/material/styles';

import { add_message } from 'store/v1/app/actions';

import FlashMessages from 'components/v2/PageElements/FlashMessages';
import IdleTimerDialog from 'components/v2/PageElements/IdleTimerDialog';

const StyledWrapper = styled('div')({
  height: '100%',
});

const AppLayout = ({ children, logout, add_message }) => {
  // const state = store.getState();
  // const loggedIn = state.profile && state.profile.id;

  // const checkSession = () => {
  //   request
  //     .get('/v1/sessions')
  //     .then()
  //     .catch((err) => {
  //       if (err.response && err.response.status === 401) {
  //         logout();
  //         add_message('timeout_error', ['Logged out due to inactivity.']);
  //       }
  //     });
  // };

  // useInterval(checkSession, loggedIn ? 60000 : null);

  return (
    <StyledWrapper>
      <IdleTimerDialog>
        <FlashMessages />
        {children}
      </IdleTimerDialog>
    </StyledWrapper>
  );
};

export default connect(null, { logout, add_message })(withRouter(AppLayout));
