import { pathBuilder } from 'config/paths/index';

export const root = '/risk_profile';
const adminRoot = '/admin/risk';

const userBasePath = (id) => {
  return `/users/${id}/risk_profile`;
};

export const userPathsBuilder = (id) => {
  return pathBuilder(userBasePath(id));
};

const settings = () => `${adminRoot}/settings`;

export const adminPaths = {
  ...pathBuilder(adminRoot),
  settings,
};

export default pathBuilder(root);
