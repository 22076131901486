import React from 'react';
import { NavLink as Link, useLocation } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { withTheme } from '@mui/styles';

import Tooltip from 'components/v2/FormElements/Tooltip';

export const preferenceTooltip =
  'Please contact your Customer Success Manager via the blue bubble in the bottom right hand corner to learn more';

const buttonStyle = ({ theme }) => ({
  alignItems: 'center',
  font: '300 10px "Roboto", sans-serif',
  width: '100%',
  cursor: 'pointer',
  border: 0,
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  padding: '0.55rem 0 0.3rem',
  transition: 'filter 0.2s ease, background-color 0.2s ease',
  '& img': {
    filter: 'grayscale(100%)',
  },
  '&.nav-active': {
    backgroundColor: theme.palette.background.paper,
    '& img': {
      filter: 'grayscale(0%)',
    },
  },
  '&.enabled:hover': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.secondary,
    '& img': {
      filter: 'grayscale(0%)',
    },
  },
  '&.disabled': {
    color: theme.palette.text.disabled,
    ':hover': {
      cursor: 'not-allowed',
    },
    '& img': {
      opacity: 0.75,
    },
  },
});

const muiIconStyle = {
  height: '1.75rem',
  width: '1.75rem',
  borderRadius: '2rem',
  padding: '3px',
  '@media (min-height: 910px)': {
    height: '2.5rem',
    width: '2.5rem',
    padding: '6px',
  },
};

const StyledLink = styled(Link)(buttonStyle);
const StyledButton = styled('button')(buttonStyle);
const StyledIcon = styled('img')(({ theme }) => ({
  height: '2rem',
  width: '2rem',
  '@media (min-height: 910px)': {
    height: '2.75rem',
    width: '2.75rem',
  },
}));
const StyledLabel = styled('span')(({ theme }) => ({
  fontSize: '0.75rem',
  marginTop: '0.2rem',
  textAlign: 'center',
  '@media (min-height: 910px)': {
    fontSize: '0.75rem',
    marginTop: '0.35rem',
  },
}));

const NavIcon = withTheme(({ icon, theme, isActive }) => {
  if (!icon) return null;

  if (typeof icon === 'string') {
    return <StyledIcon src={icon} alt="Nav Icon" />;
  }

  const activeColor = icon.props.activeColor
    ? theme.palette[icon.props.activeColor].main
    : theme.palette.navbar.activeIcon;
  const backgroundColor = isActive() ? activeColor : theme.palette.navbar.icon;

  // this assumes it's using the a MUI icon since our icons use strings as the source
  return React.cloneElement(icon, {
    lg: 'true',
    sx: {
      ...muiIconStyle,
      backgroundColor,
      color: theme.palette.navbar.main,
    },
  });
});

const NavLink = ({
  allowed = true,
  featureCallout = false,
  icon,
  label,
  activePaths = [],
  onClick,
  to,
}) => {
  const { pathname } = useLocation();
  const isActive = () => [to, ...activePaths].some((path) => pathname.startsWith(path));

  const children = (
    <>
      <NavIcon icon={icon} isActive={isActive} />
      <StyledLabel>{label}</StyledLabel>
    </>
  );

  if (!allowed && featureCallout) {
    return (
      <Tooltip title={preferenceTooltip} placement="right">
        <StyledLink to="#" className="disabled" isActive={() => false}>
          {children}
        </StyledLink>
      </Tooltip>
    );
  }

  if (!allowed) return null;

  // Returns a link if a 'to' prop is provided.  Otherwise, returns a button.
  return to ? (
    <StyledLink activeClassName="nav-active" to={to} isActive={isActive} className="enabled">
      {children}
    </StyledLink>
  ) : (
    <StyledButton onClick={onClick}>{children}</StyledButton>
  );
};

export default NavLink;
