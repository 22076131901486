import React from 'react';
import { connect } from 'react-redux';

// Adapter
import { adaptToForm } from 'adapters/v1/digitalFormRequests/formData';

// Components
import ListItem from 'components/v2/FormElements/ListItem';
import { DigitalFormTemplatesAutocomplete } from 'components/v2/FormElements/Autocomplete';

// FormElements
import TswForm, { useDetailForm, useRequiredFields } from 'components/v2/FormElements';
import DialogButtons from 'components/v2/FormElements/Dialog/Buttons';

// Mui
import List from '@mui/material/List';

const Form = ({
  afterCancel,
  callback,
  debug = false,
  disabled = false,
  isBulk,
  loading = false,
  resource = {},
}) => {
  const validate = useRequiredFields(['template_id']);

  const adapted = adaptToForm(resource);

  const { handleSubmit, handleCancel, getRequiredTextProps, getSelectHandlerProps, formData } =
    useDetailForm({
      validate,
      resource: adapted,
      afterCancel,
      callback,
    });

  return (
    <TswForm>
      {debug && JSON.stringify(formData)}
      <List>
        <ListItem>
          <DigitalFormTemplatesAutocomplete
            {...getSelectHandlerProps('template_id')}
            textFieldProps={{ ...getRequiredTextProps('template_id') }}
            disabled={disabled}
            getOptionDisabled={(option) => option.type === 'Multi-Signer Form' && isBulk}
            groupBy={(option) => option.type}
            label="Digital Form"
            multiple={false}
            value={formData.template_id || {}}
          />
        </ListItem>
      </List>

      <DialogButtons
        onCancel={handleCancel}
        loading={loading}
        onClick={handleSubmit}
        disabled={disabled}
      />
    </TswForm>
  );
};

const mapStateToProps = ({ digitalFormTemplates: { dropdown }, profile }) => {
  const mapped = {};

  Object.entries(dropdown).forEach(([key, value]) => {
    mapped[value.attributes.template_id] = value;
  });

  return { digitalFormTemplates: mapped, profile };
};

export default connect(mapStateToProps)(Form);
