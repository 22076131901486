import Menu from '@mui/material/Menu';

// TODO: copy pasted from MUI example, will clean up later
const popoverStyles = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  mt: 1.5,
  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
};

const PopoverMenu = ({ anchorEl, children, handleClose, onClick, onClose, open }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      id="account-menu"
      onClick={onClick}
      onClose={onClose}
      open={open}
      PaperProps={{
        elevation: 0,
        sx: popoverStyles,
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      {children}
    </Menu>
  );
};

export default PopoverMenu;
