import { connect } from 'react-redux';

// Mui
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

// Components
import LinkMenuItem from './MenuItem';

// Images
import LogoIcon from 'assets/images/logo/logomark.png';
import ComplianceIcon from 'assets/images/logo/compliance.png';
import RiskInsightsIcon from 'assets/images/logo/risk.png';
import VMIcon from 'assets/images/logo/vm.png';
import WorkspaceIcon from 'assets/images/logo/workspace.png';

const appLinks = {
  vm: {
    icon: VMIcon,
    text: 'Visitor Management',
    appHref: 'https://account.tractionguest.com/',
    infoHref: 'https://signinsolutions.com/products/sign-in-enterprise',
  },
  compliance: {
    icon: ComplianceIcon,
    text: 'Compliance Navigator',
    appHref: 'https://app.threatswitch.com/',
    infoHref: 'https://signinsolutions.com/products/sign-in-compliance',
  },
  workspace: {
    icon: WorkspaceIcon,
    text: 'Workspace Operations',
    appHref: 'https://my.pronestorworkspace.com/',
    infoHref: 'https://signinsolutions.com/products/sign-in-workspace',
  },
  riskInsights: {
    icon: RiskInsightsIcon,
    text: 'Risk Insights',
    infoHref: 'https://signinsolutions.com/products/sign-in-compliance', //TODO: Update this link when new marketing page is available
  },
  signInSolutions: {
    icon: LogoIcon,
    text: 'Sign In Solutions',
    infoHref: 'https://signinsolutions.com/',
  },
};

const LinkSectionHeader = ({ text, paddingTop = '1.5rem' }) => (
  <Typography
    variant="subtitle"
    component="h3"
    sx={{
      paddingLeft: 2,
      textTransform: 'uppercase',
      fontSize: '0.75rem',
      fontWeight: 400,
      paddingTop,
    }}
  >
    {text}
  </Typography>
);

const LinkMenu = ({ anchorEl, profile, handleClose, open }) => {
  const { access_vm, access_workspace } = profile?.company || {};

  return (
    <Menu
      anchorEl={anchorEl}
      id="apps-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          width: '15rem',
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <LinkSectionHeader text="Your apps" paddingTop="0.75rem" />

      {access_vm && <LinkMenuItem enabled {...appLinks.vm} />}
      <LinkMenuItem enabled {...appLinks.compliance} />
      {access_workspace && <LinkMenuItem enabled {...appLinks.workspace} />}

      {(access_vm && access_workspace) || <LinkSectionHeader text="Recommended apps" />}

      {!access_vm && <LinkMenuItem {...appLinks.vm} />}
      {!access_workspace && <LinkMenuItem {...appLinks.workspace} />}

      <Divider />

      <LinkSectionHeader text="Learn more" paddingTop="0.75rem" />

      <LinkMenuItem {...appLinks.riskInsights} />
      <LinkMenuItem {...appLinks.signInSolutions} />
    </Menu>
  );
};

export default connect(({ profile }) => ({ profile }))(LinkMenu);
