export const adaptToActiveFilters = (filtersFromStore) => {
  return Object.entries(filtersFromStore)
    .filter(([name, obj]) => obj)
    .map(([name, obj]) => ({ name, value: obj.displayName }));
};

export const adaptToQuery = (activeFilters = {}) => {
  const out = {};

  Object.entries(activeFilters)
    .filter(([key, value]) => value)
    .forEach(([key, obj]) => {
      out[key] = obj.values;
    });

  return out;
};
