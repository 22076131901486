import { deleteCell } from './CellRenderers';

const deleteActionItemProps = {
  field: 'delete',
  headerName: '',
  width: 50,
  disableColumnMenu: true,
  sortable: false,
};

export const deleteActionItem = (props) => {
  return {
    ...deleteActionItemProps,
    renderCell: deleteCell(props),
  };
};
