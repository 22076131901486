// MUI
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Components
import ActivityDrawer from 'layouts/v2/AppBar/ActivityDrawer';
import Apps from 'layouts/v2/AppBar/AppsButton';
import Notify from 'layouts/v2/AppBar/NotifyButton';
import ProfileMenu from 'layouts/v2/AppBar/profileMenu';
import SecurityContact from 'layouts/v2/AppBar/SecurityContact';
import Watermark from 'components/v2/PageElements/Watermark';

// state
import { FULL_LOGO_HEIGHT } from 'components/v2/PageElements/CompanyLogo';
import { mainNavWidth } from 'components/v1/SideNavigation/NavWrapper';
import { withFeatureState } from './state';

const AppBarWrapper = styled(Box)(({ theme }) => ({
  height: FULL_LOGO_HEIGHT - 2,
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  paddingLeft: mainNavWidth,
  paddingRight: theme.spacing(1),
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  background: theme.palette.appbar.main,
  borderBottom: `2px solid ${theme.palette.appbar.border}`,
  '& > *': {
    marginLeft: '40px',
  },
  '& > *:last-child': {
    marginLeft: '-10px',
  },
}));

const AvatarWrapper = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  cursor: 'pointer',
}));

const AppBar = ({ preferences = {} }) => {
  return (
    <AppBarWrapper>
      <SecurityContact />
      <Watermark />
      <Notify />
      <Apps />
      <ActivityDrawer />
      <AvatarWrapper>
        <ProfileMenu />
      </AvatarWrapper>
    </AppBarWrapper>
  );
};

export default withFeatureState(AppBar);
