export const root = '/analytics/dashboards';

const create = `${root}/create`;

const resourceRoot = (id) => {
  return `${root}/${id}`;
};

const resourceCreate = (id) => {
  return `${create}/${id}`;
};

const paths = {
  root: root,
  list: root,
  create,
  resource: {
    root: resourceRoot(':id'),
    edit: resourceRoot,
    detail: resourceRoot,
    create: resourceCreate,
  },
};

export default paths;
