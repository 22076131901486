const constants = {
  IMPORT_PENDING: 'IMPORT_PENDING',
  IMPORT_SUCCESS: 'IMPORT_SUCCESS',
  IMPORT_FAILURE: 'IMPORT_FAILURE',
  UPLOAD_PENDING: 'UPLOAD_PENDING',
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  DIFF_PENDING: 'DIFF_PENDING',
  DIFF_SUCCESS: 'DIFF_SUCCESS',
  JPAS_USERS_PENDING: 'JPAS_USERS_PENDING',
  JPAS_USERS_SUCCESS: 'JPAS_USERS_SUCCESS',
  JPAS_UPDATE_USERS_PENDING: 'JPAS_UPDATE_USERS_PENDING',
  JPAS_UPDATE_USERS_SUCCESS: 'JPAS_UPDATE_USERS_SUCCESS',
  REFRESH_PENDING: 'REFRESH_PENDING',
  REFRESH_SUCCESS: 'REFRESH_SUCCESS',
  RESET_IMPORT: 'RESET_IMPORT',
  CHANGE_NEW: 'CHANGE_NEW',
  CHANGE_UPDATES: 'CHANGE_UPDATES',
};

export default constants;
