import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';

// PageElements
import Route, { Switch } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths from 'config/paths/dashboard';

// Components
const Dashboard = lazy(() => import('./index'));

export const Routes = ({ paths, permissions }) => {
  return (
    <Suspense fallback={<></>}>
      <Switch>
        <Route permission={true} exact path={paths.root}>
          <Dashboard paths={paths} />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default connect(mapPermissionsToProps, null)((props) => <Routes paths={paths} {...props} />);
