import React from 'react';
import { connect } from 'react-redux';
import RowActionDropdownItem from './RowActionDropdownItem';

// FormElements
import { useDialog } from 'components/v2/FormElements/Dialog';
import { useForm } from 'components/v2/FormElements';
import ConfirmModal from 'components/v2/FormElements/ConfirmModal';

// actions
import tagActions from 'store/v2/tags/actions';

import Autocomplete, {
  selectable,
  getOptionSelected,
} from 'components/v2/FormElements/Autocomplete';

export const Redirect = ({ text = 'View', pathAction = 'detail' } = {}) => {
  return ({ history, rowItem, paths, ...rest }) => {
    const {
      row: { original },
    } = rowItem;

    const handleClick = () => history.push(paths.resource[pathAction](original.id));

    const itemProps = {
      text: text,
      key: `${text}-${rest.id}`,
      handleClick,
      ...rest,
    };

    return <RowActionDropdownItem {...itemProps} />;
  };
};

export const edit = ({ adapter = null, DialogComponent }) => {
  return ({ rowItem, ...rest }) => {
    const { onClick, onClose, open } = useDialog();
    const {
      row: { original },
    } = rowItem;

    const resource = adapter ? adapter(original) : original;

    const dialogProps = {
      open,
      resource,
      onClose,
    };

    const itemProps = {
      text: 'Edit',
      key: `Edit-${rest.id}`,
      handleClick: onClick,
      ...rest,
    };

    return (
      <>
        <RowActionDropdownItem {...itemProps} />
        <DialogComponent {...dialogProps} isEdit={true} />
      </>
    );
  };
};

export const clone = ({ adapter = null, DialogComponent, ignoreProps = [] }) => {
  return ({ rowItem, ...rest }) => {
    const { onClick, onClose, open } = useDialog();
    const {
      row: { original },
    } = rowItem;

    const resource = adapter ? adapter(original) : original;

    ignoreProps.forEach((prop) => delete resource[prop]);

    const dialogProps = {
      open,
      resource,
      onClose,
      title: 'Clone',
      clickText: 'Create',
    };

    const itemProps = {
      text: 'Clone',
      key: `Clone-${rest.id}`,
      handleClick: onClick,
      ...rest,
    };

    return (
      <>
        <RowActionDropdownItem {...itemProps} />
        <DialogComponent {...dialogProps} isEdit={true} />
      </>
    );
  };
};

// TODO! Delete this
const _Tags = ({
  action = 'Tags',
  dropdown,
  resourceableType = 'Contract',
  bulkCreateTags,
  update,
  rowItem,
  ...rest
}) => {
  const { onClick, onClose, open } = useDialog();

  const {
    row: { original },
  } = rowItem;

  const { attachments } = original;

  const getTitle = (id) => {
    const value = dropdown[id];
    if (value && value.name) return value.name;
    return null;
  };

  const selectValue =
    attachments && attachments.length
      ? attachments
          .filter((obj) => obj.attachable_type === 'Tag::Name')
          .map((obj) => ({
            id: obj.id,
            label: obj.attachable_id,
            title: getTitle(obj.attachable_id),
          }))
          .filter((obj) => obj.title)
      : [];

  const resource = { tags: selectValue };
  const { getInlineCreateSelectHandlerProps, formData, setFormData } = useForm({ resource });
  const options = selectable({ data: dropdown });

  const adaptToTagAttachment = (obj) => {
    if (obj._destroy) return obj;
    return {
      resourceable_id: original.id,
      resourceable_type: resourceableType,
      attachable_type: 'Tag::Name',
      attachable_id: obj.label,
    };
  };

  const compareIterables = (attachments = [], formIterable = []) => {
    const newAttachments = [];
    const toDelete = [];
    const existingAttachments = {};

    // We are storing any type/id combination that is present in the formData iterable
    // because it indicates the presence of an existing attachment type (i.e. the id attribute)
    if (formIterable) {
      formIterable.forEach((obj) => {
        // If there is an ID it is by definition not a new attachment, i.e. it has been created
        if (obj.id && obj.attachable_type) {
          existingAttachments[obj.attachable_type] = {
            ...existingAttachments[obj.attachable_type],
            [obj.id]: true,
          };
        } else {
          newAttachments.push(obj);
        }
      });
    }

    // For any given existing attachment if the ID is NOT present on the `existingAttachments`
    // lookup object, it means that the attachment has been removed and should be deleted.
    if (attachments) {
      attachments.forEach((obj) => {
        const type = existingAttachments[obj.attachable_type];
        if (!type || (type && !type[obj.id])) {
          toDelete.push({ id: obj.id, _destroy: true });
        }
      });
    }

    return [...newAttachments, ...toDelete];
  };

  const onConfirm = async (e, props) => {
    if (!original || !original.id) return;
    if (e && e.preventDefault) e.preventDefault();

    //  don't love tying these to type checks, but it's the easiest way for now
    const existingTags = formData.tags.filter((t) => typeof t.label === 'number');
    let newTags = formData.tags.filter((t) => typeof t.label === 'string').map((t) => t.label);

    if (newTags.length) {
      const newTagsResp = await bulkCreateTags({ tags: newTags }, true);

      // TODO: since the silent arg stops the messaging, might want to still have messaging in case of error
      if (!newTagsResp.success) return;

      delete newTagsResp.success;
      newTags = Object.values(newTagsResp).map((t) => {
        return { label: t.id };
      });
    }

    const formIterable = [...existingTags, ...newTags].map((t) => {
      return {
        id: t.id,
        label: t.label,
        attachable_type: 'Tag::Name',
      };
    });

    const adapted = compareIterables(attachments, formIterable).map(adaptToTagAttachment);
    if (adapted && adapted.length) {
      const resp = await update(original.id, { attachments_attributes: adapted });
      if (resp.success) {
        onClose();
      }
    }
  };

  // FIX HOOK
  React.useEffect(() => {
    setFormData(resource);
  }, [open, resource.attachments]); // eslint-disable-line

  const itemProps = {
    text: action,
    key: `${action}-${rest.id}`,
    handleClick: onClick,
    ...rest,
  };

  const modalProps = {
    title: 'Edit Tags',
    isOpen: open,
    onConfirm,
    confirmText: 'Save',
    onClose,
  };

  return (
    <>
      <RowActionDropdownItem {...itemProps} />
      <ConfirmModal {...modalProps}>
        <Autocomplete
          value={formData.tags || []}
          getOptionSelected={getOptionSelected}
          options={options}
          {...getInlineCreateSelectHandlerProps('tags')}
        />
      </ConfirmModal>
    </>
  );
};

// Documents is the only thing using this
export const Tags = connect(({ tagsV2: { dropdown } }) => ({ dropdown }), {
  bulkCreateTags: tagActions.bulkCreate,
})(_Tags);

export const Delete = ({
  action = 'Delete',
  confirmText = action,
  textFunc,
  destroy,
  rowItem,
  ...rest
}) => {
  const { onClick, onClose, open } = useDialog();

  const {
    value,
    row: { original },
  } = rowItem;

  const onDelete = async (e) => {
    if (e) e.preventDefault();
    if (!original || !original.id) return;
    const resp = await destroy(original.id);
    if (!resp.success) return;
    onClose();
  };

  const itemProps = {
    text: action,
    key: `${action}-${rest.id}`,
    handleClick: onClick,
    ...rest,
  };

  const record = value ? value : 'this record';
  const message = textFunc
    ? textFunc(value)
    : `Are you sure you want to ${action.toLowerCase()} ${record}?`;

  const modalProps = {
    title: value ? `${action} '${value}'` : action,
    isOpen: open,
    onConfirm: onDelete,
    confirmText,
    onClose,
    message,
  };

  return (
    <>
      <RowActionDropdownItem {...itemProps} />
      <ConfirmModal {...modalProps} />
    </>
  );
};

// TODO: This needs to be ironed out, I'm certain this can be simplified though I suspect we still want to be able
// to have access to the "original" value so we can customize content more readily.  I'm a bit fuzzy today so I'm
// going to punt on this until integrations etc.
export const ConfirmationAction = ({
  action = 'Unarchive',
  onConfirm = () => {},
  message,
  unarchive,
  rowItem,
  ...rest
}) => {
  const { onClick, onClose, open } = useDialog();
  const { value } = rowItem;

  const handleOnConfirm = () => {
    if (!onConfirm) onClose();

    const close_dialog = onConfirm();
    if (close_dialog) onClose();
  };

  const itemProps = {
    text: action,
    key: `${action}-${rest.id}`,
    handleClick: onClick,
    ...rest,
  };

  const modalProps = {
    title: value ? `${action} '${value}'` : action,
    isOpen: open,
    onConfirm: handleOnConfirm,
    confirmText: action,
    onClose,
    message,
  };

  return (
    <>
      <RowActionDropdownItem {...itemProps} />
      <ConfirmModal {...modalProps} />
    </>
  );
};

export const Unarchive = ({ action = 'Unarchive', textFunc, unarchive, rowItem, ...rest }) => {
  const { onClick, onClose, open } = useDialog();
  const {
    value,
    row: { original },
  } = rowItem;

  const onUnarchive = async (e) => {
    if (e) e.preventDefault();
    if (!original || !original.id) return;
    const resp = await unarchive(original.id);
    if (!resp.success) return;
    onClose();
  };

  const itemProps = {
    text: action,
    key: `${action}-${rest.id}`,
    handleClick: onClick,
    ...rest,
  };

  const record = value ? value : 'this record?';
  const message = textFunc
    ? textFunc(value)
    : `Are you sure you want to ${action.toLowerCase()} ${record}?`;

  const modalProps = {
    title: value ? `${action} '${value}'` : action,
    isOpen: open,
    onConfirm: onUnarchive,
    confirmText: action,
    onClose,
    message,
  };

  return (
    <>
      <RowActionDropdownItem {...itemProps} />
      <ConfirmModal {...modalProps} />
    </>
  );
};

export const Archive = (props) => <Delete action="Archive" {...props} />;

export default RowActionDropdownItem;
