import React from 'react';

// MUI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

// components
import ActiveFilters from 'components/v2/Filters/ActiveFilters';
import FilterButton from './FilterButton';
import ReportButton from './ReportButton';
import Search from './Search';

const MainBar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: 5,
});

const SearchWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginRight: 'auto',
});

// https://mui.com/components/progress/#customization
// TODO: if we like this, abstract it from here and replace all vanilla CircularProgress in app
const StyledCircularProgress = (props) => {
  return (
    <Box sx={{ position: 'relative', marginLeft: '10px' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={30}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: 'info',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={30}
        thickness={4}
        {...props}
      />
    </Box>
  );
};

const SearchAndFilterLayout = ({ SearchComponent, searchProps, ...rest }) => {
  return (
    <>
      <MainBar>
        <SearchWrapper>
          {SearchComponent ? <SearchComponent {...searchProps} /> : <Search {...rest} />}
          <ReportButton {...rest} />
          <FilterButton {...rest} />
          <ActiveFilters {...rest} />
        </SearchWrapper>
        {rest.loading && <StyledCircularProgress />}
      </MainBar>
    </>
  );
};

export default SearchAndFilterLayout;
