import trackers from 'lib/v1/tracking';

const middleware = (store) => (next) => (action) => {
  if (!action) return;
  if (!action.type) return;

  const arr = action.type.split('_');
  const verb = arr[0];
  const result = arr[arr.length - 1];

  // Track only ADD, CREATE, UPDATE, and DELETE actions
  if (
    (verb === 'ADD' || verb === 'CREATE' || verb === 'UPDATE' || verb === 'DELETE') &&
    (result === 'SUCCESS' || result === 'ERROR')
  ) {
    try {
      const obj = {
        name: action.type.toLowerCase().split('_').join(' '),
        details: strip(action.payload || {}),
      };
      trackers.intercom.track(obj);
    } catch (e) {
      console.error('Could not track action: ' + e); // eslint-disable-line
    }
  }
  return next(action);
};

function strip(payload) {
  if (Array.isArray(payload)) {
    if (payload.length === 0) {
      return;
    }
    return payload.map(strip).reduce((d) => Object.keys(d).length !== 0);
  } else {
    return pick(payload, ['name', 'contract_name', 'legal_name']);
  }
}

function pick(o, props) {
  const obj = {};
  props.forEach((prop) => {
    if (o[prop] !== null && o[prop] !== undefined) {
      obj[prop] = o[prop];
    }
  });
  return obj;
}

export default middleware;
