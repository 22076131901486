import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';
import { mapTableToPropsFactory, mergePropsToTable } from 'mappers/v1/table';

// actions
import actions from 'store/v2/digitalFormTemplates/actions';
import paths from 'config/paths/digitalFormTemplates';
import { add_message } from 'store/v1/app/actions';

// adapters
import {
  cellLinkAdapter,
  cellAdapter,
  adaptToDetail,
} from 'adapters/v1/digitalFormTemplates/formData';

export const stateBuilder = (paramId = 'id', featureReduxKey = 'digitalFormTemplates') => {
  const mapDataToTable = mapTableToPropsFactory(featureReduxKey);

  const mapResourceToProps = (store, ownProps) => {
    const reduxKey = store[featureReduxKey];
    // Map resource from store, we've wrapped the component
    // in withRouter so match is Present.
    const { match, resource } = ownProps;
    const resourceId =
      match && match.params ? Number(match.params[paramId]) : resource && resource.id;
    return { resourceId, resource: adaptToDetail(reduxKey.data[resourceId]) };
  };

  const mapStateToProps = ({ profile, people, ...rest }) => {
    const { digitalFormTemplates } = rest;
    // Generically map activity from any v2 store key in redux
    const { activity, loading } = rest[featureReduxKey] || {};

    return {
      activity: Object.values(activity || {}),
      users: people.dropdown,
      loading,
      digitalFormTemplates,
      ...mapPreferencesToProps({ profile }),
      ...mapPermissionsToProps({ profile }),
    };
  };

  const include = { include: 'comments,tag_names' };

  const mapDispatchToProps = {
    ...actions,
    list: (queryParams) =>
      actions.list({
        sort: 'created_at',
        dir: 'desc',
        ...queryParams,
        include: 'tag_names',
      }),
    show: (id, payload, silent) => actions.show(id, { ...payload, ...include }, silent),
    update: (id, payload, silent) => actions.update(id, { ...payload, ...include }, silent),
    add_message,
  };

  const withPaths = (Component) => (props) => <Component paths={paths} {...props} />;

  return {
    withTableState: (Component) => {
      return connect(
        (store) => ({ ...mapPermissionsToProps(store), ...mapDataToTable(store) }),
        mapDispatchToProps,
        mergePropsToTable
      )((props) => (
        <Component
          {...props}
          cellLinkAdapter={cellLinkAdapter}
          cellAdapter={cellAdapter}
          paths={paths}
        />
      ));
    },
    withFeatureState: (Component) => {
      return withRouter(connect(mapStateToProps, mapDispatchToProps)(withPaths(Component)));
    },
    withResourceState: (Component) => {
      return withRouter(
        connect(
          (store, ownProps) => ({
            ...mapStateToProps(store),
            ...mapResourceToProps(store, ownProps),
          }),
          mapDispatchToProps
        )(withPaths(Component))
      );
    },
  };
};

// by encapsulating all of our HOCs into a single function definition, I can cleanly swap out minimal state to reuse components across routes.
// This is literally a function, that returns an object, that contains 3 higher order components composed of 1 or more higher order components..
export const { withFeatureState, withResourceState, withTableState } = stateBuilder();
