import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import AppBar from 'layouts/v2/AppBar';
import SideNavigation from './SideNavigation';

import { FULL_LOGO_HEIGHT } from 'components/v2/PageElements/CompanyLogo';

import { withFeatureState } from './state';

const AppWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100%',
  display: 'flex',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  width: '100%',
  position: 'relative',
}));

const DashboardLayout = ({ children, preferences }) => {
  const contentPaddingTop = `${FULL_LOGO_HEIGHT - 15}px`;

  return (
    <AppWrapper>
      <AppBar />
      <SideNavigation />
      <ContentWrapper sx={{ paddingTop: contentPaddingTop }}>{children}</ContentWrapper>
    </AppWrapper>
  );
};

export default withFeatureState(DashboardLayout);
