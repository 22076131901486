import { statePath } from './constants'

const index = (state = { [statePath]: 0 }, { type }) => {
  if (type.endsWith('_SUCCESS')) {
    return {
      ...state,
      [statePath]: state[statePath] + 1
    }
  }
  return state
}

export default index
