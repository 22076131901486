import { styled } from '@mui/material/styles';
import Icon from 'components/v1/Icon';

export const StyledHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const StyledIcon = styled(Icon)({
  width: '2rem',
  margin: '0 1.25rem',
});

export const StyledCustomIcon = styled('img')({
  width: '2rem',
  margin: '0 1.25rem',
});

export const StyledUpdatedCustomIcon = styled('img')({
  width: '2.5rem',
  margin: '0 1.25rem',
});

export const StyledTitle = styled('h2')({
  fontSize: '1.1rem',
  fontWeight: 'normal',
  margin: '0',
  padding: '0',
  flexGrow: 1,
});

export const StyledBody = styled('div')(({ active }) => ({
  fontSize: '0.875rem',
  height: 0,
  padding: 0,
  margin: '0 2rem',
  overflow: 'hidden',
  p: {
    fontSize: '0.875rem',
    lineHeight: '1.1rem',
  },
  ...(active && { height: 'auto', marginTop: '0.75rem' }),
}));
