const updateCallsTracker = (payload) => {
  return (dispatch) => {
    dispatch({
      type: 'TRACK_DROPDOWN_CALL',
      payload,
    });
  };
};

const actions = {
  updateCallsTracker,
};

export default actions;
