import { createActions } from 'redux-actions';

import request from 'lib/v1/request';
import handleError from 'lib/v1/error-handling';
import CONST from './constants';

const { getTypesPending, getTypesSuccess } = createActions(
  CONST.GET_TYPES_PENDING,
  CONST.GET_TYPES_SUCCESS,
  CONST.GET_TYPES_FAILURE
);

export const getType = (type, params) => {
  const query = { sort: 'code', ...params };
  return request
    .get(`/${type}`)
    .query(query)
    .then((res) => res.body.data)
    .catch(handleError);
};

export const getTypes = () => {
  return (dispatch) => {
    dispatch(getTypesPending());
    return request
      .get('/types')
      .query({})
      .then((resp) => {
        dispatch(getTypesSuccess(resp.body.data));
      })
      .catch(handleError);
  };
};
