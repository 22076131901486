import React from 'react';

// FlexTable API
import Search from 'components/v2/FlexTable/Search';
import TableAPI from 'components/v2/FlexTable/Table';
import Pagination from 'components/v2/FlexTable/Pagination';
import AddFilter from 'components/v2/Filters';
import ActiveFilters from 'components/v2/Filters/ActiveFilters';
import filters from 'components/v2/Filters/Contracts';

export const Table = ({
  list,
  permissions,
  updateQuery,
  paths,
  cellAdapter,
  cellLinkAdapter,
  resourceName = 'Contracts',
  schema,
  ...rest
}) => {
  // Table expects a value of name and email for any adapted personnel
  // resource
  const filterOptions = () => ({
    filterOptions: filters({
      custom_fields: Object.keys(schema?.properties || {}),
      schema,
    }).map((option) => option({ updateQuery })),
  });

  const resourcePathFunc = (props = {}) => {
    if (!paths) return;
    return paths.resource.detail(props.id);
  };

  const columns = () => {
    const primaryColumn = {
      Header: 'Contract Name',
      accessor: 'contract_name',
      permission: true,
    };

    if (cellLinkAdapter && permissions && permissions.contracts.view) {
      primaryColumn.Cell = cellLinkAdapter('contract_name', resourcePathFunc);
    }

    const secondaryColumn = {
      Header: 'Contract Number',
      accessor: 'contract_number',
      permission: true,
    };

    if (cellAdapter) {
      secondaryColumn.Cell = cellAdapter('contract_number');
    }

    const data = [primaryColumn, secondaryColumn];
    return { columns: data.filter((column) => column.permission) };
  };

  const listActionProps = () => {
    return { list };
  };

  return (
    <>
      <Search {...rest} {...listActionProps()} />
      <AddFilter {...rest} {...filterOptions()} />
      <ActiveFilters {...rest} />
      <TableAPI {...columns()} {...rest} {...listActionProps()} />
      <Pagination {...rest} {...listActionProps()} />
    </>
  );
};

export default Table;
