import React from 'react';

// MUI
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { defaultFullScreenLimit, fullScreenLimitMap } from 'components/v2/FormElements/Dialog';
import Loader from 'components/v1/Loader';

import { styled } from '@mui/material/styles';

import { useWindowDimensions } from 'lib/v1/hooks';

const StyledDialog = styled((props) => <Dialog {...props} />)(({ theme, signature }) => ({
  '& .MuiDialog-paper': signature && {
    height: '90%',
    width: '80%',
    maxHeight: 'none',
    maxWidth: 'none',
  },
  '& .MuiDialogContent-root': signature && {
    height: 'calc(100% - 8em)',
  },
  '& .MuiDialogActions-root': signature && {
    padding: '8px 16px 16px',
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme, signature }) => ({
  background: theme.palette.background.paper,
  height: signature && '90%',
}));

export const useDialog = ({ isOpen = false } = {}) => {
  const [open, setOpen] = React.useState(isOpen);
  const onClick = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    if (e && e.stopPropagation) e.stopPropagation();
    setOpen((prev) => !prev);
  };

  const onClose = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    onClick();
  };

  return {
    onClick,
    onClose,
    open,
  };
};

const TswDialog = ({
  loading = false,
  onClose,
  title = 'Title',
  helpLink,
  children,
  open,
  actions,
  maxWidth = 'md',
  fullWidth = true,
  fullScreenLimit,
  signature = false,
  ...rest
}) => {
  const { width: screenWidth } = useWindowDimensions();

  const effectiveFullScreenLimit =
    fullScreenLimitMap[maxWidth] || fullScreenLimit || defaultFullScreenLimit;
  const fullScreen = rest.fullScreen || screenWidth < effectiveFullScreenLimit;

  const dialogProps = {
    onClose,
    open,
    fullWidth,
    maxWidth,
    fullScreen,
    scroll: 'body',
    signature,
  };
  const closeIcon = !actions;
  return (
    <StyledDialog {...dialogProps}>
      <DialogTitle>
        {title}
        {onClose && closeIcon ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {loading && <Loader />}
      {!loading && <StyledDialogContent>{children}</StyledDialogContent>}
      <DialogActions>{actions || <div />}</DialogActions>
    </StyledDialog>
  );
};

export default TswDialog;
