import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import {
  cellAdapterFactory,
  cellLinkAdapterFactory,
  chipCellAdapterFactory,
} from 'adapters/v2/factories';
import { adaptToJsonApiRequest, adaptJsonApiToDetail } from 'adapters/v1/json_api';
import { adaptSelectToRequest } from 'adapters/v1/index';

import { adaptToDetail as adaptTravelToDetail } from 'adapters/v1/travels/formData';
import { adaptToDetail as adaptCardToDetail } from 'adapters/v1/cards/formData';

const dateFields = ['updated_at', 'created_at', 'due_date', 'completed_at'];
const singleSelectFields = ['template_id'];

const parseAttachmentType = (type) => {
  switch (type) {
    case 'Card':
      return 'Ticket';
    case 'CardTemplate':
      return 'Workflow';
    default:
      return type;
  }
};

export const adaptToBulkAssignForm = (formData) => {
  const { signer_roles, attached_digital_form_id, ...rest } = formData;
  const adapted = adaptToIso(rest, dateFields);

  singleSelectFields.forEach((key) => {
    adapted[key] = adaptSelectToRequest(adapted ? adapted[key] : {});
  });

  if (attached_digital_form_id) {
    adapted.attached_digital_form_id = attached_digital_form_id;
  }

  // This is again hard coded for a single signer
  adapted.signer_role = signer_roles && signer_roles[0] ? signer_roles[0].name : null;

  return adapted;
};

export const adaptToRequest = (formData) => {
  const adapted = adaptToIso(formData, dateFields);

  const { signer_roles } = adapted;
  const lookup = {};

  Object.entries(adapted).forEach(([key, selectValue]) => {
    if (key.startsWith('user_id.') && selectValue && selectValue.label) {
      const role = key.replace('user_id.', '');
      lookup[role] = { role, id: parseInt(selectValue.label) };
    }
  });

  adapted.signers = (signer_roles || [])
    .map((obj) => {
      const value = lookup[obj.name];
      if (value) return { ...value };
      return null;
    })
    .filter((obj) => obj);

  singleSelectFields.forEach((key) => {
    adapted[key] = adaptSelectToRequest(adapted ? adapted[key] : {});
  });

  const { template_id, due_date, signers, send_notification_email, attached_digital_form_id } =
    adapted;

  return adaptToJsonApiRequest({
    attributes: {
      template_id,
      due_date,
      signers,
      send_notification_email,
      attached_digital_form_id,
      user_id: signers && signers[0] ? signers[0].id : null,
    },
    type: 'digital_form',
  });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const updated = adaptToDisplay(adaptJsonApiToDetail(resource), dateFields);
  const { tag_names } = updated;

  updated.subtitle = `${updated.status} - ${
    updated.completed_at
      ? `${updated.completed_at}`
      : updated.due_date
      ? `Due: ${updated.due_date}`
      : 'No due date'
  }`;

  updated.user_subtitle = `Requested: ${updated.created_at}`;
  updated.tag_array = tag_names && Array.isArray(tag_names) ? tag_names.map((obj) => obj.name) : [];

  updated.user_name = updated.user ? updated.user.display_name : '';
  updated.form_name = updated.display_name;

  updated.sign_count = `${updated.signer_submitted_count}/${updated.signer_count}`;

  updated.attachment_type_display = parseAttachmentType(updated.attachment_type);

  if (updated.attachment_type) {
    if (!updated.attachment_owner) return (updated.attachment_details = []);
    const { attachment_owner } = updated;

    if (updated.attachment_type === 'Card') {
      const adapted = attachment_owner && adaptCardToDetail(attachment_owner?.data);
      const { active_status, created_at, name, submitted_at } = adapted;

      updated.attachment_details = [
        name,
        active_status,
        `Created: ${created_at}`,
        `Submitted: ${submitted_at || 'No'}`,
      ];
    }

    if (updated.attachment_type === 'Travel') {
      const adapted = attachment_owner && adaptTravelToDetail(attachment_owner?.data);
      const { status, trip_departure_date, trip_return_date, travel_type } = adapted;

      updated.attachment_details = [
        travel_type,
        status,
        trip_departure_date && `Departure: ${trip_departure_date}`,
        trip_return_date && `Arrival: ${trip_return_date}`,
      ];
    }
  }

  return updated;
};

export const adaptToForm = (resource) => {
  if (!resource) return {};
  return { ...resource };
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
export const chipCellAdapter = chipCellAdapterFactory(adaptToDetail);
