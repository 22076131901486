import React from 'react';

import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const Message = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[200],
  fontSize: '2.5rem',
  textAlign: 'center',
  paddingBottom: '2rem',
}));

const ZeroState = ({ numCols = 4 }) => {
  return (
    <tr>
      <td colSpan={numCols}>
        <Message variant="h2">No data found</Message>
      </td>
    </tr>
  );
};

export default ZeroState;
