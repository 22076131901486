import { list } from 'store/v2/actions';
import actionType, { apiPath } from './constants';

const actions = {
  assignable: (queryParams) => {
    return list(actionType, `${apiPath}/assignable`, { sort: 'last_name', ...queryParams });
  },
};

export default actions;
