const constants = {
  GET_DOCUMENTS_PENDING: 'GET_DOCUMENTS_PENDING',
  GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',
  GET_DOCUMENTS_FAILURE: 'GET_DOCUMENTS_FAILURE',
  GET_USER_DOCUMENTS_PENDING: 'GET_USER_DOCUMENTS_PENDING',
  GET_USER_DOCUMENTS_SUCCESS: 'GET_USER_DOCUMENTS_SUCCESS',
  GET_USER_DOCUMENTS_FAILURE: 'GET_USER_DOCUMENTS_FAILURE',
  GET_DOCUMENTABLE_PENDING: 'GET_DOCUMENTABLE_PENDING',
  GET_DOCUMENTABLE_SUCCESS: 'GET_DOCUMENTABLE_SUCCESS',
  GET_DOCUMENTABLE_FAILURE: 'GET_DOCUMENTABLE_FAILURE',
  CREATE_DOCUMENT_PENDING: 'CREATE_DOCUMENT_PENDING',
  CREATE_DOCUMENT_SUCCESS: 'CREATE_DOCUMENT_SUCCESS',
  CREATE_DOCUMENT_FAILURE: 'CREATE_DOCUMENT_FAILURE',
  UPDATE_DOCUMENT_PENDING: 'UPDATE_DOCUMENT_PENDING',
  UPDATE_DOCUMENT_SUCCESS: 'UPDATE_DOCUMENT_SUCCESS',
  UPDATE_DOCUMENT_FAILURE: 'UPDATE_DOCUMENT_FAILURE',
  DELETE_DOCUMENTS_PENDING: 'DELETE_DOCUMENTS_PENDING',
  DELETE_DOCUMENTS_SUCCESS: 'DELETE_DOCUMENTS_SUCCESS',
  DELETE_DOCUMENTS_FAILURE: 'DELETE_DOCUMENTS_FAILURE',
  DELETE_DOCUMENT_PENDING: 'DELETE_DOCUMENT_PENDING',
  DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_FAILURE: 'DELETE_DOCUMENT_FAILURE',
  DOWNLOAD_DOCUMENT_PENDING: 'DOWNLOAD_DOCUMENT_PENDING',
  DOWNLOAD_DOCUMENT_SUCCESS: 'DOWNLOAD_DOCUMENT_SUCCESS',
  DOWNLOAD_DOCUMENT_FAILURE: 'DOWNLOAD_DOCUMENT_FAILURE',
  DOCUMENTS_CHANGE_PAGE: 'DOCUMENTS_CHANGE_PAGE',
  DOCUMENTS_RESET_PAGING: 'DOCUMENTS_RESET_PAGING',
};

export default constants;
