import { isAnyAdmin, isAdmin, isEmployee } from 'permissions/v1/roles';
import { noPermissions } from 'permissions/v1/index';
import { hasForms } from 'permissions/v1/preferences';

const list = (profile) => isAdmin(profile) || isEmployee(profile);
const view = (profile) => isAnyAdmin(profile);
const create = (profile) => isAnyAdmin(profile);
const update = (profile) => isAnyAdmin(profile);
const destroy = (profile) => isAnyAdmin(profile);

const permissions = (profile) => {
  if (!hasForms(profile)) return noPermissions;

  return {
    list: list(profile),
    view: view(profile),
    create: create(profile),
    update: update(profile),
    destroy: destroy(profile),
  };
};

export default permissions;
