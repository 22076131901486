import React from 'react';
import { connect } from 'react-redux';

// MUI
import { makeStyles } from '@mui/styles';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

// actions
import actions from 'store/v2/comments/actions';

// adapters
import { adaptComments, adaptToRequest } from 'adapters/v1/comments/formData';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

// components
import { Meta, Body } from './Comment';
import Composer from './Composer';
import Avatar from '../Avatar';

// PageElements
import SidebarHeader from 'components/v2/PageElements/Sidebar/Header';
import SidebarList from 'components/v2/PageElements/Sidebar/List';
import SidebarListItem from 'components/v2/PageElements/Sidebar/ListItem';

// FormElements
import Button from 'components/v2/FormElements/Button';

export const styles = {
  comment: {
    marginBottom: 0,
    padding: 0,
    alignItems: 'end',
    '&:hover .commentTools': {
      display: 'block',
    },
  },
  avatar: {
    marginBottom: '1rem',
    marginTop: 0,
    minWidth: 'auto',
    '&.self': {
      // self styles
    },
    '@media (max-width: 1400px)': {
      display: 'none',
    },
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

const useStyles = makeStyles((theme) => styles);

const CommentListItem = connect(null, { ...actions })(
  ({ comment, className, destroy, removeComment, profile = {}, preferences } = {}) => {
    const classes = useStyles();
    let {
      created_by = {},
      admin_only,
      createDateTime,
      description,
      user_id,
      name,
      avatar_url,
    } = comment;

    if (user_id) {
      created_by = {
        id: user_id,
        name,
        avatar_url,
      };
    }

    const isSelf = (user_id || created_by.id) === profile.id;
    const canDelete = profile.role !== 'employee' || isSelf;

    const onDelete = async () => {
      const resp = await destroy(comment.id);
      resp.success && removeComment && removeComment(comment.id);
    };

    const renderAvatar = () => {
      return (
        <ListItemAvatar className={`${classes.avatar} ${isSelf ? 'self' : ''}`}>
          <Avatar sm profile={created_by} className={classes.avatar} rightAlign={false} />
        </ListItemAvatar>
      );
    };

    return (
      <SidebarListItem className={`${className} ${classes.comment}`}>
        {renderAvatar()}
        <ListItemText
          primary={
            <Meta
              adminOnly={admin_only}
              createDateTime={createDateTime}
              description={description}
              onDelete={onDelete}
              canDelete={canDelete}
              preferences={preferences}
              {...created_by}
            />
          }
          secondary={<Body description={description} isSelf={isSelf} adminOnly={admin_only} />}
        />
      </SidebarListItem>
    );
  }
);

const CommentList = ({
  data = [],
  users,
  showAll = false,
  limit = 5,
  profile,
  removeComment,
  preferences,
}) => {
  const adaptedData = adaptComments(data);
  const visibleData = showAll ? adaptedData : adaptedData.slice(0, limit);

  return (
    <>
      {visibleData.map((comment) => (
        <CommentListItem
          users={users}
          key={`comment-${comment.id}`}
          comment={comment}
          profile={profile}
          preferences={preferences}
          removeComment={removeComment}
        />
      ))}
    </>
  );
};

const Comments = ({
  comments = [],
  preferences = {},
  commentable_type,
  commentable_id,
  profile,
  users,
  create,
  updateQuery,
  loading,
  jsonApi = false,
  limit = 5,
  defaultAdminOnlyNewComments,
}) => {
  const [showAll, setShowAll] = React.useState(false);
  const arrayLength = Array.isArray(comments) && comments.length;
  const showButton = arrayLength > limit;

  const mapRelationships = (payload) => {
    if (!jsonApi) return payload;

    payload.relationships = {
      comments: {
        data: payload.comments,
        meta: {
          data: 'array',
          type: 'comment',
        },
      },
    };
    return payload;
  };

  const removeComment = (destroyedId) => {
    const payload = {
      id: String(commentable_id),
      comments: [...comments.filter(({ id }) => id !== destroyedId)],
    };

    updateQuery({ payload: mapRelationships(payload) });
  };

  const callback = async (formData) => {
    if (!formData || !create) return;
    const adapted = adaptToRequest({ ...formData, commentable_type, commentable_id });
    const resp = await create(adapted, true);
    if (!resp.success) return;

    const payload = {
      id: String(commentable_id),
      comments: [...comments, resp],
    };
    updateQuery({ payload: mapRelationships(payload) });
  };

  const tooltip =
    profile.role === 'employee'
      ? 'Have a question or concern for your Security Team? Use the comments section below.'
      : 'Need to communicate with your Employees and/or the Security Team? Use the comments section below.';

  const formProps = {
    callback,
    loading,
    profile,
    defaultAdminOnlyNewComments,
    label: comments.length ? 'Add Reply' : 'Add Comment',
  };

  const commentsProps = {
    data: comments,
    users,
    showAll,
    limit,
    profile,
    removeComment,
    preferences,
  };

  return (
    <div className="commentsModule">
      <SidebarHeader tooltip={tooltip}>Comments</SidebarHeader>
      <SidebarList>
        <Composer {...formProps} />
        <CommentList {...commentsProps} />
      </SidebarList>
      {showButton && (
        <Button
          component="span"
          color="primary"
          variant="text"
          disableRipple
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? `Only ${limit} newest comments` : `All ${arrayLength} comments`}
        </Button>
      )}
    </div>
  );
};

const mapStateToProps = ({ profile, comments: { loading } }) => ({
  profile,
  loading,
  ...mapPreferencesToProps({ profile }),
});
// we can't grab all of the actions because it will overwrite the updateQuery from the parent
export default connect(mapStateToProps, { create: actions.create })(Comments);
