import { isEmployee, isAnyAdmin, isCustomRole } from 'permissions/v1/roles';
import {
  canList,
  canView,
  canCreate,
  canUpdate,
  canDestroy,
  noPermissions,
} from 'permissions/v1/index';
import { hasReportables } from 'permissions/v1/preferences';

const permission = 'Incident';

const list = (profile) => isAnyAdmin(profile) || canList(permission, profile);
const view = (profile) => isAnyAdmin(profile) || canView(permission, profile);
const create = (profile) =>
  isAnyAdmin(profile) ||
  isEmployee(profile) ||
  isCustomRole(profile) ||
  canCreate(permission, profile);
const update = (profile) => isAnyAdmin(profile) || canUpdate(permission, profile);
const destroy = (profile) => isAnyAdmin(profile) || canDestroy(permission, profile);

const permissions = (profile) => {
  if (!hasReportables(profile)) return noPermissions;

  return {
    list: list(profile),
    view: view(profile),
    create: create(profile),
    update: update(profile),
    destroy: destroy(profile),
  };
};

export default permissions;
