import store from 'store';
import PropTypes from 'prop-types';

// adapters
import selectable from 'adapters/v1/selectable';

// factories
import { SelectFactory } from 'components/v2/Filters/Factories';

//////////////////////
// MultiSelect Filter /form base component.
//////////////////////

const MultiSelect = ({
  name,
  displayName,
  updateQuery,
  mapStateToSelect = () => {},
  mapDispatchToSelect = {},
  multi = true,
  ...rest
}) => {
  const onSubmit = (options) => {
    const label = options.label || options.map((option) => option.label).join(', ');
    const _displayName = `${displayName}: ${label}`;

    const values = options.label ? [options.value] : options.map((option) => option.value);

    store.dispatch(
      updateQuery({
        filters: {
          [name]: { values, displayName: _displayName },
        },
      })
    );
  };

  const FilterForm = SelectFactory({ mapStateToSelect, mapDispatchToSelect, multi });
  const formProps = { ...rest, onSubmit, displayName };
  return <FilterForm {...formProps} />;
};

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  updateQuery: PropTypes.func.isRequired,
  mapStateToSelect: PropTypes.func,
  mapDispatchToSelect: PropTypes.func,
};

export const SingleSelect = ({
  name,
  displayName,
  updateQuery,
  mapStateToSelect = () => {},
  mapDispatchToSelect = {},
  multi = false,
  ...rest
}) => {
  const onSubmit = (options) => {
    const _displayName = `${displayName}: ${options.label}`;

    store.dispatch(
      updateQuery({
        filters: {
          [name]: { values: options.value, displayName: _displayName },
        },
      })
    );
  };

  const FilterForm = SelectFactory({ mapStateToSelect, mapDispatchToSelect, multi });
  const formProps = { ...rest, onSubmit, displayName };
  return <FilterForm {...formProps} />;
};

export const BuildBasicSelectFilter = ({ displayName, name, options }) => {
  return ({ updateQuery }) => {
    const mapStateToSelect = () => {
      return {
        options,
        isLoading: false,
        disabled: false,
      };
    };

    const selectProps = {
      name,
      displayName,
      updateQuery,
      mapStateToSelect,
    };

    return {
      Component: (props) => <SingleSelect {...selectProps} {...props} />,
      label: displayName,
      value: name,
    };
  };
};

export const BuildBinarySelectFilter = (props) =>
  BuildBasicSelectFilter({
    ...props,
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
  });

export const BuildBasicMultiSelectFilter = ({ name, displayName, options, multi }) => {
  return ({ updateQuery }) => {
    const defaultBooleanOptions = [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ];
    const mapStateToSelect = () => {
      return {
        options: options || defaultBooleanOptions,
        isLoading: false,
        disabled: false,
      };
    };

    const multiSelectProps = {
      name,
      displayName,
      updateQuery,
      mapStateToSelect,
      multi: multi === undefined ? options !== undefined : multi,
    };

    return {
      Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
      label: displayName,
      value: name,
    };
  };
};

//////////////////////
// Specialized/connected MultiSelect filters
//////////////////////

export const ByFacility = ({
  fieldName = 'facility_id',
  displayName = 'CAGE Code',
  id = 'id',
  updateQuery,
}) => {
  const mapStateToSelect = ({ facilitiesV2 }) => {
    const { loading } = facilitiesV2;
    return {
      options: selectable(facilitiesV2, {
        id: id,
        value: 'display_name',
        key: 'dropdown',
      }),
      isLoading: loading,
      disabled: loading,
    };
  };

  const multiSelectProps = {
    name: fieldName,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
    icon: 'calendar',
  };
};

export const ByUser = ({ fieldName = 'user_id', displayName = 'User', updateQuery }) => {
  const mapStateToSelect = ({ users }) => {
    const { loading } = users;
    return {
      options: selectable(users),
      isLoading: loading,
      disabled: loading,
    };
  };

  const multiSelectProps = {
    name: fieldName,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
    icon: 'calendar',
  };
};

export const ByCardTemplate = ({
  fieldName = 'card_template_id',
  displayName = 'Request Type',
  updateQuery,
}) => {
  const mapStateToSelect = ({ cardTemplates }) => {
    const { loading } = cardTemplates;
    return {
      options: selectable(cardTemplates, { key: 'dropdown' }),
      isLoading: loading,
      disabled: loading,
    };
  };

  const multiSelectProps = {
    name: fieldName,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
    icon: 'calendar',
  };
};

export const ByTag = ({ fieldName = 'tag_name_id', displayName = 'Tags', updateQuery }) => {
  const mapStateToSelect = ({ tagNames }) => {
    const { loading } = tagNames;
    return {
      options: selectable(tagNames, { key: 'dropdown' }),
      isLoading: loading,
      disabled: loading,
    };
  };

  const multiSelectProps = {
    name: fieldName,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
    icon: 'calendar',
  };
};

export const ByMeta = ({ fieldName, displayName, updateQuery, storeKey, metaKey }) => {
  const mapStateToSelect = (store) => {
    const attributes = store?.[storeKey]?.meta?.attributes?.values;
    const options = attributes?.[metaKey]?.data?.map((value) => ({ label: value, value }));

    return {
      options: options || [],
      isLoading: false,
      disabled: false,
    };
  };

  const multiSelectProps = {
    name: fieldName,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
  };
};

export default MultiSelect;
