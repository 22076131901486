import React from 'react';

import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import { cellAdapterFactory, cellLinkAdapterFactory } from 'adapters/v2/factories';
import format from 'lib/v1/format';
import { getUserDetails } from 'lib/v1/users';

import DownloadLink from 'components/v1/DownloadLink';

import { adaptTagsToForm } from 'adapters/v1';

const dateFields = ['created_at', 'updated_at'];

export const adaptDocumentTagsToRequest = (formData) => {
  const { tags } = formData;

  const adapted = {
    tags: tags.map((t) => t.label),
  };
  return adapted;
};

export const adaptToRequest = (formData) => {
  let out = { ...formData };

  if (formData.facility) {
    if (formData.facility.label === 'all') {
      out.facility_id = null;
    } else {
      out.facility_id = formData.facility.label;
    }
    delete out.facility;
  }

  return adaptToIso(out, dateFields);
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const adapted = adaptToDisplay({ ...resource }, dateFields);

  adapted.relativeCreatedAt = format.relativeTime(adapted.created_at);
  adapted.relativeUpdatedAt = format.relativeTime(adapted.updated_at);
  if (resource.user_id) {
    const creator = getUserDetails(resource.user_id);
    if (creator) adapted.relativeCreatedAt += ` by ${creator.name}`;
  }

  adapted.tag_array =
    adapted.tag_names && Array.isArray(adapted.tag_names) ? adapted.tag_names : [];

  return adapted;
};

// the incoming data will be the output of adaptToDetail
export const adaptToForm = (resource) => {
  if (!resource || !resource.id) return {};

  const out = { ...resource };
  const { tags } = adaptTagsToForm({ tag_names: resource.tag_names });

  if (resource.facility) {
    const { display_name, id } = resource.facility;
    out.facility = { label: id, title: display_name };
  }

  return { ...out, tags };
};

export const cellDownloadLinkAdapter = () => {
  return ({ row }) => {
    const obj = row ? row.original : {};

    return <DownloadLink {...obj} />;
  };
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
