import request from 'lib/v2/request';

import handleError from 'lib/v1/error-handling';
import { createActions } from 'redux-actions';
import { INBOX_ACTIONS } from './constants';

const { inboxFetchedPending, inboxFetchedSuccess, inboxFetchedFailure } = createActions(
  INBOX_ACTIONS.INBOX_FETCHED_PENDING,
  INBOX_ACTIONS.INBOX_FETCHED_SUCCESS,
  INBOX_ACTIONS.INBOX_FETCHED_FAILURE
);

const { updateInboxPending, updateInboxSuccess } = createActions(
  INBOX_ACTIONS.UPDATE_INBOX_PENDING,
  INBOX_ACTIONS.UPDATE_INBOX_SUCCESS,
  INBOX_ACTIONS.UPDATE_INBOX_FAILURE
);

export const getInbox = () => {
  return (dispatch) => {
    dispatch(inboxFetchedPending());
    return request
      .get('/v2/notifications')
      .then((res) => {
        return dispatch(inboxFetchedSuccess(res.body));
      })
      .catch((res) => {
        dispatch(inboxFetchedFailure(res));
        handleError(res);
      });
  };
};

export const updateInbox = (message) => {
  return (dispatch) => {
    dispatch(updateInboxPending());
    return request
      .put(`/v2/notifications/${message.id}/message`)
      .then((res) => dispatch(updateInboxSuccess({ ...message })))
      .catch(handleError);
  };
};
