import React from 'react';

import Menu from './Menu';

// MUI
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

import icons from 'lib/v2/icons';

// Components
import Avatar from 'components/v2/PageElements/Avatar';

// paths
import documentsPaths from 'config/paths/documents';
import peoplePaths from 'config/paths/people';
import { userPathsBuilder as userTravelsPathsBuilder } from 'config/paths/userTravels';
import { userPathsBuilder as userDigitalFormsPathsBuilder } from 'config/paths/userDigitalForms';
import { userPathsBuilder as userTasksPathsBuilder } from 'config/paths/tasks';
import { basePath as userAssetsRoot } from 'config/paths/userAssetControls';
import { basePath as userBadgesRoot } from 'config/paths/userBadgeControls';
// import { userPathsBuilder as userReportablesPathsBuilder } from 'config/paths/reportables';
import { userPathsBuilder as userVisitRequestsPathsBuilder } from 'config/paths/visitRequests';

import { withFeatureState } from './state';

const ProfileMenu = ({ history, permissions, profile, preferences, setThemeMode, theme }) => {
  // TODO: coped from MUI example, may refactor later
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const user = {
    ...profile,
    name: profile.name || `${profile.first_name} ${profile.last_name}` || '',
  };

  const onItemClick = (path) => {
    handleClose();
    history.push(path);
  };

  const ItemIcon = ({ Icon }) => {
    return (
      <ListItemIcon>
        <Icon fontSize="small" />
      </ListItemIcon>
    );
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Avatar xl dark evenMargins profile={user} />
      </IconButton>

      <Menu anchorEl={anchorEl} onClick={handleClose} onClose={handleClose} open={open}>
        <MenuItem onClick={() => onItemClick(peoplePaths.resource.detail(profile.id))}>
          <ItemIcon Icon={icons.user} />
          Profile
        </MenuItem>

        <MenuItem onClick={() => onItemClick(userDigitalFormsPathsBuilder(profile.id).root)}>
          <ItemIcon Icon={icons.forms} />
          Forms
        </MenuItem>

        {permissions.tasks.list && (
          <MenuItem onClick={() => onItemClick(userTasksPathsBuilder(profile.id).root)}>
            <ItemIcon Icon={icons.tasks} />
            Tasks
          </MenuItem>
        )}

        {preferences.travels && (
          <MenuItem onClick={() => onItemClick(userTravelsPathsBuilder(profile.id).root)}>
            <ItemIcon Icon={icons.travel} />
            Travels
          </MenuItem>
        )}

        <Divider />

        {permissions.actionRequests.list && (
          <MenuItem onClick={() => onItemClick(peoplePaths.resource.actionRequests(profile.id))}>
            <ItemIcon Icon={icons.actionRequests} />
            Action Requests
          </MenuItem>
        )}

        {/* <MenuItem onClick={() => onItemClick(userReportablesPathsBuilder(profile.id).root)}>
          <ItemIcon Icon={icons.reportables} />
          Reportable Info
        </MenuItem> */}

        <MenuItem onClick={() => onItemClick(userVisitRequestsPathsBuilder(profile.id).root)}>
          <ItemIcon Icon={icons.visits} />
          Visit Requests
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={() => onItemClick(peoplePaths.resource.accessesEligibilities(profile.id))}
        >
          <ItemIcon Icon={icons.accesses} />
          Accesses and Eligibilities
        </MenuItem>

        <MenuItem onClick={() => onItemClick(userAssetsRoot(profile.id))}>
          <ItemIcon Icon={icons.assets} />
          Assets
        </MenuItem>

        <MenuItem onClick={() => onItemClick(userBadgesRoot(profile.id))}>
          <ItemIcon Icon={icons.badges} />
          Badges
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={() =>
            onItemClick(permissions.admin.list ? documentsPaths.root : '/employee/documents')
          }
        >
          <ItemIcon Icon={icons.files} />
          Company Files
        </MenuItem>

        <Divider />

        {preferences.betaTester && theme !== 'original' && (
          <MenuItem onClick={() => setThemeMode('original')}>
            <ItemIcon Icon={BookmarkIcon} />
            Set Original Theme
          </MenuItem>
        )}
        {preferences.betaTester && theme !== 'light' && (
          <MenuItem onClick={() => setThemeMode('light')}>
            <ItemIcon Icon={LightModeIcon} />
            Set Light Theme
          </MenuItem>
        )}
        {preferences.betaTester && theme !== 'dark' && (
          <MenuItem onClick={() => setThemeMode('dark')}>
            <ItemIcon Icon={DarkModeIcon} />
            Set Dark Theme
          </MenuItem>
        )}

        {preferences.betaTester && <Divider />}

        <MenuItem onClick={() => onItemClick('/logout')}>
          <ItemIcon Icon={icons.logout} />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default withFeatureState(ProfileMenu);
