import React from 'react';
import { connect } from 'react-redux';

import { adaptToAssignableDetail } from 'adapters/v1/people/formData';
import actions from 'store/v2/assignableUsers/actions';

import DataTable from 'components/v2/PageElements/DataTable';
import { avatarCellRender } from 'components/v2/PageElements/DataTable/CellRenderers';
import SearchLayout from 'components/v2/FlexTable/SearchLayout';
import userFilters from 'components/v2/Filters/Users';

const UsersDialogDataTable = ({ assignableUsers = [], query, onSelection, loading, ...rest }) => {
  const [selectedIds, setSelectedIds] = React.useState([]);

  const adapted = assignableUsers.map((user) => adaptToAssignableDetail(user));
  const columns = [
    { field: 'avatar', headerName: '', renderCell: avatarCellRender, width: 50 },
    { field: 'display_name', headerName: 'Name', flex: 1 },
    { field: 'role_name', headerName: 'Role', flex: 0.75 },
    { field: 'email', headerName: 'Email', flex: 1 },
  ];

  const tableProps = {
    columns,
    defaultSort: { field: 'display_name', sort: 'asc' },
    disableColumnFilter: true,
    loading,
    rows: adapted,
    checkboxSelection: true,
    rowSelectionModel: selectedIds,
    handleRowSelectionChange: setSelectedIds,
    pageSizeOptions: [10, 20, 50, 100, 250],
    query,
    selectedIds,
    ...rest,
  };

  React.useEffect(() => {
    // faking the flextable v2 row objects for now so adapters work without updates
    const selection = {
      selectedFlatRows: selectedIds.map((id) => ({ original: { id } })),
    };
    onSelection(selection);
  }, [onSelection, selectedIds]);

  const filterOptions = () => ({
    filterOptions: userFilters.map((option) => option(rest)),
  });

  const searchProps = {
    filters: filterOptions(),
    query,
    ...rest,
  };

  return (
    <>
      <SearchLayout {...searchProps} />
      <DataTable {...tableProps} />
    </>
  );
};

export default connect(
  (store) => {
    const { loading, data } = store['assignableUsers'] || {};
    return {
      assignableUsers: data && Object.values(data),
      loading,
    };
  },
  {
    list: (queryParams) =>
      actions.assignable({
        ...queryParams,
        filters: { active: [true], ...(queryParams.filters || {}) },
      }),
  }
)(UsersDialogDataTable);
