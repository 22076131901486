import React from 'react';
import store from 'store';
import PropTypes from 'prop-types';
import { displayDateFormat, defaultMoment as moment } from 'config';

import { DateRangeFactory } from 'components/v2/Filters/Factories';

export const dateRangeLabel = ({ displayName, range = [] } = {}) => {
  if (!displayName || !range || !Array.isArray(range)) return { displayName: '' };

  const displayRange = range
    .map((date) => moment(date, moment.ISO_8601).format(displayDateFormat))
    .join(' to ');

  const _displayName = `${displayName}: ${displayRange}`;
  return { displayName: _displayName };
};

const DateRangeFilter = ({ name, displayName, updateQuery, ...rest }) => {
  const onSubmit = (range) => {
    range = [
      moment(range[0]).startOf('day').toISOString(),
      moment(range[1]).endOf('day').toISOString(),
    ];

    store.dispatch(
      updateQuery({
        filters: { [name]: { values: range, ...dateRangeLabel({ displayName, range }) } },
      })
    );
  };

  const FilterForm = DateRangeFactory();
  const formProps = { ...rest, onSubmit, displayName };

  return <FilterForm {...formProps} />;
};

export const BuildDateRangeFilter = ({ name, displayName }) => {
  return ({ updateQuery }) => {
    const dateRangeProps = {
      name,
      displayName,
      updateQuery,
    };

    return {
      Component: (props) => <DateRangeFilter {...dateRangeProps} {...props} />,
      label: displayName,
      value: name,
      icon: 'calendar',
    };
  };
};

export const ByUpdatedDate = BuildDateRangeFilter({
  name: 'updated_at',
  displayName: 'Updated Date',
});

export const ByCreatedDate = BuildDateRangeFilter({
  name: 'created_at',
  displayName: 'Created Date',
});

DateRangeFilter.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  updateQuery: PropTypes.func.isRequired,
};

export default DateRangeFilter;
