import { isAnyAdmin } from 'permissions/v1/roles';

import {
  noPermissions,
  canList,
  canView,
  canCreate,
  canUpdate,
  canDestroy,
} from 'permissions/v1/index';
import { hasRequirements } from 'permissions/v1/preferences';

const permission = 'Requirement';

const list = (profile) => isAnyAdmin(profile) || canList(permission, profile);
const view = (profile) => isAnyAdmin(profile) || canView(permission, profile);
const create = (profile) => isAnyAdmin(profile) || canCreate(permission, profile);
const update = (profile) => isAnyAdmin(profile) || canUpdate(permission, profile);
const destroy = (profile) => isAnyAdmin(profile) || canDestroy(permission, profile);

const permissions = (profile) => {
  if (hasRequirements(profile)) {
    return {
      list: list(profile),
      view: view(profile),
      create: create(profile),
      update: update(profile),
      destroy: destroy(profile),
    };
  }

  return noPermissions;
};

export default permissions;
