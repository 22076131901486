import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { logout } from 'store/v1/authentication/actions';
import { withStyles } from '@mui/styles';
import { summaryStyles } from './styles';

const mapStateToProps = ({ profile, people }) => {
  const peopleLoading = people.loading;
  return {
    profile,
    peopleLoading,
    title: profile && profile.company && profile.company.name,
    ...mapPermissionsToProps({ profile }),
  };
};

const mapDispatchToProps = { logout };

export const withSummaryStyles = (Component) => withStyles(summaryStyles)(Component);

const Index = (Component) =>
  withRouter(connect(mapStateToProps, mapDispatchToProps)(withSummaryStyles(Component)));

export default Index;
