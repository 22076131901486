import React from 'react';
import { Route, Switch as RouterSwitch, Redirect } from 'react-router-dom';
import ErrorPage from 'components/v1/ErrorPage';
import MissingFeaturePage from 'components/v1/MissingFeaturePage';

import { dateRangeLabel } from 'components/v2/Filters/DateRange';

export const useRoutingFilters = ({ location, updateQuery }) => {
  const activeFilters = location && location.state && location.state.activeFilters;

  // FIX HOOK
  React.useEffect(() => {
    if (!activeFilters || !updateQuery || !Array.isArray(activeFilters)) return;

    const filters = {};

    activeFilters.forEach((obj) => {
      const { type, values, name, displayName, options } = obj;
      if (!type || (!values && !options) || !name || !displayName) return;

      switch (type) {
        case 'date_range':
          filters[name] = {
            values,
            ...dateRangeLabel({ displayName, range: values }),
          };
          break;
        case 'multi_select':
          const _values = options ? options.map((obj) => obj.value) : values;
          const _labels = options ? options.map((obj) => obj.label) : values;
          const _displayName = `${displayName} - ${_labels.join(', ')}`;

          filters[name] = { values: _values, displayName: _displayName };
          break;
        default:
          break;
      }
    });
    updateQuery({
      filters,
    });
  }, []); // eslint-disable-line
};

// This is the most minimal implementation of a permissioned route that I can come up with
// It implements one new prop (defaults to false) and then just wraps react-router's Route component. That's it
const TswRoute = ({ redirect, permission = false, preference, ...rest }) => {
  if (preference === false) return redirect ? <Redirect to={redirect} /> : <MissingFeaturePage />;
  if (!permission && !preference) return redirect ? <Redirect to={redirect} /> : <ErrorPage />;
  return <Route {...rest} />;
};

export const Switch = ({ children, ...rest }) => {
  return (
    <RouterSwitch {...rest}>
      {children}
      <TswRoute path="*" />
    </RouterSwitch>
  );
};

export default TswRoute;
