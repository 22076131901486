import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapTableToPropsFactory, mergePropsToTable } from 'mappers/v1/table';

// actions
import actions from 'store/v2/activity/actions';
import paths from 'config/paths/activity';

// adapters
import { cellLinkAdapter, cellAdapter, adaptToDetail } from 'adapters/v1/activity/formData';

export const stateBuilder = (paramId = 'id', featureReduxKey = 'activity') => {
  const mapDataToTable = mapTableToPropsFactory(featureReduxKey);

  const mapResourceToProps = (store, ownProps) => {
    const reduxKey = store[featureReduxKey];
    // Map resource from store, we've wrapped the component
    // in withRouter so match is Present.
    const { match, resource } = ownProps;
    const resourceId =
      match && match.params ? Number(match.params[paramId]) : resource && resource.id;
    return { resourceId, resource: adaptToDetail(reduxKey.data[resourceId]) };
  };

  const mapStateToProps = ({ profile, people, ...rest }) => {
    // Generically map activity from any v2 store key in redux
    const { activity, loading } = rest[featureReduxKey] || {};

    return {
      activity: Object.values(activity || {}),
      users: people.dropdown,
      loading,
      ...mapPermissionsToProps({ profile }),
    };
  };

  const mapDispatchToProps = {
    ...actions,
    list: (queryParams) =>
      actions.list({
        sort: 'created_at',
        dir: 'desc',
        ...queryParams,
        include: 'user',
      }),
  };

  const withPaths = (Component) => (props) => <Component paths={paths} {...props} />;

  return {
    withTableState: (Component) => {
      return connect(
        (store) => ({ ...mapPermissionsToProps(store), ...mapDataToTable(store) }),
        mapDispatchToProps,
        mergePropsToTable
      )(
        withPaths((props) => (
          <Component {...props} cellLinkAdapter={cellLinkAdapter} cellAdapter={cellAdapter} />
        ))
      );
    },
    withFeatureState: (Component) => {
      return connect(
        mapStateToProps,
        mapDispatchToProps
      )(withRouter(Component));
    },
    withResourceState: (Component) => {
      return connect(
        (store, ownProps) => ({
          ...mapStateToProps(store),
          ...mapResourceToProps(store, ownProps),
        }),
        mapDispatchToProps
      )(withRouter(withPaths(Component)));
    },
  };
};

// by encapsulating all of our HOCs into a single function definition, I can cleanly swap out minimal state to reuse components across routes.
// This is literally a function, that returns an object, that contains 3 higher order components composed of 1 or more higher order components..
export const { withFeatureState, withResourceState, withTableState } = stateBuilder();
