import { list, create, update, destroy, show } from 'store/v2/actions';
import actionType, { apiPath } from './constants';

// Import as an object manages a lot of the details of importUnits
// the additional special actions are defined here because it is the import reducer that gets returned in the resulting request.
import { basePath } from 'store/v2/importUnits/actions';

// Generic resource Crud
// While this is a bit redundant, I prefer explicit definitions for core actions over dynamically created ones as they are easier to read/reason about.
export const listResources = (queryParams) => {
  return list(actionType, apiPath, queryParams);
};

export const showResource = (id) => {
  return show(actionType, `${apiPath}/${id}`);
};

export const destroyResource = (id) => {
  return destroy(actionType, `${apiPath}/${id}`);
};

export const updateResource = (id, payload = {}) => {
  return update(actionType, `${apiPath}/${id}`, payload);
};

export const createResource = (payload = {}) => {
  return create(actionType, apiPath, payload);
};

// Unique actions - Atypical actions specific to the workflow of the import wizard

// Sync import - Necessary for ensuring mapping values from wizard are propagated to all required records. Different from updateResource which only impacts the parent Import record.
export const syncImport = (id, payload = {}) => {
  return update(actionType, `${basePath(id)}/sync`, payload);
};

// Pull import metadata, i.e. counts of different kinds of import data, New, Error, Update
export const updateImportMeta = (id, payload) => {
  return update(actionType, `${basePath(id)}/meta`, { ...payload });
};

// Complete - Run all imports on ImportUnit data, update Import object and return
export const completeImport = (id, payload) => {
  return create(actionType, `${basePath(id)}/complete`, { ...payload });
};

export const refreshImport = (id) => {
  return destroy(actionType, `${basePath(id)}/refresh`);
};

export const uploadImportUnits = (id, payload) => {
  return create(actionType, basePath(id), payload);
};

// Generic names are easier for testing/reuuse
// Specific names are better for readibility and identification
export const listImports = listResources;
export const showImport = showResource;
export const destroyImport = destroyResource;
export const updateImport = updateResource;
export const createImport = createResource;

// This is experimental, given we are just mapping these to props
// it is much simpler to just define a default import as an object and map everything to state by feature set.
const actions = {
  list: listResources,
  show: showResource,
  create: createResource,
  destroy: destroyResource,
  update: updateResource,
  refresh: refreshImport,
  complete: completeImport,
  sync: syncImport,
  upload: uploadImportUnits,
  meta: updateImportMeta,
};

export default actions;
