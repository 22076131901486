import actionBuilder, { copy } from 'store/v2/actions';
import actionType, { bulkActionType, apiPath } from './constants';

const copyTemplate = (payload, silent = false) => {
  return copy(`${actionType}_COPY`, `${apiPath}/${payload?.data?.id}/copy`, { ...payload, silent });
};

const actions = {
  ...actionBuilder({ apiPath, bulkActionType, actionType }),
  copyTemplate,
};

export default actions;
