const flashMessage = (state, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return {
        id: action.id,
        message_type: action.message_type,
        messages: action.messages,
        seen: false,
      };
    default:
      return state;
  }
};

const initialState = {
  messages: [],
  silent: false,
};

const flashMessages = (state = initialState, action) => {
  const { silent } = state;
  switch (action.type) {
    case 'LOGOUT_PENDING':
      // Temporarily disable flash messages during logout
      return {
        ...state,
        silent: true,
      };
    case 'LOGOUT_FAILURE':
      // Re-enable flash messages if logout failed
      return {
        ...state,
        silent: false,
      };
    case 'LOGIN_PENDING':
      // Re-enable flash messages if logging back in
      return {
        ...state,
        silent: false,
      };
    case 'LOGIN_FAILURE':
      // Re-enable flash messages if logging back in
      return {
        ...state,
        silent: false,
      };
    case 'ADD_MESSAGE':
      return silent
        ? state
        : {
            ...state,
            messages: [flashMessage(undefined, action)],
          };
    case 'CLEAR_MESSAGES':
      return {
        ...state,
        messages: state.messages.filter((m) => m.message_type !== action.message_type),
      };
    case 'CLEAR_ALL_MESSAGES':
      return {
        ...state,
        messages: [],
      };
    default:
      return state;
  }
};

export default flashMessages;
