export const hasCompanyPreference = (name, profile) => {
  if (!name || !profile) return false;
  const { company } = profile;

  if (!company) return false;
  const { preference } = company;

  return company[name] || (preference && preference[name]) || false;
};

export const hasUserPreference = (name, profile) => {
  if (!name || !profile) return false;
  const { preference } = profile;

  if (!preference) return false;

  return preference[name] || false;
};

export const hasBetaTester = (profile) => {
  return hasCompanyPreference('beta_tester', profile);
};

export const hasTasks = (profile) => {
  return hasCompanyPreference('tasks_enable', profile);
};

export const hasWorkflows = (profile) => {
  return hasCompanyPreference('workflows_enable', profile);
};

export const hasCustomFields = (profile) => {
  return hasCompanyPreference('custom_fields_enable', profile);
};

export const hasRequirements = (profile) => {
  return hasCompanyPreference('requirements_enable', profile);
};

export const hasTravels = (profile) => {
  return hasCompanyPreference('travels_enable', profile);
};

export const hasVisits = (profile) => {
  return hasCompanyPreference('visits_enable', profile);
};

export const hasCustomRole = (profile) => {
  return hasCompanyPreference('custom_role_enable', profile);
};

export const hasContainers = (profile) => {
  return hasCompanyPreference('containers_enable', profile);
};

export const hasImports = (profile) => {
  return hasCompanyPreference('imports_enable', profile);
};

export const hasFedRamp = (profile) => {
  return hasCompanyPreference('fedramp_enable', profile);
};

export const hasForms = (profile) => {
  return hasCompanyPreference('forms_enable', profile);
};

export const hasLanes = (profile) => {
  return hasCompanyPreference('lanes_enable', profile);
};

export const hasLibrary = (profile) => {
  return hasCompanyPreference('library_enable', profile);
};

export const hasActionRequests = (profile) => {
  // if (hasWorkflows(profile)) return false;
  return hasCompanyPreference('action_requests_enable', profile);
};

export const hasTrainings = (profile) => {
  return hasCompanyPreference('trainings_enable', profile);
};

export const hasAssets = (profile) => {
  return hasCompanyPreference('assets_enable', profile);
};

export const hasBadges = (profile) => {
  return hasCompanyPreference('badges_enable', profile);
};

export const hasReportables = (profile) => {
  return hasCompanyPreference('reportables_enable', profile);
};

export const hasAccesses = (profile) => {
  return hasCompanyPreference('accesses_enable', profile);
};

export const hasRiskChecks = (profile) => {
  return hasCompanyPreference('risk_checks_enable', profile);
};

export const hasClientCredentials = (profile) => {
  // NOTE: should we check the company preference as well, or assume if the user
  // has the preference the company does as well
  //
  // Note: I switched it to company only for now, we can add user later.
  return hasCompanyPreference('client_credentials_enable', profile);
};

export const hasWorkday = (profile) => {
  return hasCompanyPreference('workday_integration_enable', profile);
};
