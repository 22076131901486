import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import BasicLayout from 'components/v2/BasicLayout';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import tswMark from 'assets/images/logo/logomark.svg';
import tswLogo from 'assets/images/logo/logotype.png';
import LoginForm from './Form';
import PasswordForm from '../Password/Form';
import { useForm } from 'components/v2/FormElements';
import TextField from 'components/v2/FormElements/TextField';
import { SubmitButton } from 'components/v2/FormElements/Buttons';

import { settings, changeAccount } from 'store/v1/authentication/actions';

const TopLoader = () => {
  const styles = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 5,
  };

  return <LinearProgress style={styles} />;
};

const Index = ({ settings, changeAccount, match, ...rest }) => {
  const [accountEmail, setAccountEmail] = React.useState();
  const [authConfig, setAuthConfig] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [resetPasswordMode, setResetPasswordMode] = React.useState(
    match && match.path.includes('/reset')
  );

  let params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasErrors = queryParams.has('errors');

  const fetchData = async (formData) => {
    setLoading(true);

    // TODO: this should probably fail more gracefully with failed requests, etc
    const responses = await settings(formData);

    // Check if we have any auto-redirect SSO configs
    responses.forEach((response) => {
      const sso_auto_redirect = response?.value?.body?.data?.sso_auto_redirect;
      const sso_url = response?.value?.body?.data?.sso_url;
      if (!hasErrors && sso_auto_redirect) window.location.href = sso_url;
    });

    const finishedResponses = responses.map(({ value }) => value);
    const possibleValidResponses = finishedResponses.filter(({ body }) => {
      if (!body) return false;

      // users not in the system will have a message to contact admins
      // onboarding means the user is in the system but has not completed the first login
      if (body.data.onboarding) return true;
      return !body.data.message;
    });

    const response = possibleValidResponses[0] || finishedResponses[0];
    if (!response?.body?.data) return setLoading(false);

    const authServer = new URL(response.req.url).origin;
    window.localStorage.setItem('auth_host', authServer);

    const { data } = response.body;

    setAuthConfig(data);
    setAccountEmail(data.email);
    window.localStorage.setItem('auth_email', data.email);
    if (data.logo) window.localStorage.setItem('company_logo', data.logo);
    else window.localStorage.removeItem('company_logo');
    setLoading(false);
  };

  // FIX HOOK
  React.useEffect(() => {
    if (resetPasswordMode) {
      let { resetEmail } = params;
      if (resetEmail) fetchData({ email: atob(resetEmail) });
      else {
        window.localStorage.removeItem('auth_host');
        window.localStorage.removeItem('auth_email');
      }
    } else {
      // If email is available as a query param, auto submit the form
      // This is useful for things like SIS Gateway auto-redirect, etc.
      // Otherwise fallback to local storage, if available
      const email = queryParams.get('email') || window.localStorage.getItem('auth_email');
      if (email) fetchData({ email });
    }
  }, []); // eslint-disable-line

  const onClick = async (e) => {
    if (e && e.preventDefault) e.preventDefault();
    if (e && e.stopPropagation) e.stopPropagation();
    fetchData(formData);
  };

  const handleCancel = async () => {
    setLoading(true);

    // I think this just cleared the cookie Jon was using and I can probably remove at some point
    changeAccount();

    window.localStorage.removeItem('auth_host');
    window.localStorage.removeItem('auth_email');
    setAccountEmail(null);
    setResetPasswordMode(false);
    setAuthConfig({});
    setLoading(false);
  };

  const handlePasswordReset = () => {
    setResetPasswordMode(true);
  };

  const formProps = {
    accountEmail,
    authConfig,
    loading,
    setLoading,
    handleCancel,
    handlePasswordReset,
    resetPasswordMode,
  };

  const cachedLogo = window.localStorage.getItem('company_logo');
  const logo = cachedLogo || tswLogo;

  const { getTextFieldHandlerProps, formData } = useForm({
    resource: { email: accountEmail },
  });

  const firstPasswordNeedsSet = authConfig && authConfig.onboarding && !authConfig.sso;
  const showPasswordReset = firstPasswordNeedsSet || resetPasswordMode;

  return (
    <>
      {loading && <TopLoader />}
      <BasicLayout>
        <Container maxWidth="sm" sx={{ p: 0 }}>
          <Container style={{ margin: '4rem 0rem 2rem', textAlign: 'center' }}>
            <img src={logo} alt="logo" style={{ maxHeight: 300, maxWidth: 300 }} />
          </Container>
          <Paper sx={{ p: 5 }}>
            {accountEmail && (
              <Container style={{ padding: 0 }}>
                {!showPasswordReset && (
                  <>
                    <Typography variant="h5" align="center" sx={{ p: 0, mb: '3rem' }}>
                      Welcome back!
                    </Typography>
                    <LoginForm {...formProps} />
                  </>
                )}
                {showPasswordReset && <PasswordForm {...formProps} />}
              </Container>
            )}

            {!accountEmail && (
              <>
                {!loading && (
                  <form onSubmit={onClick}>
                    <TextField
                      {...getTextFieldHandlerProps('email')}
                      label="Email Address"
                      type="email"
                      autoComplete="username"
                      fullWidth
                    />

                    <SubmitButton
                      onClick={onClick}
                      fullWidth
                      startIcon={null}
                      style={{ fontSize: 20, marginTop: '1rem' }}
                      disabled={loading}
                    >
                      Next
                    </SubmitButton>
                  </form>
                )}
              </>
            )}
          </Paper>

          <Typography
            variant="body1"
            as="span"
            align="center"
            sx={{ p: 0, pt: 3, display: 'block' }}
          >
            Powered by
            <img
              src={tswMark}
              alt="logo"
              style={{ height: 35, position: 'relative', top: '10px', margin: '0 4px 0 8px' }}
            />
            Sign In Solutions
          </Typography>
        </Container>
      </BasicLayout>
    </>
  );
};

export default connect(null, { settings, changeAccount })(Index);
