import React from 'react';

const Notification = ({ width = '64px', height = '64px', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 64 64" {...rest}>
    <path
      d="M56,33c0-0.018-0.002-0.033-0.003-0.049c-0.001-0.087-0.004-0.174-0.013-0.258c-0.002-0.017-0.006-0.031-0.008-0.047
      c-0.01-0.084-0.021-0.169-0.039-0.252c0-0.002,0-0.003,0-0.005c-0.123-0.594-0.422-1.123-0.84-1.531L32.126,8.884
      C31.582,8.338,30.831,8,30,8c-0.003,0-0.005,0.001-0.008,0.001V7.993H11V8c-1.657,0-3,1.343-3,3H7.993v19.049h0.013
      c0.015,0.878,0.409,1.657,1.023,2.196l-0.011,0.012l25,23l0.006-0.005c0.395,0.348,0.879,0.592,1.418,0.693
      c0.021,0.005,0.045,0.008,0.067,0.011c0.067,0.011,0.134,0.022,0.202,0.028C35.807,55.995,35.902,56,36,56
      c0.916,0,1.734-0.411,2.284-1.059l0.001,0.002l17-20l-0.001-0.002C55.73,34.418,56,33.741,56,33z M16,20c-2.209,0-4-1.791-4-4
      s1.791-4,4-4s4,1.791,4,4S18.209,20,16,20z"
    />
  </svg>
);

export default Notification;
