import { add_message } from 'store/v1/app/actions';
import { adaptToQuery } from 'adapters/v1/filters/table';

import request from 'lib/v2/request';
import handleError from 'lib/v1/error-handling';

const parseBody = (response) => {
  if (!response || !response.body) return {};
  const { data, jsonapi, included, meta } = response.body;
  const { version: apiVersion } = meta || {};
  if (apiVersion) window.localStorage.setItem('api_version', apiVersion);
  return { data, payload: data, jsonapi, included, meta };
};

const actionErrorHandler = (dispatch, type, name, error) => {
  dispatch({
    type: `${type}_${name}_FAILURE`,
    error,
  });
  const silent = type?.toLowerCase().includes('dropdown');
  handleError(error, silent);
};

export const list = (
  name,
  path,
  {
    page = 1,
    per_page = 10,
    showAll = false,
    sort = null,
    dir = 'asc',
    include = null,
    search = null,
    forcedFilters = {},
    filters = {},
    count,
  } = {},
  isDropdown = false
) => {
  const _filters = filters;

  const query = {
    page,
    showAll,
    per_page: showAll ? 99999 : per_page,
    sort,
    dir,
    search,
    filters: JSON.stringify({ ...forcedFilters, ...filters }),
    count,
  };

  if (include && !isDropdown) {
    query.include = include;
  }

  const action = isDropdown ? 'DROPDOWN' : 'LIST';
  return (dispatch) => {
    dispatch({ type: `${action}_${name}_PENDING` });
    return request
      .get(path)
      .query(isDropdown ? undefined : query)
      .then((resp) => {
        dispatch({
          type: `${action}_${name}_SUCCESS`,
          dropdown: isDropdown,
          query: { ...query, include, filters: _filters },
          ...parseBody(resp),
        });
        return { success: true };
      })
      .catch((error) => actionErrorHandler(dispatch, action, name, error));
  };
};

export const schema = (name, path, query = {}, action = 'SCHEMA') => {
  return (dispatch) => {
    dispatch({ type: `${action}_${name}_PENDING` });
    return request
      .get(path)
      .then((resp) => {
        const schema = resp && resp.body ? resp.body : {};

        dispatch({
          type: `${action}_${name}_SUCCESS`,
          schema,
        });

        return { schema, success: true };
      })
      .catch((error) => actionErrorHandler(dispatch, action, name, error));
  };
};

export const show = (name, path, query = {}, args = { isActivity: false }) => {
  const action = args && args.isActivity ? 'SHOW_ACTIVITY' : 'SHOW';

  return (dispatch) => {
    dispatch({ type: `${action}_${name}_PENDING` });
    return request
      .get(path)
      .query(query)
      .then((resp) => {
        const parsed = parseBody(resp);
        dispatch({
          type: `${action}_${name}_SUCCESS`,
          ...parsed,
        });
        const { data } = parsed;
        return { ...data, success: true };
      })
      .catch((error) => actionErrorHandler(dispatch, action, name, error));
  };
};

export const create = (name, path, payload = {}, isBulk = false) => {
  const action = isBulk ? 'CREATE_BULK' : 'CREATE';
  return (dispatch) => {
    dispatch({ type: `${action}_${name}_PENDING` });
    return request
      .post(path)
      .send(payload)
      .then((resp) => {
        if (!payload.silent) {
          dispatch(add_message('success', [payload.message || `Item(s) created successfully.`]));
        }

        delete payload.silent;

        const parsed = parseBody(resp);

        if (parsed?.payload?.message) dispatch(add_message('success', [parsed?.payload?.message]));

        dispatch({
          type: `${action}_${name}_SUCCESS`,
          ...parsed,
        });

        const { data } = parsed;
        return { ...data, success: true };
      })
      .catch((error) => {
        actionErrorHandler(dispatch, action, name, error);
        return { success: false };
      });
  };
};

export const copy = (name, path, payload = {}, isBulk = false) => {
  const action = isBulk ? 'COPY_BULK' : 'COPY';
  return (dispatch) => {
    dispatch({ type: `${action}_${name}_PENDING` });
    return request
      .post(path)
      .send(payload)
      .then((resp) => {
        if (!payload.silent) {
          dispatch(add_message('success', [payload.message || `Item(s) copied successfully.`]));
        }

        delete payload.silent;

        const parsed = parseBody(resp);

        dispatch({
          type: `${action}_${name}_SUCCESS`,
          ...parsed,
        });

        const { data } = parsed;
        return { ...data, success: true };
      })
      .catch((error) => {
        actionErrorHandler(dispatch, action, name, error);
        return { success: false };
      });
  };
};

export const update = (
  name,
  path,
  payload = {},
  showAll = false,
  isBulk = false,
  per_page = 10
) => {
  const action = isBulk ? 'UPDATE_BULK' : 'UPDATE';
  return (dispatch) => {
    dispatch({ type: `${action}_${name}_PENDING` });

    const query = showAll
      ? {
          showAll,
          per_page: 99999,
        }
      : {};

    return request
      .query(query)
      .put(path)
      .send(payload)
      .then((resp) => {
        if (!payload.silent) {
          // TODO: maybe update message for bulk use case
          dispatch(
            add_message(
              'success',
              payload.updateMessage ? [payload.updateMessage] : [`Item(s) updated successfully.`]
            )
          );
        }

        delete payload.silent;

        const parsed = parseBody(resp);

        dispatch({
          type: `${action}_${name}_SUCCESS`,
          ...parsed,
        });

        const { data } = parsed;
        return { ...data, success: true };
      })
      .catch((error) => {
        actionErrorHandler(dispatch, action, name, error);
        return { success: false };
      });
  };
};

export const destroy = (name, path, payload = {}, isBulk = false) => {
  const action = isBulk ? 'DESTROY_BULK' : 'DESTROY';
  return (dispatch) => {
    dispatch({ type: `${action}_${name}_PENDING` });
    return request
      .delete(path)
      .send(payload)
      .then((res) => {
        const { meta } = parseBody(res);

        if (isBulk && !payload.silent && meta.destroyed) {
          dispatch(add_message('success', [`${meta.destroyed} record(s) successfully deleted.`]));
        }

        delete payload.silent;

        dispatch({
          type: `${action}_${name}_SUCCESS`,
          payload,
          meta,
        });
        return { success: true };
      })
      .catch((error) => {
        actionErrorHandler(dispatch, action, name, error);
        return { success: false };
      });
  };
};

export const updateQuery = (name, query = {}, filters = {}, payload = {}) => {
  const action = 'UPDATE';
  return (dispatch) => {
    dispatch({ type: `${action}_${name}_QUERY_PENDING` });
    return dispatch({
      type: `${action}_${name}_QUERY_SUCCESS`,
      payload,
      query: { ...query, filters: adaptToQuery(filters) }, // Adapt to Actual HTTP request query
      filters, // Store filters for display
    });
  };
};

export const triggerState = (name) => {
  return (dispatch) => {
    dispatch({ type: `TRIGGER_${name}_PENDING` });
    return dispatch({
      type: `TRIGGER_${name}_SUCCESS`,
    });
  };
};

// Note: This is a generic function for building out actions
// for resources such as listing assigned/unassigned or bulk resource actions
// resourceBasePath is always expected to be a function that accepts a resourceId
// resourceDeletePath is similar to resourcePath but as a string without an ID
export const resourceActionsBuilder = ({
  resourceDeletePath,
  resourcePath,
  assignedActionType,
  unassignedActionType,
}) => {
  const listAssigned = (id, queryParams = {}) => {
    return list(assignedActionType, `${resourcePath(id)}/assigned`, queryParams);
  };

  const listUnassigned = (id, queryParams = {}) => {
    return list(unassignedActionType, `${resourcePath(id)}/unassigned`, queryParams);
  };

  const bulkCreateAssignments = (id, payload, silent = false, message) => {
    return create(
      assignedActionType,
      `${resourcePath(id)}/bulk`,
      { ...payload, silent, message },
      true
    );
  };

  const bulkDestroyAssignments = (payload, silent = false) => {
    return destroy(assignedActionType, `${resourceDeletePath}`, { ...payload, silent }, true);
  };

  const destroyAssignment = (id, payload, silent = false) => {
    return destroy(assignedActionType, `${resourceDeletePath}/${id}`, {
      id,
      ...payload,
      silent,
    });
  };

  return {
    listAssigned,
    listUnassigned,
    destroyAssignment,
    bulkCreateAssignments,
    bulkDestroyAssignments,
  };
};

// TODO: Add all other defult types in here. listByUser is common
// but it requires some other values and there isn't one with the things I'm working
// on right now.
const actions = ({ apiPath, actionType }) => ({
  dropdown: (queryParams) => {
    const isDropdown = true;
    return list(actionType, `${apiPath}/dropdown`, queryParams, isDropdown);
  },
  list: (queryParams) => {
    return list(actionType, apiPath, queryParams);
  },
  listSchema: (queryParams = {}) => {
    return schema(actionType, `${apiPath}/schema`, queryParams);
  },
  show: (id, queryParams = {}) => {
    return show(actionType, `${apiPath}/${id}`, queryParams);
  },
  showActivity: (id, queryParams = { include: 'user' }) => {
    return show(actionType, `${apiPath}/${id}/activity`, queryParams, { isActivity: true });
  },
  create: (payload, silent = false) => {
    return create(actionType, apiPath, { ...payload, silent });
  },
  copy: (id, payload, silent = false) => {
    return copy(actionType, `${apiPath}/${id}/copy`, { ...payload, silent });
  },
  update: (id, payload, silent = false) => {
    return update(actionType, `${apiPath}/${id}`, { ...payload, silent });
  },
  destroy: (id) => {
    return destroy(actionType, `${apiPath}/${id}`, { id });
  },
  bulkCreate: (payload, silent = false) => {
    return create(actionType, `${apiPath}/bulk`, { ...payload, silent }, true);
  },
  bulkScopedUserCreate: (payload, silent = false) => {
    return create(actionType, `${apiPath}/bulk/scoped_user_create`, { ...payload, silent }, true);
  },
  bulkCopy: (payload, silent = false) => {
    return copy(actionType, `${apiPath}/bulk`, { ...payload, silent }, true);
  },
  bulkUpdate: (payload, silent = false) => {
    return update(actionType, `${apiPath}/bulk`, { ...payload, silent }, false, true);
  },
  bulkDestroy: (payload, silent = false) => {
    return destroy(actionType, `${apiPath}/bulk`, { ...payload, silent }, true);
  },
  updateQuery: ({ query = {}, filters = [], payload = {} } = {}) => {
    return updateQuery(actionType, query, filters, payload);
  },
  triggerState: () => triggerState(actionType),

  archive: (id, silent = false) => update(actionType, `${apiPath}/${id}/archive`, { id, silent }),

  unarchive: (id, silent = false) =>
    update(actionType, `${apiPath}/${id}/unarchive`, { id, silent }),
});

export default actions;
