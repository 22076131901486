// This is mostly an experiment, we can ditch this if we want
// but I'm super freaking tired of how verbose basic javascript is
// type checking should be a fundamental part of what we do.

export function isObject(object) {
  if (typeof object === 'object') return true;
  return false;
}

// I'll be honest, I don't usually do this but
// javascript is freaking crazy to not have a basic function like this
// Why is there no consistency in type checking?
export function isArray(item) {
  return Array.isArray(item);
}

// Obviously only using objects at the moment
// Perhaps there is a more idiomatic way for this but
// the accepted answers I see for this sort of thing are crazy verbose
export function isEmpty(item) {
  if (!item) return true;

  if (isObject(item)) {
    if (Object.entries(item).length === 0) return true;
  }

  if (isArray(item)) {
    if (item.length === 0) return true;
  }

  return false;
}
