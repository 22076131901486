import React from 'react';

import TswDialog from 'components/v2/FormElements/DialogV2';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const Dialog = ({ onClose, open, title, securityOfficers, ...rest }) => {
  const modalProps = {
    onClose,
    title,
    open,
  };

  return (
    <TswDialog {...modalProps}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow key="columns">
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Facility</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {securityOfficers.map((officer, index) => (
              <TableRow key={index}>
                <TableCell>{officer.name}</TableCell>
                <TableCell>{officer.work_phone}</TableCell>
                <TableCell>
                  <a href={`mailto:${officer.email}`}>{officer.email}</a>
                </TableCell>
                <TableCell>{officer.facility_display_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TswDialog>
  );
};

export default Dialog;
