import React from 'react';
import Dialog from 'components/v2/FormElements/DialogV2';
import Button from '@mui/material/Button';
import FormItem from './FormItem';

export const FormDialog = ({
  attachment = {},
  destroy,
  icon,
  isDialog = false,
  onClick,
  onClose,
  open,
  permissions = {},
  ...rest
}) => {
  const itemProps = { attachment, destroy, permissions, icon, isDialog };

  const modalActions = <Button onClick={onClose}>Close</Button>;

  const modalProps = {
    actions: modalActions,
    onClose,
    open,
    title: 'View Form',
  };

  return (
    <Dialog {...modalProps}>
      <FormItem isDialog={true} {...itemProps} {...rest} />
    </Dialog>
  );
};

export default FormDialog;
