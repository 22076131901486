import React from 'react';
import { withRouter } from 'react-router';

// mui
import { makeStyles } from '@mui/styles';
import Chip from '@mui/material/Chip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { twilight } from 'lib/v2/colors';

export const useStyles = makeStyles((theme) => ({
  withLink: {
    textDecoration: 'underline',
    color: twilight,
    '& .MuiChip-icon': {
      color: twilight,
    },
  },
}));

const TswChip = ({ label, url, linksEnabled, history, ...rest }) => {
  const classes = useStyles();
  const isExternalLink = url && url.includes('http');
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isExternalLink) window.open(url, '_blank');
    else history.push(url);
  };

  let chipProps = {
    ...rest,
    label,
  };

  if (url && linksEnabled) {
    chipProps = {
      ...chipProps,
      onClick,
      onDelete: null,
      className: classes.withLink,
    };

    if (isExternalLink) {
      chipProps.icon = <OpenInNewIcon />;
    }
  }

  return <Chip {...chipProps} />;
};

export default withRouter(TswChip);
