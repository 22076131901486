import React, { memo, useRef } from 'react';
import { uniqueId } from 'lib/v1';

import { styled } from '@mui/material/styles';

export const StyledWrapper = styled('div')({
  alignItems: 'flex-start',
  display: 'flex',
  position: 'relative',
  lineHeight: '20px',
});

export const StyledContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
});

const StyledIcon = styled('div')({
  padding: '4px',
});

export const StyledLabel = styled('label')(({ checked }) => ({
  border: '1px solid #d4d2d2',
  backgroundColor: '#fff',
  cursor: 'pointer',
  position: 'absolute',
  width: '16px',
  height: '16px',
  top: '2px',
  left: '2px',
  '&:after': {
    background: 'transparent',
    border: '3px solid rgba(44, 68, 90, 1)',
    borderTop: 'none',
    borderRight: 'none',
    boxSizing: 'content-box',
    content: '""',
    left: '3px',
    height: '2px',
    opacity: checked ? 1 : 0,
    position: 'absolute',
    top: '3px',
    transform: 'rotate(-45deg)',
    width: '6px',
  },
}));

const StyledText = styled('label')({
  color: '#9b9b9b',
  fontSize: '16px',
  paddingLeft: '12px',
});

function CheckBox({ checked, className, disabled, icon, label, onClick }) {
  const identifier = useRef(uniqueId());
  const id = `${label}${identifier.current}`;

  return (
    <StyledWrapper className={className}>
      <StyledContainer>
        <input disabled={disabled} type="checkbox" id={id} name={id} onClick={onClick} />
        <StyledLabel name={id} checked={checked} htmlFor={id} />
      </StyledContainer>
      {label && <StyledText htmlFor={id}>{label}</StyledText>}
      <StyledIcon>{icon}</StyledIcon>
    </StyledWrapper>
  );
}

export default memo(CheckBox);
