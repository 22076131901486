import React from 'react';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
// import AnalyticsIcon from '@mui/icons-material/Analytics';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BadgeIcon from '@mui/icons-material/Badge';
import BentoIcon from '@mui/icons-material/Bento';
import BiotechIcon from '@mui/icons-material/Biotech';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import CableIcon from '@mui/icons-material/Cable';
import CakeIcon from '@mui/icons-material/Cake';
import ComputerIcon from '@mui/icons-material/Computer';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import FeedIcon from '@mui/icons-material/Feed';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import FlightIcon from '@mui/icons-material/Flight';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import GavelIcon from '@mui/icons-material/Gavel';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HelpIcon from '@mui/icons-material/Help';
import ImagesearchRollerIcon from '@mui/icons-material/ImagesearchRoller';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import InboxIcon from '@mui/icons-material/Inbox';
import InventoryIcon from '@mui/icons-material/Inventory';
import KeyIcon from '@mui/icons-material/Key';
import KitchenIcon from '@mui/icons-material/Kitchen';
import LanguageIcon from '@mui/icons-material/Language';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LiquorIcon from '@mui/icons-material/Liquor';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import MediationIcon from '@mui/icons-material/Mediation';
import MemoryIcon from '@mui/icons-material/Memory';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import NavigationIcon from '@mui/icons-material/Navigation';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
// import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PhoneIcon from '@mui/icons-material/Phone';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PolicyIcon from '@mui/icons-material/Policy';
import PolylineIcon from '@mui/icons-material/Polyline';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PublishIcon from '@mui/icons-material/Publish';
import PushPinIcon from '@mui/icons-material/PushPin';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import RocketIcon from '@mui/icons-material/Rocket';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import SchoolIcon from '@mui/icons-material/School';
import SecurityIcon from '@mui/icons-material/Security';
import SellIcon from '@mui/icons-material/Sell';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';
import SpeedIcon from '@mui/icons-material/Speed';
import StarIcon from '@mui/icons-material/Star';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TimelineIcon from '@mui/icons-material/Timeline';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WcIcon from '@mui/icons-material/Wc';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

export const icons = {
  accesses: KeyIcon,
  actionRequests: ModelTrainingIcon,
  admin: SettingsIcon,
  analytics: SpeedIcon,
  assets: InventoryIcon,
  assetDestruction: ReceiptIcon,
  assetTransfer: ReceiptIcon,
  badges: BadgeIcon,
  company: BusinessIcon,
  compliance: NavigationIcon,
  containers: KitchenIcon,
  contacts: PersonOutlineIcon,
  contracts: AssignmentIndIcon,
  customFields: DynamicFormIcon,
  eligibilities: KeyIcon,
  facilities: DomainAddIcon,
  files: FolderCopyIcon,
  forms: FeedIcon,
  imports: PublishIcon,
  inbox: InboxIcon,
  integrations: SettingsSystemDaydreamIcon,
  library: LibraryBooksIcon,
  logout: LogoutIcon,
  people: PeopleAltIcon,
  polygraphs: TimelineIcon,
  notify: SendIcon,
  reportables: SendIcon,
  reportableTemplates: DynamicFeedIcon,
  roles: AdminPanelSettingsIcon,
  settings: SettingsIcon,
  tags: SellIcon,
  tasks: TaskAltIcon,
  tickets: ConfirmationNumberIcon,
  travel: FlightIcon,
  training: SchoolIcon,
  user: AccountBoxIcon,
  visits: LocationOnIcon,
  workflows: CableIcon,
};

export const iconComponents = (type) => React.createElement(icons[type]);

export const workflowIcons = {
  AccountTreeIcon: AccountTreeIcon,
  AccountBalanceIcon: AccountBalanceIcon,
  AirplaneTicketIcon: AirplaneTicketIcon,
  AirportShuttleIcon: AirportShuttleIcon,
  ApartmentIcon: ApartmentIcon,
  BadgeIcon: BadgeIcon,
  BentoIcon: BentoIcon,
  BiotechIcon: BiotechIcon,
  BusinessCenterIcon: BusinessCenterIcon,
  CakeIcon: CakeIcon,
  ComputerIcon: ComputerIcon,
  ContactPhoneIcon: ContactPhoneIcon,
  ContentPasteSearchIcon: ContentPasteSearchIcon,
  DeveloperBoardIcon: DeveloperBoardIcon,
  DynamicFormIcon: DynamicFormIcon,
  FingerprintIcon: FingerprintIcon,
  FlagCircleIcon: FlagCircleIcon,
  FlightIcon: FlightIcon,
  FolderOpenIcon: FolderOpenIcon,
  GavelIcon: GavelIcon,
  GroupWorkIcon: GroupWorkIcon,
  HandshakeIcon: HandshakeIcon,
  HelpIcon: HelpIcon,
  ImagesearchRollerIcon: ImagesearchRollerIcon,
  ImportantDevicesIcon: ImportantDevicesIcon,
  KeyIcon: KeyIcon,
  LanguageIcon: LanguageIcon,
  LiquorIcon: LiquorIcon,
  LocalPhoneIcon: LocalPhoneIcon,
  LocalPoliceIcon: LocalPoliceIcon,
  LocationCityIcon: LocationCityIcon,
  LocationOnIcon: LocationOnIcon,
  LockIcon: LockIcon,
  MediationIcon: MediationIcon,
  MemoryIcon: MemoryIcon,
  MenuBookIcon: MenuBookIcon,
  ModelTrainingIcon: ModelTrainingIcon,
  MonetizationOnIcon: MonetizationOnIcon,
  NewspaperIcon: NewspaperIcon,
  PendingActionsIcon: PendingActionsIcon,
  PersonIcon: PersonIcon,
  PersonSearchIcon: PersonSearchIcon,
  PhoneIcon: PhoneIcon,
  PointOfSaleIcon: PointOfSaleIcon,
  PolicyIcon: PolicyIcon,
  PolylineIcon: PolylineIcon,
  PsychologyIcon: PsychologyIcon,
  PushPinIcon: PushPinIcon,
  RestaurantIcon: RestaurantIcon,
  RocketIcon: RocketIcon,
  RuleFolderIcon: RuleFolderIcon,
  SecurityIcon: SecurityIcon,
  SendIcon: SendIcon,
  SettingsInputComponentIcon: SettingsInputComponentIcon,
  StarIcon: StarIcon,
  StickyNote2Icon: StickyNote2Icon,
  SyncLockIcon: SyncLockIcon,
  VerifiedUserIcon: VerifiedUserIcon,
  VolunteerActivismIcon: VolunteerActivismIcon,
  VpnKeyIcon: VpnKeyIcon,
  VpnLockIcon: VpnLockIcon,
  WarehouseIcon: WarehouseIcon,
  WcIcon: WcIcon,
  WhereToVoteIcon: WhereToVoteIcon,
  WorkOutlineIcon: WorkOutlineIcon,
  WorkspacePremiumIcon: WorkspacePremiumIcon,
};

export const randomIconName = (icons) => {
  const iconNames = Object.keys(icons);
  return iconNames[Math.floor(Math.random() * iconNames.length)];
};

export const randomIcon = (icons) => {
  return icons[randomIconName(icons)];
};

export default icons;
