import { styled } from '@mui/material/styles';

import { lighterGray } from 'lib/v2/colors';

const headerLineHeight = '1.8rem';

export const StyledTable = styled('table')(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette.divider}`,
  tableLayout: 'fixed',
  background: theme.palette.background.paper,
}));

export const StyledHeader = styled('th')(({ theme }) => ({
  letterSpacing: '0.07em',
  color: '#3f464b',
  // fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: headerLineHeight,
}));

export const StyledCheckbox = styled(({ type = 'checkbox', ...rest }) => (
  <input type={type} {...rest} />
))({
  paddingRight: '10px',
  color: '#333',
  borderRight: '1px solid rgba(0, 0, 0, 0.05)',
});

export const StyledThead = styled('thead')(({ theme }) => ({
  opacity: (props) => (props.loading ? '0.3' : '1.0'),
  boxShadow: 'inset 0 -3px 0 0 rgba(0, 0, 0, 0.6)',
  backgroundColor: theme.palette.backgroundBasic.main,
}));

export const StyledTr = styled('tr')({
  '> th': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '> :nth-child(1)': {
    width: '25%',
  },
});

export const StyledBodyTr = styled('tr')({
  '> td': {
    position: 'relative',
    lineHeight: headerLineHeight,
  },
  '> td:last-child': {
    borderRight: `1px solid ${lighterGray}`,
  },
  '&:hover .rowAction, & .rowActionOpen': {
    opacity: 1,
    minWidth: '6rem',
    '@media (max-width: 980px)': {
      minWidth: '2rem',
      button: {
        minWidth: '2rem',
      },
    },
  },
  '&:hover': {
    backgroundColor: '#fafafa',
  },
});

export const StyledSelectTr = styled('tr')(({ visible }) => ({
  visibility: visible ? 'visible' : 'collapse',
  '> :nth-child(1)': {
    width: '2rem !important',
  },
  '> :nth-child(2)': {
    width: '25%',
  },
  '> th': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const StyledActionTr = styled('tr')(({ visible }) => ({
  visibility: visible ? 'visible' : 'collapse',
  '> th': {
    lineHeight: headerLineHeight,
  },
  '> :nth-child(1)': {
    width: '2rem',
  },
}));

export const StyledSelectAllWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledSelectAll = styled('div')({
  display: 'inline-block',
});

export const StyledSelectAllButton = styled('button')({
  display: 'inline-block',
  marginLeft: '6px',
  border: 'none',
  background: 'none',
  padding: 0,
  font: 'inherit',
  color: '#205077',
  cursor: 'pointer',
});

export const StyledUser = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
