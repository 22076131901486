import { pathBuilder } from 'config/paths/index';

export const root = '/dashboard';
const adminRoot = '/admin' + root;

export const adminPaths = {
  ...pathBuilder(adminRoot),
};

export default adminPaths;
