import React from 'react';

const Loading = ({ width = '12px', height = '12px', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" {...rest}>
    <rect x="0" y="0" width="100" height="100" fill="none" />
    <circle
      cx="50"
      cy="50"
      r="40"
      stroke="#fff"
      fill="none"
      strokeWidth="10"
      strokeLinecap="round"
    />
    <circle
      cx="50"
      cy="50"
      r="40"
      stroke="#679282"
      fill="none"
      strokeWidth="6"
      strokeLinecap="round"
    >
      <animate
        attributeName="stroke-dashoffset"
        dur="2s"
        repeatCount="indefinite"
        from="0"
        to="502"
      />
      <animate
        attributeName="stroke-dasharray"
        dur="2s"
        repeatCount="indefinite"
        values="150.6 100.4;1 250;150.6 100.4"
      />
    </circle>
  </svg>
);

export default Loading;
