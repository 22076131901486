let styles = {
  column: {
    margin: '1.25rem auto',
    maxWidth: '700px',
  },
};

const ErrorPage = () => {
  return (
    <div className="segment" style={styles.column}>
      <h2 className="header">We're Sorry</h2>
      <p>The page you are looking for does not exist.</p>
      <p>
        If you believe this is an error, please contact Customer Support at{' '}
        <a href="mailto:support@threatswitch.com">support@threatswitch.com</a>.
      </p>
    </div>
  );
};

export default ErrorPage;
