import { styled } from '@mui/material/styles';

import { blue, grey, twilight } from 'lib/v2/colors';
import Icon from 'components/v1/Icon';

const getColor = (color) => {
  if (color === 'blue') return blue;
  else if (color === 'grey') return grey;
  else if (color === 'twilight') return twilight;
};

const StyledButton = styled('button')(({ border, color, disabled, transparent }) => ({
  border: 'none',
  borderRadius: '2px',
  boxSizing: 'border-box',
  color: '#fff',
  fontSize: '14px',
  fontWeight: '300',
  fontFamily: 'Roboto, sans-serif',
  padding: '10px 14px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  '&:hover': {
    opacity: '0.8',
  },

  ...(disabled && {
    opacity: '0.7',
    cursor: 'not-allowed',
  }),

  ...(border &&
    transparent && {
      border: '1px solid',
    }),
  ...(transparent && {
    backgroundColor: 'transparent',
  }),
  ...(color &&
    transparent && {
      color: getColor(color),
    }),
  ...(color &&
    !transparent && {
      backgroundColor: getColor(color),
    }),

  'svg.Import-rightChevron': {
    marginRight: '10px',
  },
}));

const StyledContainer = styled('span')(({ align }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: align,
}));

const StyledIcon = styled(Icon)({
  height: '14px',
  width: '14px',
});

const StyledIconContainer = styled('div')(({ size }) => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  ...(size === 'large' && { marginLeft: ' -24px' }),
}));

function Button({
  align = 'center',
  border,
  children,
  color = 'twilight',
  css,
  loading,
  icon,
  iconRight,
  hideLoading,
  size,
  text,
  transparent,
  ...rest // className, disabled, onClick, style, type, etc...
}) {
  // support passing string / component as we refactor - end goal is to pass down icon name (may need to support styling icon)
  const renderIcon = () => {
    if (icon && typeof icon === 'string') return <StyledIcon name={icon} />;

    return icon;
  };

  return (
    <StyledButton border={border} color={color} css={css} transparent={transparent} {...rest}>
      <StyledContainer align={align}>
        {!hideLoading && icon && (
          <StyledIconContainer size={size}>
            {loading && <Icon name="Loading" />}
            {!loading && !iconRight && renderIcon()}
          </StyledIconContainer>
        )}
        {text && <span>{text}</span>}
        {!loading && iconRight && renderIcon()}
        {children}
      </StyledContainer>
    </StyledButton>
  );
}

export default Button;
