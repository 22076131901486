import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';

import logo from 'assets/images/logo/logomark.svg';

const StyledLoader = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '10% 0',
});

const Pulse = keyframes`
  from {
    transform: scale3d(1, 1, 1);
    opacity: 0.8;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1);
    opacity: 1;
  }

  to {
    transform: scale3d(1, 1, 1);
    opacity: 0.8;
  }
`;

const Rotation = keyframes`
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
`;

const StyledImage = styled('img')({
  animationDuration: '2s',
  animationIterationCount: 'infinite',
  animationName: Pulse,
  animationTimingFunction: 'ease-in-out',
  opacity: 0.8,
});

const StyledRotatingImage = styled('img')({
  animationName: Rotation,
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
  animationDuration: '6s',
  transformStyle: 'preserve-3d',
});

const StyledText = styled('span')({
  color: '#333',
  fontSize: '1rem',
  marginTop: '1.375rem',
});

const Loader = ({ rotate, text = 'Loading...' }) => (
  <StyledLoader>
    {rotate ? (
      <StyledRotatingImage src={logo} alt="Loading Indicator" />
    ) : (
      <StyledImage src={logo} alt="Loading Indicator" />
    )}
    <StyledText>{text}</StyledText>
  </StyledLoader>
);

export default memo(Loader);
