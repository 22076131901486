import { useState } from 'react';
import Search from 'components/v1/Search';

import { styled } from '@mui/material/styles';

export const StyledDiv = styled('div')(({ style }) => ({
  display: 'inline-flex',
  // width: '275px',
  // columnGap: '1rem',
  '& form': {
    display: 'flex',
  },
  '& form button': {
    position: 'relative',
    left: -24,
    top: 0,
  },
  ...style,
}));

const SearchContainer = ({ onSearch, search = '', children, style, ...rest }) => {
  const [value, setValue] = useState(search);

  const onChange = (e) => setValue(e.target.value);

  const onClear = (e) => {
    setValue('');
    if (!onSearch) return;
    onSearch('');
  };

  const onSubmit = (e) => {
    e.stopPropagation();

    if (!onSearch) return;

    onSearch(value);
  };

  const searchProps = {
    placeholder: 'Search',
    onSubmit,
    onChange,
    onClear,
    value,
  };

  return (
    <StyledDiv {...style}>
      <Search {...searchProps} />
      {children}
    </StyledDiv>
  );
};

export default SearchContainer;
