import { isAdmin } from 'permissions/v1/roles';
import { canList } from 'permissions/v1/index';

const permission = 'LibraryTemplate';

const list = (profile) => isAdmin(profile) || canList(permission, profile);

const permissions = (profile) => {
  return {
    list: list(profile),
    view: false,
    create: false,
    update: false,
    destroy: false,
  };
};

export default permissions;
