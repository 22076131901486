import { pathBuilder, peopleRoot } from 'config/paths/index';

export const root = '/reportable-incidents';

const basePaths = pathBuilder(root);

export const peopleBasePath = (id) => {
  return `${peopleRoot}/${id}/reportable-incidents`;
};

export const userPathsBuilder = (id) => {
  return pathBuilder(peopleBasePath(id));
};

const paths = {
  ...basePaths,
  create: root + '?create',
  settings: root + '/settings',
  notify: root + '/notify',
};

export default paths;
