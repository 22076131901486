import React from 'react';

// MUI
import Avatar from '@mui/material/Avatar';
import { withTheme } from '@mui/styles';

// Icons
import ArticleIcon from '@mui/icons-material/Article';
import ImageIcon from '@mui/icons-material/Image';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import LinkIcon from '@mui/icons-material/Link';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SlideshowIcon from '@mui/icons-material/Slideshow';

const iconMap = {
  image: ImageIcon,
  pdf: PictureAsPdfIcon,
  presentation: SlideshowIcon,
  zip: FolderZipIcon,
};
const iconTypes = Object.keys(iconMap);

const AttachmentAvatar = ({ file = {}, icon, link, name, type, theme } = {}) => {
  // default for any non mapped, works fine for documents, etc
  let AttachmentIcon = ArticleIcon;
  const { content_type } = file;
  const effectiveType = type || content_type;

  if (link) AttachmentIcon = LinkIcon;
  if (effectiveType) {
    const mappedIcon = iconTypes.find((type) => effectiveType.includes(type));
    if (mappedIcon) AttachmentIcon = iconMap[mappedIcon];
  }
  if (icon) AttachmentIcon = icon;

  return (
    <Avatar md sx={{ backgroundColor: theme.palette.info.main }}>
      <AttachmentIcon />
    </Avatar>
  );
};

export default withTheme(AttachmentAvatar);
