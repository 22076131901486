import React from 'react';
import { useTable, useSortBy, useRowSelect } from 'react-table';

import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

// hooks
import useSelectionColumn from './hooks';

// Components
import { StyledActionWrapper } from './BatchAction';
import RowActionDropdown from './RowActionDropdown';
import { twilight as sortColor } from 'lib/v2/colors';
import ZeroState from './ZeroState';

// styles
import {
  StyledSelectAllWrapper,
  StyledSelectAllButton,
  StyledSelectAll,
  StyledTable,
  StyledThead,
  StyledHeader,
  StyledTr,
  StyledBodyTr,
  StyledSelectTr,
  StyledActionTr,
} from './Styles';

// lib
import pluralator from 'lib/v1/pluralator';

const useStyles = makeStyles((theme) => ({
  rowActionCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 'auto',
  },
}));

const parseSortBy = (sortBy) => {
  if (sortBy.length) {
    const _sort = sortBy[0];
    return { sort: _sort.id, dir: _sort.desc ? 'desc' : 'asc' };
  }
  return {};
};

const SelectAllRow = ({ total_count, per_page, isSelectAllRecords, callback }) => {
  const value = pluralator(total_count, 'record', 'records');

  const selectAllText = isSelectAllRecords
    ? `Select only ${per_page} ${value}`
    : `Select all ${total_count} ${value}.`;

  const isSelectAllText = `All ${total_count} ${value} selected.`;

  const text = isSelectAllRecords
    ? isSelectAllText
    : `Only ${per_page} items on this page are selected.`;

  return (
    <StyledSelectAllWrapper>
      <StyledSelectAll>{text}</StyledSelectAll>
      <StyledSelectAllButton type="button" onClick={callback}>
        {selectAllText}
      </StyledSelectAllButton>
    </StyledSelectAllWrapper>
  );
};

const Table = ({
  columns = [],
  loading = false,
  data = [],
  list,
  total_count = 0,
  total_pages = 0,
  query = {},
  per_page,
  batchActions = [],
  rowActions = [],
  skipListOnMount = false,
  selectAllRecords = false,
  onSelection,
  autoResetSelectedRows = false,
  useCustomSelectionColumn,
  CustomZeroState,
  stateId, // Unique digest representing state of store, allows table to update based on arbitrary changes to the redux store
}) => {
  const availableHooks = [useSortBy, useRowSelect];
  const classes = useStyles();

  const [isMount, setIsMount] = React.useState(true);

  const isBatchActions = batchActions && batchActions.length > 0;
  const isSelectState = isBatchActions || onSelection;

  // This allows us to use a custom hook for handling selections
  // rather than overspecifying the default
  if (isSelectState) {
    if (useCustomSelectionColumn) {
      availableHooks.push(useCustomSelectionColumn);
    } else {
      availableHooks.push(useSelectionColumn);
    }
  }

  const [isSelectAllRecords, setIsSelectAllRecords] = React.useState(false);

  const useTableProps = {
    columns,
    data,
    manualSortBy: list ? true : false,
    autoResetSelectedRows,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    headers,
    rows,
    prepareRow,
    isAllRowsSelected,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { sortBy },
  } = useTable(useTableProps, ...availableHooks);

  const anyRowSelected = selectedFlatRows.length > 0;
  const TrStyle = isSelectState ? StyledSelectTr : StyledTr;
  const resetSelectAll = () => setIsSelectAllRecords(false);

  // FIX HOOK
  React.useEffect(() => {
    if (anyRowSelected) toggleAllRowsSelected(false);
    if (isAllRowsSelected) toggleAllRowsSelected();
    if (!list) return;

    if (isMount && skipListOnMount) {
      setIsMount(false);
      return;
    }

    const sort = parseSortBy(sortBy);

    const fetchData = async () => {
      await list({ ...query, ...sort });
    };

    fetchData();
  }, [sortBy, stateId]); // eslint-disable-line

  // FIX HOOK
  React.useEffect(() => {
    if (!onSelection) return;
    onSelection({ isAllRowsSelected, selectedFlatRows, isSelectAllRecords });
  }, [isAllRowsSelected, selectedFlatRows, isSelectAllRecords]); // eslint-disable-line

  const actionColumnIndex = isSelectState ? 1 : 0;
  const columnTitlesVisible = !isBatchActions || (isBatchActions && !anyRowSelected);

  const headerStyle = (column) => {
    const baseStyle = {
      ...column.getHeaderProps(column.getSortByToggleProps()).style,
      ...column.style,
    };

    if (column.isSorted) {
      const borderLocation = column.isSortedDesc ? 'borderBottom' : 'borderTop';
      baseStyle[borderLocation] = `2px solid ${sortColor}`;
    }

    return baseStyle;
  };

  if (!rows.length && CustomZeroState && CustomZeroState()) return <CustomZeroState />;

  return (
    <StyledTable {...getTableProps()}>
      <StyledThead loading={loading ? loading.toString() : undefined}>
        {/* Normal Column Header Configuration for select or non select modes */}
        {headerGroups.map((headerGroup, index) => (
          <TrStyle
            key={`head-tr-${index}`}
            {...headerGroup.getHeaderGroupProps()}
            visible={columnTitlesVisible}
          >
            {headerGroup.headers.map((column) => (
              <StyledHeader
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={headerStyle(column)}
              >
                {column.render('Header')}
              </StyledHeader>
            ))}
          </TrStyle>
        ))}

        {/* Batch Actions Column configuration which is select only */}
        {isBatchActions && (
          <StyledActionTr visible={anyRowSelected}>
            {headers.slice(0, 1).map((column, index) => (
              <React.Fragment key={`action-tr-${index}`}>
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                </th>
                <th colSpan={columns.length}>
                  <StyledActionWrapper>
                    {/* Note the props passed to the BatchAction */}
                    {batchActions.map((BatchAction, i) => (
                      <BatchAction
                        key={`batch-action-${i}`}
                        toggleAllRowsSelected={toggleAllRowsSelected}
                        selected={selectedFlatRows}
                        applyToAll={isSelectAllRecords}
                        resetSelectAll={resetSelectAll}
                        query={query}
                      />
                    ))}
                  </StyledActionWrapper>
                </th>
              </React.Fragment>
            ))}
          </StyledActionTr>
        )}

        {/* Batch Actions SelectAll Records toggle table row */}
        {selectAllRecords && isAllRowsSelected && total_count > per_page && (
          <tr key={`selected-head-tr`}>
            <td bgcolor="#e7f2f9" colSpan={columns.length + 1}>
              {/* Note the props passed to SelectAllRow */}
              <SelectAllRow
                callback={() => setIsSelectAllRecords(!isSelectAllRecords)}
                isSelectAllRecords={isSelectAllRecords}
                total_count={total_count}
                per_page={per_page}
              />
            </td>
          </tr>
        )}
      </StyledThead>
      <tbody {...getTableBodyProps()}>
        {!rows.length && <ZeroState numCols={headerGroups[0]?.headers.length} />}

        {/* StyledBodyTr Primary row styling - handles basics like hover */}

        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <StyledBodyTr key={`body-tr-${i}`} {...row.getRowProps()}>
              {row.cells.map((cell, col) => {
                let cellContents = cell.render('Cell');
                if (col === actionColumnIndex && rowActions) {
                  cellContents = (
                    <Box display="flex">
                      <Box className={classes.rowActionCell}>{cell.render('Cell')}</Box>
                      {rowActions && rowActions.length > 0 && (
                        <RowActionDropdown rowItem={cell} rowActions={rowActions} />
                      )}
                    </Box>
                  );
                }
                return <td {...cell.getCellProps()}>{cellContents}</td>;
              })}
            </StyledBodyTr>
          );
        })}
      </tbody>
    </StyledTable>
  );
};

export default Table;
