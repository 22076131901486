import CONST from './constants';

export const initialState = {
  currentPage: 1,
  data: {},
  result: [],
  loading: false,
  byUser: {},
  byType: {
    Badge: {},
    Training: {},
    Travel: {},
    Contract: {},
    Certificate: {},
  },
  resultByPage: {},
  meta: {},
};

const index = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case CONST.DOCUMENTS_CHANGE_PAGE:
      return {
        ...state,
        currentPage: payload,
      };
    case CONST.DOCUMENTS_RESET_PAGING:
      return {
        ...state,
        meta: {},
        resultByPage: {},
      };
    case CONST.GET_DOCUMENTS_PENDING:
      return {
        ...state,
        currentPage: payload,
        loading: true,
      };
    case CONST.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.files.reduce((files, file) => {
            files[file.id] = file;
            return files;
          }, {}),
        },
        resultByPage: {
          ...state.resultByPage,
          [meta.current_page]: payload.files.map((file) => file.id),
        },
        meta,
        loading: false,
        result: [...new Set(state.result.concat(payload.files.map((file) => file.id)))],
      };
    case CONST.GET_DOCUMENTABLE_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.GET_DOCUMENTABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...payload.files.reduce((files, file) => {
            files[file.id] = file;
            return files;
          }, {}),
        },
        byType: {
          ...state.byType,
          [payload.type]: {
            [payload.id]: payload.files,
          },
        },
        result: [...new Set(state.result.concat(payload.files.map((file) => file.id)))],
      };
    case CONST.GET_DOCUMENTABLE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONST.GET_USER_DOCUMENTS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.GET_USER_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...payload.files.reduce((files, file) => {
            files[file.id] = file;
            return files;
          }, {}),
        },
        byUser: payload.user
          ? {
              ...state.byUser,
              [payload.user]: [
                ...new Set(
                  (state.byUser[payload.user] || []).concat(payload.files.map((file) => file.id))
                ),
              ],
            }
          : state.byUser,
        result: [...new Set(state.result.concat(payload.files.map((file) => file.id)))],
      };
    case CONST.GET_USER_DOCUMENTS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONST.CREATE_DOCUMENT_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.id]: payload,
        },
        byUser: payload.documentable_id
          ? {
              ...state.byUser,
              [payload.documentable_id]: [
                ...(state.byUser[payload.documentable_id] || []),
                payload.id,
              ],
            }
          : state.byUser,
        result: [...state.result, payload.id],
      };
    case CONST.CREATE_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONST.UPDATE_DOCUMENT_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.id]: payload,
        },
      };
    case CONST.UPDATE_DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONST.DELETE_DOCUMENTS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.DELETE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        byUser: payload.documentableId
          ? {
              ...state.byUser,
              [payload.documentableId]: (state.byUser[payload.documentableId] || []).filter(
                (id) => !payload.ids.includes(id)
              ),
            }
          : state.byUser,
        resultByPage: {},
        result: state.result.filter((id) => id !== payload.id),
      };
    case CONST.DELETE_DOCUMENT_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        resultByPage: {},
        result: state.result.filter((id) => id !== payload.id),
      };
    default:
      return state;
  }
};

export default index;
