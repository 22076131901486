import reducer, { initialState } from 'store/v2/index';
import actionType from './constants';

export default reducer(actionType, {
  ...initialState(),
  query: { ...initialState().query, filters: { active: [true] } },
  filters: {
    active: {
      values: [true],
      displayName: 'Active: Yes',
    },
  },
});
