import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const CardButton = styled(ToggleButton)(({ theme }) => ({
  padding: '15px 0 5px',
  gridRow: '1',
  gridColumn: 'span 1',
  background: theme.palette.grey[50],
  display: 'block',
  border: 'none',
  borderRadius: '1px',
  borderBottom: `3px solid ${theme.palette.grey[100]}`,
  '&:hover, &.Mui-focusVisible': {
    background: theme.palette.grey[200],
  },
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  '&:first-child': {
    borderRadius: '5px 0 0 5px',
  },
  '&:last-child': {
    borderRadius: '0 5px 5px 0',
  },
}));

const CardButtons = ({ icon, label, onClick, ...rest }) => {
  return (
    <CardButton onClick={onClick} {...rest}>
      {icon}
      <Typography sx={{ display: 'block' }}>{label}</Typography>
    </CardButton>
  );
};
export default CardButtons;
