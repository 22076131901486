/*
 * The only things defined here should be generic and reusable cell types
 * If you must do custom formatting for any given cell type, please do it in the context of the feature you are building.
 */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Avatar from 'components/v2/PageElements/Avatar';
import Tooltip from 'components/v2/FormElements/Tooltip';

// material
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import FiberManualRecord from '@mui/icons-material/FiberManualRecord';

// styles
import { StyledUser } from './Styles';

// Lib
import format from 'lib/v1/format';

// Default cell type for text
export const Text = (text, className) => {
  return <div className={className || 'FlexTable-cell'}>{text}</div>;
};

// TODO: nestedText is a common cell type, just need to figure it out.
export const pluckNestedValue = (key, value) => {
  return ({ original }) => {
    return original[key][value];
  };
};

// Generic Link helper
export const Link = (name, path, className = 'FlexTable-link') => {
  return Text(<RouterLink {...{ className, to: path }}>{name}</RouterLink>);
};

export const Ssn = ({ value }) => {
  return value ? Text(`****-***-${value}`) : null;
};

export const User = ({ obj, value, children }) => {
  return (
    <StyledUser>
      <Avatar lg profile={obj} />
      {value ? value : children}
    </StyledUser>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    icon: { paddingLeft: theme.spacing(1) },
  };
});

const IconCellDefault = (props) => {
  return <FiberManualRecord color="primary" {...props} />;
};

export const IconCell = ({
  children,
  showIcon = false,
  tooltipText = '',
  Icon = <IconCellDefault />,
}) => {
  const classes = useStyles();
  return (
    <Tooltip title={tooltipText}>
      <Box display="flex" alignItems="center" className={'FlexTable-cell'}>
        {children}
        {showIcon ? React.cloneElement(Icon, { className: classes.icon, fontSize: 'small' }) : null}
      </Box>
    </Tooltip>
  );
};

const index = {
  text: ({ value }) => Text(value),
  date: ({ value }) => {
    return Text(format.date(value));
  },
};

export default index;
