import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import Icon from 'components/v1/Icon';

import { twilight } from 'lib/v2/colors';

// actions
import attachmentActions from 'store/v2/attachments/actions';
import tagActions from 'store/v2/tags/actions';

// adapters
import { adaptToOriginalRowIds } from 'adapters/v1/table/rows';
import { adaptMultipleToIds } from 'adapters/v1/autocomplete/formData';

// FormElements
import { useDialog } from 'components/v2/FormElements/Dialog';
import { TagsAutocomplete } from 'components/v2/FormElements/Autocomplete';
import TswForm, { useForm, useDisabled } from 'components/v2/FormElements';
import ConfirmModal from 'components/v2/FormElements/ConfirmModal';

export const StyledActionWrapper = styled('div')({
  color: twilight,
  background: 'rgb(237, 237, 237)',
  fontWeight: '500',
  cursor: 'pointer',
  display: 'flex',
});

const StyledBatchOperation = styled('div')({
  marginRight: '1rem',
  display: 'flex',
  color: twilight,
  '&:hover': {
    opacity: 0.8,
  },
});

const StyledIcon = styled(Icon)({
  height: '1rem',
  width: '1rem',
  marginRight: '0.4rem',
  marginTop: '0.3rem',
  color: twilight,
});

export const BatchAction = ({ icon = 'Bin', title = 'Delete', onClick }) => {
  return (
    <StyledBatchOperation onClick={onClick}>
      <StyledIcon name={icon} />
      {title}
    </StyledBatchOperation>
  );
};

// I'm doing this because I don't want to continue importing the V1 Confirmation Modal into features.  I want to be able to swap
// this out all at once with a v2 MUI based component.
export const DialogComponent = ({ action, object, children, ...props }) => (
  <ConfirmModal {...props}>
    {children ? children : `Are you sure you want to ${action} the selected ${object}?`}
  </ConfirmModal>
);

export const RemoveAction = ({
  onConfirm = async () => true,
  title = 'Delete',
  action = 'delete',
  object = 'records',
  icon = 'Bin',
  ...rest
}) => {
  const actionProps = {
    ...rest,
    title,
    icon,
    onConfirm,
    ModalComponent: (props) => <DialogComponent action={action} object={object} {...props} />,
  };

  return <DialogAction {...actionProps} />;
};

export const DialogAction = ({
  onConfirm = async () => {},
  title = '',
  icon = '',
  action = 'action',
  object = 'object',
  selected,
  debug = false,
  toggleAllRowsSelected,
  resetSelectAll,
  ModalComponent,
  confirmText,
  ...rest
}) => {
  const { onClick, open, onClose } = useDialog();

  const handleOnConfirm = async (e) => {
    if (e && e.preventDefault) e.preventDefault();

    if (onConfirm) {
      const success = await onConfirm({ selected, toggleAllRowsSelected, ...rest });
      if (debug || !success) return;
      onClose();
      if (toggleAllRowsSelected) toggleAllRowsSelected(false);
      if (resetSelectAll) resetSelectAll();
    }
  };

  const num = selected ? selected.length : 1;

  const actionProps = { onClick, icon, title };

  // There are definitely cases where we want the dialog component to have access
  // to the inner props. I.e. a Nested dialog
  const dialogProps = {
    title,
    isOpen: open,
    open,
    onClose,
    onConfirm: handleOnConfirm,
    num,
    selected,
    toggleAllRowsSelected,
    resetSelectAll,
  };

  return (
    <>
      <BatchAction {...rest} {...actionProps} />
      {ModalComponent ? (
        <ModalComponent {...dialogProps} />
      ) : (
        <DialogComponent {...dialogProps} action={action} object={object}>
          {confirmText}
        </DialogComponent>
      )}
    </>
  );
};

export const JsonApiRemoveAction = ({
  type,
  selected,
  bulkDestroy,
  query,
  applyToAll,
  ...rest
}) => {
  const onConfirm = async () => {
    // Safety first ya'll.
    if (!bulkDestroy || !selected) return;

    if (applyToAll) {
      // Query is required for honoring filters across pages.
      await bulkDestroy(query);
      return;
    }

    const ids = adaptToOriginalRowIds(selected);
    const payload = { data: { type, ids } };
    const resp = await bulkDestroy(payload);
    return resp;
  };

  const removeProps = {
    onConfirm,
  };

  // This component has access to Any of the props expected by RemoveAction
  // See it's definition for examples of the expected API.
  return <RemoveAction {...rest} {...removeProps} />;
};

export const TagAction = connect(null, {
  bulkCreate: attachmentActions.bulkCreate,
  bulkCreateTags: tagActions.bulkCreate,
})(
  ({
    resourceableType = 'Document',
    bulkCreate,
    bulkCreateTags,
    selected,
    updateQuery,
    data,
    toggleAllRowsSelected,
    ...props
  }) => {
    const { getInlineCreateSelectHandlerProps, setFormData, formData } = useForm({
      resource: {},
      defaults: { tags: [] },
    });

    const { onClick, open, onClose } = useDialog();
    const { disabled } = useDisabled({
      condition: formData.tags && formData.tags.length,
      dependencies: [formData.tags],
    });

    const onConfirm = async (e) => {
      if (e && e.preventDefault) e.preventDefault();
      if (!resourceableType) return;

      const adapted = adaptToOriginalRowIds(selected);
      const tags = adaptMultipleToIds(formData.tags);

      const existingTagIds = tags.filter((t) => typeof t != 'string');
      const newTags = tags.filter((t) => typeof t == 'string');
      let newTagIds = [];

      if (newTags.length) {
        const newTagsResp = await bulkCreateTags({ tags: newTags }, true);

        // TODO: since the silent arg stops the messaging, might want to still have messaging in case of error
        if (!newTagsResp.success) return;

        delete newTagsResp.success;
        newTagIds = Object.values(newTagsResp).map((t) => t.id);
      }

      const payload = adapted
        .map((id) =>
          [...existingTagIds, ...newTagIds].map((tagId) => ({
            resourceable_type: resourceableType,
            resourceable_id: id,
            attachable_id: tagId,
            attachable_type: 'Tag::Name',
          }))
        )
        .flat(1);

      if (!payload || !payload.length) return;
      const resp = await bulkCreate({ attachments: payload });
      if (!resp.success) return;

      // This will handle mapping tags back to any given resource
      // You have to pass redux data to the component because
      // we have to persist all existing tags/attachments
      // and not just add new ones.  This feels pretty reasonable overall
      if (data && updateQuery) {
        delete resp.success;
        Object.values(resp).forEach((obj) => {
          if (!obj) return;
          const updated = data[obj.resourceable_id];

          if (updated) {
            updated.attachments.push(obj);
            updateQuery({ payload: updated });
          }
        });
      }
      setFormData({});
      if (onClose) onClose();
      if (toggleAllRowsSelected) toggleAllRowsSelected(false);
    };

    const actionProps = { onClick, icon: 'Tag', title: 'Tag' };
    const dialogProps = { disabled, onConfirm, title: 'Assign Tags', isOpen: open, onClose, props };

    return (
      <>
        <BatchAction {...props} {...actionProps} />
        <ConfirmModal {...dialogProps}>
          <TswForm>
            <TagsAutocomplete {...getInlineCreateSelectHandlerProps('tags')} />
          </TswForm>
        </ConfirmModal>
      </>
    );
  }
);

export default BatchAction;
