// MUI
import { makeStyles } from '@mui/styles';

import HelpTooltip from 'components/v2/PageElements/HelpTooltip';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: 16,
    fontWeight: '700',
    color: theme.palette.text.secondary,
  },
}));

const Header = ({ text, tooltip, children }) => {
  const classes = useStyles();

  return (
    <h3 className={classes.header}>
      {children}
      {tooltip && <HelpTooltip title={tooltip} />}
    </h3>
  );
};

export default Header;
