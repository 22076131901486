import React from 'react';
import { connect } from 'react-redux';

// Mui
import List from '@mui/material/List';

// Components
import Buttons from './Dialog/Buttons';
import Checkbox from 'components/v2/FormElements/Checkbox';
import KeyboardDatePicker from 'components/v2/FormElements/KeyboardDatePicker';
import ListItem from 'components/v2/FormElements/ListItem';
import Tooltip from 'components/v2/FormElements/Tooltip';
import TswForm, { useForm, useRequiredFields } from 'components/v2/FormElements';
import {
  DigitalFormTemplatesAutocomplete,
  UsersAutocomplete,
} from 'components/v2/FormElements/Autocomplete';

// Actions
import formActions from 'store/v2/attachedDigitalForms/actions';
import digitalFormActions from 'store/v2/digitalFormRequests/actions';

//Adapters
import { adaptToDetail as adaptTemplateToDetail } from 'adapters/v1/digitalFormTemplates/formData';
import {
  adaptToRequest,
  adaptToDigitalFormRequest,
  adaptToDetail,
} from 'adapters/v1/attachedDigitalForms/formData';

const AddForm = ({
  afterCancel,
  callback,
  createAssignment,
  createForm,
  debug = false,
  digitalFormTemplates = {},
  disabled = false,
  formable_id,
  formable_type,
  formLoading = false,
  isDialog = false,
  onClick,
  onClose,
  open,
  preferences,
  profile,
  profileId,
  requestLoading = false,
  resource = {},
  subjects = [],
  ...rest
} = {}) => {
  const loading = formLoading || requestLoading;
  const [signerRoles, setSignerRoles] = React.useState([{ name: 'Name', order: 1 }]); // eslint-disable-line

  const requiredFields = ['due_date', 'template_id'];
  const signerFields = signerRoles
    .filter((obj) => obj && obj.name)
    .map((obj) => `user_id.${obj.name}`);
  const validate = useRequiredFields(requiredFields.concat(signerFields));

  const quickAssignOptions = subjects.map(({ id, title }) => ({ label: id, title }));
  const userPrependOptions = [...quickAssignOptions];

  const keyName = `user_id.${signerRoles[0].name}`;
  const userDefaults = profileId ? { [keyName]: { label: profileId, title: 'Myself' } } : {};

  const defaults = {
    signer_roles: signerRoles,
    send_notification_email: true,
    due_date: new Date(Date.now() + 12096e5),
    ...userDefaults,
  };

  const handleCreate = async () => {
    const { template_id } = formData;
    if (!template_id) return;

    const { label } = template_id;
    const form = digitalFormTemplates[label] || {};
    const { id } = form;

    const formObj = {
      form_id: id,
      formable_type,
      formable_id,
    };

    const adapted = adaptToRequest(formObj);
    const formResp = await createForm({ ...adapted });

    if (!formResp || !formResp.id) return;

    const newAttachedDigitalForm = formResp && formResp.id;
    formData.attached_digital_form_id = newAttachedDigitalForm;

    const adaptedDF = adaptToDigitalFormRequest(formData);
    const assignmentResp = await createAssignment({ ...adaptedDF });

    if (!assignmentResp || !assignmentResp.id) return;
    callback && callback(adaptToDetail(formResp), adaptToDetail(assignmentResp));

    onClose();
  };

  const {
    handleSubmit,
    getRequiredTextProps,
    getSelectHandlerProps,
    getSwitchHandlerProps,
    getTextFieldHandlerProps,
    formData,
    setFormData,
  } = useForm({
    validate,
    resource,
    afterCancel,
    callback: handleCreate,
    defaults,
  });

  const handleSignerRoles = (selectValue = {}) => {
    const out = { signer_roles: [] };
    if (!selectValue || !selectValue.label) return {};

    const template_id = selectValue.label;
    const template = digitalFormTemplates[template_id];
    if (!template) return out;

    const adapted = adaptTemplateToDetail(template);

    if (adapted && adapted.signer_roles) {
      out.signer_roles = adapted.signer_roles;
      setSignerRoles(adapted.signer_roles);
    }

    return out;
  };

  // FIX HOOK
  React.useEffect(() => {
    let payload = { ...formData, ...handleSignerRoles(formData.template_id) };

    if (!formData.template_id) {
      payload = defaults;
    }

    setFormData(payload);
  }, [formData.template_id]); // eslint-disable-line

  return (
    <TswForm variant="max">
      {debug && JSON.stringify(formData)}
      <List>
        <ListItem>
          <DigitalFormTemplatesAutocomplete
            {...getSelectHandlerProps('template_id')}
            textFieldProps={{ ...getRequiredTextProps('template_id') }}
            disabled={disabled}
            groupBy={(option) => option.type}
            label="Digital Form"
            multiple={false}
            value={formData.template_id || {}}
          />
        </ListItem>
        {formData.template_id && (
          <>
            {formData.signer_roles.map((obj, i) => {
              const role = obj.name;
              const prefix = 'user_id';
              const key = `${prefix}.${role}`;

              let label = `${role} Signer`;

              return (
                <ListItem key={`role-${role}`}>
                  <UsersAutocomplete
                    {...getSelectHandlerProps(key)}
                    textFieldProps={{ ...getRequiredTextProps(key) }}
                    disabled={disabled}
                    groupByLabel={'All Users'}
                    label={label}
                    multiple={false}
                    prependOptions={userPrependOptions || []}
                    skipList={true}
                    value={formData[key] || {}}
                  />
                </ListItem>
              );
            })}
          </>
        )}

        <ListItem>
          <KeyboardDatePicker
            {...getTextFieldHandlerProps('due_date')}
            {...getRequiredTextProps('due_date')}
            label="Due Date"
            disabled={disabled}
            placeholder=""
          />
        </ListItem>
        <ListItem>
          <Tooltip
            title={
              formData.send_notification_email
                ? 'Send email notification immediately'
                : 'Allow users to be notified via weekly email digest'
            }
          >
            <div style={{ width: 'fit-content' }}>
              <Checkbox
                {...getSwitchHandlerProps('send_notification_email')}
                label={'Send Email Notification'}
                disabled={disabled}
              />
            </div>
          </Tooltip>
        </ListItem>
      </List>

      <Buttons
        clickText="Add"
        onCancel={onClose}
        loading={loading}
        onClick={handleSubmit}
        disabled={disabled}
      />
    </TswForm>
  );
};

const mapStateToProps = ({
  digitalFormTemplates: { dropdown },
  attachedDigitalForms,
  digitalFormRequests,
}) => {
  const mapped = {};

  Object.entries(dropdown).forEach(([key, value]) => {
    mapped[value.attributes.template_id] = value;
  });

  return {
    digitalFormTemplates: mapped,
    formLoading: attachedDigitalForms.loading,
    requestLoading: digitalFormRequests.loading,
  };
};

export default connect(mapStateToProps, {
  createForm: formActions.create,
  createAssignment: digitalFormActions.create,
})(AddForm);
