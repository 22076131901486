import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { useProfileContext } from 'components/v2/Profile/Context';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import { Switch } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths from 'config/paths/assetControls';

// Components
const Index = lazy(() => import('./index'));
const ResourceLayout = lazy(() => import('./ResourceLayout'));

/*
 * Important thing to note about Resourceful layouts.
 *
 * If you want sublayouts such as those defined in "Detail" to successfully render
 * you cannot use exact paths, you are partial matching on the parent to ensure
 * the top level layout always gets loaded so that any child pages also get loaded.
 */

export const AssetsReduxManager = connect(mapPermissionsToProps)(({ permissions, children }) => {
  const data = permissions
    ? [
        { action: 'auditsDropdown', permission: permissions.assetControls.list },
        { action: 'contractsDropdown', permission: permissions.contracts.list },
        { action: 'destructionEventsDropdown', permission: permissions.assetControls.list },
        { action: 'facilitiesDropdown', permission: true },
        { action: 'assetControlsDropdown', permission: permissions.assetControls.list },
        { action: 'containerControlsDropdown', permission: permissions.containerControls.list },
        { action: 'peopleDropdown', permission: permissions.people.list },
      ]
        .filter((obj) => obj.permission)
        .map((obj) => obj.action)
    : [];

  return <ReduxManager data={data}>{children}</ReduxManager>;
});

export const Routes = ({ match, paths, permissions }) => {
  const { isSelf } = useProfileContext();

  // Redux Manager can handle loading any data necessary for the feature to function
  // see the component definition for available data/actions
  return (
    <AssetsReduxManager>
      <Suspense fallback={<></>}>
        <Switch>
          {(isSelf || permissions.assetControls.list) && (
            <Route exact path={paths.root}>
              <Index {...{ paths }} />
            </Route>
          )}
          {(isSelf || permissions.assetControls.list) && (
            <Route path={paths.resource.root}>
              <ResourceLayout {...{ paths }} />
            </Route>
          )}
        </Switch>
      </Suspense>
    </AssetsReduxManager>
  );
};

export default connect(mapPermissionsToProps, null)((props) => <Routes paths={paths} {...props} />);
