import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapTableToPropsFactory, mergePropsToTable } from 'mappers/v1/table';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

// actions
import actions from 'store/v2/digitalFormRequests/actions';
import paths from 'config/paths/digitalFormRequests';

// adapters
import {
  cellLinkAdapter,
  cellAdapter,
  adaptToDetail,
} from 'adapters/v1/digitalFormRequests/formData';

export const stateBuilder = (paramId = 'id', featureReduxKey = 'digitalFormRequests') => {
  const mapDataToTable = mapTableToPropsFactory(featureReduxKey);

  const mapResourceToProps = (store, ownProps) => {
    const reduxKey = store[featureReduxKey];
    // Map resource from store, we've wrapped the component
    // in withRouter so match is Present.
    const { match, resource } = ownProps;
    const resourceId =
      match && match.params ? Number(match.params[paramId]) : resource && resource.id;
    return { resourceId, resource: adaptToDetail(reduxKey.data[resourceId]) };
  };

  const mapStateToProps = ({ profile, people, ...rest }) => {
    // Generically map activity from any v2 store key in redux
    const { activity, loading } = rest[featureReduxKey] || {};

    return {
      activity: Object.values(activity || {}),
      users: people.dropdown,
      loading,
      profile,
      ...mapPermissionsToProps({ profile }),
    };
  };

  const include = { include: 'comments,digital_form_template,documents,tag_names,user' };

  const mapDispatchToProps = {
    ...actions,
    list: (queryParams) =>
      actions.list({
        sort: 'created_at',
        dir: 'desc',
        ...queryParams,
        include: 'user,digital_form_template,tag_names',
      }),
    show: (id, payload, silent) => actions.show(id, { ...payload, ...include }, silent),
    update: (id, payload, silent) => actions.update(id, { ...payload, ...include }, silent),
    create: (payload, silent) => actions.create({ ...payload, include: 'user,tag_names' }, silent),
  };

  const withPaths = (Component) => (props) => <Component paths={paths} {...props} />;

  return {
    withTableState: (Component) => {
      return connect(
        (store) => ({ ...mapPermissionsToProps(store), ...mapDataToTable(store) }),
        mapDispatchToProps,
        mergePropsToTable
      )(
        withPaths((props) => (
          <Component {...props} cellLinkAdapter={cellLinkAdapter} cellAdapter={cellAdapter} />
        ))
      );
    },
    withFeatureState: (Component) => {
      return withRouter(connect(mapStateToProps, mapDispatchToProps)(withPaths(Component)));
    },
    withResourceState: (Component) => {
      return withRouter(
        connect(
          (store, ownProps) => ({
            ...mapStateToProps(store),
            ...mapResourceToProps(store, ownProps),
            ...mapPreferencesToProps(store),
          }),
          mapDispatchToProps
        )(withPaths(Component))
      );
    },
  };
};

// by encapsulating all of our HOCs into a single function definition, I can cleanly swap out minimal state to reuse components across routes.
// This is literally a function, that returns an object, that contains 3 higher order components composed of 1 or more higher order components..
export const { withFeatureState, withResourceState, withTableState } = stateBuilder();
