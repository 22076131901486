import React from 'react';

// MUI
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { styled } from '@mui/material/styles';

// components
import DocumentAvatar from './Avatar';
import DownloadLink from 'components/v1/DownloadLink';
import SidebarListItem from 'components/v2/PageElements/Sidebar/ListItem';

const StyledListItem = styled(SidebarListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  alignItems: 'center',
  '& p': {
    padding: 0,
  },
  margin: 0,
}));

const DeleteIcon = styled(IconButton)(({ theme }) => ({
  right: -5,
  marginTop: -1,
}));

const StyledLink = styled(ListItemText)(({ theme }) => ({
  '& > div': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const AttachmentItem = ({ attachment = {}, destroy, permissions = {}, icon }) => {
  const { id, relativeCreatedAt } = attachment;

  return (
    <StyledListItem>
      <ListItemAvatar sx={{ marginTop: 0 }}>
        <DocumentAvatar md {...attachment} icon={icon} />
      </ListItemAvatar>

      <StyledLink dense primary={<DownloadLink {...attachment} />} secondary={relativeCreatedAt} />

      {/* if a document is pushed into this list (like on auto generated training, it won't have an id and can't be deleted) */}
      {permissions.destroy && (id || permissions.skipId) && (
        <ListItemSecondaryAction>
          <DeleteIcon aria-label="delete" onClick={() => destroy(id)}>
            <DeleteOutlineOutlinedIcon />
          </DeleteIcon>
        </ListItemSecondaryAction>
      )}
    </StyledListItem>
  );
};

export default AttachmentItem;
