import React from 'react';

// MUI
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// components
import Button from 'components/v2/FormElements/Button';

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(1),
    background: theme.palette.backgroundBasic.main,
    borderRadius: 10,
    marginLeft: -10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    paddingLeft: 10,
    color: theme.palette.info.dark,
    fontSize: 16,
    fontWeight: 500,
  },
  caption: {
    fontStyle: 'italic',
    fontSize: '0.75rem',
    paddingRight: 5,
    maxWidth: '60%',
    alignItems: 'center',
    display: 'contents',
  },
  content: {
    // marginLeft: theme.spacing(3),
    // marginRight: theme.spacing(3),
  },
  icon: {
    color: theme.palette.info.dark,
    width: 32,
    height: 32,
  },
}));

// generally there should only be an onClick or caption and not both
const Section = ({
  title,
  Icon,
  ActionItem,
  children,
  caption,
  CaptionIcon = null,
  permission = true,
  onClick,
  clickText = 'Edit',
}) => {
  const classes = useStyles();

  if (!permission) return null;

  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        className={classes.header}
      >
        <Box className={classes.titleContainer}>
          {Icon && <Icon className={classes.icon} />}
          <Typography variant="h3" className={classes.title} component="span">
            {title}
          </Typography>
        </Box>
        {caption && (
          <Box display="flex">
            {CaptionIcon && <CaptionIcon />}
            <Typography variant="caption" className={classes.caption}>
              {caption}
            </Typography>
          </Box>
        )}
        {onClick && (
          <Button onClick={onClick} size="small">
            {clickText}
          </Button>
        )}
        {ActionItem && <ActionItem />}
      </Box>
      <div className={classes.content}>{children}</div>
    </Box>
  );
};

export default Section;
