import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from 'assets/images/logo/logomark.svg';

import defaultPath from 'config/paths';

// MUI
import { makeStyles } from '@mui/styles';

const LOGO_SIZE = 70;
const LOGO_PADDING = 1;
export const FULL_LOGO_HEIGHT = LOGO_SIZE + LOGO_PADDING * 2;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'block',
    padding: LOGO_PADDING,
    height: LOGO_SIZE,
    width: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundOrigin: 'content-box',
  },
}));

const CompanyLogo = ({ logo }) => {
  const classes = useStyles();

  return (
    <Link
      className={classes.wrapper}
      to={defaultPath}
      style={{ backgroundImage: `url("${logo}")` }}
    >
      <div />
    </Link>
  );
};

const mapStateToProps = ({ profile }) => {
  const logo = profile?.company?.logo ?? Logo;
  return { logo };
};

export default connect(mapStateToProps)(CompanyLogo);
