import { makeStyles } from '@mui/styles';
import HelpIcon from '@mui/icons-material/Help';
import Button from '@mui/material/Button';

import { twilight, ash } from 'lib/v2/colors';
import Tooltip from 'components/v2/FormElements/Tooltip';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    float: 'right',
    marginTop: '-0.7em',
  },
  icon: {
    color: ash,
    width: '1.25rem',
    marginTop: '-0.25rem',
    '&:hover': {
      color: twilight,
      cursor: 'help',
    },
  },
}));

const Index = ({ title }) => {
  const classes = useStyles();

  return (
    <Tooltip title={title} className={classes.tooltip}>
      <Button endIcon={<HelpIcon />}>Need Help?</Button>
    </Tooltip>
  );
};

export default Index;
