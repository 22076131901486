import options from 'config/constants/options';

const roles = options.roles.map((d) => d.value);

export function getLevel(user) {
  return roles.indexOf(user.role);
}

export function omit(obj, keys) {
  if (typeof obj !== 'object') return {};

  keys = [].concat.apply([], [].slice.call(arguments, 1));
  var last = keys[keys.length - 1];
  var res = {},
    fn;

  if (typeof last === 'function') {
    fn = keys.pop();
  }

  var isFunction = typeof fn === 'function';
  if (!keys.length && !isFunction) {
    return obj;
  }
  Object.entries(obj).forEach(([key, value]) => {
    if (keys.indexOf(key) === -1) {
      if (!isFunction) {
        res[key] = value;
      } else if (fn(value, key, obj)) {
        res[key] = value;
      }
    }
  });

  return res;
}

// via https://hackernoon.com/7aac18c4431e
export function promiseSerial(arr, step, callback) {
  const funcs = arr.map(step);

  const promiseList = (funcs) =>
    funcs.reduce(
      (promise, func) => promise.then((result) => func().then(Array.prototype.concat.bind(result))),
      Promise.resolve([])
    );

  return promiseList(funcs).then(callback);
}

// Returns true if string is empty, or is only whitespace
export function isEmptyString(str) {
  if (str === '') {
    return true;
  } else if (typeof str !== 'string') {
    return false;
  } else if (str.replace(/\s/g, '').length === 0) {
    return true;
  }
  return false;
}

// JSON Schema form fails if we pass null to a field
// because null is a proper jsonschema type
// via https://stackoverflow.com/questions/23774231
export function denull(obj) {
  Object.keys(obj).forEach(
    (k) =>
      (obj[k] && typeof obj[k] === 'object' && denull(obj[k])) || (obj[k] === null && delete obj[k])
  );
  return obj;
}

// Generate unique IDs.  This is useful for form labels.
// via https://stackoverflow.com/questions/29420835
let lastId = 0;
export function uniqueId(prefix = 'id') {
  lastId++;
  return `${prefix}${lastId}`;
}

export function serializeFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => resolve(reader.result.split(',')[1]);
  });
}

// Returns the current logged-in user ID
export function getProfileId() {
  const profile = JSON.parse(window.localStorage.getItem('profile') || null);
  return profile.id;
}
