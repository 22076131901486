import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

import Menu from 'components/v2/PageElements/Menu';
import Button from 'components/v2/FormElements/Button';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 !important',
    margin: '0 !important',
    width: 0,
    opacity: 0,
    overflow: 'hidden',
    '@media (max-width: 980px)': {
      padding: '0 !important',
    },
  },
  button: {
    // backgroundColor: theme.palette.secondary.main,
    // border: '1px solid ' + theme.palette.divider,
    padding: '3px 7px',
    fontSize: '0.75rem',
    '@media (max-width: 980px)': {
      padding: '3px 3px 3px 2px',
      '& .MuiButton-endIcon': {
        marginLeft: 0,
      },
    },
  },
  label: {
    '@media (max-width: 980px)': {
      display: 'none',
    },
  },
}));

const RowActionDropdown = ({ rowItem, rowActions = [] }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActionDropdown = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box className={open ? `${classes.box} rowActionOpen` : `${classes.box} rowAction`}>
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        aria-label="perform row action"
        aria-haspopup="menu"
        className={classes.button}
        onClick={handleClick}
        endIcon={open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      >
        <Box className={classes.label}>Actions</Box>
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={closeActionDropdown}>
        {rowActions.map((Action, index) => {
          const id = `action-${index}`;
          return (
            <Action
              closeActionDropdown={closeActionDropdown}
              rowItem={rowItem}
              key={`row-action-${id}`}
              id={id}
            />
          );
        })}
      </Menu>
    </Box>
  );
};

export default RowActionDropdown;
