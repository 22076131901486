export function capitalize(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1).replace('_', ' ');
}

export const randomNumericString = (length = 12) => {
  return `${new Date().getTime()}`.slice(-length);
};

export const randomString = (length = 10) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const camelToSnakeCase = (str) => {
  // need to make sure first char is lowercase
  str = str.charAt(0).toLowerCase() + str.slice(1);
  // convert any other uppcase to lower with a prepended underscore
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};
