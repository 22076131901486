import { styled } from '@mui/material/styles';

import { blue, twilight } from 'lib/v2/colors';
import { capitalize } from 'lib/v1/strings';
import * as Icons from './Icons';

const StyledIcon = styled('svg')(({ color, disabled, flip }) => ({
  fill: 'currentColor',
  transition: 'transform 400ms',
  ...(flip && { transform: 'rotate(180deg)' }),
  ...(disabled && { opacity: 0.3 }),
  ...(color === 'blue' && { fill: blue }),
  ...(color === 'twilight' && { fill: twilight }),
}));

function Icon({ color, flip, name, onClick, ...rest }) {
  const IconComponent = Icons[capitalize(name)];

  return (
    <StyledIcon
      as={IconComponent}
      color={color}
      flip={flip ? `${flip}` : undefined}
      onClick={onClick ? onClick : undefined}
      {...rest}
    />
  );
}

export default Icon;
