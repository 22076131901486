import React from 'react';

const Tick = ({ width = '64px', height = '64px', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 64 64" {...rest}>
    <path
      d="M50,18c-0.828,0-1.578,0.336-2.121,0.879L27,39.757L16.121,28.879C15.578,28.336,14.828,28,14,28c-1.657,0-3,1.343-3,3
      c0,0.828,0.336,1.578,0.879,2.121l13,13C25.422,46.664,26.172,47,27,47s1.578-0.336,2.121-0.879l23-23
      C52.664,22.579,53,21.828,53,21C53,19.343,51.657,18,50,18z"
    />
  </svg>
);

export default Tick;
