import { memo } from 'react';
import store from 'store';

// Components
import TextField from 'components/v2/FormElements/TextField';

// Factories
import FilterFormFactory from 'components/v2/Filters/Factories';

const Search = ({ callback, ...rest }) => {
  const onChange = (e) => {
    if (callback) callback(e.target.value);
  };

  const textFieldProps = { ...rest, onChange };
  return <TextField {...textFieldProps} />;
};

export const SearchFilter = ({ name, displayName, updateQuery, ...rest }) => {
  const onSubmit = (value) => {
    const _displayName = `${displayName}: ${value}`;

    store.dispatch(
      updateQuery({
        filters: { [name]: { values: value, displayName: _displayName } },
      })
    );
  };

  const FilterForm = FilterFormFactory(Search);

  const formProps = { ...rest, onSubmit, displayName };
  return <FilterForm {...formProps} />;
};

export const BuildSearchFilter = ({ name, displayName, ...rest }) => {
  return ({ updateQuery }) => {
    const searchProps = {
      name,
      displayName,
      updateQuery,
      ...rest,
    };

    return {
      Component: (props) => <SearchFilter {...searchProps} {...props} />,
      label: displayName,
      value: name,
    };
  };
};

export default memo(Search);
