import React from 'react';

// Components
import Button from 'components/v2/FormElements/Button';
import Dialog from './Dialog';
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import { useDialog } from 'components/v2/FormElements/Dialog';

// hooks
import { withFeatureState } from '../state';

const Notify = ({ permissions, preferences, history }) => {
  const { onClick, onClose, open } = useDialog();

  const ticketData = [
    'cardsSchema',
    'cardTemplatesDropdown',
    'digitalFormTemplatesDropdown',
    'peopleDropdown',
    'customFieldsDropdown',
    'incidentTemplatesDropdown',
  ];
  if (permissions.contacts?.list) ticketData.push('contactsDropdown');

  return (
    <ReduxManager data={ticketData} showLoading={false}>
      <Dialog onClose={onClose} open={open} />
      <Button
        color="notify"
        onClick={onClick}
        sx={{ marginRight: '3rem', paddingLeft: '2rem', paddingRight: '2rem' }}
      >
        + NOTIFY
      </Button>
    </ReduxManager>
  );
};

export default withFeatureState(Notify);
