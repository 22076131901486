import reducer, { initialState } from 'store/v2/index';
import { assignedActionType, unassignedActionType } from './constants';

const initialContractState = {
  ...initialState(),
  query: { ...initialState().query, filters: { active: [true] } },
  filters: {
    active: {
      values: [true],
      displayName: 'Active: Yes',
    },
  },
};

// We have to have two reducers because we are dealing with different data entirely
export const contractUsersUnassigned = reducer(unassignedActionType, initialContractState);
export const contractUsers = reducer(assignedActionType, initialContractState);
