import { adaptToActiveFilters } from 'adapters/v1/filters/table';
import hash from 'object-hash';

export const mapTableToPropsFactory = (key) => {
  return (store) => {
    const {
      [key]: { loading, data, result, meta, filters, query, initialState, storeStateId, schema },
    } = store;

    const { per_page, search } = query;
    // This is necessary because
    // FlexTable/Table.js updates it's components
    // using a sortBy param from react-table-7.
    // and cases a double update
    //
    // We omit it because this is tracked via flextable v2 itself
    // and doesn't need to be included in the redux changes.
    delete query.sort;
    delete query.dir;
    delete query.count;
    delete query.include;

    const activeFilters = adaptToActiveFilters(filters);

    let trigger = {};

    // Experimental
    if (activeFilters && activeFilters.length) {
      // Experiment, there are definitely cases where we want to refresh the table after an action, i.e. if we have updated the state of a
      // value that may impact a filter.
      trigger = result; // Result is just a function of the identifiers and not the actual data, thus avatar URL should not impact this
    } else {
      trigger = {};
    }

    const stateId = hash({ query, trigger, storeStateId });

    return {
      stateId,
      initialState,
      data: result.map((id) => data[id]),
      activeFilters,
      loading,
      search,
      schema,
      per_page,
      query,
      ...meta,
    };
  };
};

export const mergePropsToTable = (propsFromState, propsFromDispatch, ownProps) => {
  const { query } = propsFromState;
  const { list, listByUser, updateQuery } = propsFromDispatch;
  // Handle case where a userId is specifically passed to the table
  // for use in user specific tables.
  const { userId } = ownProps;

  if (!list || !updateQuery)
    throw new Error(
      "ContractViolation: Missing required dispatch actions 'list' and/or 'updateQuery'"
    );
  if (!query) throw new Error("ContractViolation: Missing required store object 'query'");

  // For Search, Pagination, Table, and ActiveFilter components
  const _list = (queryParams) => {
    if (listByUser && userId) return listByUser(userId, { ...query, ...queryParams });
    return list({ ...query, ...queryParams });
  };

  // For Search Component
  const onSearch = (term) => {
    updateQuery({ query: { search: term } });
  };

  // For ActiveFilters Component
  const onCancelPil = (filterName) =>
    updateQuery({
      filters: { [filterName]: null },
    });

  return {
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,
    list: _list,
    onCancelPil,
    onSearch,
  };
};
