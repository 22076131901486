import { isAnyAdmin } from 'permissions/v1/roles';
import { canList } from 'permissions/v1/index';

// Custom role permissions that give a user access to admin
const listPermissible = (profile) => {
  const adminItems = [
    canList('Badge', profile),
    canList('Certificate', profile),
    canList('ClientCredential', profile),
    canList('Container', profile),
    canList('Contract', profile),
    canList('Document', profile),
    canList('DigitalFormTemplate', profile),
    canList('Facility', profile),
    canList('Form', profile),
    canList('Import', profile),
    canList('Requirement', profile),
    canList('SecretKey', profile),
  ];

  return adminItems.some(Boolean);
};

const list = (profile) => isAnyAdmin(profile) || listPermissible(profile);
const view = (profile) => isAnyAdmin(profile);
const create = (profile) => isAnyAdmin(profile);
const update = (profile) => isAnyAdmin(profile);
const destroy = (profile) => isAnyAdmin(profile);

const permissions = (profile) => {
  return {
    list: list(profile),
    view: view(profile),
    create: create(profile),
    update: update(profile),
    destroy: destroy(profile),
  };
};

export default permissions;
