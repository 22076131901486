import React from 'react';

import DataTable, { dateFieldProps } from 'components/v2/PageElements/DataTable';
import {
  buttonCellRender,
  comboCellRender,
} from 'components/v2/PageElements/DataTable/CellRenderers';
import { deleteActionItem } from 'components/v2/PageElements/DataTable/RowActionItem';

import { withFeatureState } from './state';

const Accesses = ({
  destroy,
  history,
  list,
  loading,
  paths,
  permissions,
  userAccesses,
  userId,
}) => {
  const cellClickHandler = ({ id }) => {
    paths && history.push(paths.resource.detail(id));
  };

  const columns = [
    {
      field: 'access',
      headerName: 'Name',
      flex: 1,
      renderCell:
        permissions.userAccess.update && paths
          ? buttonCellRender('access', { onClick: cellClickHandler })
          : undefined,
    },
    {
      field: 'contractName',
      headerName: 'Contract',
      renderCell: comboCellRender('contractDetails'),
      flex: 1,
    },
    {
      field: 'facilityNames',
      headerName: 'Contract Facility',
      renderCell: comboCellRender('facilityNames'),
      flex: 1,
    },
    { field: 'briefDate', headerName: 'Briefed On', ...dateFieldProps },
    { field: 'debriefDate', headerName: 'Debriefed On', ...dateFieldProps },
  ];

  if (permissions.userAccess.destroy) {
    columns.push(deleteActionItem({ destroy, nameProp: 'access', type: 'access' }));
  }

  const tableProps = {
    columns,
    defaultSort: { field: 'briefDate', sort: 'desc' },
    forcedFilters: { user_id: userId },
    hideFooter: true,
    list,
    loading,
    pageSizeOptions: [500],
    rows: userAccesses,
  };

  return (
    <>
      <DataTable {...tableProps} />
    </>
  );
};

export default withFeatureState(Accesses);
