export function canSeeAnalyticsSidebar({ looker } = {}) {
  // If a user has a looker group_id it means they
  // must be enabled and configured, otherwise the backend sends empy object
  if (!looker) {
    return false;
  }
  if (!looker.group_id) {
    return false;
  }

  return true;
}

export function needsConfiguration({ looker } = {}) {
  if (!looker) {
    return true;
  }

  // We will redirect to a splash page where we can do some background work to create necessary credentials and save ids
  if (looker.group_id && !looker.user_id) {
    return true;
  }

  return false;
}
