import React from 'react';

import { withFeatureState } from './state';
import { Refresh } from 'components/v2/FlexTable/RowActionDropdownItem';
import { Delete } from 'components/v2/FlexTable/RowActionItem';

import TagRowAction from 'components/v2/FlexTable/TagRowAction';

import { adaptToForm, adaptFormTagsToRequest } from 'adapters/v1/digitalFormTemplates/formData';

export const TagDigitalFormTemplate = withFeatureState((rest) => {
  const actionProps = {
    adaptToForm,
    adaptToRequest: adaptFormTagsToRequest,
    taggableType: 'Company::Template',
  };
  return <TagRowAction {...actionProps} {...rest} />;
});

export const ConnectedDelete = withFeatureState(Delete);
export const ConnectedRefresh = withFeatureState(Refresh);
