import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledSection = styled(Box)(({ theme }) => ({}));

function NavSection({ children }) {
  return <StyledSection>{children}</StyledSection>;
}

NavSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

export default NavSection;
