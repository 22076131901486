// MUI
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Archive from '@mui/icons-material/Archive';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { makeStyles, useTheme } from '@mui/styles';
import Unarchive from '@mui/icons-material/Unarchive';

//Components
import Button from 'components/v2/FormElements/Button';
import SidebarListItem from './ListItem';
import { useDialog } from 'components/v2/FormElements/Dialog';

import ConfirmDialog from 'components/v2/FormElements/ConfirmModal';

const styles = makeStyles(() => ({
  listItem: {
    padding: '0',
    margin: '0.5rem 0',
    borderRadius: 10,
    transition: 'all 0.5s ease',
    '&:hover': {
      boxShadow: '0 0 10px -5px rgba(0,0,0,0.3)',
    },
  },
  button: {
    justifyContent: 'left',
    paddingLeft: 0,
  },
}));

const SidebarActionListItem = ({
  buttonText,
  confirmText,
  children,
  onClick,
  onClose,
  open,
  onConfirm,
  icon = <AddCircleOutlineIcon />,
  color = 'primary',
  message,
  title,
  disabled = false,
  loading = false,
  alert,
  success,
  actionComponent,
  DialogComponent, // Note prefer this prop over actionComponent
  variant = 'outlined',
  permission = true,
  ...rest
}) => {
  const classes = styles();
  const dialogProps = useDialog();
  const RenderedDialog = DialogComponent || actionComponent;
  const renderDialogProps = {
    onConfirm,
    confirmText,
    open: open || dialogProps.open,
    onClose: onClose || dialogProps.onClose,
    onClick: onClick || dialogProps.onClick,
  };
  const theme = useTheme();

  const loadingColor =
    variant === 'text' ? theme.palette[color].main : theme.palette[color].contrastText;

  return (
    <>
      {permission ? (
        <SidebarListItem className={classes.listItem}>
          <Button
            disabled={loading || disabled}
            color={color}
            variant={variant}
            onClick={onClick || dialogProps.onClick}
            className={classes.button}
            fullWidth
          >
            <ListItemAvatar>
              {loading ? <CircularProgress size="1.5rem" style={{ color: loadingColor }} /> : icon}
            </ListItemAvatar>
            {buttonText}
          </Button>
          {!!RenderedDialog && <RenderedDialog {...renderDialogProps} {...rest} />}
          {!!onConfirm && !RenderedDialog && (
            <ConfirmDialog {...dialogProps} {...{ message, title, onConfirm, confirmText }} />
          )}
          {children}
        </SidebarListItem>
      ) : null}
    </>
  );
};

export const ArchiveSidebarActionListItem = ({
  archive,
  archived,
  id,
  loading,
  permission,
  unarchive,
}) => (
  <SidebarActionListItem
    buttonText={`${archived ? 'Unarchive' : 'Archive'}`}
    color={archived ? 'success' : 'primary'}
    icon={archived ? <Unarchive /> : <Archive />}
    loading={loading}
    onClick={() => (archived ? unarchive(id) : archive(id))}
    permission={permission}
    variant="contained"
  />
);

export default SidebarActionListItem;
