import React from 'react';
import PropTypes from 'prop-types';
import ConfirmButtons from 'components/v1/ConfirmButtons';

const FormLayout = ({ onClose, onSubmit, children, displayName }) => {
  const [selected, setSelected] = React.useState([]);

  const callback = (props) => {
    setSelected(props);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!onClose || !onSubmit) return;
    onSubmit(selected);
    onClose();
  };

  const formTitle = displayName ? `Add ${displayName} Filter` : 'Add Filter';

  return (
    <div className="Filters-form">
      <form className="Manage-section" onSubmit={handleOnSubmit}>
        <div className="Filters-form-title">{formTitle}</div>
        <div className="Filters-form-single">
          {children ? React.cloneElement(children, { callback }) : undefined}
        </div>
        <div className="Manage-row">
          <ConfirmButtons loading={false} onClose={onClose} text="Apply" noPadding />
        </div>
      </form>
    </div>
  );
};

FormLayout.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FormLayout;
