import React from 'react';

const Investigation = ({ width = '64px', height = '64px', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 64 64" {...rest}>
    <path
      d="M62.243,53.758L44.658,36.173C46.768,32.602,48,28.449,48,24C48,10.745,37.255,0,24,0S0,10.745,0,24s10.745,24,24,24
      c4.449,0,8.602-1.232,12.173-3.342l17.584,17.584C54.843,63.329,56.343,64,58,64c3.314,0,6-2.686,6-6
      C64,56.343,63.328,54.843,62.243,53.758z M24,42c-9.941,0-18-8.059-18-18c0-9.941,8.059-18,18-18c9.941,0,18,8.059,18,18
      C42,33.941,33.941,42,24,42z"
    />
  </svg>
);

export default Investigation;
