import { pathBuilder } from './index';

export const root = '/admin/integrations';

// We will likely need both of these along with the base paths
export const clientCredentialsPaths = pathBuilder(`${root}/credentials`);
export const secretKeysPaths = pathBuilder(`${root}/credentials`);
export const thirdPartyPaths = pathBuilder(`${root}/third_party`);
export const webhooksPaths = pathBuilder(`${root}/webhooks`);

export const combinedPaths = {
  credentials: clientCredentialsPaths,
  keys: secretKeysPaths,
  thirdParty: thirdPartyPaths,
  webhooks: webhooksPaths,
};

export default pathBuilder(root);
