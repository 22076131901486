import React from 'react';

const Document = ({ width = '64px', height = '64px', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 64 64" {...rest}>
    <path
      d="M32,17.001V0H16c-1.657,0-3,1.343-3,3v52c0,1.657,1.343,3,3,3h38c1.657,0,3-1.343,3-3V23.015H38
      C34.686,23.015,32,20.322,32,17.001z M38,20.008h19L35-0.036v17.037C35,18.662,36.343,20.008,38,20.008z M10,55V6
      C8.343,6,7,7.343,7,9v49c0,3.314,2.686,6,6,6h35c1.657,0,3-1.343,3-3H16C12.686,61,10,58.314,10,55z"
    />
  </svg>
);

export default Document;
