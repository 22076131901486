import { adaptToDisplay } from 'adapters/v1/dates';
import { adaptJsonApiToDetail } from 'adapters/v1/json_api';

const dateFields = ['created_at'];

export const adaptToDetail = (resource) => {
  if (!resource) return {};

  const adapted = adaptJsonApiToDetail(resource);

  return adaptToDisplay(adapted, dateFields);
};
