import React from 'react';
import { connect } from 'react-redux';

import { getReportNotDumb as getReport } from 'store/v1/reports/actions';

import Tooltip from 'components/v2/FormElements/Tooltip';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';

const ReportButton = ({ query, report, getReport }) => {
  const [loading, setLoading] = React.useState(false);

  if (!report) return null;

  const onClick = async () => {
    setLoading(true);
    await getReport(report, query);
    setLoading(false);
  };

  return (
    <Tooltip title="Export Results">
      <IconButton onClick={onClick} disabled={loading} color="primary">
        <FileDownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

export default connect(null, { getReport })(ReportButton);
