import React from 'react';

// MUI
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100%',
    '@media (max-width: 900px)': {
      display: 'block',
    },
  },
  content: {
    padding: '8px 15px 8px 8px',
    '@media (max-width: 900px)': {
      padding: '8px 8px 20px',
      maxWidth: '100%',
    },
  },
  // the extra bottom padding insures there is always room for the intercom button
  sidebar: {
    padding: '8px 8px 100px 15px',
    borderLeft: `1px solid ${theme.palette.divider}`,
    minHeight: '100%',
    '@media (max-width: 900px)': {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderLeft: 'none',
      padding: '20px 8px 100px',
      maxWidth: '100%',
    },
  },
}));

const Sidebar = ({ children, sidebarContent }) => {
  const classes = useStyles();

  if (!sidebarContent) {
    return <>{children}</>;
  }

  return (
    <Grid container className={classes.container + ' sidebar'}>
      <Grid item xs={8} className={classes.content}>
        {children}
      </Grid>
      <Grid item xs={4} className={classes.sidebar}>
        {sidebarContent}
      </Grid>
    </Grid>
  );
};

export default Sidebar;
