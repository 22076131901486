import { useEffect, useRef, useState } from 'react';

import store from 'store';
import { connect, useSelector } from 'react-redux';
import { useIdleTimerContext, IdleTimerProvider } from 'react-idle-timer';

import { defaultMoment, IDLE_TIMEOUT, PROMPT_TIMEOUT } from 'config';

import { useInterval } from 'lib/v1/hooks';

import { mapPreferencesToProps } from 'mappers/v1/preferences';

import { add_message } from 'store/v1/app/actions';
import { logout } from 'store/v1/authentication/actions';
import actions from 'store/v2/facilities/actions';
import { statePath } from 'store/v2/idleTimer/constants';

import TswDialog, { useDialog } from 'components/v2/FormElements/DialogV2';
import DialogButtons from 'components/v2/FormElements/DialogV2/Buttons';

const IdleDialog = ({ onCancel, onClick, open, ...rest }) => {
  const idleTimer = useRef(useIdleTimerContext()).current;
  const [idle, setIdle] = useState(idleTimer?.getRemainingTime());
  useInterval(() => setIdle(idleTimer?.getRemainingTime()), 1000);

  const count = useSelector((state) => state?.idleTimer[statePath]);
  useEffect(() => {
    idleTimer?.activate();
  }, [count, idleTimer]);

  const actionsProps = {
    cancelText: 'Logout',
    clickText: 'Continue',
    onCancel,
    onClick,
  };

  const modalActions = <DialogButtons {...actionsProps} />;

  const modalProps = {
    actions: modalActions,
    helpLink: '',
    idle,
    onClick,
    open,
    title: 'Session Timeout',
  };

  return (
    <TswDialog {...modalProps}>
      <p>Your online session will expire in {Math.trunc(idle / 1000)} seconds.</p>
      <p>
        Please click <i>Continue</i> to keep working; or click <i>Logout</i> to end your session
        now.
      </p>
    </TswDialog>
  );
};

const IdleTimerDialog = ({ children, logout, ping, profile }) => {
  const { onClick, onClose, open } = useDialog();

  const [timeout] = useState(() => {
    const sessionTimeout = defaultMoment
      .duration(profile?.company?.session_timeout, 'seconds')
      .asMilliseconds();

    return defaultMoment
      .duration(sessionTimeout || IDLE_TIMEOUT)
      .subtract(PROMPT_TIMEOUT, 'milliseconds')
      .subtract(1, 'minute') // safety buffer
      .asMilliseconds();
  });

  const loggedIn = !!profile?.id;

  const idleTimerProps = {
    events: [], // disable keyboard/mouse events
    onIdle: () => {
      if (!loggedIn) {
        return;
      }
      store.dispatch(add_message('idle_timeout', ['Automatically signed out due to inactivity.']));
      onClose();
      logout();
    },
    onPrompt: () => {
      if (!loggedIn) {
        return;
      }
      onClick();
    },
    promptTimeout: PROMPT_TIMEOUT,
    timeout: timeout | 0, // 32bit signed integer only
  };

  const dialogProps = {
    onCancel: () => {
      onClose();
      logout();
    },
    onClick: () => ping().then(() => onClick()),
    open,
  };

  return (
    <IdleTimerProvider {...idleTimerProps}>
      <IdleDialog {...dialogProps} />
      {children}
    </IdleTimerProvider>
  );
};

export default connect(({ profile }) => ({ profile, ...mapPreferencesToProps({ profile }) }), {
  logout,
  ping: actions.dropdown,
})(IdleTimerDialog);
