import React, { memo } from 'react';
import ReactSelect from 'react-select';

const Select = (props) => {
  const [value, setValue] = React.useState(null);
  const { callback } = props;

  const handleSelectChange = (value) => {
    setValue(value);

    if (!callback) return;
    callback(value);
  };

  return <ReactSelect {...props} value={value} onChange={handleSelectChange} />;
};

export default memo(Select);
