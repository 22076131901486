import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

// Config
import paths, { adminPaths } from 'config/paths/risk';

// Components
const Index = lazy(() => import('./index'));
const Settings = lazy(() => import('./Settings'));

export const Routes = ({ paths, permissions, preferences }) => {
  return (
    <ReduxManager data={[]}>
      <Suspense fallback={<></>}>
        <Switch>
          <Route preference={preferences.riskChecks} exact path={paths.root}>
            <Index paths={paths} />
          </Route>
          <Route preference={preferences.riskChecks} exact path={adminPaths.root}>
            <Index paths={adminPaths} />
          </Route>
          <Route preference={preferences.riskChecks} exact path={adminPaths.settings()}>
            <Settings paths={adminPaths} />
          </Route>
        </Switch>
      </Suspense>
    </ReduxManager>
  );
};

const mapStateToProps = ({ profile }) => ({
  ...mapPermissionsToProps({ profile }),
  ...mapPreferencesToProps({ profile }),
});

export default connect(mapStateToProps, null)((props) => <Routes paths={paths} {...props} />);
