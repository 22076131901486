import React from 'react';
import { withResourceState } from './state';
import Dialog from './Dialog';

import { adaptToRequest } from 'adapters/v1/digitalFormRequests/formData';

export const Create = ({ onClose, create, list, ...rest }) => {
  const callback = async (formData) => {
    if (!formData || !create) return;

    const adapted = adaptToRequest(formData);
    const resp = await create(adapted);

    if (!resp.success) return;
    await list();
    onClose();
  };

  const ownProps = {
    title: 'Create Signature Request',
    onClose,
    callback,
  };
  return <Dialog {...ownProps} {...rest} />;
};

export default withResourceState(Create);
