import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

// actions
import actions from 'store/v2/notifications/actions';

const stateBuilder = () => {
  const mapStateToProps = ({
    profile,
    notifications,
    inbox: { reminders, actions, loading },
    feed: { feed: activities },
  }) => {
    const { role, id } = profile;
    const isEmployee = role === 'employee' ? true : false;

    return {
      actions,
      activities,
      isEmployee,
      loading,
      notifications: Object.values(notifications.data.notifications || {}), // v2 notifications
      profile,
      profileId: id,
      reminders,
      ...mapPermissionsToProps({ profile }),
      ...mapPreferencesToProps({ profile }),
    };
  };

  const include = {
    include: '',
  };

  const mapDispatchToProps = {
    ...actions,
    listEmployeeItems: (queryParams) =>
      actions.employeeItems({
        ...queryParams,
        ...include,
      }),
  };

  return {
    withFeatureState: (Component) => {
      return withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
    },
  };
};

export const { withFeatureState, withResourceState } = stateBuilder();
