import React from 'react';

import DataTable, { dateFieldProps } from 'components/v2/PageElements/DataTable';
import {
  buttonCellRender,
  comboCellRender,
} from 'components/v2/PageElements/DataTable/CellRenderers';
import { deleteActionItem } from 'components/v2/PageElements/DataTable/RowActionItem';

import { withFeatureState } from './state';

const Eligibilities = ({
  destroy,
  history,
  list,
  loading,
  paths,
  permissions,
  userEligibilities,
  userId,
}) => {
  const cellClickHandler = ({ id }) => {
    paths && history.push(paths.resource.detail(id));
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell:
        permissions.userEligibility.update && paths
          ? buttonCellRender('name', { onClick: cellClickHandler })
          : undefined,
    },
    {
      field: 'investigation',
      headerName: 'Investigation',
      renderCell: comboCellRender('investigationDetails'),
      flex: 1,
    },
    { field: 'investigation_status', headerName: 'Status', flex: 0.5 },
    {
      field: 'reinvestigationDate',
      headerName: 'ReInvestigation',
      ...dateFieldProps,
    },
    {
      field: 'grantingDetails',
      headerName: 'Granted',
      renderCell: comboCellRender('grantingDetails'),
      flex: 1,
    },
    { field: 'ceDetails', headerName: 'CE', renderCell: comboCellRender('ceDetails'), flex: 0.5 },
  ];

  if (permissions.profile.isAnyAdmin) {
    columns.push(deleteActionItem({ destroy, nameProp: 'name', type: 'eligibility' }));
  }

  const tableProps = {
    columns,
    forcedFilters: { user_id: userId },
    hideFooter: true,
    list,
    loading,
    pageSizeOptions: [500],
    rows: userEligibilities,
  };

  return (
    <>
      <DataTable {...tableProps} />
    </>
  );
};

export default withFeatureState(Eligibilities);
