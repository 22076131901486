import React, { useEffect } from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import raygun from 'raygun4js';
import RouteV2 from 'components/v2/PageElements/Routes';
import store from 'store';
import FlashMessages from 'components/v2/PageElements/FlashMessages';

// Components
import AppContext from 'components/v2/AppContext';
import Loader from 'components/v2/Loader';
import ErrorPage from 'components/v1/ErrorPage';

// Profile update stuff
import { refreshProfileSuccess } from 'store/v1/authentication/actions';
import request from 'lib/v1/request';

// Actions
import { getUsersSlim } from 'store/v1/users/actions';

// Layouts
import AppLayout from 'layouts/v1/AppLayout';
import DashboardLayout from 'layouts/v1/DashboardLayout';

// Libs
import { isNotProduction, isLabs } from 'config';
import tracking from 'lib/v1/tracking.js';

// Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

// Routes
import EmployeeRoutes from 'routes/EmployeeRoutes';
import NonEmployeeRoutes from 'routes/NonEmployeeRoutes';
// Authentication
import LoginPageV2 from 'components/v2/Authentication/Login';
import SAMLAutoLogin, {
  getSsoRedirectCookiePath,
} from 'components/v1/Authentication/SAMLAutoLogin';

// paths
import { defaultPath } from 'config/paths';
import emailPaths from 'config/paths/email';
import { userPathsBuilder as userDigitalFormsPathsBuilder } from 'config/paths/userDigitalForms';

// UI V2
import AppLayoutLabs, { Logout } from 'components/v2/AppLayoutLabs/Routes';

// Public
const ResetPasswordPage = lazy(() => import('views/authentication/ResetPasswordPage'));
const PrivacyPolicy = lazy(() => import('components/v1/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('components/v1/TermsAndConditions'));

const Docs = lazy(() => import('components/v2/ApiDocs'));

// v2 routes
const EmailRoutes = lazy(() =>
  import(
    // webpackPrefetch: true
    'components/v2/Email/Routes'
  )
);
const DevRoutes = lazy(() => import('./DevRoutes'));
// const NotifyDashboardRoutes = lazy(() =>
//   import(
//     // webpackPrefetch: true
//     'layouts/v2/NotifyLayout/Routes'
//   )
// );

// MFA
const Security = lazy(() => import('components/v1/Security'));

export const ThreatSwitchRoute = ({
  access = true,
  children,
  component: Component,
  layout: Layout = null,
  wrapper: Wrapper = null,
  redirect,
  location,
  ...rest
}) => {
  // See if user is logged in
  const state = store.getState();
  if (!state.profile || !state.profile.id) {
    return <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />;
  }

  if (!access) {
    // There is some functionality in the app that relies on redirect so I'm going to allow it conditionally
    // even as we move away from this component
    return redirect ? <Redirect to={redirect} /> : <ErrorPage />;
  }

  if (Wrapper) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Wrapper {...props}>
            <Component {...props} />
          </Wrapper>
        )}
      />
    );
  }

  if (Layout) {
    return (
      <Layout>
        <Route {...rest}>{children}</Route>
      </Layout>
    );
  }

  return (
    <Route {...rest} component={Component}>
      {children}
    </Route>
  );
};

export const AuthenticationRoute = ({ children, profile, ...rest }) => {
  const { id } = profile;

  if (id) {
    return <Redirect to={getSsoRedirectCookiePath() ?? '/inbox'} />;
  }

  return <Route {...rest}>{children}</Route>;
};

const useBootup = ({ permissions, profile = {}, history }) => {
  const { id } = profile;
  const authenticated = !!id;
  const [loading, setLoading] = React.useState(true);

  const refreshProfile = async () => {
    setLoading(true);
    const response = await request
      .get(`/users/${id}`)
      .then((response, status) => {
        if (
          response.body.user.data &&
          response.body.user.data.company &&
          response.body.user.data.company.preference.beta_tester
        ) {
          window.localStorage.setItem('betaTester', true);
        } else {
          window.localStorage.removeItem('betaTester');
        }

        store.dispatch(refreshProfileSuccess(response.body.user.data));
        return response.body.user.data;
      })
      .catch((err) => {
        console.error(err); // eslint-disable-line
      });
    setLoading(false);
    return response;
  };

  // FIX HOOK
  React.useEffect(() => {
    tracking.intercom.boot();

    if (!authenticated) {
      setLoading(false);
      return;
    }

    refreshProfile().then((profile) => tracking.start(profile));
  }, [authenticated]); // eslint-disable-line

  return { id, authenticated, loading };
};

// FIX HOOK
const useLegacy = ({ authenticated, v2 }) => {
  React.useEffect(() => {
    if (!authenticated || v2) return;
    // store.dispatch(getAll.bind(this, 'facilities')({ per_page: 10000 }));
    store.dispatch(getUsersSlim());
  }, [authenticated]); // eslint-disable-line

  return;
};

const useV2 = ({ pathname, preferences }) => {
  const [v2, setV2] = React.useState(window.localStorage.getItem('v2') || isLabs);

  // FIX HOOK
  React.useEffect(() => {
    if (!preferences.betaTester) return;
    if (pathname === '/v2/enable') {
      window.localStorage.setItem('v2', 'true');
      setV2(true);
    }
    if (pathname === '/v2/disable') {
      window.localStorage.removeItem('v2');
      setV2(false);
    }
  }, [pathname]); // eslint-disable-line

  return { v2, setV2 };
};

const useRedirect = ({ authenticated, pathname, v2 }) => {
  const [redirectPath, setRedirectPath] = React.useState('/');

  // FIX HOOK
  React.useEffect(() => {
    if (!authenticated) {
      if (['/', '/login', '/signin'].includes(pathname)) return;
      setRedirectPath(pathname);
    }
  }, [authenticated]); // eslint-disable-line

  return { redirectPath, setRedirectPath };
};

export const AppRoutes = ({ profile, preferences, permissions, history = {} }) => {
  const { location } = history;
  const { pathname } = location || {};
  const { id, authenticated, loading } = useBootup({
    profile,
    history,
  });

  useEffect(() => raygun('trackEvent', { type: 'pageView', path: pathname }), [pathname]);

  const { v2 } = useV2({ pathname, preferences });

  useLegacy({ v2, authenticated });

  const { redirectPath, setRedirectPath } = useRedirect({ authenticated, pathname, v2 });

  const onLogout = () => {
    setRedirectPath('/');
  };

  if (loading) return <Loader />;

  if (v2) {
    return (
      <>
        <FlashMessages />
        <Switch>
          {authenticated && (
            <Switch>
              <AppLayoutLabs {...{ onLogout }} />
            </Switch>
          )}
          {!authenticated && (
            <Switch>
              <Route exact path="/signin">
                <SAMLAutoLogin />
              </Route>
              <Route path="*">
                <LoginPageV2 {...{ redirect: redirectPath }} />
              </Route>
            </Switch>
          )}
        </Switch>
      </>
    );
  } else {
    const context = { v2, permissions, preferences, profile };

    const defaultRolePath = profile?.role === 'admin' ? '/admin' : defaultPath;
    // const defaultRolePath = defaultPath;

    return (
      <AppLayout>
        <AppContext.Provider value={context}>
          <Suspense fallback={<></>}>
            <Switch>
              <Route exact path="/docs" render={(props) => <Docs />} />

              <Route
                exact
                path="/"
                render={() => {
                  if (!authenticated) return <Redirect to="/login" />;
                  return <Redirect to={redirectPath === '/' ? defaultRolePath : redirectPath} />;
                }}
              />
              <Route path="/logout" render={() => <Logout {...{ onLogout }} />} />

              {/* Legacy Route Redirects */}
              {authenticated && (
                <RouteV2
                  permission={true}
                  path={['/profile/my-forms', '/employee/forms']}
                  render={(props) => (
                    <Redirect to={userDigitalFormsPathsBuilder(id).root} {...props} />
                  )}
                />
              )}

              {/* Cleanup v2 routes in case there is any residual pathery and v1 context */}
              {authenticated && (
                <RouteV2
                  exact
                  path={[
                    '/v2/disable',
                    '/v2/enable',
                    '/v2/inbox',
                    '/v2/admin',
                    '/v2/home',
                    '/v2/app',
                  ]}
                  redirect={defaultRolePath}
                />
              )}

              {/* Parse email routes after authenticated, so it evaluates company preferences */}
              {authenticated && <Route path={emailPaths.root} component={EmailRoutes} />}

              {/* Public */}
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/terms-and-conditions" component={TermsAndConditions} />

              <AuthenticationRoute exact path="/login" profile={profile} component={LoginPageV2} />

              <AuthenticationRoute
                exact
                path="/signin"
                profile={profile}
                component={SAMLAutoLogin}
              />

              <AuthenticationRoute
                exact
                path="/reset-password"
                profile={profile}
                component={LoginPageV2}
              />

              <AuthenticationRoute
                exact
                path="/reset-password/:resetEmail"
                profile={profile}
                component={LoginPageV2}
              />

              <Route exact path="/new-password/:token_id/:id">
                {authenticated && <Redirect to="/inbox" />}
                {!authenticated && <ResetPasswordPage />}
              </Route>

              <ThreatSwitchRoute
                exact
                path="/security"
                component={Security}
                wrapper={DashboardLayout}
              />

              {/* notifyPaths */}
              {/* <ThreatSwitchRoute path={notifyLayoutComponentPaths} layout={DashboardLayout}>
                <NotifyDashboardRoutes />
              </ThreatSwitchRoute> */}

              {/* DevRoutes */}

              <RouteV2 permission={isNotProduction} path="/dev" component={DevRoutes} />

              {/* Employee Pages*/}
              <RouteV2
                path="/employee"
                permission={id && !permissions.admin.list}
                component={EmployeeRoutes}
              />

              {/* Non Employee Pages */}
              <ThreatSwitchRoute path="/" access={authenticated} component={NonEmployeeRoutes} />

              <ThreatSwitchRoute
                path="*"
                access={authenticated}
                redirect="/login"
                component={ErrorPage}
              />
            </Switch>
          </Suspense>
        </AppContext.Provider>
      </AppLayout>
    );
  }
};

const mapStateToProps = ({ profile }) => {
  return { profile, ...mapPermissionsToProps({ profile }), ...mapPreferencesToProps({ profile }) };
};

export default connect(mapStateToProps)(withRouter(AppRoutes));
