import React from 'react';
import { connect } from 'react-redux';

// styled mui
import Chip from './Chip';
import TextField from './TextField';

// mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

import contractsPaths from 'config/paths/contracts';

// mui
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// actions
import assetControlsActions from 'store/v2/assetControls/actions';
import contactsActions from 'store/v2/contacts/actions';
import containerControlsActions from 'store/v2/containerControls/actions';
import facilitiesActions from 'store/v2/facilities/actions';
import formTemplateActions from 'store/v2/formTemplates/actions';
import tagsActions from 'store/v2/tags/actions';
import peopleActions from 'store/v2/people/actions';
import trainingActions from 'store/v2/training/actions';

// adapters
import { adaptAttributeObjectArrayToSelect } from 'adapters/v1/meta';

export const getOptionSelected = (option, { label }) => {
  return String(option.label) === String(label);
};

const TswAutocomplete = ({
  Icon,
  placeHolder = 'Start typing to search...',
  label = 'Select',
  helperText,
  multiple = true,
  linksEnabled = false,
  textFieldProps = {},
  ...rest
} = {}) => {
  if (helperText) textFieldProps.helperText = helperText;

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 300,
  });

  return (
    <Autocomplete
      getOptionLabel={(option) => option.title || ''}
      multiple={multiple}
      filterOptions={filterOptions}
      size="small"
      openOnFocus
      autoHighlight
      disableCloseOnSelect={multiple}
      renderOption={(props, option) => (
        <li {...props} key={option.label}>
          {option.title}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeHolder} {...textFieldProps} />
      )}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map(({ title, label, url } = {}, index) => {
          return (
            <Chip
              {...getTagProps({ index })}
              icon={Icon ? <Icon /> : Icon}
              label={title}
              url={url}
              linksEnabled={linksEnabled}
            />
          );
        });
      }}
      isOptionEqualToValue={getOptionSelected}
      {...rest}
    />
  );
};

export const LinkableSingleSelectAutocomplete = ({
  Icon,
  placeHolder = 'Search...',
  label = 'Select',
  helperText,
  multiple = true,
  linksEnabled = false,
  textFieldProps = {},
  onLinkClick = null,
  ...rest
} = {}) => {
  const [value, setValue] = React.useState('');
  const startAdornment = value && (
    <InputAdornment position="start">
      <OpenInNewIcon onClick={onLinkClick} />
    </InputAdornment>
  );
  return (
    <TswAutocomplete
      {...rest}
      multiple={false}
      onChange={(event, value) => setValue(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeHolder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {startAdornment}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          {...textFieldProps}
        />
      )}
    />
  );
};

// Mapper/helper for creating dropdown fields from store.
export const selectable = ({ data, key = 'name', id = 'id', filterFunc, orderedSet } = {}) => {
  let values = Object.values(data);

  if (filterFunc) values = values.filter(filterFunc);

  const options = values.map((obj) => {
    const title = obj.attributes ? obj.attributes[key] : obj[key];
    const label = obj.attributes && obj.attributes[id] ? obj.attributes[id] : obj[id];
    return { label, title, attributes: obj.attributes };
  });

  if (orderedSet) {
    return options.sort((a, b) => orderedSet.indexOf(a.label) - orderedSet.indexOf(b.label));
  }
  return options;
};

// HOC for building Connected Autocomplete components
export const AutocompleteFactory = (mapStateToProps, mapDispatchToProps) => {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(({ list, options = [], skipList = false, prependOptions = [], groupByLabel, ...rest }) => {
    // const fetchData = async () => {
    //   await list();
    // };

    // React.useEffect(() => {
    //   if (!list || skipList) return;
    //   fetchData();
    // }, []);

    return (
      <TswAutocomplete
        isOptionEqualToValue={getOptionSelected}
        options={[...prependOptions, ...options]}
        groupBy={(props) => {
          if (!prependOptions.includes(props)) return groupByLabel;
        }}
        {...rest}
      />
    );
  });
};

const mapMetaToAutocompleteFactory = (reduxKey, attributeName) => {
  return ({
    [reduxKey]: {
      meta: {
        attributes: { values },
      },
    },
  }) => {
    let options = [];

    const attribute = values[attributeName] || {};
    const { type } = attribute && attribute.items ? attribute.items : {};
    const upperFirst = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

    // We are using jsonschema for the meta endpoint so we can actually support
    // quite a few different mappings with reasonable safety
    switch (type) {
      case 'string':
        options = attribute.data.map((value) => ({ title: upperFirst(value), label: value }));
        break;
      case 'object':
        // Jsonschema allows you to define properties along with descriptions
        options = adaptAttributeObjectArrayToSelect(attribute);
        break;
      default:
        break;
    }

    return { options };
  };
};

// *METADATA DROPDOWN PATTERNS*
// Primary HOC for handling metadata value dropdown definitions
// See components/v2/RequirementControls/Requirements/Autocomplete.js for example implementations
export const AttributeMetaAutocompleteFactory = (reduxKey, attributeName) =>
  AutocompleteFactory(mapMetaToAutocompleteFactory(reduxKey, attributeName));

// *SPECIALIZED CONNECTED AUTOCOMPLETE DROPDOWN DATA*
export const FormTemplatesAutocomplete = AutocompleteFactory(
  ({ formTemplates: { dropdown = [] } = {} } = {}) => {
    const filterFunc = ({ signer_roles }) => signer_roles.length === 1;
    const options = selectable({ data: dropdown, key: 'title', filterFunc });
    return { options };
  },
  { list: formTemplateActions.dropdown }
);

export const UsersAutocomplete = AutocompleteFactory(
  ({ users: { data } }) => ({ options: selectable({ data, key: 'full_name' }) })
  // ({ people: { dropdown } }) => ({ options: selectable({ data: dropdown }) }),

  // TODO: this doesn't actually do anything until we fix the v2 dropdown call
  // { list: peopleActions.dropdown }
);

export const ContactsAutocomplete = AutocompleteFactory(
  ({ contacts: { dropdown } }) => ({ options: selectable({ data: dropdown, key: 'name' }) }),
  { list: contactsActions.dropdown }
);

export const AdminUsersAutocomplete = AutocompleteFactory(({ users: { data } }) => {
  const filterFunc = (user) => user.role !== 'employee';
  const options = selectable({ data, key: 'full_name', filterFunc });
  return { options };
});

// decided to make this it's own thing in case we change who can have a key
export const ApiUsersAutocomplete = AutocompleteFactory(({ users: { data } }) => {
  const filterFunc = (user) => user.role === 'admin';
  const options = selectable({ data, key: 'full_name', filterFunc });
  return { options };
});

export const TagsAutocomplete = AutocompleteFactory(
  ({ tagsV2: { dropdown } }) => ({
    options: selectable({ data: dropdown, key: 'name' }),
  }),
  { list: tagsActions.dropdown }
);

export const AssetsAutocomplete = AutocompleteFactory(
  ({ assetControls: { dropdown } }) => ({
    options: selectable({ data: dropdown, key: 'document_name' }),
  }),
  { list: assetControlsActions.dropdown }
);

export const AssetsWithIdentifierAutocomplete = AutocompleteFactory(
  ({ assetControls: { dropdown } }) => {
    const options = selectable({ data: dropdown, key: 'display_name_with_identifier' });
    return { options };
  }
);

export const TrainingsAutocomplete = AutocompleteFactory(
  ({ trainingsV2: { dropdown, dropdownResult } }) => ({
    options: selectable({ data: dropdown, key: 'title', orderedSet: dropdownResult }),
  }),
  { list: trainingActions.dropdown }
);

export const FacilitiesAutocomplete = AutocompleteFactory(
  ({ facilitiesV2: { dropdown } }) => ({
    options: selectable({ data: dropdown, key: 'display_name' }),
  }),
  { list: facilitiesActions.dropdown }
);

export const ContainersAutocomplete = AutocompleteFactory(
  ({ containerControls: { dropdown } }) => ({
    options: selectable({ data: dropdown, key: 'name' }),
  }),
  { list: containerControlsActions.dropdown }
);

export const ContractsAutocomplete = AutocompleteFactory(({ contractsV2: { dropdown } }) => ({
  options: selectable({ data: dropdown, key: 'contract_name' }).map((obj) => ({
    ...obj,
    url: contractsPaths.resource.detail(obj.label),
  })),
}));

export const CountriesAutocomplete = AutocompleteFactory(({ lookups: { countries } }) => ({
  options: selectable({ data: countries, key: 'label', id: 'value' }),
}));

export const DigitalFormTemplatesAutocomplete = AutocompleteFactory(
  ({ digitalFormTemplates: { dropdown } }) => ({
    options: Object.values(dropdown)
      .map((obj) => {
        const { attributes } = obj;
        const { signer_roles = [] } = attributes;
        const type = signer_roles.length > 1 ? 'Multi-Signer Form' : 'Single-Signer Form';
        return { label: attributes.template_id, title: attributes.display_name, type: type };
      })
      .sort((a, b) => b.type.toString().localeCompare(a.type.toString())),
  })
);

export const DigitalFormTemplatesSingleAutocomplete = AutocompleteFactory(
  ({ digitalFormTemplates: { dropdown } }) => ({
    options: Object.values(dropdown)
      .filter(({ attributes }) => {
        const { signer_roles = [] } = attributes;
        return signer_roles.length < 2;
      })
      .map(({ attributes }) => {
        return { label: attributes.template_id, title: attributes.display_name };
      })
      .sort((a, b) => a.title.toString().localeCompare(b.title.toString())),
  })
);

export const WorkflowDigitalFormTemplatesAutocomplete = AutocompleteFactory(
  ({ formTemplates: { dropdown = [] } = {} } = {}) => {
    const options = Object.values(dropdown)
      // Only single signer forms
      .filter(({ signer_roles = [] } = {}) => signer_roles?.length < 2)
      // Only standard signer_role
      .map((obj) => {
        const { signer_roles = [], id, title } = obj;
        const first_signer = signer_roles[0];
        const type = first_signer?.['name'] === 'Name' ? 'Standard Form' : 'Custom Form';
        return { label: id, title, attributes: obj?.attributes, type };
      })
      .sort((a, b) => b.type.toString().localeCompare(a.type.toString()));
    return { options };
  },
  { list: formTemplateActions.dropdown }
);

export const IncidentTemplatesAutocomplete = AutocompleteFactory(
  ({ incidentTemplates: { dropdown } }) => ({
    options: selectable({ data: dropdown, key: 'name' }),
  })
);

export const AccessesAutocomplete = AutocompleteFactory(({ accesses: { dropdown } }) => ({
  options: selectable({ data: dropdown, key: 'display_name' }),
}));

export const EligibilitiesAutocomplete = AutocompleteFactory(({ eligibilities: { dropdown } }) => ({
  options: selectable({ data: dropdown, key: 'display_name' }),
}));

export const PolygraphsAutocomplete = AutocompleteFactory(({ polygraphs: { dropdown } }) => ({
  options: selectable({ data: dropdown, key: 'display_name' }),
}));

export const RolesAutocomplete = AttributeMetaAutocompleteFactory('people', 'role');

export const TagNamesAutocomplete = AutocompleteFactory(({ tagNames: { dropdown } }) => ({
  options: selectable({ data: dropdown, key: 'name' }),
}));

// Notes: This whole thing is an effort to deal with the inconsistent business needs of user dropdown data populations
// as well as limitations in how we currently handle user dropdowns on the backend.
//
// It is very much hard coded and may or may not be a pattern worth emulating, but it is the path I'm taking today
// to ensure restricted user permissions for the client credentials API.
const mapConditionalStateToProps = (store) => {
  const { permissions } = mapPermissionsToProps(store);
  const {
    profile,
    users: { data },
  } = store;
  const isSelfScoped = permissions.profile.isCustomRole && !permissions.people.list;
  const dataScope = isSelfScoped ? { [profile.id]: data[profile.id] } : data;

  return { options: selectable({ data: dataScope }) };
};

export const UsersPermissionsConditionalAutocomplete = AutocompleteFactory(
  mapConditionalStateToProps,
  { list: peopleActions.dropdown }
);

export default TswAutocomplete;
