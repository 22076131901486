import { memo } from 'react';

// Logo
import logo from 'assets/images/logo/logomark.svg';

// MUI
import { styled } from '@mui/material/styles';

const StyledLoader = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '10% 0',
}));

const StyledImage = styled('img')(({ theme }) => ({
  animation: 'pulse 2s ease-in-out infinite',
  '@keyframes pulse': {
    from: {
      transform: 'scale3d(1, 1, 1)',
      opacity: '0.8',
    },
    '50%': {
      transform: 'scale3d(1.1, 1.1, 1.1)',
      opacity: '1',
    },
  },
  opacity: '0.8',
}));

const StyledRotatingImage = styled('img')(({ theme }) => ({
  animation: 'spin 6s linear infinite preserve-3d',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));

const StyledText = styled('span')(({ theme }) => ({
  color: '#333',
  fontSize: '1rem',
  marginTop: '1.375rem',
}));

const Loader = ({ rotate, text = 'Loading...' }) => (
  <StyledLoader>
    {rotate ? (
      <StyledRotatingImage src={logo} alt="Loading Indicator" />
    ) : (
      <StyledImage src={logo} alt="Loading Indicator" />
    )}
    <StyledText>{text}</StyledText>
  </StyledLoader>
);

export default memo(Loader);
