import React from 'react';
import { withFeatureState } from './state';
import Dialog from './Dialog';
import { adaptToRequest } from 'adapters/v1/documents/formData';

export const Edit = ({ onClose, resource, update, ...rest }) => {
  const callback = async (formData) => {
    if (!formData || !update) return;

    const adapted = adaptToRequest(formData);
    const resp = await update(resource.id, adapted, true);

    if (!resp.success || !onClose) return;
    onClose();
  };

  const ownProps = {
    title: `Edit ${resource ? resource.name : ''}`,
    onClose,
    callback,
    resource,
  };
  return <Dialog {...ownProps} {...rest} />;
};

export default withFeatureState(Edit);
