import { list } from 'store/v2/actions';
import actionType, { apiPath } from './constants';

export const basePath = (id) => `${apiPath}/${id}/units`;

export const listResources = (id, queryParams) => {
  return list(actionType, basePath(id), queryParams);
};

const actions = {
  listChildren: listResources,
};

export default actions;
