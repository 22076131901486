import React from 'react';
import { Suspense, lazy } from 'react';
import { Switch } from 'react-router-dom';

// Redux State Mappers
import { withIndexState } from './state';

// Routes
import { ThreatSwitchRoute } from 'routes';

// Components
const Index = lazy(() => import('./index'));
const Create = lazy(() => import('./Create'));
const Delete = lazy(() => import('./Delete'));
const Wizard = lazy(() => import('./Wizard'));

export const Routes = ({ paths, featurePermissions }) => {
  return (
    <Suspense fallback={<></>}>
      <Switch>
        <ThreatSwitchRoute
          exact
          path={paths.delete.resource(':id')}
          access={featurePermissions.destroy}
          component={Delete}
        />
        <ThreatSwitchRoute
          exact
          path={paths.root}
          access={featurePermissions.list}
          component={Index}
        />
        <ThreatSwitchRoute
          path={paths.create}
          access={featurePermissions.create}
          component={Create}
        />
        <ThreatSwitchRoute
          path={paths.resource(':id')}
          access={featurePermissions.create}
          component={Wizard}
        />
      </Switch>
    </Suspense>
  );
};

export default withIndexState(Routes);
