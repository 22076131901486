import {
  hasAccesses,
  hasActionRequests,
  hasAssets,
  hasBadges,
  hasBetaTester,
  hasClientCredentials,
  hasContainers,
  hasCustomFields,
  hasCustomRole,
  hasFedRamp,
  hasForms,
  hasLanes,
  hasLibrary,
  hasReportables,
  hasRequirements,
  hasRiskChecks,
  hasTasks,
  hasTrainings,
  hasTravels,
  hasVisits,
  hasWorkday,
  hasWorkflows,
} from 'permissions/v1/preferences';

export const mapPreferencesToProps = ({ profile }) => {
  return {
    preferences: {
      accesses: hasAccesses(profile),
      actionRequests: hasActionRequests(profile),
      assets: hasAssets(profile),
      badges: hasBadges(profile),
      betaTester: hasBetaTester(profile),
      clientCredentials: hasClientCredentials(profile),
      containers: hasContainers(profile),
      customFields: hasCustomFields(profile),
      customRole: hasCustomRole(profile),
      fedRamp: hasFedRamp(profile),
      forms: hasForms(profile),
      lanes: hasLanes(profile),
      library: hasLibrary(profile),
      reportables: hasReportables(profile),
      requirements: hasRequirements(profile),
      riskChecks: hasRiskChecks(profile),
      tasks: hasTasks(profile),
      trainings: hasTrainings(profile),
      travels: hasTravels(profile),
      visits: hasVisits(profile),
      workday: hasWorkday(profile),
      workflows: hasWorkflows(profile),
    },
  };
};
