import { styled } from '@mui/material/styles';

export const StyledWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const StyledCard = styled('div')({
  maxWidth: '14.375rem',
  textAlign: 'center',
});

export const StyledHeadline = styled('h2')({
  fontSize: '1.1rem',
});

export const StyledSubHeadline = styled('h3')({
  fontSize: '0.875rem',
});
