// Import Icons
import Alcohol from 'assets/images/reports/alcohol-and-drug-activity.svg';
import CriminalConduct from 'assets/images/reports/criminal-conduct.svg';
import CyberAttacks from 'assets/images/reports/cyber-attacks.svg';
import DisloyaltyToTheUS from 'assets/images/reports/disloyalty-to-the-us.svg';
import FinancialActivities from 'assets/images/reports/financial-activities.svg';
import FociCall from 'assets/images/reports/foci-call-logging.svg';
import ForeignAllegiance from 'assets/images/reports/foreign-allegiance.svg';
import ForeignContacts from 'assets/images/reports/foreign-contacts.svg';
import ForeignInfluence from 'assets/images/reports/foreign-influence.svg';
import ForeignTravel from 'assets/images/reports/foreign-travel.svg';
import MishandlingSensitiveInformation from 'assets/images/reports/mishandling-sensitive-information.svg';
import MisuseOfITSystems from 'assets/images/reports/misuse-of-it-systems.svg';
import OutsideEmploymentActivities from 'assets/images/reports/outside-employment-activities.svg';
import PersonalConduct from 'assets/images/reports/personal-conduct.svg';
import PsychologicalConditions from 'assets/images/reports/psychological-conditions.svg';
import SexualBehavior from 'assets/images/reports/sexual-behavior.svg';
import SuspiciousActivity from 'assets/images/reports/suspicious-activity.svg';
import Life from 'assets/images/reports/changes-in-my-life.svg';

// Export incident types
const INCIDENT_TYPES = {
  'alcohol-or-drugs': {
    filterValue: 'alcohol-or-drugs',
    icon: Alcohol,
    text: 'Alcohol and Drugs Activity',
  },
  'criminal-conduct': {
    filterValue: 'criminal-conduct',
    icon: CriminalConduct,
    text: 'Criminal Conduct',
  },
  'cyber-attack': { filterValue: 'cyber-attack', icon: CyberAttacks, text: 'Cyber Attacks' },
  'financial-conduct': {
    filterValue: 'financial-conduct',
    icon: FinancialActivities,
    text: 'Financial Conduct',
  },
  'foci-call': { filterValue: 'foci', icon: FociCall, text: 'FOCI Call' },
  'foreign-allegiance': {
    filterValue: 'foreign-allegiance',
    icon: ForeignAllegiance,
    text: 'Foreign Allegiance',
  },
  'foreign-contact': {
    filterValue: 'foreign-contact',
    icon: ForeignContacts,
    text: 'Foreign Contact',
  },
  'foreign-influence': {
    filterValue: 'foreign-influence',
    icon: ForeignInfluence,
    text: 'Foreign Influence',
  },
  'foreign-travel': { filterValue: 'pre-travel', icon: ForeignTravel, text: 'Foreign Travel' },
  'incidents-and-violations': {
    filterValue: 'incidents-and-violations',
    icon: SuspiciousActivity,
    text: 'Incidents and Violations',
  },
  'life-changes': { filterValue: 'life-changes', icon: Life, text: 'Life Events' },
  'mishandling-classified': {
    filterValue: 'mishandling-classified',
    icon: MishandlingSensitiveInformation,
    text: 'Mishandling Sensitive Information',
  },
  'national-disloyalty': {
    filterValue: 'national-disloyalty',
    icon: DisloyaltyToTheUS,
    text: 'Disloyalty to the US',
  },
  'outside-activity': {
    filterValue: 'outside-activity',
    icon: OutsideEmploymentActivities,
    text: 'Outside Employment or Activities',
  },
  'personal-conduct': {
    filterValue: 'personal-conduct',
    icon: PersonalConduct,
    text: 'Personal Conduct',
  },
  'psychological-conditions': {
    filterValue: 'psychological-conditions',
    icon: PsychologicalConditions,
    text: 'Psychological Conditions',
  },
  'sexual-behavior': {
    filterValue: 'sexual-behavior',
    icon: SexualBehavior,
    text: 'Sexual Behavior',
  },
  'suspicious-activity': {
    filterValue: 'suspicious-activity',
    icon: SuspiciousActivity,
    text: 'Suspicious Activity',
  },
  'technology-misuse': {
    filterValue: 'technology-misuse',
    icon: MisuseOfITSystems,
    text: 'Misuse of Tech',
  },
  'travel-report': { filterValue: 'pre-travel', icon: ForeignTravel, text: 'Travel' },
  other: { filterValue: 'suspicious-activity', icon: SuspiciousActivity, text: 'Other' },
};

export default INCIDENT_TYPES;
