import React from 'react';
// HOC
import { withFeatureState } from './state';
import { Refresh } from 'components/v2/FlexTable/RowActionDropdownItem';
import { Delete } from 'components/v2/FlexTable/RowActionItem';
import { adaptToOriginal } from 'adapters/v1/table/rows';

export const ConnectedDelete = withFeatureState((props) => {
  const {
    rowItem: { row },
  } = props;

  const original = adaptToOriginal(row);

  const {
    attributes: { display_name, signer_count },
  } = original;

  const singleSign = signer_count === 1;
  const otherSignatures = signer_count > 1 ? `(${signer_count - 1})` : '';

  const textFunc = () =>
    `Are you sure you want to delete '${display_name}'? ${
      !singleSign
        ? ` Any associated signature requests will also be deleted. ${otherSignatures}`
        : ''
    }`;
  const deleteProps = { ...props, textFunc };
  return <Delete {...deleteProps} />;
});

export const ConnectedRefresh = withFeatureState(Refresh);
