import { createActions } from 'redux-actions';

import request from 'lib/v1/request';
import handleError from 'lib/v1/error-handling';
import CONST from './constants';

const { getCountriesPending, getCountriesSuccess } = createActions(
  CONST.GET_COUNTRIES_PENDING,
  CONST.GET_COUNTRIES_SUCCESS,
  CONST.GET_COUNTRIES_FAILURE
);

export const getCountries = () => {
  return (dispatch) => {
    dispatch(getCountriesPending());
    return request
      .get('/lookups/countries')
      .then((response) => {
        // Force JSON to parse even if superagent decides not to
        const json = response.body || JSON.parse(response.text);
        return dispatch(getCountriesSuccess(json));
      })
      .catch(handleError);
  };
};
