import { displayDateTimeFormat, defaultMoment as moment } from 'config';
import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import { cellAdapterFactory, cellLinkAdapterFactory } from 'adapters/v2/factories';

const dateFields = ['created_at', 'updated_at'];

export const adaptToRequest = (formData) => {
  // const { mentions } = formData;

  const out = {
    ...formData,
  };

  // out.mentions = mentions && mentions.map ? mentions.map((obj) => obj.id) : [];

  return adaptToIso(out, dateFields);
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};

  let updated = {
    ...resource,
  };

  updated = adaptToDisplay(updated, dateFields);

  updated.createDateTime = moment(resource.created_at, moment.ISO_8601)
    .local()
    .format(displayDateTimeFormat);

  return updated;
};

export const adaptComments = (comments) => {
  if (!comments) return;

  return Array.isArray(comments)
    ? comments.map((c) => adaptToDetail(c)).sort((a, b) => b.id - a.id)
    : [];
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
