import { pathBuilder } from 'config/paths/index';

export const root = '/visits';

const userBasePath = (id) => {
  return `/users/${id}/visits`;
};

export const userPathsBuilder = (id) => {
  return pathBuilder(userBasePath(id));
};

const adminRoot = '/admin/visits';
export const adminPaths = pathBuilder(adminRoot);

export default pathBuilder(root);
