import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const ClickableCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  height: '100%',
}));

const StyledHeader = styled(CardHeader)(({ backgroundColor }) => ({
  flexDirection: 'column',
  '& .MuiCardHeader-avatar': {
    color: 'white',
    fontSize: '1rem',
    height: '80px',
    margin: '0',
    width: ' 100%',
    borderRadius: '2%',
  },
  '& .MuiSvgIcon-root': {
    borderRadius: '0.2em',
    padding: '0.4em',
  },
  '& .MuiCardHeader-title': {
    color: '#757575',
    fontSize: '1rem',
    lineHeight: '1.3',
  },
  '& .MuiCardHeader-content': {
    paddingTop: '0.5em',
  },
  '& .MuiCardHeader-subheader': {
    color: '#2b2e42',
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: '1.3',
  },
}));

const GenericCard = ({
  icon,
  title,
  subtitle,
  onCardClick,
  children,
  action,
  dateText = 'Last updated',
  dateValue,
  ...props
}) => {
  return (
    <ClickableCard onClick={onCardClick} {...props}>
      <StyledHeader
        avatar={icon}
        title={title}
        subheader={subtitle}
        action={action}
        backgroundColor={props.backgroundColor}
      />
      <Stack direction="row" spacing={2} sx={{ padding: '0 1em 1em' }}>
        {children}
      </Stack>
    </ClickableCard>
  );
};

export default GenericCard;
