import { INBOX_ACTIONS } from './constants';

export const initialState = {
  reminders: [],
  actions: [],
  loading: false,
};

const inbox = (state = initialState, { payload, type }) => {
  switch (type) {
    case INBOX_ACTIONS.INBOX_FETCHED_PENDING:
      const loading = state.feed && state.feed.length ? false : true;
      return {
        ...state,
        loading,
      };
    case INBOX_ACTIONS.INBOX_FETCHED_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case INBOX_ACTIONS.UPDATE_INBOX_PENDING:
      return {
        ...state,
        loading: true,
      };
    case INBOX_ACTIONS.UPDATE_INBOX_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case INBOX_ACTIONS.UPDATE_INBOX_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default inbox;
