import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { adaptToOriginalFromRowItem } from 'adapters/v1/table/rows';

const noop = () => {};

const DropdownItem = ({
  text = 'Delete',
  handleClick = noop,
  closeActionDropdown = noop,
  ...rest
}) => {
  const onClick = () => {
    handleClick();
    closeActionDropdown();
  };

  const itemProps = {
    'data-row-action-button': text,
    onClick,
  };

  return <MenuItem {...itemProps}>{text}</MenuItem>;
};

export const Refresh = ({ show, rowItem, ...rest }) => {
  const original = adaptToOriginalFromRowItem(rowItem);
  const { id } = original;

  const itemProps = {
    text: 'Refresh',
    handleClick: async () => {
      if (id) await show(id);
    },
  };

  return <DropdownItem {...itemProps} />;
};

export default DropdownItem;
