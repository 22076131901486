import { createActions } from 'redux-actions';
import request from 'lib/v1/request';
import handleError, { displayError } from 'lib/v1/error-handling';
import CONST from './constants';
import { add_message } from 'store/v1/app/actions';
import { toApi, fromApi, fromApiIndex } from 'adapters/v1/users';

export const { allUsersSuccess, allUsersPending } = createActions(
  {
    [CONST.ALL_USERS_SUCCESS]: [(payload) => payload, (payload, meta) => meta],
  },
  CONST.ALL_USERS_PENDING,
  CONST.ALL_USERS_FAILURE
);

const { getUserPending, getUserSuccess } = createActions(
  CONST.GET_USER_PENDING,
  CONST.GET_USER_SUCCESS,
  CONST.GET_USER_FAILURE
);

const { getUserAccessesPending, getUserAccessesSuccess } = createActions(
  CONST.GET_USER_ACCESSES_PENDING,
  CONST.GET_USER_ACCESSES_SUCCESS,
  CONST.GET_USER_ACCESSES_FAILURE
);

const { createUserPending, createUserSuccess } = createActions(
  CONST.CREATE_USER_PENDING,
  CONST.CREATE_USER_SUCCESS,
  CONST.CREATE_USER_FAILURE
);

const { sendWelcomeEmailPending, sendWelcomeEmailSuccess } = createActions(
  CONST.SEND_WELCOME_EMAIL_PENDING,
  CONST.SEND_WELCOME_EMAIL_SUCCESS,
  CONST.SEND_WELCOME_EMAIL_FAILURE
);

const { updateUserPending, updateUserSuccess } = createActions(
  CONST.UPDATE_USER_PENDING,
  CONST.UPDATE_USER_SUCCESS,
  CONST.UPDATE_USER_FAILURE
);

const { updateAllUsersPending, updateAllUsersSuccess } = createActions(
  CONST.UPDATE_ALL_USERS_PENDING,
  CONST.UPDATE_ALL_USERS_SUCCESS,
  CONST.UPDATE_ALL_USERS_FAILURE
);

const { deleteUserPending, deleteUserSuccess } = createActions(
  CONST.DELETE_USER_PENDING,
  CONST.DELETE_USER_SUCCESS,
  CONST.DELETE_USER_FAILURE
);

const { getUsersSlimPending, getUsersSlimSuccess } = createActions(
  CONST.GET_USERS_SLIM_PENDING,
  CONST.GET_USERS_SLIM_SUCCESS,
  CONST.GET_USERS_SLIM_FAILURE
);

const { getUserCommentsPending, getUserCommentsSuccess } = createActions(
  CONST.GET_USER_COMMENTS_PENDING,
  CONST.GET_USER_COMMENTS_SUCCESS,
  CONST.GET_USER_COMMENTS_FAILURE
);

const { createUserCommentPending, createUserCommentSuccess } = createActions(
  CONST.CREATE_USER_COMMENT_PENDING,
  CONST.CREATE_USER_COMMENT_SUCCESS,
  CONST.CREATE_USER_COMMENT_FAILURE
);

export const getUsers = ({
  page = 1,
  per_page = 10,
  sort = 'name',
  dir = 'asc',
  search = null,
  filters = null,
} = {}) => {
  const filterJSON = filters ? JSON.stringify(filters) : '';
  return (dispatch) => {
    dispatch(allUsersPending(page));
    return request
      .get('/users')
      .query({ page, per_page, sort, dir, search, filters: filterJSON })
      .then((res) => {
        const { data, meta } = fromApiIndex(res.body);
        return dispatch(allUsersSuccess(data, meta));
      })
      .catch(handleError);
  };
};

export const listUsers = getUsers;

export const getUser = (id) => {
  return (dispatch) => {
    dispatch(getUserPending());
    return request
      .get(`/users/${id}`)
      .then((res) => dispatch(getUserSuccess(fromApi(res.body.user.data))))
      .catch(handleError);
  };
};

export const getUserAccesses = (id) => {
  return (dispatch) => {
    dispatch(getUserAccessesPending());
    return request
      .get(`/users/${id}/user_accesses`)
      .then((res) => {
        const obj = { id, user_accesses: res.body.data };
        return dispatch(getUserAccessesSuccess(obj));
      })
      .catch(handleError);
  };
};

export const createUser = (payload) => {
  return (dispatch) => {
    dispatch(createUserPending());
    return request
      .post('/users')
      .send(toApi(payload))
      .then((res) => dispatch(createUserSuccess(res.body.data)))
      .catch((e) => handleUserError(payload, e));
  };
};

export const sendWelcome = (ids) => {
  return (dispatch) => {
    const params = { ids: Array.isArray(ids) ? ids : [ids] };

    dispatch(sendWelcomeEmailPending());
    return request
      .post(`/users/send_welcome`)
      .send(params)
      .then((res) => {
        dispatch(sendWelcomeEmailSuccess(res.body.data));
        dispatch(
          add_message('success', [
            `Welcome email${params.ids.length > 1 ? 's' : ''} sent successfully.`,
          ])
        );
      })
      .catch(handleError);
  };
};

export const updateUser = (payload) => {
  return (dispatch) => {
    dispatch(updateUserPending());
    return request
      .put(`/users/${payload.id}`)
      .send(toApi(payload))
      .then((res) => {
        const { data } = res.body;
        // Attempt to update localStorage profile if we've edited our own profile
        try {
          const profile = JSON.parse(window.localStorage.getItem('profile'));
          if (profile && profile.id === data.id) {
            window.localStorage.setItem('profile', JSON.stringify(data));
          }
        } catch (e) {
          console.error(e); // eslint-disable-line
        }
        const newObj = Object.assign({}, payload, res.body.data);

        return dispatch(updateUserSuccess(fromApi(newObj)));
      })
      .catch(handleError);
  };
};

export const updateAllUsers = (payload, { filters, search } = {}) => {
  const filterJSON = filters ? JSON.stringify(filters) : '';
  return (dispatch) => {
    dispatch(updateAllUsersPending());
    return request
      .post(`/users/bulk/update`)
      .query({ search, filters: filterJSON })
      .send({ all: true, ...payload })
      .then((res) => dispatch(updateAllUsersSuccess({ ...fromApi(payload), ...res.body.data })))
      .catch((e) => handleUserError(payload, e));
  };
};

export const deleteUser = (ids) => {
  return (dispatch) => {
    dispatch(deleteUserPending());
    return request
      .del(`/users/${ids.join(',')}`)
      .then((res) => dispatch(deleteUserSuccess(ids)))
      .catch(handleError);
  };
};

export const tagUser = (ids) => {
  return (dispatch) => {
    return request.post(`/tags/${ids.join(',')}`).catch(handleError);
  };
};

export const getUsersSlim = () => {
  return (dispatch) => {
    dispatch(getUsersSlimPending());
    return request
      .get(`/users/dropdown`)
      .then((res) => dispatch(getUsersSlimSuccess(res.body.data)))
      .catch((e) => handleError(e));
  };
};

export const updatePassword = ({ current_password, password, password_confirmation }) => {
  return (dispatch) => {
    return request
      .put(`/users/password`)
      .send({ current_password, password, password_confirmation })
      .then((res) => ({ type: 'SUCCESS' }))
      .catch(handleError);
  };
};

export const changePassword = (id, password, password_confirmation) => {
  return (dispatch) => {
    return request
      .put(`/users/${id}/password_change`)
      .send({ password, password_confirmation })
      .then((res) => ({ type: 'SUCCESS' }))
      .catch(handleError);
  };
};

export const createUserComment = ({ description, commentable_id, created_by }) => {
  return (dispatch) => {
    dispatch(createUserCommentPending());
    return request
      .post('/comments')
      .send({ description, commentable_id, commentable_type: 'User', created_by })
      .then((res) =>
        dispatch(createUserCommentSuccess({ id: commentable_id, comment: res.body.data }))
      )
      .catch(handleError);
  };
};

export const getUserComments = (id) => {
  return (dispatch) => {
    dispatch(getUserCommentsPending());
    return request
      .get('/comments')
      .query({ commentable_id: id, commentable_type: 'User' })
      .then((res) => dispatch(getUserCommentsSuccess({ id, comment: res.body.data })))
      .catch(handleError);
  };
};

function handleUserError(payload, err) {
  const { status, body } = err.response;
  if (status === 400) {
    return displayError(body.message);
  } else {
    // TODO: we will likely need more robust error handling in the future.
    return displayError(
      'Could not save this user. Please ensure this email is not already in the system.'
    );
  }
}
