import React from 'react';
import { connect } from 'react-redux';

// Hooks
import { useDialog } from 'components/v2/FormElements/Dialog';
import { useForm } from 'components/v2/FormElements';

// FormElements
import ConfirmModal from 'components/v2/FormElements/ConfirmModal';
import { TagNamesAutocomplete } from 'components/v2/FormElements/Autocomplete';

// Components
import RowActionDropdownItem from './RowActionDropdownItem';

// Actions
import tagNameActions from 'store/v2/tagNames/actions';

// Adapter
import { adaptToBulkCreateRequest } from 'adapters/v1/tagNames/formData';

const adaptTagsToRequest = (values) => {
  // Takes a multi select output object and converts it to an array of strings
  if (!values || !Array.isArray(values)) return [];

  return values.filter((obj) => obj.created).map((obj) => obj.label);
};

const adaptRespToRequest = (resp) => {
  // Converts the odd response coming back from bulkCreate into
  // the same format as the multiselect selection array object
  if (!resp) return [];

  return Object.entries(resp)
    .map(([key, value]) => {
      // Ugh, this is an annoying data model to have to deal with
      if (key === 'success') return null;
      return { title: value.name, label: value.id };
    })
    .filter((obj) => obj);
};

export const useBulkCreateTags = async ({ selectedTags, bulkCreateTags }) => {
  // Abstracted for potential reuse in batch action
  if (!bulkCreateTags || !selectedTags || !selectedTags.length) return [];

  const tags = adaptTagsToRequest(selectedTags);
  let resp = null;

  if (bulkCreateTags && tags.length) {
    const adapted = adaptToBulkCreateRequest(tags);
    resp = await bulkCreateTags({ data: adapted.data, silent: true });
  }

  return adaptRespToRequest(resp);
};

// Minor convenience here
export const useExistingTags = (selectedTags = []) => {
  const output = selectedTags.filter((obj) => !obj.created);
  return output && Array.isArray(output) ? output : [];
};

export const TagRowAction = ({
  action = 'Tags',
  taggableType,
  bulkCreateTags = () => {},
  update = () => {},
  rowItem = {},
  adaptToForm,
  adaptToRequest,
  formDataTagKey = 'tags',
  debug = false,
  ...rest
}) => {
  const { onClick, onClose, open } = useDialog();

  // Fail hard, should be development only
  if (!taggableType) throw 'taggableType must be defined'; /* eslint-disable-line */

  const {
    row: { original },
  } = rowItem;

  const resource = adaptToForm ? adaptToForm(original) : original;
  const { getInlineCreateSelectHandlerProps, formData, setFormData } = useForm({ resource });
  const selectedTags = formData[formDataTagKey];

  const useOnConfirm = async () => {
    // await is pretty important here
    const created = await useBulkCreateTags({ bulkCreateTags, selectedTags });
    const tags = useExistingTags(selectedTags).concat(created);

    const adapted = adaptToRequest
      ? adaptToRequest({ [formDataTagKey]: tags })
      : { [formDataTagKey]: tags };

    if (debug) console.log({ adapted }); /* eslint-disable-line */
    if (!update || debug) return;

    const resp = await update(original.id, adapted);
    if (!resp.success) return;

    onClose();
  };

  // FIX HOOK
  React.useEffect(() => {
    setFormData(resource);
  }, [open]); // eslint-disable-line

  const itemProps = {
    text: action,
    key: `${action}-${rest.id}`,
    handleClick: onClick,
    ...rest,
  };

  const modalProps = {
    title: 'Edit Tags',
    isOpen: open,
    onConfirm: useOnConfirm,
    confirmText: 'Save',
    onClose,
  };

  return (
    <>
      <RowActionDropdownItem {...itemProps} />
      <ConfirmModal {...modalProps}>
        <TagNamesAutocomplete
          {...getInlineCreateSelectHandlerProps(formDataTagKey)}
          value={selectedTags || []}
        />
      </ConfirmModal>
    </>
  );
};

export default connect(null, { bulkCreateTags: tagNameActions.bulkCreate })(TagRowAction);
