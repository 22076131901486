import React from 'react';

// material-core
import ListItem from '@mui/material/ListItem';

// styles
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'block',
    marginLeft: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const Index = ({ permission = true, ...rest }) => {
  const classes = useStyles();

  if (!permission) return null;

  return <ListItem className={classes.listItem} {...rest} />;
};

export default Index;
