import React, { Component } from 'react';
import ErrorPage from 'components/v1/ErrorPage';
import Loader from 'components/v2/Loader';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/********
 * Usage:
 * ******
 * Handle fetching, loading, and error handling of any given resourceful route. i.e. /<feature>/:id/<edit || detail>
 *
 * This effectively deprecated withResourceId as it builds on top of it and gets to what you really want, the resource itself.
 *
 *******
 * Props:
 * *****
 *
 * Component: any component wrapped with withRouter()
 * show: function for the resource that takes a resourceId
 * data: object following a {resourceId: {...resource}} format, probably mapped from redux store
 *
 * *********
 * Behavior:
 * ********
 *
 * If resource is available in data via resourceId it will return it
 * If resource is not available it will render a loader, call show(resourceId)
 * If route is not a number or falsey it will return an ErrorPage
 * If resource is falsey it will return an ErrorPage
 *
 * This component makes all resourceful routes history agnostic. I.e. they will utilize the store
 * for performance reasons if routed to, if the link is bookmarked or typed in independently it will
 * make the HTTP request.
 *
 * will load if resource is not present
 */
class ResourceContainer extends Component {
  state = { resource: {}, loading: false };

  UNSAFE_componentWillMount = async () => {
    const { match, data, show, urlParam } = this.props;

    if (!match || !data || !urlParam || !show) return;

    let resourceId = false;

    if (match && match.params && match.params[urlParam]) {
      resourceId = match.params[urlParam];
    }

    if (isNaN(resourceId) || !resourceId) return <ErrorPage />;

    let resource = data ? data[resourceId] : null;

    if (!resource && show) {
      this.setState({ loading: true });
      resource = await show(resourceId);
    }

    this.setState({ resource, loading: false });
  };

  // This is how we handle the fact that child components do not rerender
  // in the case of changes in props passed to them by the parent.
  //
  // You must pass a callback function that updates the state of the parent
  // in order to force a rerunder for a child component.
  //
  // This is therefore a generic API for handling resourceful updates in child components and it is oppinionated.  I.e. it requires you to pass a method named "update".
  updateResource = async (id, kwargs) => {
    const { update } = this.props;

    if (!update) {
      const message =
        "WARNING: Contract violation: 'update' not defined. Required for updating resource state";
      console.log(message); // eslint-disable-line
    }

    const resp = await update(id, kwargs);

    if (!resp.success) return;

    this.setState({ resource: resp });
  };

  render = () => {
    const { loading, resource } = this.state;
    const { Component } = this.props;

    return (
      <>
        {loading && <Loader />}
        {!loading && !resource && <ErrorPage />}
        {!loading && resource && (
          <Component updateResource={this.updateResource} resource={resource} {...this.props} />
        )}
      </>
    );
  };
}

const withResource = (Component, urlParam = 'id') => {
  return (props) => {
    const resourceProps = { ...props, Component, urlParam };
    return <ResourceContainer {...resourceProps} />;
  };
};

// Factory === function that returns higher order component
export const withResourceFactory = (mapStateToProps, mapDispatchToProps) => {
  return (Component) => {
    return (props) => {
      return connect(
        mapStateToProps,
        mapDispatchToProps
      )(withResource(withRouter(<Component {...props} />)));
    };
  };
};

export default withResource;
