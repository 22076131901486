import React from 'react';
import { withRouter, matchPath } from 'react-router-dom';

// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// MUI
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const ListItemButton = withRouter(
  ({
    location: { pathname },
    history,
    path,
    text,
    exact,
    onClick,
    className = '',
    Icon = <AccountCircleIcon />,
  }) => {
    const [selected, setSelected] = React.useState(false);

    const handleClick = () => {
      if (!history && !pathname) onClick();
      history.push(path);
    };

    React.useEffect(() => {
      const matched = matchPath(pathname, { path, exact });
      setSelected(matched ? true : false);
    }, [pathname, path, exact]);

    return (
      <ListItem button {...{ onClick: handleClick, selected, className }}>
        <ListItemIcon>{Icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    );
  }
);

export default ListItemButton;
