import store from 'store';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

// Shared
import pluralator from 'lib/v1/pluralator';

// Paths
import badgeControlsPaths from 'config/paths/badgeControls';
import contractsPaths from 'config/paths/contracts';
import digitalFormRequestsPaths from 'config/paths/digitalFormRequests';
import peoplePaths from 'config/paths/people';
import visitRequestsPaths from 'config/paths/visitRequests';
import { userPathsBuilder as userTasksPathsBuilder } from 'config/paths/tasks';
import { userPathsBuilder as userDigitalFormsPathsBuilder } from 'config/paths/userDigitalForms';
import { userPathsBuilder as userTravelsPathsBuilder } from 'config/paths/userTravels';

// States
import badgeActions from 'store/v2/badgeControls/actions';
import contractActions from 'store/v2/contracts/actions';
import formActions from 'store/v2/digitalFormRequests/actions';
import peopleActions from 'store/v2/people/actions';
import taskActions from 'store/v2/tasks/actions';
import travelActions from 'store/v2/travels/actions';
import visitActions from 'store/v2/visitRequests/actions';

// MUI
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Styles
import { StyledSmallIcon } from 'styles/Icon/styles';

export const typeMap = ({ profile }) => {
  const dateRange = [moment(), moment().add(30, 'days')];
  const dateRangeValues = dateRange.map((date) => date.toISOString());
  const dateRangeLabel = `(${dateRange.map((date) => date.format('MM/DD/YYYY')).join(' to ')})`;

  return {
    reinvestigations_due: {
      icon: 'search',
      singular: 'Reinvestigation Due',
      plural: 'Reinvestigations Due',
      path: peoplePaths.root,
      updateQuery: peopleActions.updateQuery,
      filters: {
        reinvestigation_at: {
          type: 'date_range',
          values: dateRangeValues,
          displayName: `Eligibility Reinvestigation Due: ${dateRangeLabel}`,
        },
        active: {
          type: 'multi_select',
          values: ['Yes'],
          displayName: 'Active: Yes',
        },
      },
    },
    expiring_contracts: {
      icon: 'tag',
      singular: 'Contract Expiring',
      plural: 'Contracts Expiring',
      path: contractsPaths.root,
      updateQuery: contractActions.updateQuery,
      filters: {
        contract_end_date: {
          type: 'date_range',
          values: dateRangeValues,
          displayName: `End Date: ${dateRangeLabel}`,
        },
      },
    },
    expiring_badges: {
      icon: 'user',
      singular: 'Badge Expiring',
      plural: 'Badges Expiring',
      path: badgeControlsPaths.root,
      updateQuery: badgeActions.updateQuery,
      filters: {
        expired_at: {
          type: 'date_range',
          values: dateRangeValues,
          displayName: `Expiring: ${dateRangeLabel}`,
        },
      },
    },
    form_requests_due: {
      icon: 'document',
      singular: 'Incomplete Form Due',
      plural: 'Incomplete Forms Due',
      path: digitalFormRequestsPaths.root,
      updateQuery: formActions.updateQuery,
      filters: {
        due_date: {
          type: 'date_range',
          values: dateRangeValues,
          displayName: `Due Date ${dateRangeLabel}`,
        },
        status: {
          type: 'multi_select',
          values: ['Incomplete'],
          displayName: 'Status: Incomplete',
        },
      },
    },
    expiring_visits: {
      icon: 'suitcase',
      singular: 'Visit Expiring',
      plural: 'Visits Expiring',
      path: visitRequestsPaths.root,
      updateQuery: visitActions.updateQuery,
      filters: {
        end_date: {
          type: 'date_range',
          values: dateRangeValues,
          displayName: `End Date: ${dateRangeLabel}`,
        },
      },
    },
    tasks_due: {
      icon: 'check',
      singular: 'Task Due',
      plural: 'Tasks Due',
      path: userTasksPathsBuilder(profile?.id).root,
      updateQuery: taskActions.updateQuery,
      filters: {
        complete: {
          type: 'select',
          values: ['false'],
          displayName: 'Complete: No',
        },
      },
    },
    travels_due: {
      icon: 'suitcase',
      singular: 'Travel Post Briefing Due',
      plural: 'Travel Post Briefings Due',
      path: userTravelsPathsBuilder(profile?.id).root,
      updateQuery: travelActions.updateQuery,
      filters: {
        status: {
          type: 'select',
          values: ['pre-travel'],
          displayName: 'Status: Pre-Travel',
        },
      },
    },
    form_signatures_due: {
      icon: 'document',
      singular: 'Form Signature Needed',
      plural: 'Form Signatures Needed',
      path: userDigitalFormsPathsBuilder(profile?.id).root,
    },
  };
};

// Strip correct value out of `count`
const parseCount = (n) => {
  const result = parseInt(n, 10);
  return isNaN(result) ? null : result;
};

const Reminder = ({ closeDrawer, object, profile, history }) => {
  const { count, type } = object;
  const num = parseCount(count);

  if (!type) return <></>;

  const reminder = typeMap({ profile })[type];
  if (!reminder) return <></>;

  const { filters, updateQuery, path, plural, singular } = reminder;
  const content = pluralator(num, singular, plural);

  const onClick = () => {
    if (filters) {
      store.dispatch(updateQuery({ filters }));
    }
    history.push(path);
    closeDrawer();
  };

  return (
    <ListItem disableGutters dense onClick={onClick}>
      <ListItemButton disableGutters>
        <ListItemIcon sx={{ minWidth: 'auto' }}>
          <StyledSmallIcon name={reminder.icon} />
        </ListItemIcon>
        <ListItemText primary={`${num} ${content}`} />
      </ListItemButton>
    </ListItem>
  );
};

export default withRouter(Reminder);
