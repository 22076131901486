import React from 'react';
import Tooltip from 'components/v2/FormElements/Tooltip';

import { uniqueId } from 'lib/v1';
import { StyledWrapper, StyledContainer, StyledLabel } from 'components/v1/Checkbox';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;
  const id = `check-id-${uniqueId()}`;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <StyledWrapper>
      <StyledContainer>
        <input id={id} visibility="hidden" type="checkbox" ref={resolvedRef} {...rest} />
        <StyledLabel htmlFor={id} checked={rest.checked} onClick={rest.onChange} />
      </StyledContainer>
    </StyledWrapper>
  );
});

export const IndeterminateTooltipCheckbox = React.forwardRef(
  ({ indeterminate, tooltip, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    const id = `check-id-${uniqueId()}`;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <StyledWrapper>
        <StyledContainer>
          <input id={id} visibility="hidden" type="checkbox" ref={resolvedRef} {...rest} />
          <Tooltip title={tooltip} disableHoverListener={!rest.disabled}>
            <StyledLabel htmlFor={id} checked={rest.checked} onClick={rest.onChange} />
          </Tooltip>
        </StyledContainer>
      </StyledWrapper>
    );
  }
);

export default IndeterminateCheckbox;
