import React from 'react';
import { connect } from 'react-redux';

// Mui
import Box from '@mui/material/Box';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import { styled } from '@mui/material/styles';
import { uniqueId } from 'lib/v1';

// Components
import Buttons from './Dialog/Buttons';
import DocumentAvatar from 'components/v2/PageElements/Attachments/Avatar';
import FileDrop from 'components/v1/FileDrop';
import List from 'components/v2/PageElements/Sidebar/List';
import SidebarListItem from 'components/v2/PageElements/Sidebar/ListItem';

import { add_message } from 'store/v1/app/actions';
import documentActions from 'store/v2/documents/actions';
import store from 'store';

const SLOW_UPLOAD_WARNING_SIZE = 10;

const StyledListItem = styled(SidebarListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  alignItems: 'center',
}));

export const fileSizeError = (files = []) => {
  const {
    profile: { company },
  } = store.getState();

  const fileSizes = files.map((f = {}) => f.size || 0);
  const maxFileSize = Math.max(fileSizes);

  // I don't know how likely this is but it was in the v1 legacy so I kept it for now
  if (fileSizes.includes(0)) {
    store.dispatch(add_message('file_error', ['File size cannot be zero.']));
    return true;
  }

  if (maxFileSize > company.file_upload_size_limit * 1000000) {
    store.dispatch(
      add_message('file_error', [
        `File size cannot be larger than ${company.file_upload_size_limit}mb.`,
      ])
    );
    return true;
  }

  if (maxFileSize > SLOW_UPLOAD_WARNING_SIZE * 1000000) {
    store.dispatch(
      add_message('file_warning', [
        `Because of large file size, site may seem unresponsive until upload completes`,
      ])
    );
  }
  return false;
};

export const AddDocument = ({
  callback,
  create,
  description = 'Workflow Request Attachment',
  documentable_id,
  documentable_type,
  loading,
  onChange,
  onClose,
  onUpload,
  resourceScope,
}) => {
  const [files, setFiles] = React.useState([]);

  const uploadFile = async () => {
    if (!create) return;
    if (!files) return;

    for (var i = 0; i < files.length; i++) {
      const file = files[i];

      if (fileSizeError([file])) return;

      const reader = new FileReader();
      reader.onload = async (event) => {
        // a DataURL includes the base64 encoded content seperated by a comma
        const content = event.target.result.split(',')[1];
        const filename = file.name;
        const fileProps = {
          content_type: file.type,
          content: content,
          filename,
          size: (file.size / 1000000).toFixed(2) + 'MB',
        };

        if (onUpload) return onUpload(fileProps);

        const resp = await create(
          {
            description,
            file: fileProps,
            documentable_id,
            documentable_type,
            name: filename,
            resource_scope: resourceScope,
          },
          false
        );

        if (!resp || !resp.id) return;
        callback && callback(resp);
        setFiles([]);
        onClose();
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOnChange = (event) => {
    if (onChange) onChange();
    setFiles((prev) => [...event.target.files]);
  };

  const handleSubmit = async () => {
    if (!files || files.length < 1) return;
    await uploadFile();
  };

  const id = `upload-${uniqueId()}`;
  const fileSelected = files[0];
  const attachedFiles = files.map((file) => {
    return (
      <StyledListItem document={file} key={`file-${file.name}`}>
        <ListItemAvatar sx={{ marginTop: 0 }}>
          <DocumentAvatar md type={file.type} />
        </ListItemAvatar>
        {file.name}
      </StyledListItem>
    );
  });

  return (
    <Box sx={{ m: '1rem' }}>
      <label htmlFor={id}>
        <FileDrop
          css={'height: 240px; margin: 0;'}
          multiple={false}
          onChangeEvent={handleOnChange}
        />
        {fileSelected && (
          <Box>
            <List
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  Selected Items
                </ListSubheader>
              }
            >
              {attachedFiles}
            </List>
          </Box>
        )}
      </label>
      <Buttons
        clickText="Add"
        onClick={handleSubmit}
        onCancel={onClose}
        loading={loading}
        disabled={!fileSelected}
      />
    </Box>
  );
};

export default connect(({ documentsV2: { loading } }) => ({ loading }), {
  create: documentActions.create,
})(AddDocument);
