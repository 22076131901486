import React from 'react';
import { connect } from 'react-redux';

// MUI
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';

import { clear_messages } from 'store/v1/app/actions';

const autoHideDuration = 6000;
const anchorOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const Alert = React.forwardRef(function Alert({ severity, ...rest }, ref) {
  const validTypes = ['success', 'info', 'error', 'alert'];
  if (!validTypes.includes(severity)) severity = 'info';
  return <MuiAlert elevation={6} ref={ref} variant="filled" severity={severity} {...rest} />;
});

export const FlashMessages = ({ messages = [], remove } = {}) => {
  const snackbarProps = {
    open: true,
    autoHideDuration,
    anchorOrigin,
    TransitionComponent: Fade,
  };

  return (
    <>
      {messages.map((m = {}) => (
        <Snackbar
          key={`snackbar-${m?.id}`}
          onClose={() => remove(m.message_type)}
          {...snackbarProps}
        >
          <Alert
            key={`alert-${m?.id}`}
            onClose={() => remove(m.message_type)}
            severity={m.message_type}
          >
            {m.messages.join(' ')}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return { messages: state.flashMessages.messages };
};

export default connect(mapStateToProps, { remove: clear_messages })(FlashMessages);
