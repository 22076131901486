import actionBuilder, { list } from 'store/v2/actions';
import actionType, { bulkActionType, apiPath } from './constants';
import { userResourceBasePath } from 'store/v2/constants';

export const userBasePath = (id) => `${userResourceBasePath}/${id}/cards`;

const actions = {
  ...actionBuilder({ apiPath, bulkActionType, actionType }),
  listByUser: (id, queryParams) => {
    return list(actionType, userBasePath(id), queryParams);
  },
};

export default actions;
