import React from 'react';

const Eligibility = ({ width = '64px', height = '64px', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 64 64" {...rest}>
    <path
      d="M58,35H38c-1.657,0-3,1.343-3,3v20c0,1.657,1.343,3,3,3h20c1.657,0,3-1.343,3-3V38C61,36.343,59.657,35,58,35z M55,55H41
      V41h14V55z M26,35H6c-1.657,0-3,1.343-3,3v20c0,1.657,1.343,3,3,3h20c1.657,0,3-1.343,3-3V38C29,36.343,27.657,35,26,35z M23,55
      H9V41h14V55z M58,3H38c-1.657,0-3,1.343-3,3v20c0,1.657,1.343,3,3,3h20c1.657,0,3-1.343,3-3V6C61,4.343,59.657,3,58,3z M55,23H41
      V9h14V23z M26,3H6C4.343,3,3,4.343,3,6v20c0,1.657,1.343,3,3,3h20c1.657,0,3-1.343,3-3V6C29,4.343,27.657,3,26,3z M23,23H9V9h14
      V23z"
    />
  </svg>
);

export default Eligibility;
