import React from 'react';
import queryString from 'query-string';

// This is a generic parser for react-router-dom in our version
// An update should give us access to these but I think we've got some compatibility issuesParam
//
export const useQueryString = ({ history }) => {
  if (!history || !history.location || !history.location.search) return {};

  const {
    location: { search },
  } = history;

  return queryString.parse(search) || {};
};

/*
  Give me a show action, a resourceId, and optional dependencies and I will return to you a loading indicator and whatever data is returned from show.
*/
export const useResource = ({
  show,
  showActivity,
  resourceId,
  dependencies = [],
  query = {},
  callback,
}) => {
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [notFound, setNotFound] = React.useState(false);

  // FIX HOOK
  React.useEffect(() => {
    if (!show || !resourceId) return;

    const fetchData = async () => {
      setLoading((state) => true);

      const response = await show(resourceId, query);
      if (!response) setNotFound((state) => true);
      if (showActivity) await showActivity(resourceId);
      if (callback) callback(response);

      setData((state) => response);
      setLoading((state) => false);
    };
    fetchData();
  }, [...dependencies, resourceId]); // eslint-disable-line

  // there are use cases where this context persists when moving around to detail
  // page for the same type of component and the original data doesn't get updated
  // this way if the resourceId changes, we should clear the state.
  // for example: view one user detail, refresh page so only that user is in store
  // then click My Profile to switch to your detail and the state persists.
  // FIX HOOK
  React.useEffect(() => {
    if (data && data.id) {
      setData((state) => {});
      setNotFound((state) => false);
    }
  }, [resourceId]); // eslint-disable-line

  return { resourceLoading: loading, loading, data, notFound };
};

// this hook waits for the initial loading to toggle true and then false
// since loading often starts false, it can cause a flash of empty content before loader
export const useLoaded = ({ loading }) => {
  const [loaded, setLoaded] = React.useState(null);

  // FIX HOOK
  React.useEffect(() => {
    if (loading && loaded === null) setLoaded(false);
    else if (loaded === false && !loading) setLoaded(true);
  }, [loading]); // eslint-disable-line

  return loaded === true;
};
