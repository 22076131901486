import React from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { useAppContext } from 'components/v2/AppContext';

import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Sidebar from 'components/v2/PageElements/Sidebar';
import SidebarHeader from 'components/v2/PageElements/Sidebar/Header';
import SidebarList from 'components/v2/PageElements/Sidebar/List';
import SidebarActionListItem from 'components/v2/PageElements/Sidebar/ActionListItem';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import InboxZero from 'components/v2/Inbox/InboxZero';

import HeaderLayout from 'layouts/v2/HeaderLayout';
import { humanizeSnake } from 'lib/v1/format';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from 'components/v2/FormElements/Button';
import Typography from '@mui/material/Typography';

// import { GrowChildren } from 'components/v2/PageElements/Transitions';

const useStyles = makeStyles({
  root: {},
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const mapping = {
  form_requests_due: { title: 'Digital Forms' },
  reinvestigations_due: { title: 'People' },
  expiring_contracts: { title: 'Contracts' },
  expiring_visits: { title: 'Visits' },
  expiring_badges: { title: 'Badges' },
};

export const OutlinedCard = ({ type, count = 0 }) => {
  const classes = useStyles();
  const title = (mapping[type] && mapping[type].title) || type;

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography className={classes.title} color="secondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" component="h2">
          {count} {humanizeSnake(type)}
        </Typography>
        {/*
        <Typography variant="body2" component="p">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}

        </Typography>
        */}
      </CardContent>
      <CardActions>
        <Button size="small">Click to View</Button>
      </CardActions>
    </Card>
  );
};

const TodoItem = (props) => (
  <SidebarActionListItem icon={<CheckBoxOutlineBlankIcon />} {...props} />
);

/*
const choose = (choices) => {
  var index = Math.floor(Math.random() * choices.length);
  return choices[index];
};
*/
const mapRemindersToProps = ({ inbox: { reminders } }) => ({ data: reminders });

const Reminders = connect(mapRemindersToProps)(({ data = [] }) => {
  const visible = data
    .map((reminder, i) => {
      if (!reminder || !reminder.count) return undefined;
      return (
        <Grid key={`grid-item-${i}`} item xs={6} sm={4}>
          <OutlinedCard type={reminder.type} count={reminder.count} />
        </Grid>
      );
    })
    .filter((c) => c);

  return (
    <Grid container spacing={2}>
      {visible}
    </Grid>
  );
});

const Dashboard = (props) => {
  return (
    <HeaderLayout title="Dashboard">
      <Reminders />
    </HeaderLayout>
  );
};

const Todo = ({ data = [] }) => {
  if (!data || !data.length) return <InboxZero />;
  return (
    <>
      <SidebarHeader>{'My Todo List'}</SidebarHeader>
      <SidebarList>
        {data.map((name) => (
          <TodoItem key={`todo-${name}`} buttonText={name} />
        ))}
      </SidebarList>
    </>
  );
};

const RoutingListItem = withRouter(({ path, history, ...rest }) => (
  <SidebarActionListItem {...{ ...rest, onClick: () => history.push(path) }} />
));

export const MySidebarContent = () => {
  const {
    profile: { id },
    paths: { my },
  } = useAppContext();

  return (
    <>
      <SidebarHeader>{'Have Something to Share?'}</SidebarHeader>
      <SidebarList>
        <RoutingListItem
          path={my['foreign-travel'].root}
          buttonText="Report a trip or other info to my security manager"
        />
        <RoutingListItem
          buttonText="Request a Visit to another facility"
          path={my.profile.resource.visits(id)}
        />
        <RoutingListItem
          path={my.profile.resource.actionRequests(id)}
          buttonText="Request Action from my Security Team"
        />
      </SidebarList>
      <SidebarHeader>{'My Information'}</SidebarHeader>
      <SidebarList>
        <RoutingListItem buttonText="View My Assets" path={my.profile.resource.assets(id)} />
        <RoutingListItem
          buttonText="View My Accesses and Eligibilities"
          path={my.profile.resource.eligibility(id)}
        />
        <RoutingListItem buttonText="Manage My Badges" path={my.profile.resource.badges(id)} />
      </SidebarList>
    </>
  );
};

const Component = ({ isAdminContext = false }) => {
  const data = ['Sign 2 Digital Form Signature Requests', 'Complete 1 Training Assignment'];

  return (
    <Sidebar>
      <ReduxManager data={['notifications']}>
        {isAdminContext ? <Dashboard /> : <Todo {...{ data }} />}
      </ReduxManager>
    </Sidebar>
  );
};

export default Component;
