import React from 'react';

const Carat = ({ height = '13px', width = '21px', ...rest }) => (
  <svg height={height} width={width} viewBox="0 0 21 13" {...rest}>
    <g strokeWidth="3px" stroke="#979797" strokeLinecap="square">
      <path d="M18.5 10.5l-8-8M10.5 2.5l-8 8" />
    </g>
  </svg>
);

export default Carat;
