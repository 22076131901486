const initialState = {};

const index = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    default:
      return state;
  }
};

export default index;
