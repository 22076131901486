import React from 'react';

// MUI
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import LockIcon from '@mui/icons-material/Lock';
import Typography from '@mui/material/Typography';

import Tooltip from 'components/v2/FormElements/Tooltip';
import { useDialog } from 'components/v2/FormElements/Dialog';
import ConfirmModal from 'components/v2/FormElements/ConfirmModal';

export const styles = (theme) => ({
  metaContainer: {
    display: 'flex',
    alignItems: 'end',
  },
  meta: {
    fontSize: 12,
    wordBreak: 'break-word',
  },
  author: {
    fontWeight: '700',
    // color: charcoal,
    whiteSpace: 'nowrap',
    paddingRight: '1.25rem',
  },
  date: {
    fontWeight: '400',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
  lockIcon: {
    color: theme.palette.alert.main,
    width: '1rem',
    position: 'absolute',
    right: -17,
    bottom: 20,
    '&:hover': {
      cursor: 'help',
    },
  },
  commentBody: {
    fontSize: 12,
    // border: `1px solid ${theme.palette.divider}`,
    borderRadius: 10,
    wordBreak: 'break-word',
    padding: '0.625rem',
    background: theme.palette.divider,
    // color: charcoal,
    whiteSpace: 'pre-line',
    '&.adminOnly': {
      // border: `1px solid ${theme.palette.alert.main}`,
    },
    '&.self': {
      background: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      // borderColor: theme.palette.success.contrastText,
    },
  },
  commentToolsContainer: {
    zIndex: 1,
    // border: `1px solid ${theme.palette.divider}`,
    background: theme.palette.navbar.main,
    borderRadius: 20,
    position: 'absolute',
    right: 10,
    top: 10,
    display: 'none',
    padding: 2,
    cursor: 'pointer',
    boxShadow: '0 0 15px -5px rgba(0,0,0,0.5)',
  },
  commentTools: {
    display: 'flex',
  },
  commentTool: {
    fontSize: `1rem`,
    color: theme.palette.alert.main,
  },
});

const useStyles = makeStyles(styles);

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const findTags = (string) => {
  const regex = /\[(\S*)\]/gm;
  const tags = string.match(regex);
  return tags ? tags.filter(onlyUnique) : null;
};

export const parseTags = (arr) => {
  if (!arr || !arr.map) return arr;
  const parsed = arr.map((tag) => {
    const json = JSON.parse(tag.replace('[[', '[').replace(']]', ']'))[0];
    return { string: tag, json };
  });
  return parsed;
};

const escapeRegExp = (text) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const formatTags = (description) => {
  const parsed = parseTags(findTags(description));
  if (!parsed) return description;

  let temp = description;
  parsed.forEach((obj) => {
    const regex = new RegExp(escapeRegExp(obj.string), 'g');
    temp = temp.replace(regex, `@${obj.json.value}`);
  });

  return temp;
};

export const Meta = ({
  name,
  createDateTime,
  adminOnly,
  onDelete,
  canDelete,
  preferences,
  description,
}) => {
  const classes = useStyles();
  const { onClick, onClose, open } = useDialog();

  const handleDelete = async () => {
    await onDelete();
    onClose();
  };

  // we'll need to modify a bit when we get more actions
  const modalProps = {
    title: 'Delete Comment',
    isOpen: open,
    onConfirm: handleDelete,
    confirmText: 'Delete',
    onClose,
  };

  return (
    <Box className={classes.metaContainer}>
      <Box className={classes.meta}>
        <span className={classes.author}>{name || 'Unknown User'}</span>
        <span className={classes.date}>{createDateTime}</span>
      </Box>
      {canDelete && (
        <Box className={`${classes.commentToolsContainer} commentTools`}>
          <Box className={classes.commentTools}>
            {/* for now just letting us see how multiple tools would look until we build edit */}
            {preferences.betaTester && (
              <IconButton>
                <EditIcon className={classes.commentTool} />
              </IconButton>
            )}
            <IconButton onClick={onClick}>
              <DeleteIcon className={classes.commentTool} />
            </IconButton>
          </Box>
        </Box>
      )}
      {adminOnly && (
        <Tooltip
          title={'Only Administrators and Security Managers will be able to see this comment'}
        >
          <LockIcon className={classes.lockIcon} />
        </Tooltip>
      )}
      <ConfirmModal {...modalProps}>
        Are you sure you want to permanently delete this comment?
        <Typography variant="caption" component="p">
          {description}
        </Typography>
      </ConfirmModal>
    </Box>
  );
};

export const Body = ({ description, isSelf, adminOnly }) => {
  const classes = useStyles();
  return (
    <Box
      className={`${classes.commentBody} ${isSelf ? 'self' : ''} ${adminOnly ? 'adminOnly' : ''}`}
    >
      {description}
    </Box>
  );
};
