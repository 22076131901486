import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths from 'config/paths/analytics';
import reportsPaths from 'config/paths/reports';
import dashboardsPaths from 'config/paths/dashboards';

// Components
const Index = lazy(() => import('./index'));
const ReportsCreate = lazy(() => import('./Reports/Create'));
const ReportsDetail = lazy(() => import('./Reports/Detail'));
const DashboardsDetail = lazy(() => import('./Dashboards/Detail'));

export const Routes = ({ paths, permissions }) => {
  return (
    <Suspense fallback={<></>}>
      <Switch>
        <Route exact path={reportsPaths.create} component={ReportsCreate} />

        <Route
          exact
          path={[reportsPaths.resource.detail(':id'), reportsPaths.resource.create(':id')]}
          component={ReportsDetail}
        />

        <Route exact path={dashboardsPaths.resource.detail(':id')} component={DashboardsDetail} />

        <Route path={paths.root} component={Index} />
      </Switch>
    </Suspense>
  );
};

export default connect(mapPermissionsToProps, null)((props) => <Routes paths={paths} {...props} />);
