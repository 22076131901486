import React from 'react';
import { Suspense, lazy } from 'react';

import { withPermissionsAndPreferences } from 'lib/v1/hooks';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch } from 'components/v2/PageElements/Routes';

// Config
import paths, { adminPaths } from 'config/paths/tasks';

// Components
const Index = lazy(() => import('./index'));
const Detail = lazy(() => import('./Detail'));

export const Routes = ({ paths, permissions, preferences }) => {
  return (
    <ReduxManager data={['peopleDropdown', 'digitalFormTemplatesDropdown']}>
      <Suspense fallback={<></>}>
        <Switch>
          <Route preference={preferences.tasks || preferences.workflows} exact path={paths.root}>
            <Index paths={paths} />
          </Route>
          <Route preference={preferences.tasks || preferences.workflows} path={paths.resource.root}>
            <Detail paths={paths} />
          </Route>

          <Route
            preference={preferences.tasks || preferences.workflows}
            exact
            path={adminPaths.root}
          >
            <Index paths={adminPaths} />
          </Route>
          <Route
            preference={preferences.tasks || preferences.workflows}
            path={adminPaths.resource.root}
          >
            <Detail paths={adminPaths} />
          </Route>
        </Switch>
      </Suspense>
    </ReduxManager>
  );
};

export default withPermissionsAndPreferences((props) => <Routes paths={paths} {...props} />);
