const initialState = {
  calledObjects: {},
};

const reduxManagerCalls = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'TRACK_DROPDOWN_CALL':
      return {
        ...state,
        calledObjects: {
          ...state.calledObjects,
          [payload.callType]: payload.callTime,
        },
      };
    default:
      return state;
  }
};

export default reduxManagerCalls;
