// MUI
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
  },
}));

const Index = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <List className={classes.list} {...rest}>
      {children}
    </List>
  );
};

export default Index;
