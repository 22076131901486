import React from 'react';

import Chip from '@mui/material/Chip';

const Pil = ({ name, value = 'Text', onCancel = () => {} }) => {
  const handleRemove = (e) => {
    e.preventDefault();
    if (!onCancel) return;
    onCancel(name);
  };

  return <Chip label={value} onDelete={handleRemove} />;
};

const ActiveFilters = ({ activeFilters = [], onCancelPil }) => {
  const arr = activeFilters || [];
  if (!arr.length) return null;
  return (
    <div className="Filters-pills" style={{ paddingTop: 5 }}>
      {arr.map((d, i) => (
        <Pil {...d} key={i} index={i} onCancel={onCancelPil} />
      ))}
    </div>
  );
};

export default ActiveFilters;
