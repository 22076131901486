import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

const stateBuilder = () => {
  const mapStateToProps = ({ profile }) => {
    return {
      profile,
      ...mapPermissionsToProps({ profile }),
      ...mapPreferencesToProps({ profile }),
    };
  };

  const mapDispatchToProps = {};

  return {
    withFeatureState: (Component) => {
      return withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
    },
  };
};

export const { withFeatureState } = stateBuilder();
