import { createActions } from 'redux-actions';

import request from 'lib/v1/request';
import handleError from 'lib/v1/error-handling';
import CONST from './constants';
import fileDownload from 'js-file-download';

const { getReportPending, getReportSuccess } = createActions(
  {
    [CONST.GET_REPORT_SUCCESS]: [(payload) => payload, (payload, meta) => meta],
  },
  CONST.GET_REPORT_PENDING,
  CONST.GET_REPORT_FAILURE
);

const makeFilename = (e, ext = 'csv') => {
  let str = e[0].toUpperCase() + e.substring(1) + '.' + ext;
  return str;
};

export const getReport = (endpoint, filters) => {
  const params = { filters };

  return async (dispatch) => {
    dispatch(getReportPending());
    return request
      .post(`/reports/${endpoint}`)
      .send(params)
      .then((res) => {
        fileDownload(res.text, makeFilename(endpoint));
        dispatch(getReportSuccess());
      })
      .catch(handleError);
  };
};

// Backwards compatibility ya'll
export const getReportNotDumb = (endpoint, query) => {
  return async (dispatch) => {
    dispatch(getReportPending());
    return request
      .post(`/reports/${endpoint}`)
      .send(query)
      .then((res) => {
        fileDownload(res.text, makeFilename(endpoint));
        dispatch(getReportSuccess());
      })
      .catch(handleError);
  };
};

export const getTravelReport = (id) => {
  return async (dispatch) => {
    dispatch(getReportPending());
    return request
      .post(`/travels/${id}/details`)
      .then((res) => {
        fileDownload(res.text, makeFilename('Travel Report', 'pdf'));
        dispatch(getReportSuccess());
      })
      .catch(handleError);
  };
};
