import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

// actions
import actions from 'store/v2/userAccesses/actions';
import facilityActions from 'store/v2/facilities/actions';

// adapters
import { adaptToDetail } from 'adapters/v1/userAccesses/formData';

const stateBuilder = () => {
  const mapResourceToProps = ({ userAccesses }, ownProps) => {
    const { match, resource } = ownProps;
    const resourceId = match?.params ? Number(match.params.id) : resource?.id;
    return { resourceId, resource: adaptToDetail(userAccesses.data[resourceId]) };
  };

  const mapStateToProps = ({ profile, userAccesses, contractsV2, facilitiesV2, users }) => {
    const { activity, loading, data } = userAccesses || {};

    return {
      activity: Object.values(activity || {}),
      contracts: contractsV2.dropdown,
      facilities: Object.values(facilitiesV2.dropdown),
      userFacilities: facilitiesV2.data,
      profile,
      users,
      loading,
      userAccesses: Object.values(data || {}).map(adaptToDetail),
      ...mapPermissionsToProps({ profile }),
      ...mapPreferencesToProps({ profile }),
    };
  };

  const include = {
    include: 'user,type_access,contract,facilities',
  };

  const mapDispatchToProps = {
    ...actions,
    list: (queryParams) =>
      actions.list({
        ...queryParams,
        ...include,
        showAll: true,
      }),
    listFacilities: (queryParams) => facilityActions.list({ ...queryParams, showAll: true }),
    show: (id, payload, silent) => actions.show(id, { ...payload, ...include }, silent),
    create: (payload, silent) => actions.create({ ...payload, ...include }, silent),
    update: (id, payload, silent) => actions.update(id, { ...payload, ...include }, silent),
  };

  return {
    withFeatureState: (Component) => {
      return withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
    },
    withResourceState: (Component) => {
      return withRouter(
        connect(
          (store, ownProps) => ({
            ...mapStateToProps(store),
            ...mapResourceToProps(store, ownProps),
          }),
          mapDispatchToProps
        )(Component)
      );
    },
  };
};

export const { withFeatureState, withResourceState } = stateBuilder();
