import store from 'store';

import { hasWorkflows } from 'permissions/v1/preferences';

const isWorkflowsContext = () => {
  const state = store.getState() || {};
  const { profile } = state;
  return hasWorkflows(profile);
};

export const ticketObjectName = (str) => {
  const workflowsContext = isWorkflowsContext();

  if (workflowsContext) {
    str = str.replace('Object', 'Ticket');
    str = str.replace('an object', 'a ticket');
    str = str.replace('object', 'ticket');
  } else {
    str = str.replace('Object', 'Request');
    str = str.replace('an object', 'an action request');
    str = str.replace('object', 'action request');
  }

  return str;
};

export const workflowObjectName = (str) => {
  const workflowsContext = isWorkflowsContext();

  if (workflowsContext) {
    str = str.replace('Object', 'Workflow');
    str = str.replace('object', 'workflow');
  } else {
    str = str.replace('Object', 'Request Template');
    str = str.replace('object', 'request template');
  }

  return str;
};
