import { withRouter } from 'react-router';
import store from 'store';
import { capitalize } from 'lib/v1/strings';

const TITLE_ALIASES = {
  reportable: 'Reportable Info',
};

const PageTitle = ({ history, children }) => {
  const state = store.getState() || {};
  const { profile } = state;

  const cleanTitle = (title = '') => {
    return title
      .replace('-', ' ')
      .replace('_', ' ')
      .split(' ')
      .map((word) => capitalize(word))
      .join(' ');
  };

  const userTitle = (pathname = '') => {
    const pathParts = pathname.split('/');
    if (pathParts.length > 3) {
      const id = pathParts[2];
      const items = cleanTitle(pathParts[3]);
      const prefix = id === String(profile?.id) ? 'My ' : 'User ';
      return `${prefix} ${items}`;
    } else return 'Users';
  };

  history.listen(({ pathname }, action) => {
    let title = pathname.split('/')[1];

    if (title === 'admin') title = cleanTitle(pathname.split('/')[2]);
    else title = cleanTitle(title);

    if (title.toLowerCase() === 'users') title = userTitle(pathname);

    const alias = TITLE_ALIASES[title?.toLowerCase()];

    document.title = `Sign In Compliance - ${alias || title}`;
  });

  return children;
};

export default withRouter(PageTitle);
