import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths from 'config/paths/activity';

// Components
import Index from './index';
import Detail from './Detail';

export const Routes = ({ paths, permissions }) => {
  return (
    <ReduxManager data={[]}>
      <Switch>
        <Route
          permission={true}
          exact
          path={paths.root}
          render={(props) => <Index {...props} paths={paths} />}
        />
        <Route
          permission={true}
          exact
          path={paths.resource.detail(':id')}
          render={(props) => <Detail {...props} paths={paths} />}
        />
      </Switch>
    </ReduxManager>
  );
};

export default connect(
  mapPermissionsToProps,
  null
)((props) => <Routes paths={paths} {...props} />);
