import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';
import { mapTableToPropsFactory, mergePropsToTable } from 'mappers/v1/table';

// actions
import actions from 'store/v2/incidents/actions';
import paths from 'config/paths/reportablesV2';

// adapters
import { adaptToDetail } from 'adapters/v1/incidents/formData';

export const stateBuilder = (paramId = 'id', featureReduxKey = 'incidents') => {
  const mapDataToTable = mapTableToPropsFactory(featureReduxKey);

  const mapResourceToProps = (store, ownProps) => {
    const reduxKey = store[featureReduxKey];
    // Map resource from store, we've wrapped the component
    // in withRouter so match is Present.
    const { match, resource } = ownProps;
    const resourceId =
      match && match.params ? Number(match.params[paramId]) : resource && resource.id;
    return { resourceId, resource: adaptToDetail(reduxKey.data[resourceId]) };
  };

  const mapStateToProps = ({ profile, people, incidentTemplates, ...rest }) => {
    // Generically map activity from any v2 store key in redux
    const data = rest[featureReduxKey] || {};
    const { activity, loading } = data;

    const reportableTemplates = Object.values(incidentTemplates.dropdown || {}).map(adaptToDetail);

    return {
      activity: Object.values(activity || {}),
      rows: Object.values(data.data || {}).map(adaptToDetail),
      users: people.dropdown,
      reportableTemplates,
      loading,
      profile,
      ...mapPermissionsToProps({ profile }),
      ...mapPreferencesToProps({ profile }),
    };
  };

  const include = {
    include:
      'comments,created_by,documents,incident_template,instance_custom_fields,reviewer,subjects,watchers',
  };

  const mapDispatchToProps = {
    ...actions,
    create: (payload) => actions.create({ ...payload, ...include }),
    list: (queryParams) =>
      actions.list({
        sort: 'created_at',
        ...queryParams,
        include: 'incident_template,reviewer,subjects,watchers',
      }),
    show: (id, payload, silent) => actions.show(id, { ...payload, ...include }, silent),
    update: (id, payload, silent) => actions.update(id, { ...payload, ...include }, silent),
  };

  const withPaths = (Component) => (props) => <Component paths={paths} {...props} />;

  return {
    withTableState: (Component) => {
      return withRouter(
        connect(
          (store, ownProps) => ({
            ...mapDataToTable(store),
            ...mapStateToProps(store),
            ...mapResourceToProps(store, ownProps),
          }),
          mapDispatchToProps,
          mergePropsToTable
        )(withPaths(Component))
      );
    },
    withFeatureState: (Component) => {
      return connect(mapStateToProps, mapDispatchToProps)(withRouter(Component));
    },
    withResourceState: (Component) => {
      return withRouter(
        connect(
          (store, ownProps) => ({
            ...mapStateToProps(store),
            ...mapResourceToProps(store, ownProps),
          }),
          mapDispatchToProps
        )(withPaths(Component))
      );
    },
  };
};

// by encapsulating all of our HOCs into a single function definition, I can cleanly swap out minimal state to reuse components across routes.
// This is literally a function, that returns an object, that contains 3 higher order components composed of 1 or more higher order components..
export const { withFeatureState, withResourceState, withTableState } = stateBuilder();
