import React from 'react';

const Time = ({ width = '64px', height = '64px', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 64 64" {...rest}>
    <path
      d="M45,29H35V14c0-1.657-1.343-3-3-3s-3,1.343-3,3v18c0,1.657,1.343,3,3,3h13c1.657,0,3-1.343,3-3S46.657,29,45,29z M32,0
      C14.327,0,0,14.327,0,32s14.327,32,32,32s32-14.327,32-32S49.673,0,32,0z M32,58C17.641,58,6,46.359,6,32C6,17.641,17.641,6,32,6
      c14.359,0,26,11.64,26,26C58,46.359,46.359,58,32,58z"
    />
  </svg>
);

export default Time;
