import React from 'react';

import CompanyLogo from 'components/v2/PageElements/CompanyLogo';

import { withPermissionsAndPreferences } from 'lib/v1/hooks';

// MUI
import { makeStyles } from '@mui/styles';

export const mainNavWidth = '5.25rem';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    zIndex: 2,
    background: theme.palette.navbar.main,
    minHeight: '100vh',
    padding: '0',
    width: mainNavWidth,
  },
  channelStrip: {
    height: 'calc(100vh - 90px)',
    display: 'flex',
    flexDirection: 'column',
  },
  pushBottom: {
    justifyContent: 'space-between',
    '& > :last-child': {
      marginTop: 'auto',
    },
  },
}));

const NavWrapper = ({ children, preferences }) => {
  const classes = useStyles();

  return (
    <nav className={classes.wrapper}>
      <CompanyLogo />
      <div className={classes.channelStrip}>{children}</div>
    </nav>
  );
};

export default withPermissionsAndPreferences(NavWrapper);
