import tinycolor from 'tinycolor2';
import { styled } from '@mui/material/styles';

import {
  StyledHeader,
  StyledCustomIcon,
  StyledUpdatedCustomIcon,
  StyledTitle,
  StyledIcon,
  StyledBody,
} from 'styles/Inbox/FeedItem.js';

const StyledWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 4,
  boxShadow: theme.shadows[1],
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  cursor: 'pointer',
  fontWeight: 'normal',
  marginBottom: '1rem',
  padding: '1.375rem 0',
  overflow: 'hidden',
}));

const StyledMuiIcon = styled(({ Icon, ...props }) => <Icon {...props} />)(({ theme, color }) => ({
  color: color,
  backgroundColor: tinycolor(color).lighten(40).toString(),
  borderRadius: '2px',
  margin: '0 1.25rem',
  padding: '0.2em',
  height: '2.5rem',
  width: '2.5rem',
}));

// this active stuff is a bit weird, but the DOM doesn't like passing booleans
const FeedItem = ({ active, children, icon, Icon, title, onToggleCard }) => (
  <StyledWrapper active={active ? `${active}` : undefined} onClick={onToggleCard}>
    <StyledHeader>
      {Icon && <StyledMuiIcon Icon={Icon.src} color={Icon.color} />}
      {!Icon && icon.updated && <StyledUpdatedCustomIcon alt={icon.alt} src={icon.src} />}
      {!Icon && !icon.updated && <StyledCustomIcon src={icon.src} alt={icon.alt} />}
      <StyledTitle>{title}</StyledTitle>
      <StyledIcon name="Carat" flip={active ? `${active}` : undefined} />
    </StyledHeader>
    <StyledBody active={active ? `${active}` : undefined}>{children}</StyledBody>
  </StyledWrapper>
);

export default FeedItem;
