import request from 'lib/v1/request';
import agent from 'superagent';
import { createAction } from 'redux-actions';
import { FEED_ACTIONS } from './constants';
import handleError from 'lib/v1/error-handling';

/*YQL for 5 most recent TSW blog posts
 *
 * select title, link, pubDate from rss where url = 'http://threatswitch.com/feed/' limit 5
 *
 * */
const THREATSWITCH_RSS_YQL = 'http://www.threatswitch.com/threatswitchblog/rss.xml';
const parseXML = (documentXML) => {
  return (
    (Array.from(documentXML.querySelectorAll('item')) || []).slice(0, 5).map((item) => ({
      title: item.querySelector('title').innerHTML,
      link: item.querySelector('link').innerHTML,
      date: item.querySelector('date').innerHTML,
    })) || []
  );
};

export const feed_fetched = createAction(FEED_ACTIONS.FEED_FETCHED);
export const news_feed_fetched = createAction(FEED_ACTIONS.NEWS_FEED_FETCHED);

export function getFeed({ page = 1, limit = 10 } = {}) {
  return (dispatch) => {
    return request
      .get(`/activities?page=${page}&limit=${limit}`)
      .then((res) => dispatch(feed_fetched({ feed: res.body.data, total_pages: res.body.meta })))
      .catch((err) => dispatch(() => handleError(err, true)));
  };
}

export function getNewsFeed() {
  return (dispatch) => {
    return agent
      .get(THREATSWITCH_RSS_YQL)
      .then((res) => {
        const parser = new window.DOMParser();
        const documentXML = parser.parseFromString(res.text, 'application/xml');
        return dispatch(news_feed_fetched(parseXML(documentXML)));
      })
      .catch((err) => dispatch(() => handleError(err, true)));
  };
}
