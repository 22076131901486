export const root = '/admin/imports';

export const editPath = (id) => {
  return `${root}/${id}/edit`;
};

export const resourcePath = (id) => {
  return `${root}/${id}`;
};

export const showPath = (id) => {
  return `${root}/${id}/detail`;
};

export const deletePath = (id) => {
  return `${root}/${id}/delete`;
};

export const preparePath = (id) => {
  return `${root}/${id}/prepare`;
};

export const requiredPath = (id) => {
  return `${root}/${id}/required`;
};

export const uploadPath = (id) => {
  return `${root}/${id}/upload`;
};

export const finalizePath = (id) => {
  return `${root}/${id}/finalize`;
};

const paths = {
  root: root,
  list: `${root}`,
  create: `${root}?create`,
  update: `${root}/edit`,
  resource: resourcePath,
  delete: {
    resource: deletePath,
  },
  edit: {
    resource: editPath,
  },
  show: {
    resource: showPath,
  },
  required: {
    root: `${root}/required`,
    resource: requiredPath,
  },
  prepare: {
    root: `${root}/prepare`,
    resource: preparePath,
  },
  upload: {
    root: `${root}/upload`,
    resource: uploadPath,
  },
  finalize: {
    root: `${root}/finalize`,
    resource: finalizePath,
  },
};

export default paths;
