import CONST from './constants';

const initialState = {
  data: {},
  result: [],
};

const index = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case CONST.GET_LABELS_PENDING:
    case CONST.CREATE_LABEL_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.GET_LABELS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...payload.reduce((labels, label) => {
            labels[label.id] = label;
            return labels;
          }, {}),
        },
        result: [...new Set(state.result.concat(payload.map((label) => label.id)))],
      };
    case CONST.CREATE_LABELS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.id]: payload,
        },
        result: [...new Set([payload.id].concat(state.result))],
      };
    default:
      return state;
  }
};

export default index;
