import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import { cellAdapterFactory, cellLinkAdapterFactory } from 'adapters/v2/factories';
import { adaptToJsonApiRequest, adaptJsonApiToDetail } from 'adapters/v1/json_api';
import { adaptSingleSelectFieldsToRequest } from 'adapters/v1';
import { adaptToCalendarDate } from './index';

const dateFields = [];
const singleSelectFields = [];

export const adaptToRequest = (formData) => {
  const adapted = {
    ...adaptToIso(formData, dateFields),
    ...adaptSingleSelectFieldsToRequest({ formData, singleSelectFields }),
  };

  return adaptToJsonApiRequest({ attributes: adapted, type: 'paper_trail_version' });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const updated = adaptToDisplay(adaptJsonApiToDetail(resource), dateFields);
  const { user, created_at } = updated;

  if (user) {
    updated.user_id = user.name;
  }

  updated.created_at = adaptToCalendarDate(created_at);

  updated.display_name = `${updated.item_type} ${updated.event} at ${updated.created_at}`;

  return updated;
};

export const adaptToForm = (resource) => {
  if (!resource) return {};
  return { ...resource };
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
