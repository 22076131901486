import { useProfileContext } from 'components/v2/Profile/Context';

// mappers
import { withTableState } from './state';

// FlexTable API
import TableAPI from 'components/v2/FlexTable/Table';
import Pagination from 'components/v2/FlexTable/Pagination';
import SearchLayout from 'components/v2/FlexTable/SearchLayout';

import { ByDueDate, ByStatus, ByTemplate } from './Filters';
import { ByUser, ByTag } from 'components/v2/Filters/MultiSelect';
import { ConnectedRefresh as Refresh } from './RowActions';
import { comboCellAdapter } from 'adapters/v2/factories';
import { chipCellAdapter } from 'adapters/v1/digitalFormTemplates/formData';
import { DetailPath } from 'components/v2/Email/Routes';
import { useStaticQuery } from 'components/v2/FlexTable/hooks';

export const Table = ({
  list,
  children,
  permissions,
  updateQuery,
  paths,
  cellAdapter,
  cellLinkAdapter,
  resourceName = 'Digital Form',
  ...rest
}) => {
  const { isSelf, userId } = useProfileContext();
  useStaticQuery({ query: rest.query, queryKey: 'user_id', resourceId: userId, refresh: !userId });

  const permissionedFilters = [
    {
      filter: ByUser,
      permission: !userId,
    },
    {
      filter: ByStatus,
      permission: true,
    },
    {
      filter: ByTemplate,
      permission: true,
    },
    {
      filter: ByDueDate,
      permission: true,
    },
    {
      filter: ByTag,
      permission: true,
    },
  ];

  const filterOptions = () => ({
    filterOptions: permissionedFilters
      .filter((obj) => obj.permission)
      .map((obj) => obj.filter({ updateQuery })),
  });

  const rowActions = () => {
    if (!permissions) return [];

    const actions = [
      { action: Refresh, permission: permissions.digitalFormRequests.view || isSelf },
    ];

    return {
      rowActions: actions.filter((action) => action.permission).map((action) => action.action),
    };
  };

  const columns = () => {
    if (!permissions) return [];

    const data = [
      {
        Header: 'Requested Signature',
        accessor: 'template_id',
        Cell: comboCellAdapter([
          cellLinkAdapter('form_name', ({ id }) => paths.resource.detail(id)),
          cellAdapter('subtitle'),
        ]),
        permission: true,
      },
      {
        Header: 'Signatures',
        accessor: 'sign_count',
        Cell: cellAdapter('sign_count'),
        disableSortBy: true,
        permission: true,
      },
      {
        Header: 'Signing User',
        accessor: 'user_id',
        Cell: comboCellAdapter([
          cellLinkAdapter('user_name', 'userDetail', { id: 'user_id' }),
          cellAdapter('user_subtitle'),
        ]),
        permission: !userId,
      },
      {
        Header: 'Date Requested',
        accessor: 'created_at',
        Cell: cellAdapter('created_at'),
        permission: true,
      },
      {
        Header: 'Linked Item',
        accessor: 'attachment_type',
        Cell: cellLinkAdapter('attachment_type_display', (props) =>
          DetailPath({
            match: {
              params: {
                id: props.attachment_id,
                module: props.attachment_type?.toLowerCase(),
              },
            },
          })
        ),
        disableSortBy: true,
        permission: true,
      },
      {
        Header: 'Tags',
        accessor: 'tag_array',
        Cell: chipCellAdapter('tag_array'),
        disableSortBy: true,
        permission: true,
      },
    ];

    return { columns: data.filter((column) => column.permission) };
  };

  const listActionProps = () => {
    return { list };
  };

  if (userId) {
    rest.data = rest.data.filter((obj) => obj?.attributes?.user_id === userId);
  }

  return (
    <>
      {!userId && <SearchLayout filters={filterOptions()} {...rest} />}
      <TableAPI {...columns()} {...rowActions()} {...listActionProps()} {...rest} />
      <Pagination {...rest} {...listActionProps()} />
    </>
  );
};

export default withTableState(Table);
