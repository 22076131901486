// mappers
import { withPermissionsAndPreferences } from 'lib/v1/hooks';

// Components
import Divider from '@mui/material/Divider';
import Reminder from 'components/v2/Inbox/InboxReminder';

export const ReminderList = ({
  array,
  closeDrawer,
  preferences = {},
  permissions,
  profile,
} = {}) => {
  const showReminder = (key) => {
    return {
      reinvestigations_due: preferences.accesses,
      form_requests_due: preferences.forms && permissions && permissions.forms.list,
      expiring_contracts: permissions && permissions.contracts.list,
      expiring_visits: preferences.visits && permissions && permissions.visits.list,
      expiring_badges: preferences.badges && permissions && permissions.badges.list,
      tasks_due: permissions && permissions.tasks.list,
      travels_due: preferences.travels,
      form_signatures_due: preferences.forms,
    }[key];
  };

  return array.map((item, index) => {
    // edge case where someone has a reminder without explicit permissions
    if (item.count < 1 && !showReminder(item.type)) return null;

    return (
      <>
        {item.type === 'tasks_due' && index > 0 && <Divider />}
        <Reminder
          closeDrawer={closeDrawer}
          object={item}
          profile={profile}
          key={`reminder-${index}`}
        />
      </>
    );
  });
};

export default withPermissionsAndPreferences(ReminderList);
