import CONST from './constants';
import { initialState } from 'store/v2/index';

const index = (state = initialState(), { type, payload = {} }) => {
  const { data, meta, error } = payload;
  switch (type) {
    case CONST.LIST_SIGNATURE_REQUESTS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.LIST_SIGNATURE_REQUESTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...data.reduce((obj, form) => {
            const id = form.signature_request_id;
            obj[id] = form;

            // Allow client-side to override status
            // This is needed due to HelloSign's inconsistent API
            obj[id].is_complete = state.data[id] ? state.data[id].is_complete : obj[id].is_complete;

            return obj;
          }, {}),
        },
        meta,
        loading: false,
        result: data.map((d) => d.signature_request_id),
      };
    case CONST.LIST_SIGNATURE_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: error,
      };

    default:
      return state;
  }
};

export default index;
