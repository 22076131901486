const constants = {
  ALL_USERS_PENDING: 'ALL_USERS_PENDING',
  ALL_USERS_SUCCESS: 'ALL_USERS_SUCCESS',
  ALL_USERS_FAILURE: 'ALL_USERS_FAILURE',
  GET_USER_PENDING: 'GET_USER_PENDING',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_FAILURE: 'GET_USER_FAILURE',
  GET_USER_ACCESSES_PENDING: 'GET_USER_ACCESSES_PENDING',
  GET_USER_ACCESSES_SUCCESS: 'GET_USER_ACCESSES_SUCCESS',
  GET_USER_ACCESSES_FAILURE: 'GET_USER_ACCESSES_FAILURE',
  CREATE_USER_PENDING: 'CREATE_USER_PENDING',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  SEND_WELCOME_EMAIL_PENDING: 'SEND_WELCOME_EMAIL_PENDING',
  SEND_WELCOME_EMAIL_SUCCESS: 'SEND_WELCOME_EMAIL_SUCCESS',
  SEND_WELCOME_EMAIL_FAILURE: 'SEND_WELCOME_EMAIL_FAILURE',
  UPDATE_USER_PENDING: 'UPDATE_USER_PENDING',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',
  UPDATE_ALL_USERS_PENDING: 'UPDATE_ALL_USERS_PENDING',
  UPDATE_ALL_USERS_SUCCESS: 'UPDATE_ALL_USERS_SUCCESS',
  UPDATE_ALL_USERS_FAILURE: 'UPDATE_ALL_USERS_FAILURE',
  DELETE_USER_PENDING: 'DELETE_USER_PENDING',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
  USERS_CHANGE_PAGE: 'USERS_CHANGE_PAGE',
  USERS_RESET_PAGING: 'USERS_RESET_PAGING',
  GET_USERS_SLIM_PENDING: 'GET_USERS_SLIM_PENDING',
  GET_USERS_SLIM_SUCCESS: 'GET_USERS_SLIM_SUCCESS',
  GET_USERS_SLIM_FAILURE: 'GET_USERS_SLIM_FAILURE',
  ADD_USER_FACILITIES_PENDING: 'ADD_USER_FACILITIES_PENDING',
  ADD_USER_FACILITIES_SUCCESS: 'ADD_USER_FACILITIES_SUCCESS',
  ADD_USER_FACILITIES_FAILURE: 'ADD_USER_FACILITIES_FAILURE',
  DELETE_USER_FACILITIES_PENDING: 'DELETE_USER_FACILITIES_PENDING',
  DELETE_USER_FACILITIES_SUCCESS: 'DELETE_USER_FACILITIES_SUCCESS',
  DELETE_USER_FACILITIES_FAILURE: 'DELETE_USER_FACILITIES_FAILURE',
  GET_USER_COMMENTS_PENDING: 'GET_USER_COMMENTS_PENDING',
  GET_USER_COMMENTS_SUCCESS: 'GET_USER_COMMENTS_SUCCESS',
  GET_USER_COMMENTS_FAILURE: 'GET_USER_COMMENTS_FAILURE',
  CREATE_USER_COMMENT_PENDING: 'CREATE_USER_COMMENT_PENDING',
  CREATE_USER_COMMENT_SUCCESS: 'CREATE_USER_COMMENT_SUCCESS',
  CREATE_USER_COMMENT_FAILURE: 'CREATE_USER_COMMENT_FAILURE',
};

export default constants;
