import actionBuilder, { show } from 'store/v2/actions';
import actionType, { bulkActionType, apiPath } from './constants';

const pdf = (id, queryParams = {}) => {
  return show(`${actionType}_PDF`, `${apiPath}/${id}/pdf`, queryParams);
};

const actions = { ...actionBuilder({ apiPath, bulkActionType, actionType }), pdf };

export default actions;
