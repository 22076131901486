import { ByMeta, ByTag } from 'components/v2/Filters/MultiSelect';

const ByStatus = ({ fieldName = 'status', updateQuery }) => {
  return ByMeta({
    fieldName,
    displayName: 'Status',
    updateQuery,
    storeKey: 'digitalFormTemplates',
    metaKey: 'status',
  });
};

const filters = [ByTag, ByStatus];

export default filters;
