import { displayDateFormat, defaultMoment as moment } from 'config';

export const formatDate = (value) =>
  value ? moment(value, moment.ISO_8601).format(displayDateFormat) : '';

export const adaptToIso = (obj, dateFields) => {
  if (!obj) return {};
  const updated = { ...obj };

  dateFields.forEach((key) => {
    const value = updated[key];

    if (value) {
      updated[key] = moment(value).toISOString();
    }
  });

  return updated;
};

export const adaptToDisplay = (obj, dateFields) => {
  if (!obj) return {};

  const updated = { ...obj };

  dateFields.forEach((key) => {
    const value = updated[key];

    if (value) {
      updated[key] = formatDate(value);
    }
  });

  return updated;
};
