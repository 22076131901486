const constants = {
  SUBMIT_SIGNATURE_REQUEST_PENDING: 'SUBMIT_SIGNATURE_REQUEST_PENDING',
  SUBMIT_SIGNATURE_REQUEST_SUCCESS: 'SUBMIT_SIGNATURE_REQUEST_SUCCESS',
  SUBMIT_SIGNATURE_REQUEST_FAILURE: 'SUBMIT_SIGNATURE_REQUEST_FAILURE',

  GET_SIGNATURE_REQUEST_PENDING: 'GET_SIGNATURE_REQUEST_PENDING',
  GET_SIGNATURE_REQUEST_SUCCESS: 'GET_SIGNATURE_REQUEST_SUCCESS',
  GET_SIGNATURE_REQUEST_FAILURE: 'GET_SIGNATURE_REQUEST_FAILURE',
  GET_SIGNATURE_REQUEST_FILES_PENDING: 'GET_SIGNATURE_REQUEST_FILES_PENDING',
  GET_SIGNATURE_REQUEST_FILES_SUCCESS: 'GET_SIGNATURE_REQUEST_FILES_SUCCESS',
  GET_SIGNATURE_REQUEST_FILES_FAILURE: 'GET_SIGNATURE_REQUEST_FILES_FAILURE',
  GET_SIGNATURE_REQUESTS_PENDING: 'GET_SIGNATURE_REQUESTS_PENDING',
  GET_SIGNATURE_REQUESTS_SUCCESS: 'GET_SIGNATURE_REQUESTS_SUCCESS',
  GET_SIGNATURE_REQUESTS_FAILURE: 'GET_SIGNATURE_REQUESTS_FAILURE',
  ALL_SIGNATURE_REQUESTS_PENDING: 'ALL_SIGNATURE_REQUESTS_PENDING',
  ALL_SIGNATURE_REQUESTS_SUCCESS: 'ALL_SIGNATURE_REQUESTS_SUCCESS',
  ALL_SIGNATURE_REQUESTS_FAILURE: 'ALL_SIGNATURE_REQUESTS_FAILURE',
  GET_SIGNATURE_REQUEST_EMBED_PENDING: 'GET_SIGNATURE_REQUEST_EMBED_PENDING',
  GET_SIGNATURE_REQUEST_EMBED_SUCCESS: 'GET_SIGNATURE_REQUEST_EMBED_SUCCESS',
  GET_SIGNATURE_REQUEST_EMBED_FAILURE: 'GET_SIGNATURE_REQUEST_EMBED_FAILURE',
  CREATE_SIGNATURE_REQUEST_PENDING: 'CREATE_SIGNATURE_REQUEST_PENDING',
  CREATE_SIGNATURE_REQUEST_SUCCESS: 'CREATE_SIGNATURE_REQUEST_SUCCESS',
  CREATE_SIGNATURE_REQUEST_FAILURE: 'CREATE_SIGNATURE_REQUEST_FAILURE',
  DELETE_SIGNATURE_REQUEST_PENDING: 'DELETE_SIGNATURE_REQUEST_PENDING',
  DELETE_SIGNATURE_REQUEST_SUCCESS: 'DELETE_SIGNATURE_REQUEST_SUCCESS',
  DELETE_SIGNATURE_REQUEST_FAILURE: 'DELETE_SIGNATURE_REQUEST_FAILURE',
  REASSIGN_SIGNATURE_REQUEST_PENDING: 'REASSIGN_SIGNATURE_REQUEST_PENDING',
  REASSIGN_SIGNATURE_REQUEST_SUCCESS: 'REASSIGN_SIGNATURE_REQUEST_SUCCESS',
  REASSIGN_SIGNATURE_REQUEST_FAILURE: 'REASSIGN_SIGNATURE_REQUEST_FAILURE',
  MARK_SIGNATURE_REQUEST_COMPLETE_SUCCESS: 'MARK_SIGNATURE_REQUEST_COMPLETE_SUCCESS',
};

export default constants;
