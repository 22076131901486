import { pathBuilder } from 'config/paths/index';

const userBasePath = (id) => {
  return `/users/${id}/tasks`;
};

export const userPathsBuilder = (id) => {
  return pathBuilder(userBasePath(id));
};

const adminRoot = '/admin/tasks';
export const adminPaths = pathBuilder(adminRoot);

export const root = '/tasks';
export default pathBuilder(root);
