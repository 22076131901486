import { displayDateFormat, displayDateTimeFormat, defaultMoment as moment } from 'config';
import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import {
  cellAdapterFactory,
  cellLinkAdapterFactory,
  arrayCountCellAdapterFactory,
} from 'adapters/v2/factories';
import { adaptToJsonApiRequest, adaptJsonApiToDetail } from 'adapters/v1/json_api';
import { adaptSingleSelectFieldsToRequest } from 'adapters/v1';
import {
  adaptPortsToTransportations,
  adaptToCountries,
  adaptToTravelRequest,
  adaptTransportationToPort,
} from '../transportations/formData';
import { getUserDetails } from 'lib/v1/users';
import { adaptToOriginalRowIds } from 'adapters/v1/table/rows';

// Utilities
import { capitalize } from 'lib/v1/strings';

const dateFields = [
  'updated_at',
  'created_at',
  'submitted_at',
  'diss_submitted_at',
  'trip_departure_date',
  'trip_return_date',
  'departure_date',
  'arrival_date',
];
const singleSelectFields = ['assignee_id', 'reviewer_id'];

export const jsonApiType = 'travel';

export const adaptToDigitalFormRequest = (formData) => {
  const { attached_digital_form_id } = formData;
  const adapted = {
    ...adaptToIso(formData, dateFields),
  };

  const {
    assignee_id,
    due_date,
    send_notification_email,
    signer_roles = [],
    template_id,
    travel_id,
  } = adapted;

  const userId = assignee_id ? parseInt(assignee_id) : null;

  const signers = signer_roles
    .map((obj) => {
      if (!assignee_id) return null;
      return { role: signer_roles[0]?.name, id: userId };
    })
    .filter((obj) => obj);

  const attributes = {
    attached_digital_form_id,
    due_date,
    send_notification_email,
    signers,
    template_id,
    travel_id,
    user_id: userId,
  };

  return adaptToJsonApiRequest({
    attributes,
    type: 'digital_form',
  });
};

export const mapIncomingInputsToForeignContactsDetails = (formData) => {
  const foreignContacts = [];
  let currentForeignContact = 0;
  while (formData[`foreign_contact_${currentForeignContact}_name`]) {
    foreignContacts.push({
      name: formData[`foreign_contact_${currentForeignContact}_name`],
      relationship: formData[`foreign_contact_${currentForeignContact}_relationship`],
      citizenship: formData[`foreign_contact_${currentForeignContact}_citizenship`],
    });
    currentForeignContact++;
  }
  return foreignContacts;
};

export const adaptToRequest = (formData) => {
  const relationships = {};

  const adapted = {
    ...adaptToIso(formData, dateFields),
    ...adaptSingleSelectFieldsToRequest({ formData, singleSelectFields }),
  };

  const foreignContacts = mapIncomingInputsToForeignContactsDetails(formData);
  if (foreignContacts.length) {
    adapted.foreign_contacts = JSON.stringify(foreignContacts);
  }
  // if no foreign contacts, but value in contacts field, set foreign contacts to null
  // otherwise, is remains the existing value
  else if (formData.contacts !== undefined) {
    adapted.foreign_contacts = null;
  }

  if (formData.lodgings) {
    relationships.lodgings = { data: formData.lodgings };
  }

  if (formData.transportations) {
    const transportations = formData.transportations;

    // Include ports as full-fledged boat transportations
    const nonBoats = transportations?.filter(({ type }) => !type?.includes('Boat'));
    let boats = transportations?.filter(({ type }) => type?.includes('Boat'));
    const ports = boats.map((boat) => adaptPortsToTransportations(boat));
    boats = boats.map((t) => ({ ...t, ports: undefined })); // Don't parse ports twice
    const results = [nonBoats, boats, ...ports].flat().filter((x) => !!x);

    const data = results.map((t) => adaptToTravelRequest(t));
    relationships.transportations = { data };
  }

  const attributes = { ...adapted };

  if (formData.reviewer) {
    // If user clears the assigned reviewer dropdown, send null
    attributes.reviewer_id = formData.reviewer?.label || null;
  }

  attributes.assignee_id = formData.assignee?.label;
  attributes.status = formData?.status?.toLowerCase();
  attributes.travel_type = formData.travel_type?.label;

  delete attributes.assignee;
  delete attributes.destinations;
  delete attributes.reviewer;
  delete attributes.lodgings;
  delete attributes.transportations;

  return adaptToJsonApiRequest({
    attributes,
    relationships,
    type: jsonApiType,
  });
};

// I may move this back into the main adaptToRequest, but keeping it seperate for now
export const adaptToPostBriefingRequest = ({ answers }) => {
  const attributes = {
    status: 'post-travel',
  };

  const relationships = {
    answers: {
      data: answers,
    },
  };

  return adaptToJsonApiRequest({
    attributes,
    relationships,
    type: jsonApiType,
  });
};

export const adaptToPostBriefingDetail = ({ answers, ...rest }) => {
  const adaptedAnswers = answers?.map((answer) => {
    const { has_comment, ...rest } = answer;
    return {
      ...rest,
      has_comment: `${has_comment}`,
    };
  });

  const adapted = {
    ...rest,
    answers: adaptedAnswers,
  };
  return adapted;
};

const foreignContactProps = ['name', 'relationship', 'citizenship'];
export const mapForeignContactsToInputs = (foreignContacts) => {
  const contactFields = {};
  foreignContacts.forEach((contact, i) => {
    foreignContactProps.forEach((prop) => {
      contactFields[`foreign_contact_${i}_${prop}`] = contact[prop];
    });
  });

  return contactFields;
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  let adapted = adaptToDisplay(adaptJsonApiToDetail(resource), dateFields);

  adapted.display_name = '';

  try {
    adapted.foreignContacts = JSON.parse(adapted.foreign_contacts);
    const foreignContactFields = mapForeignContactsToInputs(adapted.foreignContacts);
    adapted = {
      ...adapted,
      ...foreignContactFields,
    };

    // if contacts text field is empty, foreign contacts will be hidden on page load
    if (adapted.foreignContacts.length && !adapted.contacts) adapted.contacts = ' ';
  } catch (e) {
    adapted.foreignContacts = [];
  }

  const diss_submitted_at = adapted.diss_submitted_at;
  adapted.diss_submitted_text = diss_submitted_at ? `DISS - ${diss_submitted_at}` : '';

  // Assignee should always be present, or fallback
  adapted.assignee_name = adapted?.assignee?.name ?? `User Deleted (Id ${adapted.assignee_id})`;
  adapted.display_name += adapted.assignee_name;

  if (adapted.assigner_id) {
    adapted.assigner = getUserDetails(adapted.assigner_id) || {};
  }

  if (adapted.destinations.length) {
    adapted.destinations = adapted.destinations.join(', ');
    adapted.display_name += ` - ${adapted.destinations}`;
  }

  // if (adapted.trip_departure_date && adapted.trip_return_date) {
  //   adapted.trip_departure_date = new Date(adapted.trip_departure_date);
  //   adapted.trip_departure_date = new Date(adapted.trip_departure_date);
  // }

  if (adapted.reviewer) {
    adapted.reviewer_id = adapted.reviewer.id;
    adapted.reviewer_name = adapted.reviewer.name;
  }

  // TODO: Make this a constant
  if (adapted.status === 'post-travel') {
    adapted.status = 'Post-Travel';
  } else if (adapted.status === 'draft-post-travel') {
    adapted.status = 'Draft Post-Travel';
  } else if (adapted.status === 'pre-travel') {
    adapted.status = 'Pre-Travel';
  } else if (adapted.status === 'draft-pre-travel') {
    adapted.status = 'Draft Pre-Travel';
  }

  if (adapted.transportations) {
    const transportations = adapted.transportations?.map((t) => adaptToCountries(t));
    const boats = transportations?.filter(({ type }) => type?.includes('Boat'));

    const groupedBoats = boats.reduce((groups, boat) => {
      const name = boat.transit_company_name + boat.transit_number;
      groups[name] ??= [];
      groups[name].push(boat);
      return groups;
    }, {});

    // treat first chronological boat transportation as "parent" item
    const boatsWithPorts = Object.values(groupedBoats).map((group) => {
      const sorted = group.sort((a, b) => new Date(a.departure_date) - new Date(b.departure_date));
      const boat = sorted.shift(); // first transportation
      boat.ports = sorted.map((g) => adaptTransportationToPort(g)); // remaining transportations
      return boat;
    });

    adapted.transportations = [
      ...transportations?.filter(({ type }) => !type?.includes('Boat')),
      ...boatsWithPorts,
    ];
  }

  adapted.updatedDateTime = moment(resource.attributes.updated_at, moment.ISO_8601)
    .local()
    .format(displayDateTimeFormat);

  return adapted;
};

export const adaptToForm = (resource) => {
  if (!resource) return {};
  const adapted = { ...resource };

  if (adapted.assignee) {
    adapted.assignee = { title: adapted.assignee_name, label: adapted.assignee_id };
  }

  if (adapted.reviewer) {
    adapted.reviewer = { title: adapted.reviewer_name, label: adapted.reviewer_id };
  }

  if (adapted.travel_type) {
    adapted.travel_type = { title: capitalize(adapted.travel_type), label: adapted.travel_type };
  }

  // Commenting this out as we don't want status to be editable for now
  // if (adapted.status) {
  //   adapted.status = { title: adapted.status, label: adapted.status };
  // }

  return adapted;
};

export const adaptToBulkRemindRequest = ({ selected = [] } = {}) => {
  const ids = adaptToOriginalRowIds(selected);

  const data = {
    attributes: { send_reminder: true },
    ids,
    type: 'travel',
  };

  return { data };
};

export const adaptToReport = (resource) => {
  if (!resource) return {};
  const adapted = { ...resource };

  const getTransportationsByType = (transport_type) =>
    adapted.transportations.filter(({ type }) => type === transport_type);

  adapted.flights = getTransportationsByType('Travel::Transportation::Flight');
  adapted.cars = getTransportationsByType('Travel::Transportation::Car');
  adapted.boats = getTransportationsByType('Travel::Transportation::Boat');
  adapted.trains = getTransportationsByType('Travel::Transportation::Rail');

  adapted.allPorts = adapted.boats.map(
    ({
      id,
      arrival_city,
      arrival_country_name,
      arrival_port,
      arrival_date,
      departure_city,
      departure_country_name,
      departure_date,
      departure_port,
      transit_number,
      transit_company_name,
      ports,
    }) => [
      {
        id,
        // port_country, // {label: 'BS', title: 'Bahamas'}
        port_name: departure_port,
        port_city: departure_city,
        port_country_name: departure_country_name,
        departure_date: moment(departure_date).format(displayDateFormat),
        transit_company_name,
        transit_number,
      },
      ...ports.map((p) => ({
        ...p,
        id,
        transit_company_name,
        transit_number,
        port_country_name: p.port_country?.title,
      })),
      {
        id,
        port_name: arrival_port,
        port_city: arrival_city,
        port_country_name: arrival_country_name,
        arrival_date: moment(arrival_date).format(displayDateFormat),
        transit_company_name,
        transit_number,
      },
    ]
  );

  adapted.lodgings = adapted.lodgings.map((l) => ({
    ...l,
    arrival_date: moment(l.arrival_date).format(displayDateFormat),
    departure_date: moment(l.departure_date, moment.ISO_8601).format(displayDateFormat),
  }));

  return adapted;
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
export const arrayCountCellAdapter = arrayCountCellAdapterFactory(adaptToDetail);
