import actionBuilder, { create } from 'store/v2/actions';
import actionType, { bulkActionType, apiPath } from './constants';

const bulkRemind = (payload, silent = false) => {
  const message = 'Any tasks with assigned users have been sent a reminder email.';
  return create(actionType, `${apiPath}/bulk/remind`, { ...payload, silent, message });
};
const actions = { ...actionBuilder({ apiPath, bulkActionType, actionType }), bulkRemind };

export default actions;
