const constants = {
  GET_LABELS_PENDING: 'GET_LABELS_PENDING',
  GET_LABELS_SUCCESS: 'GET_LABELS_SUCCESS',
  GET_LABELS_FAILURE: 'GET_LABELS_FAILURE',
  CREATE_LABEL_PENDING: 'CREATE_LABEL_PENDING',
  CREATE_LABEL_SUCCESS: 'CREATE_LABEL_SUCCESS',
  CREATE_LABEL_FAILURE: 'CREATE_LABEL_FAILURE',
};

export default constants;
