import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths from 'config/paths/requestTemplates';

// Components
const Index = lazy(() => import('./index'));
const Detail = lazy(() => import('./Detail'));

export const RequestTemplatesReduxManager = ({ children }) => (
  <ReduxManager data={['documentsDropdown', 'peopleDropdown', 'formTemplateActions']}>
    {children}
  </ReduxManager>
);

export const Routes = ({ paths, permissions }) => {
  return (
    <RequestTemplatesReduxManager>
      <Suspense fallback={<></>}>
        <Switch>
          <Route permission={permissions.requestTemplates.list} exact path={paths.root}>
            <Index paths={paths} />
          </Route>
          <Route
            permission={permissions.requestTemplates.view}
            path={paths.resource.root}
            render={(props) => <Detail paths={paths} {...props} />}
          />
        </Switch>
      </Suspense>
    </RequestTemplatesReduxManager>
  );
};

export default connect(mapPermissionsToProps, null)((props) => <Routes paths={paths} {...props} />);
