import React from 'react';
import { connect } from 'react-redux';

// mui
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { uniqueId } from 'lib/v1';

// formElements
import Button from 'components/v2/FormElements/Button';
import documentActions from 'store/v2/documents/actions';

import { add_message } from 'store/v1/app/actions';
import store from 'store';

const SLOW_UPLOAD_WARNING_SIZE = 10;

export const fileSizeError = (files = []) => {
  const {
    profile: { company },
  } = store.getState();

  const fileSizes = files.map((f = {}) => f.size || 0);
  const maxFileSize = Math.max(fileSizes);

  // I don't know how likely this is but it was in the v1 legacy so I kept it for now
  if (fileSizes.includes(0)) {
    store.dispatch(add_message('file_error', ['File size cannot be zero.']));
    return true;
  }

  if (maxFileSize > company.file_upload_size_limit * 1000000) {
    store.dispatch(
      add_message('file_error', [
        `File size cannot be larger than ${company.file_upload_size_limit}mb.`,
      ])
    );
    return true;
  }

  if (maxFileSize > SLOW_UPLOAD_WARNING_SIZE * 1000000) {
    store.dispatch(
      add_message('file_warning', [
        `Because of large file size, site may seem unresponsive until upload completes`,
      ])
    );
  }
  return false;
};

export const AddDocument = ({
  create,
  description = 'Workflow Request Attachment',
  resourceScope,
  documentable_type,
  documentable_id,
  callback,
  loading,
  label,
  onChange,
  onUpload,
}) => {
  const [files, setFiles] = React.useState([]);

  React.useEffect(() => {
    const uploadFile = async () => {
      if (!create) return;

      for (var i = 0; i < files.length; i++) {
        const file = files.item(i);

        if (fileSizeError([file])) return;

        const reader = new FileReader();
        reader.onload = async (event) => {
          // a DataURL includes the base64 encoded content seperated by a comma
          const content = event.target.result.split(',')[1];
          const filename = file.name;
          const fileProps = {
            content_type: file.type,
            content: content,
            filename,
            size: (file.size / 1000000).toFixed(2) + 'MB',
          };

          if (onUpload) {
            return onUpload(fileProps);
          }

          const resp = await create(
            {
              name: filename,
              resource_scope: resourceScope,
              documentable_type,
              documentable_id,
              file: fileProps,
              description,
            },
            false
          );

          if (!resp || !resp.id) return;
          callback && callback(resp);
        };
        reader.readAsDataURL(file);
      }
    };

    if (files.length > 0) {
      uploadFile();
      setFiles([]);
    }
  }, [
    callback,
    create,
    description,
    documentable_id,
    documentable_type,
    files,
    onUpload,
    resourceScope,
  ]);

  const handleOnChange = (event) => {
    if (onChange) {
      onChange();
    }

    setFiles(event.target.files);

    if (onChange) {
      onChange();
    }
  };

  const id = `upload-${uniqueId()}`;
  return (
    <label htmlFor={id}>
      <Input
        onChange={handleOnChange}
        disabled={loading}
        style={{ display: 'none' }}
        id={id}
        name={id}
        type="file"
      />
      <Box display="flex" flexDirection="flex-start">
        <Button
          disabled={loading}
          component="span"
          color="primary"
          variant="text"
          disableRipple
          startIcon={loading ? <CircularProgress size={'1em'} /> : <AddCircleIcon />}
        >
          {label || 'Upload File'}
        </Button>
      </Box>
    </label>
  );
};

export default connect(({ documentsV2: { loading } }) => ({ loading }), {
  create: documentActions.create,
})(AddDocument);
