import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import DashboardLayout from 'layouts/v1/DashboardLayout';
import Documents from 'components/v2/Documents/index';
import AppContent from 'layouts/v2/AppContent';

const AppDocuments = (props) => (
  <AppContent>
    <Documents {...props} />
  </AppContent>
);

function EmployeeRoutes() {
  return (
    <DashboardLayout>
      <Switch>
        <Route exact path="/employee">
          <Redirect to="/inbox" />
        </Route>
        <Route exact path="/employee/inbox" render={() => <Redirect to="/inbox" />} />
        <Route exact path="/employee/documents" component={AppDocuments} />
      </Switch>
    </DashboardLayout>
  );
}

export default EmployeeRoutes;
