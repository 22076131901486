import actionBuilder from 'store/v2/actions';
import { update } from 'store/v2/actions';
import actionType, { bulkActionType, apiPath } from './constants';

const unarchive = (id, silent = false) => {
  return update(actionType, `${apiPath}/${id}/unarchive`, { id, silent });
};

const actions = { ...actionBuilder({ apiPath, bulkActionType, actionType }), unarchive };

export default actions;
