import React from 'react';

const Receipt = ({ height = '12px', width = '12px', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" {...rest}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 15V17H18V15H6ZM6 11V13H18V11H6ZM6 7V9H18V7H6ZM4.5 20.5L3 22V2L4.5 3.5L6 2L7.5 3.5L9 2L10.5 3.5L12 2L13.5 3.5L15 2L16.5 3.5L18 2L19.5 3.5L21 2V22L19.5 20.5L18 22L16.5 20.5L15 22L13.5 20.5L12 22L10.5 20.5L9 22L7.5 20.5L6 22L4.5 20.5Z"
      fill="#205077"
    />
  </svg>
);

export default Receipt;
