import './styles.css';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { styled } from '@mui/material/styles';

const StyledInput = styled('input')({
  backgroundColor: '#ffffff',
  borderRadius: '2px',
  border: 'solid 1px #d4d2d2',
  color: '#4a4a4a',
  fontSize: '16px',
  padding: '0.3rem 0.625rem',
});

const noop = () => {};

const Input = ({
  attributes = {},
  checked = false,
  className,
  disabled,
  format,
  isInvalid = false,
  modifier,
  name,
  onChange = noop,
  onBlur = noop,
  onFocus = noop,
  placeholder,
  type = 'text',
  value = '',
}) => {
  const [isFocused, setIsFocused] = React.useState(false);

  function handleOnBlur(e) {
    setIsFocused(false);
    onBlur(e);
  }

  function handleOnFocus(e) {
    setIsFocused(true);
    onFocus(e);
  }

  function validate(e) {
    if (format === 'ssn') {
      const value = e.target.value
        .split('')
        .filter((s) => s !== ' ')
        .join('');
      e.target.value = value;
      if (Number(value) || value.length === 0 || Number(value) === 0) {
        return onChange(e);
      }
    } else {
      onChange(e);
    }
  }

  function formatValue() {
    if (format === 'ssn') {
      const str = value || '';
      return `${str.slice(0, 3)} ${str.slice(3, 5)} ${str.slice(5)}`.trim();
    }
    return value;
  }

  return (
    <StyledInput
      {...attributes}
      className={cx('Input', {
        [`Input--${modifier}`]: modifier,
        'is-focused': isFocused || value,
        'is-invalid': isInvalid,
        [className]: className,
      })}
      checked={checked}
      onChange={(e) => validate(e)}
      onFocus={(e) => handleOnFocus(e)}
      onBlur={(e) => handleOnBlur(e)}
      placeholder={placeholder}
      disabled={disabled}
      value={formatValue()}
      type={type}
      name={name}
      id={name}
    />
  );
};

Input.propTypes = {
  isInvalid: PropTypes.bool,
  modifier: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Input;
