import React from 'react';

const CommentsIcon = ({ height = '12px', width = '12px', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 32 32" {...rest}>
    <g strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-272.000000, -1190.000000)">
        <g transform="translate(272.000000, 1190.000000)">
          <path
            d="M13.6170213,4.81157447 C6.09565957,4.81157447 0,10.2794894 0,17.0219574 C0,19.5057021 0.829957447,21.8171915 2.25293617,23.7453617 C2.06638298,25.9710638 1.52646809,29.1097872 0,30.6389787 C0,30.6389787 4.66723404,29.9819574 7.82978723,28.0721702 C9.58774468,28.8142979 11.5479149,29.2289362 13.6170213,29.2289362 C21.138383,29.2289362 27.2340426,23.7637447 27.2340426,17.0219574 C27.2340426,10.2794894 21.138383,4.81157447 13.6170213,4.81157447 Z"
            id="Fill-1"
            fill="#3BA58B"
          />
          <path
            d="M31.5901277,12.3438298 C31.5901277,5.6013617 25.4944681,0.136170213 17.9731064,0.136170213 C12.5453617,0.136170213 7.85634043,2.98757447 5.67012766,7.10944681 C7.9067234,5.6653617 10.6491915,4.81157447 13.6170213,4.81157447 C21.138383,4.81157447 27.2340426,10.2794894 27.2340426,17.0219574 C27.2340426,19.6017021 26.340766,21.9901277 24.8163404,23.963234 C27.867234,25.4365957 31.5901277,25.9608511 31.5901277,25.9608511 C30.0636596,24.434383 29.5264681,21.2956596 29.3405957,19.067234 C30.7608511,17.1390638 31.5901277,14.8302979 31.5901277,12.3438298 Z"
            id="Fill-2"
            fill="#226BAC"
          />
          <path
            d="M8.17021277,17.0219574 C8.17021277,17.9234043 7.43897872,18.6546383 6.53753191,18.6546383 C5.63268085,18.6546383 4.90144681,17.9234043 4.90144681,17.0219574 C4.90144681,16.1177872 5.63268085,15.3858723 6.53753191,15.3858723 C7.43897872,15.3858723 8.17021277,16.1177872 8.17021277,17.0219574 Z"
            id="Fill-3"
            fill="#FFFFFF"
          />
          <path
            d="M15.2497021,17.0219574 C15.2497021,17.9234043 14.5184681,18.6546383 13.6170213,18.6546383 C12.7155745,18.6546383 11.9843404,17.9234043 11.9843404,17.0219574 C11.9843404,16.1177872 12.7155745,15.3858723 13.6170213,15.3858723 C14.5184681,15.3858723 15.2497021,16.1177872 15.2497021,17.0219574 Z"
            id="Fill-4"
            fill="#FFFFFF"
          />
          <path
            d="M22.3325957,17.0219574 C22.3325957,17.9234043 21.6013617,18.6546383 20.6965106,18.6546383 C19.7950638,18.6546383 19.0638298,17.9234043 19.0638298,17.0219574 C19.0638298,16.1177872 19.7950638,15.3858723 20.6965106,15.3858723 C21.6013617,15.3858723 22.3325957,16.1177872 22.3325957,17.0219574 Z"
            id="Fill-5"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default CommentsIcon;
