import { isAdmin } from 'permissions/v1/roles';
import { canList, canView, canCreate, canUpdate, canDestroy } from 'permissions/v1/index';

// TEMPLATE TODO: Use proper rails model name for Custom Roles
const permission = 'TravelSettings';

// TEMPLATE TODO: Update permissions as necessary.
const list = (profile) => isAdmin(profile) || canList(permission, profile);
const view = (profile) => isAdmin(profile) || canView(permission, profile);
const create = (profile) => isAdmin(profile) || canCreate(permission, profile);
const update = (profile) => isAdmin(profile) || canUpdate(permission, profile);
const destroy = (profile) => isAdmin(profile) || canDestroy(permission, profile);

const permissions = (profile) => {
  return {
    list: list(profile),
    view: view(profile),
    create: create(profile),
    update: update(profile),
    destroy: destroy(profile),
  };
};

export default permissions;
