import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Routes
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route from 'components/v2/PageElements/Routes';

// Config
import paths from 'config/paths/destructionEvents';

// Components
const Detail = lazy(() => import('./Detail'));

/*
 * Important thing to note about Resourceful layouts.
 *
 * If you want sublayouts such as those defined in "Detail" to successfully render
 * you cannot use exact paths, you are partial matching on the parent to ensure
 * the top level layout always gets loaded so that any child pages also get loaded.
 */

export const Routes = ({ paths, permissions }) => {
  return (
    <ReduxManager
      data={[
        'facilitiesDropdown',
        'assetControlsDropdown',
        'peopleDropdown',
        'destructionEventsDropdown',
      ]}
    >
      <Suspense fallback={<></>}>
        <Switch>
          <Route
            exact
            path={paths.resource.detail(':id')}
            permission={permissions.admin.list}
            component={Detail}
          />
        </Switch>
      </Suspense>
    </ReduxManager>
  );
};

export default connect(mapPermissionsToProps, null)((props) => <Routes paths={paths} {...props} />);
