// Mui
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const LinkMenuItem = ({ appHref, enabled, icon, infoHref, svg, text, ...rest }) => {
  return (
    <MenuItem
      component="a"
      href={enabled ? appHref : infoHref}
      target="_blank"
      rel="noreferrer"
      {...rest}
    >
      <ListItemIcon>
        <img src={icon} alt={`${text} logo`} style={{ maxHeight: 20, maxWidth: 20, margin: 0.5 }} />
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ variant: 'button' }}>{text}</ListItemText>
    </MenuItem>
  );
};

export default LinkMenuItem;
