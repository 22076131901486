/*
 * This is my answer to the initial state problem.
 *
 * As I looked at dropdowns I realized that having dropdowns
 * manage their own initial state was going to be an issue.
 * I.e. when you have multiple filters that use the same redux data to populate their dropdowns.
 *
 * User Assignees vs. User Reviewers
 *
 * We don't want to execute an http request for EVERY component we render
 * so instead I've thrown this together as a generic layout for putting
 * in data we may need for any given feature.
 *
 * Just pass in a data array with the key name of the values you need
 * to populate the store as defined in mapDispatchToProps
 */
import React from 'react';
import { connect } from 'react-redux';

// layouts
import StateManager from 'layouts/v2/StateManager';
import { withIdentityManager } from './IdentityManager';

// actions
import { getInbox } from 'store/v2/inbox/actions';
import { getFeed as getInboxActivites } from 'store/v1/feed/actions';
import { getUsersSlim } from 'store/v1/users/actions';
import { getTypes } from 'store/v1/types/actions';
import { getCountries } from 'store/v1/lookups/actions';
import accessesActions from 'store/v2/accesses/actions';
import assetControlsActions from 'store/v2/assetControls/actions';
import activityActions from 'store/v2/activity/actions';
import auditsActions from 'store/v2/audits/actions';
import badgeControlsActions from 'store/v2/badgeControls/actions';
import cardActions from 'store/v2/cards/actions';
import cardTemplateActions from 'store/v2/cardTemplates/actions';
import contactsActions from 'store/v2/contacts/actions';
import containerControlsActions from 'store/v2/containerControls/actions';
import contractsActions from 'store/v2/contracts/actions';
import controlsActions from 'store/v2/controls/actions';
import customFieldsActions from 'store/v2/customFields/actions';
import customRolesActions from 'store/v2/customRoles/actions';
import destructionEventsActions from 'store/v2/destructionEvents/actions';
import documentsActions from 'store/v2/documents/actions';
import digitalFormRequestsActions from 'store/v2/digitalFormRequests/actions';
import digitalFormTemplatesActions from 'store/v2/digitalFormTemplates/actions';
import eligibilitiesActions from 'store/v2/eligibilities/actions';
import facilitiesActions from 'store/v2/facilities/actions';
import formTemplateActions from 'store/v2/formTemplates/actions';
import incidentsActions from 'store/v2/incidents/actions';
import incidentTemplatesActions from 'store/v2/incidentTemplates/actions';
import integrationsActions from 'store/v2/serviceIntegrations/actions';
import libraryActions from 'store/v2/library/actions';
import peopleActions from 'store/v2/people/actions';
import polygraphsActions from 'store/v2/polygraphs/actions';
import rolesActions from 'store/v2/roles/actions';
import requirementsActions from 'store/v2/requirements/actions';
import tagsActions from 'store/v2/tags/actions';
import tagNamesActions from 'store/v2/tagNames/actions';
import trainingActions from 'store/v2/training/actions';
import travelsActions from 'store/v2/travels/actions';
import userEligibilitiesActions from 'store/v2/userEligibilities/actions';
import userPolygraphsActions from 'store/v2/userPolygraphs/actions';
import visitRequestsActions from 'store/v2/visitRequests/actions';

import reduxManagerActions from 'store/v2/reduxManager/actions';

const slowRollTimeout = 60 * 1000;
const normalTimeout = 10 * 1000;

const ReduxManager = ({
  data = [],
  userId,
  children,
  calledObjects,
  reduxManagerTracker,
  query = {},
  ...rest
}) => {
  const isResourceful = { userActionRequests: true };
  const oneTime = { countries: true };
  const slowRoll = {
    cardsSchema: true,
    cardTemplatesDropdown: true,
    contactsDropdown: true,
    contractsDropdown: true,
    customRolesDropdown: true,
    digitalFormTemplatesDropdown: true,
    eligibilitiesDropdown: true,
    facilitiesDropdown: true,
    formTemplateActions: true,
    // inboxActivites: true,
    // inboxFeed: true,
    library: true,
    polygraphsDropdown: true,
    tagsV2Dropdown: true,
    tagNamesDropdown: true,
    trainingsDropdown: true,
    typesDropdown: true,
  };

  function shouldFetch(action) {
    if (!calledObjects[action]) {
      // console.log('!   ReduxManager: first time calling: ', action); // eslint-disable-line
      reduxManagerTracker({ callType: action, callTime: Date.now() });
      return true;
    } else if (oneTime[action]) {
      // console.log('-   ReduxManager: one time skipped: ', action); // eslint-disable-line
      return false;
    } else if (slowRoll[action] && Date.now() - calledObjects[action] < slowRollTimeout) {
      // console.log('*   ReduxManager: slow roll skipped: ', action); // eslint-disable-line
      return false;
    } else if (Date.now() - calledObjects[action] < normalTimeout) {
      // console.log('-   ReduxManager: debounced: ', action); // eslint-disable-line
      reduxManagerTracker({ callType: action, callTime: Date.now() - calledObjects[action] });
      return false;
    }

    reduxManagerTracker({ callType: action, callTime: Date.now() });
    return true;
  }

  const fetchState = () => {
    const promises = data
      .filter((action) => rest[action])
      .filter(shouldFetch)
      .map((action) => {
        if (isResourceful[action]) return rest[action](userId);

        if (query[action]) {
          return rest[action]({ ...query[action] });
        }

        return rest[action]();
      });

    return Promise.all(promises);
  };

  return (
    <StateManager fetchState={fetchState} {...rest}>
      {children}
    </StateManager>
  );
};

const mapDispatchToProps = {
  accessesDropdown: accessesActions.dropdown,
  assetControlsDropdown: assetControlsActions.dropdown,
  auditsDropdown: auditsActions.dropdown,
  badgeControlsDropdown: badgeControlsActions.dropdown,
  cardsSchema: cardActions.listSchema,
  cardTemplates: cardTemplateActions.list,
  cardTemplatesDropdown: cardTemplateActions.dropdown,
  cardTemplatesSchema: cardTemplateActions.listSchema,
  contactsDropdown: contactsActions.dropdown,
  containerControlsDropdown: containerControlsActions.dropdown,
  contractsDropdown: contractsActions.dropdown,
  controlsDropdown: controlsActions.dropdown,
  countries: getCountries,
  customFieldsDropdown: customFieldsActions.dropdown,
  customFieldsSchema: customFieldsActions.listSchema,
  customRolesDropdown: customRolesActions.dropdown,
  destructionEventsDropdown: destructionEventsActions.dropdown,
  digitalFormTemplatesDropdown: digitalFormTemplatesActions.dropdown,
  digitalFormRequestsDropdown: digitalFormRequestsActions.dropdown,
  documentsDropdown: documentsActions.dropdown,
  drawerActivities: activityActions.getDrawerActivities,
  eligibilitiesDropdown: eligibilitiesActions.dropdown,
  facilitiesDropdown: facilitiesActions.dropdown,
  formTemplateActions: formTemplateActions.dropdown,
  inboxActivites: getInboxActivites,
  inboxFeed: getInbox,
  incidentsDropdown: incidentsActions.dropdown,
  incidentTemplatesDropdown: incidentTemplatesActions.dropdown,
  integrationsDropdown: integrationsActions.dropdown,
  library: libraryActions.list,
  notifications: getInbox,
  peopleDropdown: peopleActions.dropdown,
  polygraphsDropdown: polygraphsActions.dropdown,
  requirementsDropdown: requirementsActions.dropdown,
  rolesDropdown: rolesActions.dropdown,
  tagsV2Dropdown: tagsActions.dropdown,
  tagNamesDropdown: tagNamesActions.dropdown,
  trainingsDropdown: trainingActions.dropdown,
  travelsDropdown: travelsActions.dropdown,
  typesDropdown: getTypes,
  userEligibilitiesDropdown: userEligibilitiesActions.dropdown,
  userPolygraphsDropdown: userPolygraphsActions.dropdown,
  users: getUsersSlim,
  usersDropdown: getUsersSlim,
  visitRequestsDropdown: visitRequestsActions.dropdown,
  reduxManagerTracker: reduxManagerActions.updateCallsTracker,
};

// There are cases where we need to persist state, for almost all paginated (list) requests this is probably true
// I have no idea what this is doing or if it's still needed
const mapStateToProps = ({ cards: { query }, reduxManager }) => ({
  query: { cards: query },
  calledObjects: reduxManager.calledObjects,
});

export default connect(mapStateToProps, mapDispatchToProps)(withIdentityManager(ReduxManager));
