import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { withIdentityManager } from 'components/v2/PageElements/IdentityManager';

// Routes
import Route, { Switch } from 'components/v2/PageElements/Routes';

// Config

import paths from 'config/paths/documents';

// Components
const Index = lazy(() => import('./index'));
const ResourceLayout = lazy(() => import('./ResourceLayout'));

export const Routes = ({ paths, permissions, isSelf, userId }) => {
  return (
    <Suspense fallback={<></>}>
      <Switch>
        <Route exact path={paths.root} permission={permissions.documents.list}>
          <Index {...{ paths }} />
        </Route>
        <Route path={paths.resource.root} permission={permissions.documents.list}>
          <ResourceLayout {...{ paths }} />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default withIdentityManager(
  connect(mapPermissionsToProps, null)((props) => <Routes paths={paths} {...props} />)
);
