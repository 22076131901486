import reducer, { initialState } from 'store/v2/index';
import { displayDateFormat, defaultMoment as moment } from 'config';

import actionType from './constants';

const start = moment().subtract(1, 'year').startOf('day');
const end = moment().endOf('day');

const startString = start.format(displayDateFormat);
const endString = end.format(displayDateFormat);

const range = [start, end];

const trainingInitialState = {
  ...initialState(),
  query: { ...initialState().query, filters: { active: [true], created_at: range } },
  filters: {
    active: {
      values: [true],
      displayName: 'Archived: No',
    },
    created_at: {
      values: range,
      displayName: `Created Date: ${startString} - ${endString}`,
    },
  },
};

export default reducer(actionType, trainingInitialState);
