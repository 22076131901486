import React from 'react';
import Loader from 'components/v2/Loader';

/*
 * USAGE:
 *
 * This is a generic container wrapper for handling asynchronous state fetching on any given view.
 *
 * Props:
 * fetchState - async function that fetches API data and maps it to redux store, or does anything else you might need to setup required state for a component.
 * children - Whatever child component(s) to render that may rely on the required state.
 *
 */

const StateManager = ({ fetchState, showLoading = true, children }) => {
  const [loading, setLoading] = React.useState(false);

  // FIX HOOK
  React.useEffect(() => {
    if (!fetchState) return;
    async function fetch() {
      if (showLoading) setLoading((state) => true);
      await fetchState();
      if (showLoading) setLoading((state) => false);
    }

    fetch();
  }, [showLoading]); // eslint-disable-line

  return (
    <>
      {loading && <Loader />}
      {!loading && children}
    </>
  );
};

export default StateManager;
