import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import { cellAdapterFactory, cellLinkAdapterFactory } from 'adapters/v2/factories';
import { adaptToJsonApiRequest, adaptJsonApiToDetail } from 'adapters/v1/json_api';
import { adaptSelectToRequest } from 'adapters/v1/index';

const dateFields = ['issued_at', 'updated_at', 'created_at', 'expired_at'];

const singleSelectFields = [
  'user_id',
  'facility_id',
  'contract_id',
  'status',
  'agency_other',
  'badge_type',
];

export const adaptToRequest = (formData) => {
  const adapted = adaptToIso(formData, dateFields);

  singleSelectFields.forEach((key) => {
    if (key === 'user_id' && !isNaN(adapted[key])) return;
    adapted[key] = adaptSelectToRequest(adapted ? adapted[key] : {});
  });

  return adaptToJsonApiRequest({ attributes: adapted, type: 'badge' });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const updated = adaptToDisplay(adaptJsonApiToDetail(resource), dateFields);

  updated.subtitle = `Type: ${updated.badge_type}`;
  updated.user_name = updated.user
    ? updated.user.display_name
    : updated.user_other
    ? updated.user_other
    : '';
  updated.facility_name = updated.facility ? updated.facility.display_name : '';
  updated.contract_name = updated.contract ? updated.contract.contract_name : '';

  updated.issued_subtitle = `Issued: ${updated.issued_at}`;
  updated.issuedDate = updated.issued_at && new Date(updated.issued_at);

  return updated;
};

export const adaptToForm = (resource) => {
  if (!resource) return {};

  const { badge_type, agency_other, user_other, facility, user, contract, ...rest } = resource;

  // These are all artifacts that are getting added because adaptToDetail is being called before adaptToForm..
  [
    'comments',
    'documents',
    'subtitle',
    'user_name',
    'facility_name',
    'contract_name',
    'issued_subtitle',
  ].forEach((val) => delete rest[val]);

  const out = { ...rest };

  if (agency_other) out.agency_other = { label: agency_other, title: agency_other };
  if (badge_type) out.badge_type = { label: badge_type, title: badge_type };

  // Status is protected in JS
  if (rest.status) out.status = { label: rest.status, title: rest.status };

  if (!out.user_id && user_other) {
    out.user_id = { title: user_other, label: 0 };
  }

  if (user) {
    out.user_id = { title: user.name, label: parseInt(user.id) };
  }

  if (facility) {
    out.facility_id = { title: facility.display_name, label: parseInt(facility.id) };
  }

  if (contract) {
    out.contract_id = { title: contract.contract_name, label: parseInt(contract.id) };
  }

  return out;
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
