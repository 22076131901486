import { types as contract_types } from 'adapters/v1/contracts/formData';

export const adaptToMetaAttributes = (meta) => {
  if (!meta || !meta.attributes || !meta.attributes.values) return {};
  return meta.attributes.values;
};

const knownBadValues = { ...contract_types };

const badDataFixer = (str) => {
  const replaceableValue = knownBadValues[str.toLowerCase()];
  if (replaceableValue) return replaceableValue;
  return str;
};

const mapToSelect = (str) => ({ label: str, value: str });

export const adaptToSelectableMetaAttributes = (meta) => {
  const out = {};

  Object.entries(adaptToMetaAttributes(meta)).forEach(([key, value]) => {
    if (key === 'contract_type') {
      out[key] = value.data
        .map(badDataFixer)
        .filter((value, index, arr) => {
          return arr.indexOf(value) === index;
        })
        .map(mapToSelect);
    } else {
      out[key] = value.data.map(mapToSelect);
    }
  });

  return out;
};

export const validateAttributeObjectArray = (attribute) => {
  if (!attribute || !attribute.data || attribute.type !== 'array') return;
  const { items } = attribute;

  if (!items || items.type !== 'object') return;

  const { properties } = items;

  if (!properties.id || !properties.name) return;

  return true;
};

export const adaptAttributeObjectArrayToSelect = (attribute) => {
  if (!validateAttributeObjectArray(attribute)) return [];
  return attribute.data.map((obj) => ({ title: obj.name, label: obj.id }));
};

export const mapArrayToObject = ({ data = [], key = 'id', val = 'name' }) => {
  return Object.assign({}, ...data.map((item) => ({ [item[key]]: item[val] })));
};
