import { createTheme } from '@mui/material/styles';

import baseline, { baselinePalette } from '../baseline';
import { twilight, sisGray6, red, green, blue, blueLight } from 'lib/v2/colors';

export const primary = twilight;
export const secondary = sisGray6;

const backgroundDefault = '#fff';

const defaultTheme = createTheme();
export const theme = createTheme({
  ...baseline,
  palette: {
    ...baselinePalette,
    primary: { main: primary, contrastText: '#fefefe' },
    secondary: { main: secondary, contrastText: '#fefefe' },
    info: { main: blue, dark: primary, contrastText: '#fefefe' },
    alert: { main: red },
    success: { main: green },
    // text: { primary: charcoal, secondary: ash, disabled: grey },
    background: { default: backgroundDefault, paper: backgroundDefault },
    appbar: defaultTheme.palette.augmentColor({
      color: { main: backgroundDefault, border: blueLight },
      name: 'navbar',
    }),
    mode: 'light',
  },
  components: {
    ...baseline.components,
    MuiCssBaseline: {
      styleOverrides: {
        body: {},
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fafafa',
          '&.Mui-disabled': {
            backgroundColor: 'inherit',
            '& input, & textarea': {
              color: `${secondary}`,
              textFillColor: `${secondary}`,
              cursor: 'text',
            },
            '& button, & svg': {
              display: 'none',
            },
          },
        },
      },
    },
  },
});

export default theme;
