import React from 'react';

// core
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContents from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MuiDialogTitle from '@mui/material/DialogTitle';

// formElements
import DialogButtons from './Buttons';

// flexTable
import ContractsDialogTable from 'components/v2/FlexTable/Contracts';

import UsersDialogDataTable from 'components/v2/PageElements/DataTable/UsersDialogDataTable';

//styles
import { makeStyles } from '@mui/styles';

// icons
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { withPermissionsAndPreferences, useWindowDimensions } from 'lib/v1/hooks';

export const defaultFullScreenLimit = 1000;
export const fullScreenLimitMap = {
  sm: 600,
  md: 800,
};

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 !important',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '20px',
  },
  title: {},
  helpLink: {
    color: '#fff',
    fontWeight: 100,
    fontSize: '12px',
    margin: 0,
    padding: 0,
    display: 'block',
    paddingRight: '2.5em',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    fill: '#fff',
    cursor: 'pointer',
    height: '20px',
    marginLeft: 'auto',
    width: '20px',
  },
  buttons: {},
  content: {
    paddingBottom: '0 !important',
  },
}));

const DialogTitle = ({ children, onClose, ...rest }) => {
  const classes = useStyles();
  return (
    <MuiDialogTitle className={classes.header} {...rest}>
      {children}
      {onClose ? (
        <IconButton
          data-dialog-close-button="true"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <HighlightOffIcon className={classes.icon} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export const useDialog = ({ isOpen = false } = {}) => {
  const [open, setOpen] = React.useState(isOpen);

  const onClick = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    if (e && e.stopPropagation) e.stopPropagation();
    setOpen((prev) => !prev);
  };

  const onClose = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    onClick();
  };

  return {
    onClick,
    onClose,
    open,
  };
};

export const FlexTableDialogLayout = ({
  children,
  title,
  open = false,
  onClose,
  onSave,
  batchAction,
  clickText,
  disabled,
  maxWidth,
  ...rest
}) => {
  const classes = useStyles();

  const dialogProps = { title, open, onClose, maxWidth, fullScreenLimit: 1000 };
  const [saving, setSaving] = React.useState(false);

  let selection = {};

  const [locallyDisabled, setLocallyDisabled] = React.useState(true);

  const onSelection = (value) => {
    if (value && value.preventDefault) return;
    selection = value;

    if (selection?.selectedFlatRows?.length > 0) {
      setLocallyDisabled(false);
    } else {
      setLocallyDisabled(true);
    }
  };

  const handleOnClick = async (props) => {
    if (!onSave) return;
    setSaving(true);
    await onSave(selection);
    setSaving(false);
  };

  const tableProps = {
    onSelection,
    batchActions: batchAction ? [batchAction] : undefined,
    ...rest,
  };

  const InnerButtons = (props) => (
    <DialogButtons
      loading={saving}
      onCancel={onClose}
      onClick={handleOnClick}
      disabled={locallyDisabled || disabled}
      {...props}
    />
  );

  return (
    <TswDialog {...dialogProps}>
      <Box display="flex" flexDirection="column">
        <Box>{React.cloneElement(children, { ...tableProps })}</Box>
        <Box className={classes.buttons}>
          {!batchAction && <InnerButtons clickText={clickText} />}
        </Box>
      </Box>
    </TswDialog>
  );
};

/*
 * Implementation notes.
 *
 * Define your generic 2 column FlexTable in components/v2/FlexTable/<Object Name>.js
 *
 * See Users.js and Contracts.js.  The API implementation should be the same
 *
 * You'll need to ensure you follow the same state patterns as evidences in
 *
 * components/v2/Contracts/Users/state.js and components/v2/Contracts/Contracts/state.js
 *
 * If you follow those patterns and then define specialized components as those below you will
 * have a functional, stateful, redux connected assignment dialog for any model type.
 *
 * You will still need to pass in your high level dialog management props such as open, onClose, and onSave
 *
 * See components/v2/Contracts/Contracts/Dialog.js and components/v2/Contracts/ResourceLayout.js
 *
 */

// Specialized Assignment Dialog Types based on Specialized FlexTable types and the Layout defined above
// To Encourage reuse let's define these specialized dialogs here and establish the generic API.
export const AssignContractsDialog = (props) => (
  <FlexTableDialogLayout {...props}>
    <ContractsDialogTable />
  </FlexTableDialogLayout>
);

export const AssignUsersDialog = withPermissionsAndPreferences(({ preferences, ...rest }) => {
  // the way FlexTableDialogLayout currently works, it needs a single child element
  return (
    <FlexTableDialogLayout maxWidth={'xl'} {...rest}>
      <UsersDialogDataTable />
    </FlexTableDialogLayout>
  );
});

/*
export const Assign<Object>Dialog = (props) => (
  <FlexTableDialogLayout {...props}>
    <ObjectDialogTable />
  </FlexTableDialogLayout>
);
*/

const TswDialog = ({
  onClose,
  title = 'Title',
  helpLink,
  children,
  open = false,
  maxWidth = 'md',
  fullScreenLimit,
  ...rest
}) => {
  const classes = useStyles();

  const { width: screenWidth } = useWindowDimensions();

  const effectiveFullScreenLimit =
    fullScreenLimitMap[maxWidth] || fullScreenLimit || defaultFullScreenLimit;
  const fullScreen = rest.fullScreen || screenWidth < effectiveFullScreenLimit;

  const innerProps = {
    onEscapeKeyDown: onClose,
    onBackdropClick: onClose,
    open,
  };

  return (
    <Dialog
      fullWidth={true}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      className={classes.root}
      {...rest}
      {...innerProps}
    >
      <DialogTitle onClose={onClose}>
        {title}
        {helpLink && (
          <Box className={classes.helpLink}>
            <Link
              aria-label="link to help"
              color="inherit"
              href={helpLink}
              rel="noreferrer"
              target="_blank"
            >
              Need Help?
            </Link>
          </Box>
        )}
      </DialogTitle>
      <DialogContents className={classes.content}>{children}</DialogContents>
    </Dialog>
  );
};

export default TswDialog;
