import React, { useState } from 'react';

// material ui
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import MaskedInput from 'react-text-mask';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PhoneMask = ({ ref, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

const SsnMask = ({ ref, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref}
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

const useVisible = (state) => {
  const [visible, setVisible] = useState(state);
  const toggleVisible = () => {
    setVisible((prev) => !visible);
  };

  return [visible, toggleVisible];
};

const useGetPasswordTextProps = ({ disabled, defaultVisible = false, InputProps = {} } = {}) => {
  const [visible, toggleVisible] = useVisible(defaultVisible);

  const inputProps = {
    endAdornment: (
      <IconButton
        aria-label="toggle password visibility"
        onClick={toggleVisible}
        disabled={disabled}
      >
        {visible ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    ),
  };

  return { type: visible ? 'text' : 'password', InputProps: { ...InputProps, ...inputProps } };
};

const getMaskTextProps = ({ mask, InputProps = {} } = {}) => {
  const masks = { phone: PhoneMask, ssn: SsnMask };
  const maxLength = mask === 'ssn' ? { maxLength: 9 } : {};
  const inputProps = masks[mask] ? { inputComponent: masks[mask], ...maxLength } : {};

  return { InputProps: { ...InputProps, ...inputProps } };
};

// Internet Explorer 11 isn't handling the shrink behavior correctly, so we're manually setting shrink when a value is present.
const TswTextField = ({ password, mask, ...props }) => {
  // Just making these mutually exclusive for simplicity
  const getPasswordTextProps = useGetPasswordTextProps(props);
  const passwordProps = password ? getPasswordTextProps : {};
  const maskProps =
    mask && false ? getMaskTextProps({ mask, InputProps: passwordProps.InputProps, ...props }) : {};

  return (
    <TextField
      variant="filled"
      size="small"
      fullWidth
      InputLabelProps={!!props.value || !!mask ? { shrink: true } : undefined}
      {...passwordProps}
      {...maskProps}
      {...props}
    />
  );
};

const GenerateIdentifier = (props) => {
  return (
    <IconButton {...props}>
      <AddCircleIcon color="primary" />
    </IconButton>
  );
};

export const GenerateIdentifierTextField = ({ onClick, ...rest }) => {
  const { disabled } = rest;

  return (
    <TswTextField
      {...rest}
      InputProps={disabled ? {} : { endAdornment: <GenerateIdentifier onClick={onClick} /> }}
    />
  );
};

// Note, this can now be accessed via the core TextField by passing password as a truthy prop
export const PasswordField = ({ disabled, onClick, ...rest }) => {
  const [visible, setVisible] = useState(false);
  const handleClickShowPassword = () => {
    setVisible((prev) => !visible);
  };

  return (
    <TswTextField
      {...rest}
      type={visible ? 'text' : 'password'}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            disabled={disabled}
          >
            {visible ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        ),
      }}
    />
  );
};

export default TswTextField;
