const constants = {
  LIST_ANALYTICS_REPORTS_PENDING: 'LIST_ANALYTICS_REPORTS_PENDING',
  LIST_ANALYTICS_REPORTS_SUCCESS: 'LIST_ANALYTICS_REPORTS_SUCCESS',
  LIST_ANALYTICS_REPORTS_FAILURE: 'LIST_ANALYTICS_REPORTS_FAILURE',
  // Dashboards
  LIST_ANALYTICS_DASHBOARDS_PENDING: 'LIST_ANALYTICS_DASHBOARDS_PENDING',
  LIST_ANALYTICS_DASHBOARDS_SUCCESS: 'LIST_ANALYTICS_DASHBOARDS_SUCCESS',
  LIST_ANALYTICS_DASHBOARDS_FAILURE: 'LIST_ANALYTICS_DASHBOARDS_FAILURE',
  // Explores
  LIST_ANALYTICS_EXPLORES_PENDING: 'LIST_ANALYTICS_EXPLORES_PENDING',
  LIST_ANALYTICS_EXPLORES_SUCCESS: 'LIST_ANALYTICS_EXPLORES_SUCCESS',
  LIST_ANALYTICS_EXPLORES_FAILURE: 'LIST_ANALYTICS_EXPLORES_FAILURE',
};

export default constants;
