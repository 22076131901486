import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths from 'config/paths/containerControls';

// Components
const Index = lazy(() => import('./index'));
const ResourceLayout = lazy(() => import('./ResourceLayout'));

// We've encapsulated all the state necessary for containers in a layout that can be reused for the profile context

export const ContainersReduxManager = ({ children }) => (
  <ReduxManager
    data={[
      'containerControlsDropdown',
      'contractsDropdown',
      'customRolesDropdown',
      'facilitiesDropdown',
      'peopleDropdown',
      'tagNamesDropdown',
      'typesDropdown',
    ]}
  >
    {children}
  </ReduxManager>
);

export const Routes = ({ paths, permissions }) => {
  // Redux Manager can handle loading any data necessary for the feature to function
  // see the component definition for available data/actions
  return (
    <ContainersReduxManager data={[]}>
      <Suspense fallback={<></>}>
        <Switch>
          <Route permission={permissions.containerControls.list} exact path={paths.root}>
            <Index paths={paths} />
          </Route>
          <Route permission={permissions.containerControls.list} path={paths.resource.root}>
            <ResourceLayout paths={paths} />
          </Route>
        </Switch>
      </Suspense>
    </ContainersReduxManager>
  );
};

export default connect(mapPermissionsToProps, null)((props) => <Routes paths={paths} {...props} />);
