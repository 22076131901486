import { isAnyAdmin, isEmployee, isCustomRole } from 'permissions/v1/roles';
import { noPermissions, canView, canCreate, canUpdate, canDestroy } from 'permissions/v1/index';

import { hasTravels } from 'permissions/v1/preferences';

const permission = 'Travel';

const list = (profile) => isEmployee(profile) || isAnyAdmin(profile) || isCustomRole(profile);
const view = (profile) => isAnyAdmin(profile) || canView(permission, profile);
const create = (profile) =>
  isAnyAdmin(profile) || isEmployee(profile) || canCreate(permission, profile);
const update = (profile) =>
  isAnyAdmin(profile) || isEmployee(profile) || canUpdate(permission, profile);
const destroy = (profile) => isAnyAdmin(profile) || canDestroy(permission, profile);
const manage = (profile) => isAnyAdmin(profile) || canUpdate(permission, profile);

const permissions = (profile) => {
  if (!hasTravels(profile)) return noPermissions;

  return {
    list: list(profile),
    view: view(profile),
    create: create(profile),
    update: update(profile),
    destroy: destroy(profile),
    manage: manage(profile),
  };
};

export default permissions;
