import { PROFILE_ACTIONS } from './constants';

let nextTodoId = 0;
export const addTodo = (text) => {
  return {
    type: 'ADD_TODO',
    id: nextTodoId++,
    text,
  };
};

export const setVisibilityFilter = (filter) => {
  return {
    type: 'SET_VISIBILITY_FILTER',
    filter,
  };
};

export const toggleTodo = (id) => {
  return {
    type: 'TOGGLE_TODO',
    id,
  };
};

/* Profile Store Actions */
export const auth_start = () => {
  return {
    type: PROFILE_ACTIONS.AUTH_START,
  };
};

export const auth_success = (details) => {
  return {
    type: PROFILE_ACTIONS.AUTH_SUCCESS,
    details: details,
  };
};

export const update_profile = (profile) => {
  return {
    type: PROFILE_ACTIONS.PROFILE_UPDATED,
    profile,
  };
};

export const auth_failure = (message) => {
  return {
    type: PROFILE_ACTIONS.AUTH_FAILURE,
    message: message,
  };
};

/* Message Store Actions */
let messageId = 0;
export const add_message = (type, messages) => {
  try {
    window.Intercom('trackEvent', 'Message', { data: `${type} - ${messages.join(',')}` });
  } catch (e) {}
  try {
    window.heap.track('Message', {
      type,
      messages: messages.join(','),
    });
  } catch (e) {}

  return {
    type: 'ADD_MESSAGE',
    id: messageId++,
    message_type: type,
    messages: messages,
  };
};

export const clear_messages = (type) => {
  return {
    type: 'CLEAR_MESSAGES',
    message_type: type,
  };
};

export const clear_all_messages = () => {
  return {
    type: 'CLEAR_ALL_MESSAGES',
  };
};

/* Login action flow */
