import React from 'react';

// MUI
import { styled } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';

// components
import AddFilter from 'components/v2/Filters';
import Tooltip from 'components/v2/FormElements/Tooltip';

const FilterButton = ({ onClick }) => {
  return (
    <Tooltip title="Filter Results">
      <IconButton onClick={onClick} color="primary">
        <FilterListIcon />
      </IconButton>
    </Tooltip>
  );
};

// overriding some styles until we rebuild these bits
const sharedStyles = {
  // left: 'auto',
  // right: 5,
  top: 40,
};
const StyledFilters = styled('div')({
  '& .Filters-menu .Filters-menu-select': {
    ...sharedStyles,
  },
  '& .Filters-menu .Filters-form': {
    ...sharedStyles,
    boxShadow: '0 0 20px -5px rgba(0,0,0,0.5)',
  },
});

const Index = ({ filters }) => {
  if (!filters) return null;

  return (
    <StyledFilters>
      <AddFilter CustomButton={FilterButton} {...filters} />
    </StyledFilters>
  );
};

export default Index;
