import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch, useRoutingFilters } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPreferencesToProps } from 'mappers/v1/preferences';
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// config
import paths, { adminPaths } from 'config/paths/visitRequests';

// components
import actions from 'store/v2/visitRequests/actions';

// V2
const Index = lazy(() => import('./index'));
const Detail = lazy(() => import('./Detail'));

export const VisitsReduxManager = ({ children }) => (
  <ReduxManager data={['visitRequestsDropdown', 'facilitiesDropdown', 'contractsDropdown']}>
    {children}
  </ReduxManager>
);

export const Routes = ({ paths, permissions, preferences, location, updateQuery }) => {
  useRoutingFilters({ location, updateQuery });

  return (
    <VisitsReduxManager>
      <Suspense fallback={<></>}>
        <Switch>
          <Route exact permission={preferences.visits} path={paths.root}>
            <Index paths={paths} />
          </Route>
          <Route permission={preferences.visits} exact path={paths.resource.detail(':id')}>
            <Detail paths={paths} />
          </Route>

          <Route permission={permissions.admin.list} exact path={adminPaths.root}>
            <Index paths={adminPaths} />
          </Route>
          <Route permission={permissions.admin.list} path={adminPaths.resource.root}>
            <Detail paths={adminPaths} />
          </Route>
        </Switch>
      </Suspense>
    </VisitsReduxManager>
  );
};

export default withRouter(
  connect(
    ({ profile }) => ({
      ...mapPermissionsToProps({ profile }),
      ...mapPreferencesToProps({ profile }),
    }),
    { updateQuery: actions.updateQuery }
  )((props) => <Routes paths={paths} {...props} />)
);
