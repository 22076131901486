import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const StyledGroup = styled('div')({
  paddingBottom: '20px',
});

export const StyledList = styled('ul')({
  listStyle: 'disc',
  marginLeft: '1rem',
});

export const StyledListItem = styled('li')({
  listStyle: 'disc',
});

export const StyledContainer = styled('div')({
  borderLeft: '3px solid rgba(151, 151, 151, 0.5)',
  flexBasis: '33.3%',
  maxWidth: '33.3%',
  padding: '0 0.8rem',
});

export const StyledReminderContainer = styled('div')({
  display: 'flex',
  paddingBottom: '8px',
});

export const StyledListContainer = styled('div')({
  paddingBottom: '25px',
});

export const StyledHeader = styled('h2')({
  fontSize: '20px',
  fontWeight: 'normal',
  color: '#5f5e5e',
  marginTop: '0',
  paddingTop: '0',
  marginBottom: '0',
});

export const StyledFeedContainer = styled('div')({
  flexBasis: '66.6%',
  flexGrow: '1',
  paddingRight: '0.8rem',
});

export const StyledInboxContainer = styled('div')({
  display: 'flex',
});

export const StyledListItemLink = styled(Link)({
  color: '#205077',
  fontSize: '14px',
  verticalAlign: 'top',
});

export const StyledSubHeader = styled('h4')({
  fontSize: '12px',
  fontWeight: 'normal',
  color: '#5f5e5e',
  marginTop: '0',
  paddingTop: '0',
  paddingBottom: '15px',
});
