import { createActions, createAction } from 'redux-actions';
import { add_message } from 'store/v1/app/actions';

import request from 'lib/v1/request';
import handleError from 'lib/v1/error-handling';
import CONST from './constants';

const { getDocumentsSuccess, getDocumentsPending } = createActions(
  {
    [CONST.GET_DOCUMENTS_SUCCESS]: [(payload) => payload, (payload, meta) => meta],
  },
  CONST.GET_DOCUMENTS_PENDING,
  CONST.GET_DOCUMENTS_FAILURE
);

const { getUserDocumentsPending, getUserDocumentsSuccess } = createActions(
  CONST.GET_USER_DOCUMENTS_PENDING,
  CONST.GET_USER_DOCUMENTS_SUCCESS,
  CONST.GET_USER_DOCUMENTS_FAILURE
);

const { getDocumentablePending, getDocumentableSuccess } = createActions(
  CONST.GET_DOCUMENTABLE_PENDING,
  CONST.GET_DOCUMENTABLE_SUCCESS,
  CONST.GET_DOCUMENTABLE_FAILURE
);

const { createDocumentPending, createDocumentSuccess } = createActions(
  CONST.CREATE_DOCUMENT_PENDING,
  CONST.CREATE_DOCUMENT_SUCCESS,
  CONST.CREATE_DOCUMENT_FAILURE
);

const { updateDocumentPending, updateDocumentSuccess } = createActions(
  CONST.UPDATE_DOCUMENT_PENDING,
  CONST.UPDATE_DOCUMENT_SUCCESS,
  CONST.UPDATE_DOCUMENT_FAILURE
);

const { deleteDocumentsPending, deleteDocumentsSuccess } = createActions(
  CONST.DELETE_DOCUMENTS_PENDING,
  CONST.DELETE_DOCUMENTS_SUCCESS,
  CONST.DELETE_DOCUMENTS_FAILURE
);

const { deleteDocumentPending, deleteDocumentSuccess } = createActions(
  CONST.DELETE_DOCUMENT_PENDING,
  CONST.DELETE_DOCUMENT_SUCCESS,
  CONST.DELETE_DOCUMENT_FAILURE
);

const { downloadDocumentPending } = createActions(
  CONST.DOWNLOAD_DOCUMENT_PENDING,
  CONST.DOWNLOAD_DOCUMENT_SUCCESS,
  CONST.DOWNLOAD_DOCUMENT_FAILURE
);

export const documentsChangePage = createAction(CONST.DOCUMENTS_CHANGE_PAGE);
export const documentsResetPaging = createAction(CONST.DOCUMENTS_RESET_PAGING);

export const getDocuments = ({
  page = 1,
  per_page = 10,
  sort = null,
  dir = 'asc',
  search = null,
} = {}) => {
  return (dispatch) => {
    dispatch(getDocumentsPending(page));
    return request
      .get('/documents')
      .query({ page, per_page, sort, dir, search })
      .then((res) => dispatch(getDocumentsSuccess({ files: res.body.data }, res.body.meta)))
      .catch(handleError);
  };
};

export const getDocument = (id) => {
  return request
    .get(`/documents/${id}`)
    .then((res) => res.body.data)
    .catch(handleError);
};

export const getUserDocuments = (id, { sort = null, dir = 'asc' } = {}) => {
  return (dispatch) => {
    dispatch(getUserDocumentsPending());
    return request
      .get('/documents')
      .query({ documentable_type: 'User', documentable_id: id, sort, dir })
      .then((res) => dispatch(getUserDocumentsSuccess({ files: res.body.data, user: id })))
      .catch(handleError);
  };
};

export const getDocumentable = (type, id) => {
  return (dispatch) => {
    dispatch(getDocumentablePending());
    return request
      .get('/documents')
      .query({ documentable_type: type, documentable_id: id })
      .then((res) => dispatch(getDocumentableSuccess({ files: res.body.data, type, id })))
      .catch(handleError);
  };
};

export const createDocument = (payload) => {
  return (dispatch) => {
    dispatch(createDocumentPending());
    return request
      .post('/documents')
      .send(payload)
      .then((res) => dispatch(createDocumentSuccess(res.body.data)))
      .catch((e) => {
        if (e.response.body && e.response.body.errors) {
          if (Array.isArray(e.response.body.errors)) {
            dispatch(add_message('upload_error', e.response.body.errors));
          } else {
            dispatch(add_message('upload_error', [e.response.body.errors]));
          }
        } else {
          handleError(e);
        }
      });
  };
};

export const updateDocument = (payload) => {
  return (dispatch) => {
    dispatch(updateDocumentPending());
    return request
      .put(`/documents/${payload.id}`)
      .send(payload)
      .then((res) => dispatch(updateDocumentSuccess(res.body.data)))
      .catch(handleError);
  };
};

export const deleteDocument = (id) => {
  return (dispatch) => {
    dispatch(deleteDocumentPending());
    return request
      .del(`/documents/${id}`)
      .then((res) => dispatch(deleteDocumentSuccess(id)))
      .catch(handleError);
  };
};

export const deleteDocuments = (ids, documentableId) => {
  return (dispatch) => {
    dispatch(deleteDocumentsPending());
    return request
      .del(`/documents/${ids.join(',')}`)
      .then((res) => dispatch(deleteDocumentsSuccess({ ids, documentableId })))
      .catch(handleError);
  };
};

export const downloadDocument = (id) => {
  return (dispatch) => {
    dispatch(downloadDocumentPending());
    return request
      .get(`/documents/${id}/download`)
      .then((res) => {
        const tab = window.open(res.body.data.url, '_blank');
        if (!tab || tab.closed || typeof tab.closed === 'undefined') {
          alert('Pop-up Blocker is enabled! Please add this site to your exception list.');
        }
      })
      .catch(handleError);
  };
};

export const download = (id) => {
  return (dispatch) => {
    return request.get(`/documents/${id}/download`).then((res) => {
      window.open(res.body.data.url);
    });
  };
};
