import React from 'react';
import Goal from 'assets/images/goal.svg';
import Superhero from 'assets/images/superhero.svg';

import {
  StyledCard,
  StyledHeadline,
  StyledWrapper,
  StyledSubHeadline,
} from 'styles/Inbox/InboxZero';

const Hero = () => (
  <StyledCard>
    <img src={Superhero} alt="Superhero" />
    <StyledHeadline>You've reached inbox zero!</StyledHeadline>
    <StyledSubHeadline>Not all heroes wear capes.</StyledSubHeadline>
  </StyledCard>
);

const MountainClimber = () => (
  <StyledCard>
    <img src={Goal} alt="Goal" />
    <StyledHeadline>You've reached inbox zero!</StyledHeadline>
    <StyledSubHeadline>How's the view from the top?</StyledSubHeadline>
  </StyledCard>
);

const cards = [Hero, MountainClimber];

function InboxZero() {
  const Card = cards[Math.floor(Math.random() * Math.floor(cards.length))];

  return (
    <StyledWrapper>
      <Card />
    </StyledWrapper>
  );
}

export default InboxZero;
