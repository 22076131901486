export const initialState = JSON.parse(window.localStorage.getItem('profile') || null) || {
  company: {},
};

const index = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case 'LOGIN_SUCCESS':
      return payload;
    case 'REFRESH_PROFILE_SUCCESS':
      return payload;
    case 'GET_PROFILE_SUCCESS':
      return payload;
    case 'LOGOUT_SUCCESS':
      return { company: {} };
    case 'CREATE_LOOKER_USER_PENDING':
      return {
        ...state,
        loading: true,
      };
    case 'CREATE_LOOKER_USER_SUCCESS':
      return {
        ...state,
        loading: false,
        looker: {
          ...state.looker,
          user_id: payload.looker_user_id,
        },
      };
    default:
      return state;
  }
};

export default index;
