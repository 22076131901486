import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths, { combinedPaths } from 'config/paths/integrations';

// Components
const Index = lazy(() => import('./index'));
const ServiceIntegrationResourceLayout = lazy(() => import('./ThirdParty/ResourceLayout'));

export const Routes = ({ paths, permissions }) => {
  return (
    <ReduxManager data={['integrationsDropdown']}>
      <Route permission={permissions.clientCredentials.list} exact path={paths.root}>
        <Redirect to={combinedPaths.credentials.root} />
      </Route>
      <Suspense fallback={<></>}>
        <Switch>
          <Route
            permission={permissions.clientCredentials.list}
            path={combinedPaths.thirdParty.resource.root}
          >
            <ServiceIntegrationResourceLayout paths={combinedPaths.thirdParty} />
          </Route>

          {permissions.clientCredentials.list && (
            <Index
              credentialsPaths={combinedPaths.credentials}
              thirdPartyPaths={combinedPaths.thirdParty}
              webhooksPaths={combinedPaths.webhooks}
            />
          )}
        </Switch>
      </Suspense>
    </ReduxManager>
  );
};

const mapStateToProps = ({ profile }) => {
  return { ...mapPermissionsToProps({ profile }), paths };
};

export default connect(mapStateToProps, {})((props) => <Routes paths={paths} {...props} />);
