import { createTheme } from '@mui/material/styles';
import {
  alert,
  blueLight,
  activeIconColor,
  tableActionColor,
  ticketsColor,
  travelColor,
  visitsColor,
  notifyColor,
  navbarColor,
  sisGray1,
  sisGray6,
} from 'lib/v2/colors';

const popoverProps = {
  // boxShadow: '0 0 8px rgba(0,0,0,0.2)',
  // border: `1px solid ${lightGray}`,
};

const defaultTheme = createTheme();
export const baselinePalette = {
  backgroundBasic: defaultTheme.palette.augmentColor({
    color: { main: blueLight },
    name: 'backgroundBasic',
  }),
  tableAction: defaultTheme.palette.augmentColor({
    color: { main: tableActionColor, icon: sisGray6 },
    name: 'tableAction',
  }),
  navbar: defaultTheme.palette.augmentColor({
    color: { main: blueLight, border: navbarColor, icon: sisGray6, activeIcon: activeIconColor },
    name: 'navbar',
  }),
  notify: defaultTheme.palette.augmentColor({
    color: { main: notifyColor, contrastText: '#000' },
    name: 'notify',
  }),
  tickets: defaultTheme.palette.augmentColor({
    color: { main: ticketsColor },
    name: 'tickets',
  }),
  travel: defaultTheme.palette.augmentColor({
    color: { main: travelColor, contrastText: '#fff' },
    name: 'travel',
  }),
  visits: defaultTheme.palette.augmentColor({
    color: { main: visitsColor, contrastText: '#fff' },
    name: 'visits',
  }),
};

const baseline = {
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    button: { textTransform: 'none' },
    h1: {
      fontSize: '2.5rem',
      // fontWeight: 400,
    },
    h2: {
      fontSize: '2rem',
      // fontWeight: 300,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    caption: {},
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          position: 'relative',
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: blueLight,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 500,
          },
          '& .MuiDataGrid-booleanCell': {
            '&[data-value="false"]': {
              color: alert,
            },
          },
          '& .datagrid_row_even': {
            // backgroundColor: blueLight,
          },
          '& .datagrid_row_odd': {
            backgroundColor: '#fcfcfc',
          },
          '& .datagrid_row_unviewed': {
            '> .MuiDataGrid-cell:first-child': {
              borderLeft: `solid 2px ${activeIconColor}`,
            },
          },
          '& .datagrid_row_viewed': {
            '> .MuiDataGrid-cell:first-child': {
              borderLeft: `solid 2px ${sisGray1}`,
            },
          },
        },
      },
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          marginRight: '0.75rem',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
        },
        dense: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          alignItems: 'baseline',
          top: '10px !important', // this aligns it nicely in the appbar
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {},
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            // example syntax for focused label styling
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.875rem', // 14px
          fontWeight: 300,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '& .MuiListItem-root': {
            paddingLeft: '0',
            paddingRight: '0',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem',
          padding: '20px 24px 8px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px 24px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {},
        input: {},
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {},
        input: {},
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          ...popoverProps,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: '100%',
        },
        endAdornment: {
          '& .Mui-disabled': {
            display: 'none',
          },
        },
        // this styles the dropdown box
        paper: {
          ...popoverProps,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          margin: 2,
        },
        deleteIcon: {},
        icon: {
          width: '1rem',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          padding: 0,
        },
        contained: {
          marginLeft: 12,
          marginRight: 12,
        },
        marginDense: {
          marginTop: 2,
        },
      },
    },
    // calendar picker header with month, etc
    MuiPickersCalendarHeader: {
      styleOverrides: {
        transitionContainer: {
          height: '2rem', // not sure why this isn't sizing properly, but this forces it tall enough
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '0.75rem 0',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '0.75rem 1rem',
        },
      },
    },
  },
};

export default baseline;
