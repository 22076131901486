import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths from 'config/paths/company';
import accessesPaths from 'config/paths/accesses';
import customFieldsPaths from 'config/paths/customFields';
import eligibilitiesPaths from 'config/paths/eligibilities';
import polygraphsPaths from 'config/paths/polygraphs';

// Components
const Index = lazy(() => import('./index'));
const AccessDetail = lazy(() => import('./Accesses/Detail'));
const CustomFieldDetail = lazy(() => import('components/v2/CustomFields/Detail'));
const EligibilityDetail = lazy(() => import('./Eligibilities/Detail'));
const PolygraphDetail = lazy(() => import('./Polygraphs/Detail'));

/*
 * Important thing to note about Resourceful layouts.
 *
 * If you want sublayouts such as those defined in "Detail" to successfully render
 * you cannot use exact paths, you are partial matching on the parent to ensure
 * the top level layout always gets loaded so that any child pages also get loaded.
 */

export const Routes = ({
  paths,
  permissions,
  accessesPaths,
  eligibilitiesPaths,
  polygraphsPaths,
  v2,
}) => {
  const indexProps = {
    accessesPaths,
    customFieldsPaths,
    eligibilitiesPaths,
    polygraphsPaths,
  };

  const title = v2 ? 'Account Details' : 'Company';

  return (
    <ReduxManager data={['peopleDropdown', 'customFieldsDropdown']}>
      <Suspense fallback={<></>}>
        <Switch>
          <Route
            permission={permissions.company.view}
            path={accessesPaths.resource.root}
            component={AccessDetail}
          />
          <Route
            permission={permissions.company.view}
            path={eligibilitiesPaths.resource.root}
            component={EligibilityDetail}
          />
          <Route
            permission={permissions.company.view}
            path={polygraphsPaths.resource.root}
            component={PolygraphDetail}
          />
          <Route
            permission={permissions.customFields.list}
            path={customFieldsPaths.resource.root}
            component={CustomFieldDetail}
          />
          <Route permission={permissions.company.list} path={paths.root}>
            <Index {...{ paths, title, ...indexProps }} />
          </Route>
        </Switch>
      </Suspense>
    </ReduxManager>
  );
};

export default connect(mapPermissionsToProps)((props) => (
  <Routes {...{ paths, accessesPaths, eligibilitiesPaths, polygraphsPaths, ...props }} />
));
