// User-related util functions
import store from 'store';
import { mapArrayToObject } from 'adapters/v1/meta';

export const getCustomFieldPropNames = (type) => {
  const data = store.getState()[type];
  if (data && data.schema && data.schema.properties) {
    return Object.keys(data.schema.properties);
  }
  return [];
};

export const adaptCustomFieldsToForm = (resource, adapted) => {
  if (!resource) return {};
  const customFields = getCustomFieldPropNames(resource);
  customFields.forEach((attr) => {
    if (resource[attr] === undefined) return;
    const hasLabel = resource[attr] && resource[attr].label !== undefined;
    adapted[attr] = hasLabel ? resource[attr].label : resource[attr];
  });
  return adapted;
};

export const getCustomFieldMapping = () => {
  const meta = store.getState().customFields.meta;

  if (!meta) return {};

  const {
    attributes: { values },
  } = meta;

  const fieldType = mapArrayToObject({ data: values.field_type.data });
  const model = mapArrayToObject({ data: values.model.data });

  return { fieldType, model };
};
