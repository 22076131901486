import React from 'react';

// TEMPLATE TODO: update header icon
import HeaderIcon from 'assets/images/action_request.svg';

// Components
import Loader from 'components/v2/Loader';
import ErrorPage from 'components/v1/ErrorPage';
import HeaderLayout from 'layouts/v2/HeaderLayout';
import Sidebar from 'components/v2/PageElements/Sidebar';
import Comments from 'components/v2/PageElements/Comments';
import Attachments from 'components/v2/PageElements/Attachments';
import { ExpandableHistoryItem } from 'components/v2/PageElements/History';
import { useResource } from 'components/v2/PageElements';

// state
import { withResourceState } from './state';

const MODEL_TYPE = 'PaperTrail::Version';
const JSON_API = true;

const Detail = ({
  resource = {},
  resourceId,
  show,
  update,
  paths,
  loading,
  permissions,
  updateQuery,
  showActivity,
}) => {
  const { resourceLoading, notFound } = useResource({ show, resourceId, showActivity });

  const ready = !resourceLoading && resourceId;

  const headerProps = {
    title: `${resource.display_name || resource.name}`,
    icon: HeaderIcon,
    allowCreate: false,
    backPath: paths.root,
    subtitle: `Last Updated: ${resource.updated_at}`,
  };

  const commentProps = {
    jsonApi: JSON_API,
    comments: resource.comments || [],
    commentable_type: MODEL_TYPE,
    commentable_id: resourceId,
    updateQuery,
  };

  const attachmentsProps = {
    jsonApi: JSON_API,
    data: resource.documents,
    documentable_type: MODEL_TYPE,
    documentable_id: resourceId,
    updateQuery,
    permissions: permissions.activity,
  };

  const sidebarContent = (
    <>
      <Attachments {...attachmentsProps} />
      <Comments {...commentProps} />
    </>
  );

  if (notFound) return <ErrorPage />;

  const historyItemProps = {
    action: resource.event,
    date: resource.created_at,
    object_changes: resource.object_changes,
  };

  return (
    <>
      {resourceLoading && <Loader />}
      {ready && (
        <Sidebar sidebarContent={sidebarContent}>
          <HeaderLayout {...headerProps}>
            <ExpandableHistoryItem {...historyItemProps} />
          </HeaderLayout>
        </Sidebar>
      )}
    </>
  );
};

export default withResourceState(Detail);
