import { isAnyAdmin } from 'permissions/v1/roles';

const list = (profile) => isAnyAdmin(profile);
const view = (profile) => isAnyAdmin(profile);
const create = (profile) => isAnyAdmin(profile);
const update = (profile) => isAnyAdmin(profile);
const destroy = (profile) => isAnyAdmin(profile);

const permissions = (profile) => {
  return {
    list: list(profile),
    view: view(profile),
    create: create(profile),
    update: update(profile),
    destroy: destroy(profile),
  };
};

export default permissions;
