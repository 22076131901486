import reducer, { initialState } from 'store/v2/index';
import actionType from './constants';

export default reducer(actionType, {
  ...initialState(),
  query: { ...initialState().query, filters: { taskable_type: ['Card'], complete: [false] } },
  filters: {
    complete: {
      values: [false],
      displayName: 'Complete: No',
    },
  },
});
