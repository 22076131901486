import CONST from './constants';

export const initialState = {
  data: {},
  result: [],
  loading: false,
  meta: {},
};

const index = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case CONST.GET_FORMS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.GET_FORMS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.reduce((data, form) => {
            data[form.template_id] = form;
            return data;
          }, {}),
        },
        meta,
        loading: false,
        result: [
          ...new Set(
            state.result.concat(
              payload.reduce((filtered, form) => {
                if (!form.deleted) {
                  filtered.push(form.template_id);
                }
                return filtered;
              }, [])
            )
          ),
        ],
      };
    case CONST.GET_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          ...state.data,
          [payload.template_id]: payload,
        },
        result: [...new Set(state.result.concat(payload.template_id))],
      };
    case CONST.CREATE_FORM_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.CREATE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.template_id]: payload,
        },
        result: [...new Set([payload.template_id].concat(state.result))],
      };
    case CONST.CREATE_FORM_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONST.UPDATE_FORM_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.UPDATE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.template_id]: payload,
        },
      };
    case CONST.UPDATE_FORM_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CONST.DELETE_FORM_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.DELETE_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [payload.template_id]: {
            deleted: true,
            ...state.data[payload.template_id],
          },
        },
        result: state.result.filter((id) => id !== payload.template_id),
      };
    default:
      return state;
  }
};

export default index;
