import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch } from 'components/v2/PageElements/Routes';

import { withIdentityManager } from 'components/v2/PageElements/IdentityManager';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// actions
import actions from 'store/v2/badgeControls/actions';

// Config
import paths from 'config/paths/badgeControls';

// I don't love how duplicative this is
import { dateRangeLabel } from 'components/v2/Filters/DateRange';

// Components
const WrappedIndex = lazy(() => import('./index'));
const Table = lazy(() => import('./Table'));
const Detail = lazy(() => import('./Detail'));

// We've encapsulated all the state necessary for badges in a layout that can be reused for the profile context
export const BadgesReduxManager = ({ children }) => (
  <ReduxManager
    data={['badgeControlsDropdown', 'contractsDropdown', 'facilitiesDropdown', 'peopleDropdown']}
  >
    {children}
  </ReduxManager>
);

export const Routes = ({ paths, permissions, updateQuery, location, isSelf, userId }) => {
  // TODO - useRoutingFilters hook implements this now.

  const values =
    location &&
    location.state &&
    location.state.activeFilters &&
    location.state.activeFilters.badge_end_date;

  // FIX HOOK
  React.useEffect(() => {
    if (!values || !updateQuery) return;

    updateQuery({
      filters: {
        expired_at: {
          values,
          ...dateRangeLabel({ displayName: 'Expiration Date', range: values }),
        },
      },
    });
  }, []); // eslint-disable-line

  return (
    <BadgesReduxManager>
      <Suspense fallback={<></>}>
        <Switch>
          <Route permission={isSelf || permissions.badgeControls.list} exact path={paths.root}>
            <WrappedIndex {...{ paths, isSelf, userId }}>
              <Table {...{ paths, userId }} />
            </WrappedIndex>
          </Route>
          <Route
            permission={isSelf || permissions.badgeControls.list}
            path={paths.resource.root}
            render={(props) => <Detail paths={paths} {...props} />}
          />
        </Switch>
      </Suspense>
    </BadgesReduxManager>
  );
};

export default withIdentityManager(
  connect(
    mapPermissionsToProps,
    actions
  )(withRouter((props) => <Routes paths={paths} {...props} />))
);
