import TagBatchAction from 'components/v2/FlexTable/TagBatchAction';
import { withFeatureState } from './state';

export const Tag = withFeatureState(({ ...rest }) => {
  const props = { taggableType: 'Document' };
  return <TagBatchAction {...rest} {...props} />;
});

const batchActions = ({ permissions }) => {
  if (!permissions) return [];

  const actions = [{ action: Tag, permission: permissions.documents.update }];

  return {
    batchActions: actions.filter((action) => action.permission).map((action) => action.action),
  };
};

export default batchActions;
