import React from 'react';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

// MUI
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import ListItem from './ListItem';
import Button from './Button';
import TextField from './TextField';

export const useStyles = makeStyles((theme) => ({
  listItem: {
    // zIndex: 1300, // this appears to be the magic number that allows the elements to appear in the modal and form, without overlapping or disappearing; will remove eventually
    // paddingTop: 2,
    // paddingBottom: 2,
    backgroundColor: 'rba(255, 255, 255, 0.8)',
    '& .MuiInputAdornment-root': {
      margin: '0 !important',
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& .MuiSvgIcon-root': {
      width: '2rem',
    },
  },
  itemtextField: {
    margin: 0,
  },
  draghandle: {
    cursor: 'grab',
    width: '1rem',
    padding: '0',
    marginRight: -20,
    marginLeft: -20,
  },
}));

const DragHandle = SortableHandle(() => {
  const classes = useStyles();

  return (
    <ListItemIcon className={classes.draghandle}>
      <DragIndicatorIcon />
    </ListItemIcon>
  );
});

const SortableItem = SortableElement(
  ({
    id,
    value,
    label,
    onItemEdit,
    onItemDelete,
    index,
    textFieldProps = {},
    showDelete = true,
  }) => {
    const classes = useStyles();

    const [text, setText] = React.useState(value);

    // FIX HOOK
    React.useEffect(() => onItemEdit(id, text), [text]); // eslint-disable-line

    const handleChange = (event) => setText(event.target.value);
    const handleDelete = (event) => onItemDelete(id);

    const DeleteButton = () => (
      <InputAdornment position="start">
        <IconButton onClick={handleDelete}>
          <DeleteOutlineIcon />
        </IconButton>
      </InputAdornment>
    );

    const inputProps = showDelete ? { endAdornment: <DeleteButton /> } : {};

    return (
      <ListItem className={classes.listItem}>
        <DragHandle />
        <TextField
          autoFocus={!text}
          InputProps={inputProps}
          label={label}
          onChange={handleChange}
          value={text}
          {...textFieldProps}
        />
      </ListItem>
    );
  }
);

const SortableListContainer = SortableContainer(
  ({
    items,
    onItemEdit,
    onItemAdd,
    itemLabel,
    onItemDelete,
    addLabel = 'Add New',
    textFieldProps,
    allowEmpty = true,
  }) => {
    const classes = useStyles();

    const showDelete = allowEmpty ? true : items && items.length > 1;

    return (
      <List>
        {items.map(({ title, label }, index) => (
          <SortableItem
            key={title}
            id={title}
            index={index}
            value={label}
            label={itemLabel}
            onItemEdit={onItemEdit}
            onItemDelete={onItemDelete}
            textFieldProps={textFieldProps}
            showDelete={showDelete}
          />
        ))}
        <ListItem className={classes.listItem} disableGutters>
          <Button color="primary" variant="text" onClick={onItemAdd} startIcon={<AddCircleIcon />}>
            {addLabel}
          </Button>
        </ListItem>
      </List>
    );
  }
);

const SortableList = ({
  value,
  onChange,
  itemLabel = 'item',
  addLabel,
  textFieldProps = {},
  allowEmpty = true,
}) => {
  const [initialized, setInitialized] = React.useState(false);
  const [listItems, setListItems] = React.useState(value);
  const [newItemNumber, setNewItemNumber] = React.useState(1);

  if (!initialized && value && value.length) {
    setListItems(value);
    setInitialized(true);
  }

  // FIX HOOK
  React.useEffect(() => {
    onChange &&
      onChange(
        listItems
          .filter(({ label }) => label)
          .map(({ title, label }) => {
            return {
              label,
              title: String(title).includes('new') ? null : title,
            };
          })
      );
  }, [listItems]); // eslint-disable-line

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedList = arrayMove(listItems, oldIndex, newIndex);
    setListItems(updatedList);
  };

  const onItemEdit = (id, newValue) => {
    let tempItems = [...listItems];
    const changedIndex = tempItems.findIndex((item) => item.title === id);
    tempItems[changedIndex] = {
      ...tempItems[changedIndex],
      label: newValue,
    };
    setListItems(tempItems);
  };

  const onItemDelete = (id) => {
    if (
      String(id).includes('new') ||
      window.confirm(`Are you sure you want to remove this forever?`)
    ) {
      setListItems([...listItems].filter((item) => item.title !== id));
    }
  };

  const onItemAdd = () => {
    setListItems([...listItems, { title: `new-${newItemNumber}`, label: '' }]);
    setNewItemNumber(newItemNumber + 1);
  };

  const listProps = {
    items: listItems,
    onSortEnd,
    onItemEdit,
    onItemAdd,
    onItemDelete,
    itemLabel,
    addLabel,
    useDragHandle: true,
    textFieldProps,
    allowEmpty,
  };

  return <SortableListContainer {...listProps} />;
};

export default SortableList;
