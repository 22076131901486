import React from 'react';

// MUI
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Box from '@mui/material/Box';
import { makeStyles, withStyles } from '@mui/styles';
import Accordian from '@mui/material/Accordion'; // Note this is updated to Accordion in current MUI
import AccordianSummaryBase from '@mui/material/AccordionSummary';
import AccordianDetails from '@mui/material/AccordionDetails';
import { humanizeSnake } from 'lib/v1/format';

// PageElements
import SidebarHeader from 'components/v2/PageElements/Sidebar/Header';
import SidebarList from 'components/v2/PageElements/Sidebar/List';
import SidebarListItem from 'components/v2/PageElements/Sidebar/ListItem'; // components
import Avatar from 'components/v2/PageElements/Avatar';

// FormElements
import Button from 'components/v2/FormElements/Button';

// Styles
import { styles as commentStyles } from 'components/v2/PageElements/Comments/Comment.js';
import { styles as commentIndexStyles } from 'components/v2/PageElements/Comments/index.js';

// It's pretty easy to override/extend styles from other places if you abstract the core style piece into a basic object
const styles = (theme) => ({
  ...commentStyles(theme),
  deltaContainer: { ...commentStyles(theme).meta, paddingBottom: '0.25rem' },
  avatar: {
    ...commentIndexStyles.avatar,
    marginTop: '0.25rem',
    display: 'block !important', // this overrides the responsive avatars in the source comment styles
  },
  action: { weight: 400 },
  author: { ...commentStyles(theme).author, paddingRight: '0.25rem' },
  expansion: { padding: 0, margin: 0, backgroundColor: 'inherit' },
});

styles.delta = { ...styles.author, marginTop: '1.0rem', paddingLeft: '1.0rem' };

const useStyles = makeStyles(styles);

const summaryStyles = {
  root: { padding: 0, margin: 0 },
  content: { padding: 0, margin: 0 },
};

summaryStyles.disabled = summaryStyles.root;

const AccordianSummary = withStyles(summaryStyles)(AccordianSummaryBase);

const HistoryDelta = ({ classes, field, previous, current }) => {
  const actionName = previous
    ? ` changed from ${previous} to ${current ? current : 'none'}`
    : ` set to ${current}`;
  return (
    <Box className={classes.deltaContainer}>
      <span className={classes.delta}>{humanizeSnake(field)}</span>
      <span className={classes ? classes.action : undefined}>{actionName}</span>
    </Box>
  );
};

export const ExpandableHistoryItem = ({ user, name, action, date, object_changes = {} }) => {
  const classes = useStyles();

  const deltas = Object.entries(object_changes);

  return (
    <SidebarListItem>
      <Accordian className={classes.expansion} elevation={0}>
        <AccordianSummary>
          <ListItemAvatar className={classes.avatar}>
            <Avatar lg profile={user} />
          </ListItemAvatar>
          <ListItemText>
            <Box>
              <Box className={classes.meta}>
                <span className={classes.author}>{name}</span>
                <span className={classes.action}>{action}</span>
              </Box>
              <Box className={classes.meta}>
                <span className={classes.date}>{date}</span>
              </Box>
            </Box>
          </ListItemText>
        </AccordianSummary>
        <AccordianDetails className={classes.expansion}>
          <Box>
            {deltas.map(([key, val], index) => (
              <HistoryDelta
                classes={classes}
                field={key}
                previous={val[0]}
                current={val[1]}
                key={`history-delta-${index}`}
              />
            ))}
          </Box>
        </AccordianDetails>
      </Accordian>
    </SidebarListItem>
  );
};

export const HistoryItem = ({ user, name, action, date, object_changes = {} }) => {
  const classes = useStyles();

  return (
    <SidebarListItem>
      <ListItemAvatar className={classes.avatar}>
        <Avatar lg profile={user} />
      </ListItemAvatar>
      <ListItemText>
        <Box>
          <Box className={classes.meta}>
            <span className={classes.author}>{name}</span>
            <span className={classes.action}>{action}</span>
          </Box>
          <Box className={classes.meta}>
            <span className={classes.date}>{date}</span>
          </Box>
        </Box>
      </ListItemText>
    </SidebarListItem>
  );
};

export const HistoryList = ({ data = [], showAll = false, limit = 5 }) => {
  if (!data || !Array.isArray(data)) return;

  const visibleData = showAll ? data : data.slice(0, limit);

  return (
    <SidebarList>
      {visibleData.map((props, index) => {
        const hasDelta =
          props && props.object_changes && Object.entries(props.object_changes).length === 0
            ? false
            : true;
        return hasDelta ? (
          <ExpandableHistoryItem key={`expandable-history-item-${index}`} {...props} />
        ) : (
          <HistoryItem key={`history-item-${index}`} {...props} />
        );
      })}
    </SidebarList>
  );
};

const History = ({ data = [], limit = 5, ...props }) => {
  const [showAll, setShowAll] = React.useState(false);
  const arrayLength = Array.isArray(data) && data.length;
  const showButton = arrayLength > limit;

  const historyProps = {
    ...props,
    data,
    limit,
    showAll,
  };

  return (
    <div className="historyModule">
      <SidebarHeader>History</SidebarHeader>
      <HistoryList {...historyProps} />
      {showButton && (
        <Button
          component="span"
          color="primary"
          variant="text"
          disableRipple
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? `Only ${limit} newest activities` : `All ${arrayLength} activities`}
        </Button>
      )}
    </div>
  );
};

export default History;
