import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import { cellAdapterFactory, cellLinkAdapterFactory } from 'adapters/v2/factories';
import { adaptJsonApiToDetail, adaptToJsonApiRequest } from 'adapters/v1/json_api';
import { adaptSingleSelectFieldsToRequest } from 'adapters/v1';
import { getUserDetails } from 'lib/v1/users';

const dateFields = ['created_at', 'due_date', 'updated_at'];

const singleSelectFields = ['template_id', 'user_id'];

export const jsonApiType = 'attached_digital_form';

export const adaptToDigitalFormRequest = (formData) => {
  const { attached_digital_form_id } = formData;
  const lookup = {};

  const adapted = {
    ...adaptToIso(formData, dateFields),
    ...adaptSingleSelectFieldsToRequest({ formData, singleSelectFields }),
  };

  const { due_date, template_id, send_notification_email, signer_roles } = adapted;

  Object.entries(adapted).forEach(([key, selectValue]) => {
    if (key.startsWith('user_id.') && selectValue && selectValue.label) {
      const role = key.replace('user_id.', '');
      lookup[role] = { role, id: parseInt(selectValue.label) };
    }
  });

  const signers = (signer_roles || [])
    .map((obj) => {
      const value = lookup[obj.name];
      if (value) return { ...value };
      return null;
    })
    .filter((obj) => obj);

  const attributes = {
    attached_digital_form_id,
    template_id,
    due_date,
    signers,
    send_notification_email,
    user_id: signers && signers[0] ? signers[0].id : null,
  };

  return adaptToJsonApiRequest({
    attributes,
    type: 'digital_form',
  });
};

export const adaptToRequest = (formData) => {
  // const relationships = {};

  const adapted = {
    ...adaptToIso(formData, dateFields),
    ...adaptSingleSelectFieldsToRequest({ formData, singleSelectFields }),
  };
  // const { assignment } = formData;

  // relationships.assignments = { data: [assignment] };

  const { form_id, formable_id, formable_type } = adapted;
  const attributes = { form_id, formable_id, formable_type };

  return adaptToJsonApiRequest({
    attributes,
    type: jsonApiType,
    // include: 'assignments,form',
    // relationships,
  });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const adapted = adaptToDisplay(adaptJsonApiToDetail(resource), dateFields);

  const { assignments, created_at, form } = adapted;

  if (form) {
    adapted.text = form.title;
  }

  if (assignments) {
    assignments.map((assignment) => {
      return (assignment.user = getUserDetails(assignment.user_id));
    });
  }

  if (created_at) {
    adapted.description = created_at;
  }

  return adapted;
};

export const adaptToForm = (resource) => {
  if (!resource) return {};
  return { ...resource };
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
