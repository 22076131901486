import reducer, { initialState } from 'store/v2/index';
import actionType from './constants';

const payload = {
  ...initialState(),
  filters: {
    archived: {
      displayName: 'Archived: No',
      values: false,
    },
  },
};

export default reducer(actionType, payload);
