import React from 'react';

// components
import Button from '../Button';
import FormButtons from '../Buttons';
import ConfirmModal from 'components/v2/FormElements/ConfirmModal';
import { useDialog } from 'components/v2/FormElements/Dialog';

const DialogButtons = (props) => {
  const { Buttons = FormButtons } = props;
  const { onClick, onClose, open } = useDialog();

  const modalProps = {
    title: 'Delete Item',
    isOpen: open,
    onConfirm: props.onDelete,
    confirmText: 'Delete',
    onClose,
  };

  return (
    <>
      {props.onDelete && (
        <>
          <ConfirmModal {...modalProps}>
            Are you sure you want to permanently delete this item?
          </ConfirmModal>
          <Button variant="text" color="error" onClick={onClick}>
            Delete
          </Button>
        </>
      )}
      <Buttons {...props} />
    </>
  );
};

export default DialogButtons;
