// User-related util functions
import store from 'store';

// Maps an array of user IDs to a list of users.
// We can currently rely on a full list of users to be present in the store
// due to the call to GET_USERS_SLIM that happens at page load.
export function mapUserIds(arr, key = 'id') {
  const { users } = store.getState();
  return arr
    .map((d) => {
      return users.data[d[key]];
    })
    .filter((d) => d !== undefined);
}

export const getUserDetails = (id) => {
  const { users } = store.getState();
  return users.data[id];
};

// this should usually be handled in state.js, but this allows usage in smaller helper functions
export const currentUserCompany = () => {
  const { profile } = store.getState();
  return profile?.company || {};
};
