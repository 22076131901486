const constants = {
  GET_FORMS_PENDING: 'GET_FORMS_PENDING',
  GET_FORMS_SUCCESS: 'GET_FORMS_SUCCESS',
  GET_FORMS_FAILURE: 'GET_FORMS_FAILURE',
  GET_FORM_PENDING: 'GET_FORM_PENDING',
  GET_FORM_SUCCESS: 'GET_FORM_SUCCESS',
  GET_FORM_FAILURE: 'GET_FORM_FAILURE',
  GET_FORM_EDIT_URL_PENDING: 'GET_FORM_EDIT_URL_PENDING',
  GET_FORM_EDIT_URL_SUCCESS: 'GET_FORM_EDIT_URL_SUCCESS',
  GET_FORM_EDIT_URL_FAILURE: 'GET_FORM_EDIT_URL_FAILURE',
  CREATE_FORM_PENDING: 'CREATE_FORM_PENDING',
  CREATE_FORM_SUCCESS: 'CREATE_FORM_SUCCESS',
  CREATE_FORM_FAILURE: 'CREATE_FORM_FAILURE',
  CREATE_FORM_EMBED_PENDING: 'CREATE_FORM_EMBED_PENDING',
  CREATE_FORM_EMBED_SUCCESS: 'CREATE_FORM_EMBED_SUCCESS',
  CREATE_FORM_EMBED_FAILURE: 'CREATE_FORM_EMBED_FAILURE',
  UPDATE_FORM_PENDING: 'UPDATE_FORM_PENDING',
  UPDATE_FORM_SUCCESS: 'UPDATE_FORM_SUCCESS',
  UPDATE_FORM_FAILURE: 'UPDATE_FORM_FAILURE',
  DELETE_FORM_PENDING: 'DELETE_FORM_PENDING',
  DELETE_FORM_SUCCESS: 'DELETE_FORM_SUCCESS',
  DELETE_FORM_FAILURE: 'DELETE_FORM_FAILURE',
};

export default constants;
