import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';
import { adaptToDetail } from 'adapters/v2/activities/formData';

import { getInbox } from 'store/v2/inbox/actions';
import { setThemeMode } from 'store/v2/themes/actions';
import actions from 'store/v2/activity/actions';

const stateBuilder = () => {
  const mapStateToProps = ({ activity, inbox, profile, themes }) => {
    const mappedActivities = Object.values(activity.data).reverse().map(adaptToDetail);

    return {
      activities: mappedActivities,
      inbox,
      loading: activity.loading,
      profile,
      theme: themes.mode,
      ...mapPermissionsToProps({ profile }),
      ...mapPreferencesToProps({ profile }),
    };
  };

  const mapDispatchToProps = {
    ...actions,
    getInbox,
    setThemeMode,
  };

  return {
    withFeatureState: (Component) => {
      return withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
    },
  };
};

export const { withFeatureState } = stateBuilder();
