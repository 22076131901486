import CONST from './constants';

export const initialState = {
  loaded: false,
  loading: false,
  type_eligibilities: [],
  type_investigations: [],
  type_accesses: [],
  type_agencies: [],
  type_polygraphs: [],
};

const index = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case CONST.GET_TYPES_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.GET_TYPES_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        loaded: true,
      };
    case CONST.GET_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default index;
