import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Select from 'react-select';
import Button from 'components/v1/Button';

import 'components/v1/Filters/styles.css';

const createAvailable = (filterOptions) => {
  const out = {};
  filterOptions.forEach((filter) => (out[filter.value] = filter));

  return out;
};

const Filters = ({ filterOptions = [], CustomButton }) => {
  const [addFilter, setAddFilter] = useState(false);
  const [active, setActive] = useState(null);

  const available = createAvailable(filterOptions);

  const onSelectFilter = (props) => {
    setActive(props.value);
  };

  const onCancel = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setActive(null);
  };

  const onCancelFilter = () => {
    setAddFilter(false);
  };

  const form = available[active];
  const formProps = { onClose: onCancel };

  return (
    <div className="Filters">
      <div className="Filters-menu">
        {!CustomButton && (
          <Button
            data-filter-button="true"
            css="padding-left: 0;"
            color="twilight"
            onClick={(e) => setAddFilter(true)}
            icon="PlusCircle"
            text="Add a Filter"
            transparent
          />
        )}

        {CustomButton && <CustomButton onClick={(e) => setAddFilter(true)} />}

        {addFilter && (
          <Select
            name="filter"
            autosize={true}
            className="Filters-menu-select"
            placeholder="Search filter"
            options={filterOptions}
            onChange={onSelectFilter}
            autoFocus={true}
            openOnFocus={true}
            onClose={onCancelFilter}
          />
        )}
        {form && <form.Component {...formProps} />}
      </div>
    </div>
  );
};

Filters.propTypes = {
  filterOptions: PropTypes.array.isRequired,
};

export default Filters;
