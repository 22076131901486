import React from 'react';
import { Suspense, lazy } from 'react';

import { withPermissionsAndPreferences } from 'lib/v1/hooks';

// config
import basePaths, { adminPaths } from 'config/paths/actionRequests';

import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch } from 'components/v2/PageElements/Routes';

// Components
const Index = lazy(() => import('./index'));
const AvailableRequestsIndex = lazy(() => import('./AvailableRequestsIndex'));
const Detail = lazy(() => import('./Detail'));

export const Routes = ({ paths, permissions, preferences }) => {
  paths = paths || basePaths;

  return (
    <ReduxManager data={['peopleDropdown', 'cardTemplatesDropdown', 'formTemplateActions']}>
      <Suspense fallback={<></>}>
        <Switch>
          <Route permission={preferences.actionRequests} exact path={paths.root}>
            <Index paths={paths} />
          </Route>
          <Route permission={preferences.actionRequests} exact path={adminPaths.root}>
            <Index paths={paths} />
          </Route>
          <Route permission={preferences.actionRequests} exact path={paths.create}>
            <AvailableRequestsIndex paths={paths} />
          </Route>
          <Route permission={preferences.actionRequests} exact path={paths.resource.detail(':id')}>
            <Detail paths={paths} />
          </Route>
          <Route permission={preferences.actionRequests} exact path={paths.resource.edit(':id')}>
            <Detail paths={paths} />
          </Route>
        </Switch>
      </Suspense>
    </ReduxManager>
  );
};

export default withPermissionsAndPreferences(Routes);
