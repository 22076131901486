import reducer, { initialState } from 'store/v2/index';
import actionType from './constants';

export default reducer(actionType, {
  ...initialState(),
  query: { ...initialState().query, filters: { assignment: [0] } },
  filters: {
    assignment: {
      values: [0],
      displayName: 'Assignment Status - Incomplete',
    },
  },
});
