import React from 'react';

// MUI
import ListItem from '@mui/material/ListItem';

const Index = ({ children, ...rest }) => {
  return (
    <ListItem disableGutters alignItems="flex-start" {...rest}>
      {children}
    </ListItem>
  );
};

export default Index;
