import actionBuilder, { list, create, update, destroy, show } from 'store/v2/actions';
import actionType, { bulkActionType, apiPath } from './constants';

// TODO: migrate actions over to use standard built actions
const actions = {
  ...actionBuilder({ apiPath, bulkActionType, actionType }),

  list: (queryParams) => {
    return list(actionType, apiPath, queryParams);
  },
  show: (id) => {
    return show(actionType, `${apiPath}/${id}`);
  },
  destroy: (id) => {
    return destroy(actionType, `${apiPath}/${id}`);
  },
  update: (id, payload, silent = false) => {
    return update(actionType, `${apiPath}/${id}`, { ...payload, silent });
  },
  create: (payload, silent = false) => {
    return create(actionType, apiPath, { ...payload, silent });
  },
  bulkCreate: (payload, silent = false) => {
    return create(bulkActionType, `${apiPath}/bulk/create`, { ...payload, silent });
  },
};

export default actions;
