import { isEmpty, isObject } from 'lib/v1/types';

export const visitorUsers = (visitor) => {
  if (!visitor) return {};
  const data = visitor;

  if (data.submitter && isObject(data.submitter) && !isEmpty(data.submitter)) {
    return data.submitter;
  }

  if (!data.outgoing_visitors) return {};

  if (!isEmpty(data.outgoing_visitors)) {
    return data.outgoing_visitors[0];
  }

  return {};
};
