import { Suspense, lazy } from 'react';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch, useRoutingFilters } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { withPermissionsAndPreferences } from 'lib/v1/hooks';

// Components
const Index = lazy(() => import('./index'));

export const Routes = ({ paths, permissions, preferences, location, updateQuery, userId }) => {
  useRoutingFilters({ location, updateQuery });
  const reduxData = ['tagNamesDropdown'];

  return (
    <ReduxManager data={reduxData}>
      <Suspense fallback={<></>}>
        <Switch>
          <Route permission={permissions.tags.list} exact path={paths.root}>
            <Index paths={paths} {...{ permissions, preferences, location, userId }} />
          </Route>
        </Switch>
      </Suspense>
    </ReduxManager>
  );
};

export default withPermissionsAndPreferences(Routes);
