import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapTableToPropsFactory, mergePropsToTable } from 'mappers/v1/table';

// actions
import actions from 'store/v2/userEligibilities/actions';

// adapters
import {
  cellLinkAdapter,
  cellAdapter,
  adaptToDetail,
} from 'adapters/v1/userEligibilities/formData';

export const stateBuilder = () => {
  const mapDataToTable = mapTableToPropsFactory('userEligibilities');

  const mapResourceToProps = ({ userEligibilities }, ownProps) => {
    const { match, resource } = ownProps;
    const resourceId = match?.params ? Number(match.params.id) : resource?.id;
    return { resourceId, resource: adaptToDetail(userEligibilities.data[resourceId]) };
  };

  const mapStateToProps = ({ profile, people, userEligibilities, ...rest }) => {
    const { activity, loading, data } = userEligibilities || {};

    return {
      activity: Object.values(activity || {}),
      // users: people.dropdown,
      userEligibilities: Object.values(data || {}).map(adaptToDetail),
      loading,
      profile,
      ...mapPermissionsToProps({ profile }),
    };
  };

  const include = {
    include: 'user,type_eligibility,type_investigation,investigated_by,granted_by',
  };
  const mapDispatchToProps = {
    ...actions,
    list: (queryParams) =>
      actions.list({
        ...queryParams,
        ...include,
      }),
    show: (id, payload, silent) => actions.show(id, { ...payload, ...include }, silent),
    create: (payload, silent) => actions.create({ ...payload, ...include }, silent),
    update: (id, payload, silent) => actions.update(id, { ...payload, ...include }, silent),
  };

  return {
    withTableState: (Component) => {
      return connect(
        ({ profile = {}, ...rest } = {}) => ({
          company: profile.company,
          ...mapPermissionsToProps({ profile }),
          ...mapDataToTable(rest),
        }),
        mapDispatchToProps,
        mergePropsToTable
      )((props) => (
        <Component {...props} cellLinkAdapter={cellLinkAdapter} cellAdapter={cellAdapter} />
      ));
    },
    withFeatureState: (Component) => {
      return connect(mapStateToProps, mapDispatchToProps)(withRouter(Component));
    },
    withResourceState: (Component) => {
      return withRouter(
        connect(
          (store, ownProps) => ({
            ...mapStateToProps(store),
            ...mapResourceToProps(store, ownProps),
          }),
          mapDispatchToProps
        )(Component)
      );
    },
  };
};

// by encapsulating all of our HOCs into a single function definition, I can cleanly swap out minimal state to reuse components across routes.
// This is literally a function, that returns an object, that contains 3 higher order components composed of 1 or more higher order components..
export const { withFeatureState, withResourceState, withTableState } = stateBuilder();
