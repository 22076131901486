import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import { cellAdapterFactory, cellLinkAdapterFactory } from 'adapters/v2/factories';
import { adaptToJsonApiRequest, adaptJsonApiToDetail } from 'adapters/v1/json_api';
import { adaptSelectToRequest } from 'adapters/v1/index';

const dateFields = ['grant_date', 'brief_date', 'debrief_date', 'created_at', 'updated_at'];

const singleSelectFields = ['contract_id', 'type_access_id'];
const whitelist = [...singleSelectFields, ...dateFields, 'user_id'];

export const adaptToRequest = (formData) => {
  const adapted = adaptToIso(formData, dateFields);
  const out = {};
  whitelist.forEach((key) => (out[key] = adapted[key]));

  singleSelectFields.forEach((key) => {
    out[key] = adaptSelectToRequest(out ? out[key] : {});
  });

  return adaptToJsonApiRequest({ attributes: out, type: 'user_access' });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const adapted = adaptJsonApiToDetail(resource);

  const { contract, type_access, facilities } = adapted;

  adapted.access = type_access?.display_name;
  adapted.contractDetails = [contract?.contract_name, contract?.contract_number];
  adapted.contractName = contract?.contract_name;
  adapted.briefDate = adapted.brief_date && new Date(adapted.brief_date);
  adapted.debriefDate = adapted.debrief_date && new Date(adapted.debrief_date);
  adapted.facilityNames = facilities?.map(({ display_name }) => display_name);

  return adaptToDisplay(adapted, dateFields);
};

export const adaptToForm = (resource) => {
  if (!resource) return {};
  const out = { ...resource };

  const { type_access, contract } = resource;

  if (type_access) {
    out.type_access_id = {
      title: type_access.display_name,
      label: parseInt(type_access.id),
    };
  }

  if (contract) {
    out.contract_id = {
      title: contract.contract_name,
      label: parseInt(contract.id),
    };
  }

  return out;
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
