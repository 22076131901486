import { toObject } from 'store/v2/index';

export const makeInitialState = () => {
  return {
    all: [],
    cache: {},
    data: [],
    v2: {},
    loading: false,
    meta: {},
    result: [],
  };
};

const initialState = makeInitialState();

// Combines arrays by ID
// NOTE: This is similar to lodash's _.union() and should be replaced at some point.
const union = (a, b) => {
  const temp = b.concat(a).reduce((obj, d) => {
    obj[d.id] = d;
    return obj;
  }, {});

  return Object.keys(temp).reduce((arr, key) => {
    arr.push(temp[key]);
    return arr;
  }, []);
};

export const getPending = (state) => {
  return {
    ...state,
    loading: true,
  };
};

export const getSuccess = (state, payload, meta) => {
  return {
    ...state,
    all: union(payload, state.all),
    data: payload,

    cache: {
      ...state.cache,
      [meta.hash]: payload,
    },
    meta,
    loading: false,
  };
};

export const getOneSuccess = (state, payload, meta) => {
  return {
    ...state,
    all: [payload, ...state.all],
    meta,
    loading: false,
  };
};

export const getAllSuccess = (state, payload) => {
  return {
    ...state,
    all: payload,
    v2: { ...state.v2, ...toObject(payload) },
    loading: false,
  };
};

export const createSuccess = (state, payload) => {
  return {
    ...state,
    loading: false,
    all: union([payload], state.all),
  };
};

export const getFailure = (state) => {
  return {
    ...state,
    loading: false,
  };
};

export const deleteSuccess = (state, payload) => {
  return {
    ...state,
    loading: false,
    data: state.data.filter((item) => item.id !== payload.id),
  };
};

const index = (name, reducerFunction) => {
  return (state = initialState, { type, payload, meta }) => {
    switch (type) {
      case `GET_${name}_PENDING`:
        return getPending(state);
      case `GET_${name}_SUCCESS`:
        return getSuccess(state, payload, meta);
      case `GET_ONE_${name}_PENDING`:
        return getPending(state);
      case `GET_ONE_${name}_SUCCESS`:
        return getOneSuccess(state, payload, meta);
      case `GET_ALL_${name}_PENDING`:
        return getPending(state);
      case `GET_ALL_${name}_SUCCESS`:
        return getAllSuccess(state, payload);
      case `CREATE_${name}_PENDING`:
        return getPending(state);
      case `CREATE_${name}_SUCCESS`:
        return createSuccess(state, payload);
      case `CREATE_${name}_FAILURE`:
        return getFailure(state);
      case `UPDATE_${name}_PENDING`:
        return getPending(state);
      case `UPDATE_${name}_SUCCESS`:
        return createSuccess(state, payload);
      case `UPDATE_${name}_FAILURE`:
        return getFailure(state);
      case `DELETE_${name}_PENDING`:
        return getPending(state);
      case `DELETE_${name}_SUCCESS`:
        return deleteSuccess(state, payload);
      default:
        return state;
    }
  };
};

export default index;
