import React from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import { useAppContext } from 'components/v2/AppContext';

// mui
import ListItemButton from './ListItems';
import ExpansionPanel from './ExpansionPanel';
import List from './List';

// icons
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VisibilityIcon from '@mui/icons-material/Visibility';

// paths
import { buildPaths, sharedRoots } from 'config/paths';

const useExpansionByLocation = ({ location, path }) => {
  const [expanded, setExpanded] = React.useState(false);
  const { pathname } = location;

  // FIX HOOK
  React.useEffect(() => {
    const matched = matchPath(pathname, path);
    setExpanded(matched ? true : false);
  }, [pathname]); // eslint-disable-line

  return { expanded };
};

export const Assignments = withRouter(({ history, location }) => {
  const {
    paths: { my },
  } = useAppContext();

  const { expanded } = useExpansionByLocation({ location, path: my.assignments.root });

  const onClick = (props) => {
    history.push(my.training.root);
  };

  return (
    <ExpansionPanel {...{ onClick, expanded, title: 'Assignments', Icon: <AssignmentIcon /> }}>
      <List>
        <ListItemButton text="Training" path={my.training.root} />
        <ListItemButton text="Digital Forms" path={my.forms.root} />
      </List>
    </ExpansionPanel>
  );
});

export const Settings = withRouter(({ location, history }) => {
  const {
    isAdminContext,
    paths: { admin, context },
  } = useAppContext();

  const { expanded } = useExpansionByLocation({ path: admin.settings.root, location });

  const onClick = (props) => {
    history.push(isAdminContext ? admin.account.root : context.preferences.root);
  };

  return (
    <ExpansionPanel {...{ onClick, expanded, title: 'Settings', Icon: <SettingsIcon /> }}>
      <List>
        {isAdminContext && <ListItemButton text="Account Details" path={admin.account.root} />}
        {isAdminContext && (
          <ListItemButton text="Custom Fields" path={admin['custom-fields'].root} />
        )}
        {/* <ListItemButton text="Preferences" path={context.preferences.root} /> */}
        {isAdminContext && <ListItemButton text="Roles and Permissions" path={admin.roles.root} />}
        {/* <ListItemButton text="Security" path={context.security.root} /> */}
      </List>
    </ExpansionPanel>
  );
});

export const Events = withRouter(({ history, location }) => {
  const {
    paths: { my },
  } = useAppContext();

  const { expanded } = useExpansionByLocation({ path: my.actions.root, location });

  const onClick = (props) => {
    history.push(my.visits.root);
  };

  return (
    <ExpansionPanel {...{ title: 'Actions', Icon: <DoubleArrowIcon />, expanded, onClick }}>
      <List>
        <ListItemButton text="Visits" path={my.visits.root} />
        <ListItemButton text="Foreign Travel" path={my['foreign-travel'].root} />
        <ListItemButton text="Reportable Info" path={my['reportable-info'].root} />
        <ListItemButton text="Action Requests" path={my['action-requests'].root} />
      </List>
    </ExpansionPanel>
  );
});

export const Organization = withRouter(({ location, history }) => {
  const {
    paths: { admin },
  } = useAppContext();

  const { expanded } = useExpansionByLocation({ location, path: admin.organization.root });

  const onClick = (props) => {
    history.push(admin.files.root);
  };

  return (
    <ExpansionPanel {...{ title: 'Organization', expanded, onClick }}>
      <List>
        <ListItemButton text="File Sharing" path={admin.files.root} />
        <ListItemButton text="Action Requests" path={admin['action-requests'].root} />
        <ListItemButton text="Digital Forms" path={admin.forms.root} />
        <ListItemButton text="Training" path={admin.training.root} />
      </List>
    </ExpansionPanel>
  );
});

export const Insights = withRouter(({ history, location }) => {
  const {
    paths: { admin },
  } = useAppContext();

  const { expanded } = useExpansionByLocation({ path: admin.insights.root, location });

  // Hmm, so any given expansion element needs a default location.
  const onClick = (props) => {
    history.push(admin.activity.root);
  };

  return (
    <ExpansionPanel {...{ onClick, title: 'Insights', Icon: <VisibilityIcon />, expanded }}>
      <List>
        <ListItemButton text="Audit Log" path={admin.activity.root} />
        {/* <ListItemButton text="Analytics" path={admin.analytics.root} /> */}
      </List>
    </ExpansionPanel>
  );
});

const ProfileList = withRouter(({ location }) => {
  const {
    isAdminContext,
    isMyContext,
    paths: { my, admin },
  } = useAppContext();

  const resourceRoot = isMyContext ? my.profile.resource.root : admin.people.resource.root;
  const { url } = matchPath(location.pathname, resourceRoot) || {};
  const userResourcePaths = buildPaths(url, sharedRoots);

  return (
    <List>
      <ListItemButton text="Accesses" path={userResourcePaths.accesses.root} />
      <ListItemButton text="Action Requests" path={userResourcePaths['action-requests'].root} />
      <ListItemButton text="Assets" path={userResourcePaths.assets.root} />
      <ListItemButton text="Badges" path={userResourcePaths.badges.root} />
      <ListItemButton text="Contracts" path={userResourcePaths.contracts.root} />
      <ListItemButton text="Digital Forms" path={userResourcePaths.forms.root} />
      <ListItemButton text="Eligibilities" path={userResourcePaths.eligibility.root} />
      <ListItemButton text="Facilities" path={userResourcePaths.facilities.root} />
      <ListItemButton text="Polygraphs" path={userResourcePaths.polygraphs.root} />
      {isAdminContext && <ListItemButton text="Profile" path={userResourcePaths.details.root} />}
      <ListItemButton text="Reports" path={userResourcePaths['reportable-info'].root} />
      {isMyContext && <ListItemButton text="Shared Files" path={userResourcePaths.files.root} />}
      <ListItemButton text="Training" path={userResourcePaths.training.root} />
      <ListItemButton text="Visits" path={userResourcePaths.visits.root} />
    </List>
  );
});

export const Profile = withRouter(({ location, title = 'Profile', history }) => {
  const [selected, setSelected] = React.useState(false);

  const {
    profile,
    paths: { my },
  } = useAppContext();

  const path = my.profile.resource.detail(profile.id);
  const { pathname } = location;
  const { expanded } = useExpansionByLocation({ location, path: my.profile.resource.root });

  const onClick = () => {
    history.push(path);
  };

  // FIX HOOK
  React.useEffect(() => {
    setSelected(pathname === path);
  }, [pathname]); // eslint-disable-line

  return (
    <ExpansionPanel {...{ onClick, selected, expanded, title, Icon: <AccountCircleIcon /> }}>
      <ProfileList />
    </ExpansionPanel>
  );
});

export const People = withRouter(({ title = 'People', history, location = {} }) => {
  const [selected, setSelected] = React.useState(false);

  const {
    paths: { admin },
  } = useAppContext();

  const { pathname } = location;

  const { expanded } = useExpansionByLocation({ location, path: admin.people.resource.root });

  const onClick = (props) => {
    history.push(admin.people.root);
  };

  // FIX HOOK
  React.useEffect(() => {
    setSelected(pathname === admin.people.root);
  }, [pathname]); // eslint-disable-line

  return (
    <ExpansionPanel {...{ onClick, expanded, title, selected, Icon: <PeopleIcon /> }}>
      <ProfileList />
    </ExpansionPanel>
  );
});
