import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';

import Icon from 'components/v1/Icon';
import { download } from 'store/v1/documents/actions';

const StyledWrapper = styled('div')({
  position: 'relative',
});

const StyledLink = styled('a')({
  paddingRight: '40px',
});

export function DownloadLink({ file, link, name, noDownload, text, ...rest }) {
  const handleClick = () => {
    if (link || noDownload) return;
  };

  const renderLink = () => {
    if (text) return text;
    if (link)
      return (
        <>
          {`${name} `}
          <Icon color="twilight" css="margin-left: 6px" name="ExternalLink" />
        </>
      );

    return name;
  };

  return (
    <StyledWrapper {...rest}>
      <StyledLink
        href={file && file.url ? file.url : link}
        onClick={handleClick}
        target="_blank"
        rel="noopener noreferrer"
        download={link ? null : name}
      >
        {renderLink()}
      </StyledLink>
    </StyledWrapper>
  );
}

export default connect(null, { download })(DownloadLink);
