import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// permissions
import featurePermissions from 'permissions/v1/imports';
import { mapPermissionsToProps } from 'mappers/v1/permissions';

//higher order components
import withResource from 'components/v2/withResource';

// paths
import paths from 'config/paths/imports';

// actions
import featureActions from 'store/v2/imports/actions';
import featureChildActions from 'store/v2/importUnits/actions';

import { adaptToTable } from 'adapters/v1/imports/formData';

const mapStepPathsToProps = () => {
  return {
    stepPaths: {
      upload: {
        previous: () => paths.root,
        next: paths.required.resource,
      },
      required: {
        previous: paths.upload.resource,
        next: paths.prepare.resource,
      },
      prepare: {
        previous: paths.required.resource,
        next: paths.finalize.resource,
      },
      finalize: {
        previous: paths.prepare.resource,
        next: () => paths.root,
      },
    },
  };
};

// store & config
export const mapPathsToProps = () => {
  return {
    paths,
  };
};

export const mapStateToProps = ({ profile, imports, importUnits }) => {
  return {
    data: imports,
    loading: imports.loading || importUnits.loading,
    rows: Object.values(imports.data || {}).map(adaptToTable),
    resourceData: importUnits,
    featurePermissions: featurePermissions(profile), // Note: This is a legacy pattern
    ...mapPermissionsToProps({ profile }),
    ...mapPathsToProps(),
    ...mapStepPathsToProps(),
  };
};

export const mapDispatchToProps = {
  ...featureActions,
  list: (queryParams) =>
    featureActions.list({
      ...queryParams,
      sort: queryParams && queryParams.sort ? queryParams.sort : 'created_at',
      dir: queryParams && queryParams.dir ? queryParams.dir : 'DESC',
    }),
  ...featureChildActions,
};

// factories

export const withIndexState = (Component) => {
  return connect(mapStateToProps, mapDispatchToProps)(withRouter(Component));
};

export const withResourceState = (Component) => {
  return connect(mapStateToProps, mapDispatchToProps)(withResource(Component));
};
