export const ui = (type, options = {}) => {
  // types: Checkbox, DatePicker, Select, SSN
  return { 'ui:widget': type, ...options };
};

// Helper function to create enum for react-jsonschema-form
export const makeEnum = (title, values = [], type = 'string', props) => {
  return {
    title,
    type,
    default: values.find((d) => d.default),
    enum: values.map((d) => d.value),
    enumNames: values.map((d) => d.label),
    ...props,
  };
};

export const buildSchema = (arr) => {
  const out = {};
  arr.forEach((obj) => {
    const { key } = obj;

    if (obj.view) {
      out[key] = obj.schema;
    }
  });

  return out;
};

const buildUiSchema = (arr) => {
  const out = {};
  arr.forEach((obj) => {
    let temp = {};
    const { key } = obj;

    if (obj.ui) {
      temp = { ...obj.ui };
    }

    out[key] = { ...temp, hidden: !obj.edit };
  });

  return out;
};

export const adaptToForm = (arr) => {
  return {
    schema: {
      type: 'object',
      required: arr.filter((obj) => obj.required).map((obj) => obj.key),
      properties: buildSchema(arr),
    },
    uiSchema: buildUiSchema(arr),
  };
};
