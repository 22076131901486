import React from 'react';
import { connect } from 'react-redux';
import { useProfileContext } from 'components/v2/Profile/Context';
import { adaptToDetail as adaptTemplateToDetail } from 'adapters/v1/digitalFormTemplates/formData';

/// FormElements
import TswForm, { useDetailForm, useRequiredFields } from 'components/v2/FormElements';
import SavePrompt from 'components/v2/FormElements/SavePrompt';
import DialogButtons from 'components/v2/FormElements/Dialog/Buttons';
import KeyboardDatePicker from 'components/v2/FormElements/KeyboardDatePicker';

// material
import List from '@mui/material/List';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from 'components/v2/FormElements/Tooltip';
import Typography from '@mui/material/Typography';

//components
import ListItem from 'components/v2/FormElements/ListItem';
import {
  UsersAutocomplete,
  DigitalFormTemplatesAutocomplete,
} from 'components/v2/FormElements/Autocomplete';

// adapter
import { adaptToForm } from 'adapters/v1/digitalFormRequests/formData';

const Form = ({
  afterCancel,
  callback,
  debug = false,
  digitalFormTemplates = {},
  disabled = false,
  isBulk,
  isDialog = false,
  loading = false,
  profile = {},
  resource = {},
  templateId,
}) => {
  const { userId } = useProfileContext();

  // handle dynamic required fields
  const [signerRoles, setSignerRoles] = React.useState([{ name: 'Name', order: 1 }]);

  const dueDate = ['due_date'];

  const requiredFields = isBulk
    ? dueDate
    : dueDate.concat(
        signerRoles.filter((obj) => obj && obj.name).map((obj) => `user_id.${obj.name}`)
      );

  if (!templateId) requiredFields.push('template_id');

  const validate = useRequiredFields(requiredFields);
  const keyName = `user_id.${signerRoles[0].name}`;

  const profileId = profile && profile.id;
  const userPrependOptions = [{ label: profileId, title: 'Myself' }];

  // User Context
  const userDefaults = !!userId ? { [keyName]: { label: userId, title: 'Profile User' } } : {};

  const defaults = {
    signer_roles: signerRoles,
    send_notification_email: true,
    due_date: new Date(Date.now() + 12096e5),
    ...userDefaults,
  };

  const adapted = adaptToForm(resource);

  const {
    handleSubmit,
    handleCancel,
    getTextFieldHandlerProps,
    getRequiredTextProps,
    getSelectHandlerProps,
    getSwitchHandlerProps,
    formData,
    setFormData,
    formEditMode,
  } = useDetailForm({
    defaults,
    validate,
    resource: adapted,
    afterCancel,
    callback,
  });

  const handleSignerRoles = (selectValue = {}) => {
    const out = { signer_roles: [] };
    if (!selectValue || !selectValue.label) return {};

    const template_id = selectValue.label;
    const template = digitalFormTemplates[template_id];
    if (!template) return out;

    const adapted = adaptTemplateToDetail(template);

    if (adapted && adapted.signer_roles) {
      out.signer_roles = adapted.signer_roles;
      setSignerRoles(adapted.signer_roles);
    }

    return out;
  };

  // FIX HOOK
  React.useEffect(() => {
    let payload = { ...formData, ...handleSignerRoles(formData.template_id) };

    if (!formData.template_id) {
      payload = defaults;
    }

    setFormData(payload);
  }, [formData.template_id]); // eslint-disable-line

  // FIX HOOK
  React.useEffect(() => {
    let payload = { ...formData, ...userDefaults };

    setFormData(payload);
  }, [signerRoles]); // eslint-disable-line

  const singleSigner = signerRoles.length === 1;

  return (
    <TswForm>
      {debug && JSON.stringify(formData)}
      <List>
        {!templateId && (
          <ListItem>
            <DigitalFormTemplatesAutocomplete
              {...getSelectHandlerProps('template_id')}
              textFieldProps={{ ...getRequiredTextProps('template_id') }}
              disabled={disabled}
              getOptionDisabled={(option) => option.type === 'Multi-Signer Form' && isBulk}
              groupBy={(option) => option.type}
              label="Digital Form"
              multiple={false}
              value={formData.template_id || {}}
            />
            {isBulk && (
              <Typography color="gray" variant="body2">
                * Multi-sign forms are not available in bulk assignment, please assign in User
                Profile or Admin.
              </Typography>
            )}
          </ListItem>
        )}
        {formData.template_id && (
          <>
            {formData.signer_roles.map((obj, i) => {
              const role = obj.name;
              const prefix = 'user_id';
              const key = `${prefix}.${role}`;

              let label = `${role} Signer`;

              const hideProfileSigner = userId && singleSigner;
              const disableProfileFirstSigner = userId && i === 0;

              const showBulkFirstSigner = isBulk && singleSigner && i === 0;
              const disableBulkFirstSigner = isBulk && i === 0;

              const disableSlot = disableBulkFirstSigner || disableProfileFirstSigner;
              const showBulkTip = disableBulkFirstSigner;
              const showProfileTip = disableProfileFirstSigner;

              return (
                !(showBulkFirstSigner || hideProfileSigner) && (
                  <ListItem key={`role-${role}`}>
                    <UsersAutocomplete
                      {...getSelectHandlerProps(key)}
                      textFieldProps={{ ...getRequiredTextProps(key) }}
                      disabled={disabled || disableSlot}
                      groupByLabel={'All Users'}
                      label={label}
                      multiple={false}
                      prependOptions={userPrependOptions}
                      skipList={true}
                      value={formData[key] || {}}
                    />
                    {showBulkTip && (
                      <Typography color="gray" variant="body2">
                        * Selected users will each be set as the 'Primary Signer' and sent a
                        separate form.
                      </Typography>
                    )}
                    {showProfileTip && (
                      <Typography color="gray" variant="body2">
                        * Profile user will be set as the 'Primary Signer' on this form.
                      </Typography>
                    )}
                  </ListItem>
                )
              );
            })}
          </>
        )}
        {(!!userId || (!userId && formData.template_id)) && (
          <>
            <ListItem>
              <KeyboardDatePicker
                {...getTextFieldHandlerProps('due_date')}
                {...getRequiredTextProps('due_date')}
                label="Due Date"
                disabled={disabled}
                placeholder=""
              />
            </ListItem>
            <ListItem>
              <Tooltip
                title={
                  formData.send_notification_email
                    ? 'Send email notification immediately'
                    : 'Allow users to be notified via weekly email digest'
                }
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      {...getSwitchHandlerProps('send_notification_email')}
                      disabled={disabled}
                    />
                  }
                  label={'Send Email Notification'}
                />
              </Tooltip>
            </ListItem>
          </>
        )}
      </List>

      {!isDialog && !disabled && formEditMode && (
        <SavePrompt onCancel={handleCancel} loading={loading} onClick={handleSubmit} />
      )}

      {isDialog && (
        <DialogButtons
          onCancel={handleCancel}
          loading={loading}
          onClick={handleSubmit}
          disabled={disabled}
        />
      )}
    </TswForm>
  );
};

const mapStateToProps = ({ digitalFormTemplates: { dropdown }, profile }) => {
  const mapped = {};

  Object.entries(dropdown).forEach(([key, value]) => {
    mapped[value.attributes.template_id] = value;
  });

  return { digitalFormTemplates: mapped, profile };
};

export default connect(mapStateToProps)(Form);
