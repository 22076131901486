import React from 'react';

const Presentation = ({ width = '64px', height = '64px', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 64 64" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61,6H35V3c0-1.657-1.343-3-3-3c-1.657,0-3,1.343-3,3v3H3C1.343,6,0,7.343,0,9
      c0,1.657,1.343,3,3,3h3v34c0,1.657,1.343,3,3,3h15.757l-9.879,9.879C14.336,59.422,14,60.172,14,61c0,1.657,1.343,3,3,3
      c0.828,0,1.579-0.336,2.121-0.879L29,53.243V61c0,1.657,1.343,3,3,3c1.657,0,3-1.343,3-3v-7.757l9.879,9.879
      C45.422,63.664,46.172,64,47,64c1.657,0,3-1.343,3-3c0-0.828-0.336-1.578-0.879-2.121L39.243,49H55c1.657,0,3-1.343,3-3V12h3
      c1.657,0,3-1.343,3-3C64,7.343,62.657,6,61,6z M52,43H12V12h40V43z"
    />
  </svg>
);

export default Presentation;
