import React from 'react';
import { connect } from 'react-redux';
import PropTypes, { oneOfType } from 'prop-types';
import { withRouter } from 'react-router-dom';

// Shared
import PageHeader from 'components/v2/PageElements/Header';
import Button from 'components/v2/FormElements/Button';
import BackButton from 'components/v2/PageElements/BackButton';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PublishIcon from '@mui/icons-material/Publish';
import { useQueryString } from 'components/v2/PageElements';

import importPaths from 'config/paths/imports';

import { getReportNotDumb as getReport } from 'store/v1/reports/actions';

const HeaderLayout = ({
  title = 'Header Title',
  icon,
  updatedIcon,
  allowCreate,
  buttonText = 'Create New',
  buttonIcon,
  onClick = () => {},
  backPath = undefined,
  backText,
  history,
  subtitle,
  children,
  importType,
  exportType,
  query,
  getReport,
  extraButtons = [],
  preferHistoryGoBack = false,
  userContextPathFunc,
}) => {
  const queryString = useQueryString({ history });
  const [loading, setLoading] = React.useState(false);

  const handleBackButtonClick = (event) => {
    if (event) event.preventDefault();
    if (!history) return;

    // make sure there is history to go back to. A new tab and pasted URL will give a length of 2
    if (preferHistoryGoBack && history.length > 2) {
      return history.goBack();
    }

    if (userContextPathFunc && queryString.userId) {
      backPath = userContextPathFunc(queryString.userId);
    }

    history.push(backPath);
  };

  const createProps = {
    color: 'tableAction',
    variant: 'outlined',
    startIcon: buttonIcon || <AddIcon />,
    onClick,
    buttonText,
  };

  const baseExtraButtonProps = {
    color: 'tableAction',
    variant: 'text',
    style: { marginRight: 10 },
  };

  const importCsvProps = {
    ...baseExtraButtonProps,
    startIcon: <PublishIcon />,
    onClick: () => history.push(`${importPaths.create}=${importType}`),
    text: 'Import CSV',
  };

  const exportOnClick = async () => {
    setLoading(true);
    await getReport(exportType, query);
    setLoading(false);
  };

  const exportCsvProps = {
    color: 'tableAction',
    variant: 'text',
    startIcon: <FileDownloadIcon />,
    onClick: exportOnClick,
    text: 'Export CSV',
    disabled: loading,
    style: { marginRight: 10 },
  };

  return (
    <>
      {backPath && <BackButton onClick={handleBackButtonClick} text={backText} />}
      <PageHeader
        title={title}
        icon={icon}
        alt={title}
        subtitle={subtitle}
        updatedIcon={updatedIcon}
      >
        {extraButtons.map(({ text, ...rest }, index) => (
          <Button key={`header-button-${index}`} {...baseExtraButtonProps} {...rest}>
            {text}
          </Button>
        ))}

        {importType && <Button {...importCsvProps}>Import CSV</Button>}
        {exportType && query && <Button {...exportCsvProps}>Export CSV</Button>}
        {allowCreate && (
          <Button data-create-button="true" {...createProps}>
            {buttonText}
          </Button>
        )}
      </PageHeader>
      {children}
    </>
  );
};

HeaderLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: oneOfType([PropTypes.string, PropTypes.object]),
  allowCreate: PropTypes.bool,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  backPath: PropTypes.string,
};

export default connect(null, { getReport })(withRouter(HeaderLayout));
