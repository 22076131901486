import React from 'react';
import { connect } from 'react-redux';

import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

import {
  ConnectedFormRequests as FormRequests,
  Incident,
  Message,
  ConnectedTrainings as TrainingAssignments,
  VisitRequests,
  ConnectedForeignTravels as ForeignTravels,
  TasksDue,
  ConnectedAssetAudits as AssetAudits,
  ConnectedActionRequests as ActionRequests,
  DraftTickets,
} from './FeedTypes';

import INCIDENT_TYPES from './config';

const InboxFeed = ({ isEmployee, profileId, feedItems, onRefresh }) => {
  const [activeItem, setActiveItem] = React.useState(0);

  const formatItem = (item, index) => {
    const props = {
      active: index === activeItem,
      data: item.data,
      onToggleCard: () => setActiveItem(index),
      isEmployee,
      onRefresh,
    };

    switch (item.type) {
      case 'incident':
        return <Incident incidentType={INCIDENT_TYPES[item.data.kind]} {...props} key={index} />;
      case 'card_template':
        return <ActionRequests requestType={item.data.kind} {...props} key={index} />;
      case 'draft_cards':
        return <DraftTickets profileId={profileId} {...props} key={index} />;
      case 'message':
        return <Message {...props} key={index} />;
      case 'training_assignments':
        return <TrainingAssignments {...props} key={index} />;
      case 'signature_requests':
        return <FormRequests {...props} key={index} />;
      case 'visitor_requests':
        return <VisitRequests {...props} key={index} />;
      case 'foreign_travel':
        return <ForeignTravels {...props} key={index} />;
      case 'certificate_audits':
        return <AssetAudits profileId={profileId} {...props} key={index} />;
      case 'tasks_due':
        return <TasksDue profileId={profileId} {...props} key={index} />;
      default:
        return <Message {...props} key={index} />;
    }
  };

  return <>{feedItems.map(formatItem)}</>;
};

export default connect(({ profile }) => ({
  ...mapPreferencesToProps({ profile }),
  ...mapPermissionsToProps({ profile }),
}))(InboxFeed);
