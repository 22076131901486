import actionBuilder, { list } from 'store/v2/actions';
import actionType, { bulkActionType, apiPath } from './constants';

const adminItems = (payload, silent = false) => {
  return list(actionType, `${apiPath}/admin`, { ...payload, silent });
};

const employeeItems = (payload, silent = false) => {
  return list(actionType, `${apiPath}/employee`, { ...payload, silent });
};

const actions = {
  ...actionBuilder({ apiPath, bulkActionType, actionType }),
  adminItems,
  employeeItems,
};

export default actions;
