import CONST from './constants';

const initialState = {
  loaded: false,
  loading: false,
  countries: [],
};

const process = function (payload) {
  if (payload && payload.data) {
    try {
      return Object.keys(payload.data)
        .reduce((arr, k) => {
          if (k !== 'COUNTRY_ISO_CODE') {
            arr.push({ value: k, label: payload.data[k] });
          }
          return arr;
        }, [])
        .sort((a, b) => {
          // force US to the top
          if (a.value === 'US') return -1;
          if (b.value === 'US') return 1;

          // Sort by label
          const textA = a.label.toUpperCase();
          const textB = b.label.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }
};

const index = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case CONST.GET_COUNTRIES_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: process(payload),
        loading: false,
        loaded: true,
      };
    case CONST.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default index;
