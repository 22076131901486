import React from 'react';
import { Link } from 'react-router-dom';

// Shared
import peoplePaths from 'config/paths/people';
import { DetailPath } from 'components/v2/Email/Routes';

// Components
import Avatar from 'components/v2/PageElements/Avatar';

// MUI
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

export const subjectItemLink = ({ subject_id, subject }) => {
  if (subject_id) {
    const path = DetailPath({
      match: {
        params: {
          id: subject_id,
          module: subject.replace(' ', '_'),
        },
      },
    });
    if (path && !path.includes('inbox')) return path;
  }
};

const InboxActivity = ({ activities = {}, loading }) => {
  return (
    <>
      <Typography variant="h4" sx={{ pb: 0.9 }}>
        Activity
      </Typography>

      {loading && <LinearProgress />}

      {activities.length > 0 && (
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
            opacity: loading ? '0.3' : '1',
          }}
        >
          {activities.map((item, index) => {
            const user = item.user || {};
            const isEmployee = user.is_employee;
            const itemLink = subjectItemLink(item);
            const action = item.display_action;
            const validLink = itemLink && !itemLink.includes('inbox') && action !== 'deleted';

            return (
              <ListItem disableGutters>
                <ListItemAvatar sx={{ mr: 0 }}>
                  <Avatar lg profile={user} sx={{ mr: 0 }} />
                </ListItemAvatar>
                <ListItemText
                  primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                  primary={
                    <>
                      <Link to={peoplePaths.resource.detail(user.id)}>{user.name}</Link>
                      &nbsp;{item.display_action}&nbsp;
                      {isEmployee && `${item.display_name}`}
                      {!isEmployee && validLink ? (
                        <Link to={itemLink}>{`${item.display_name}`}</Link>
                      ) : (
                        `${item.display_name}`
                      )}
                    </>
                  }
                  secondary={
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="caption"
                      color="text.primary"
                    >
                      {item.action_date}
                    </Typography>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      )}
    </>
  );
};

export default InboxActivity;
