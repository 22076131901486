import { pathBuilder } from 'config/paths/index';

export const root = '/workflows';
const basePaths = pathBuilder(root);

const resourceRoot = (id) => {
  return `${root}/${id}`;
};

const board = (id) => {
  return `${resourceRoot(id)}/board`;
};

const ticket = (id) => {
  return `${resourceRoot(id)}/ticket`;
};

const paths = {
  ...basePaths,
  resource: {
    ...basePaths.resource,
    board,
    ticket,
  },
};

export default paths;
