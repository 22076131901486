import React from 'react';
import { withFeatureState } from './state';
import Dialog from './Dialog';
import { adaptToRequest } from 'adapters/v1/documents/formData';

export const Create = ({ onClose, create, ...rest }) => {
  const callback = async (formData) => {
    if (!formData || !create) return;

    const adapted = adaptToRequest(formData);
    const resp = await create(adapted);

    if (!resp.success) return;
    onClose();
  };

  const ownProps = {
    title: 'Upload a New File',
    onClose,
    callback,
  };
  return <Dialog {...ownProps} {...rest} />;
};

export default withFeatureState(Create);
