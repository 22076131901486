import React from 'react';
import withFeatureState from './state';

import { useAppContext } from 'components/v2/AppContext';
// mui
import List from './List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// icons
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DashboardIcon from '@mui/icons-material/Dashboard';

// paths
import { appBasePath, adminBasePath } from 'config/paths';
import { Organization, People, Profile, Settings, Insights } from './Sections';

const Component = ({ classes, history, ...rest }) => {
  const { isAdminContext, isMyContext } = useAppContext();

  return (
    <>
      <List>
        <ListItem
          className={classes.singleMenuItem}
          button
          {...{ onClick: () => history.push(isAdminContext ? adminBasePath : appBasePath) }}
        >
          <ListItemIcon>{isAdminContext ? <DashboardIcon /> : <InboxIcon />}</ListItemIcon>
          <ListItemText primary={isAdminContext ? 'Dashboard' : 'Home'} />
        </ListItem>
      </List>
      {isAdminContext && <People />}
      {isMyContext && <Profile />}
      {isAdminContext && <Organization />}
      {isAdminContext && <Insights />}

      <Divider className={classes.divider} />

      <List className={classes.loweredButtons}>
        <Divider className={classes.divider} />
        <Settings />
      </List>
    </>
  );
};

export default withFeatureState(Component);
