import React from 'react';
import { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import RouteV2 from 'components/v2/PageElements/Routes';
import { ThreatSwitchRoute } from 'routes';
import { connect } from 'react-redux';

// mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

// Layouts
import DashboardLayout from 'layouts/v1/DashboardLayout';
import AppContentLayout from 'layouts/v2/AppContent';

// TODO: Evaluate these
import Inbox from 'components/v2/Inbox';

// Legacy
import AdminRoutes from './AdminRoutes';

// paths
import analyticsPaths from 'config/paths/analytics';

// Modern Routes
import ActionRequests from 'components/v2/ActionRequests/Routes';
import AnalyticsRoutes from 'components/v2/Analytics/Routes';
import InboxV2Routes from 'components/v2/InboxV2/Routes';
import PeopleRoutes from 'components/v2/People/Routes';
import ReportablesRoutes from 'components/v2/Reportables/Routes';
import ReportableTemplatesRoutes from 'components/v2/ReportableTemplates/Routes';
import TaskRoutes from 'components/v2/Tasks/Routes';
import TicketRoutes from 'components/v2/Tickets/Routes';
import TrainingRoutes from 'components/v2/Training/Routes';
import Travels from 'components/v2/Travels/Routes';
import VisitRoutes from 'components/v2/VisitRequests/Routes';
import WorkflowRoutes from 'components/v2/WorkflowControls/Routes';

// Modern paths
import actionRequestsPaths from 'config/paths/actionRequests';
import inboxV2Paths from 'config/paths/inboxV2';
import peoplePaths from 'config/paths/people';
import reportablesV2Paths from 'config/paths/reportablesV2';
import reportableTemplatesPaths from 'config/paths/reportableTemplates';
import taskPaths from 'config/paths/tasks';
import ticketPaths from 'config/paths/tickets';
import trainingPaths from 'config/paths/training';
import travelsPaths from 'config/paths/travels';
import visitRequestPaths from 'config/paths/visitRequests';
import workflowControlPaths from 'config/paths/workflowControls';

// The entire notion of "role-based" routing is legacy.  We should use permissions to define all levels of access
const NonEmployeeRoutes = ({ permissions, preferences }) => {
  return (
    <DashboardLayout>
      <Suspense fallback={<></>}>
        <Switch>
          {/* Visits V2*/}
          <RouteV2 path={visitRequestPaths.root} permission={true}>
            <AppContentLayout>
              <VisitRoutes />
            </AppContentLayout>
          </RouteV2>

          {/* People V2*/}
          <RouteV2 path={peoplePaths.root} permission={true}>
            <AppContentLayout>
              <PeopleRoutes />
            </AppContentLayout>
          </RouteV2>

          {/* Training V2*/}
          <RouteV2 path={trainingPaths.root} permission={permissions.training.list}>
            <TrainingRoutes />
          </RouteV2>

          {/* Tasks */}
          <RouteV2 path={taskPaths.root} permission={true}>
            <AppContentLayout>
              <TaskRoutes />
            </AppContentLayout>
          </RouteV2>

          {/* Tickets */}
          <RouteV2 path={[ticketPaths.root]} permission={true}>
            <AppContentLayout>
              <TicketRoutes />
            </AppContentLayout>
          </RouteV2>

          {/* Workflows V2*/}
          <RouteV2 path={[workflowControlPaths.root, ticketPaths.root]} permission={true}>
            <AppContentLayout>
              <WorkflowRoutes />
            </AppContentLayout>
          </RouteV2>

          {/* Administrative Routes */}
          <ThreatSwitchRoute
            path="/admin"
            component={AdminRoutes}
            access={permissions.admin.list}
          />

          {/* Reports */}
          <ThreatSwitchRoute path={analyticsPaths.root} layout={AppContentLayout}>
            <AnalyticsRoutes />
          </ThreatSwitchRoute>

          {/* Inbox */}
          <ThreatSwitchRoute exact path="/inbox" component={Inbox} />

          {/* Inbox V2 */}
          <RouteV2 path={[inboxV2Paths.root]} permission={preferences.betaTester}>
            <AppContentLayout>
              <InboxV2Routes />
            </AppContentLayout>
          </RouteV2>

          <RouteV2 permission={permissions.travels.list} path={travelsPaths.root}>
            <AppContentLayout>
              <Travels basePath={travelsPaths.root} />
            </AppContentLayout>
          </RouteV2>

          <RouteV2 permission={permissions.actionRequests.list} path={actionRequestsPaths.root}>
            <AppContentLayout>
              <ActionRequests basePath={actionRequestsPaths.root} />
            </AppContentLayout>
          </RouteV2>

          {/* Reportables V2 */}
          <ThreatSwitchRoute path={reportablesV2Paths.root}>
            <AppContentLayout>
              <ReportablesRoutes />
            </AppContentLayout>
          </ThreatSwitchRoute>

          {/* ReportableTemplates */}
          <ThreatSwitchRoute path={reportableTemplatesPaths.root}>
            <AppContentLayout>
              <ReportableTemplatesRoutes />
            </AppContentLayout>
          </ThreatSwitchRoute>

          {/* <RouteV2 permission={canListVisits} path={visitRequestsPaths.root}>
            <VisitRequests />
          </RouteV2> */}

          {/* <RouteV2 exact path={notifyRoot} redirect={defaultUrl()} /> */}
        </Switch>
      </Suspense>
    </DashboardLayout>
  );
};

const mapStateToProps = ({ profile }) => {
  return {
    ...mapPermissionsToProps({ profile }),
    ...mapPreferencesToProps({ profile }),
  };
};

export default connect(mapStateToProps, {})(NonEmployeeRoutes);
