import React, { useState, useEffect } from 'react';
import { useAppContext } from 'components/v2/AppContext';
import { getUserDetails } from 'lib/v1/users';
import { getFacilityDetails } from 'lib/v1/facilities';

// material-ui
import { makeStyles } from '@mui/styles';
import { createFilterOptions } from '@mui/material/Autocomplete';

import hash from 'object-hash';

export const ConstrainedWidth = '60rem';

const useStyles = makeStyles(() => ({
  constrained: {
    maxWidth: ConstrainedWidth,
  },
  max: { maxWidth: '100%' },
}));

export const useDisabled = ({ condition, dependencies = [] }) => {
  const [disabled, setDisabled] = React.useState(true);

  // FIX HOOK
  React.useEffect(() => {
    if (condition) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, dependencies); // eslint-disable-line

  return { disabled, setDisabled };
};

export const useRequiredFields = (required = []) => {
  return (formData) => {
    const errors = {};
    required.forEach((fieldName) => {
      // No errors by default
      errors[fieldName] = false;

      const value = formData[fieldName];
      const isArray = Array.isArray(value);
      //const isObject = !!value && !value.length

      if (!value) errors[fieldName] = 'required';
      else if (isArray && value.length === 0) errors[fieldName] = 'required';
    });

    return errors;
  };
};

export const useNestedRequiredFields = (required = []) => {
  return (formData) => {
    const errors = {};
    required.forEach((fieldName) => {
      const nested = fieldName.split('.');

      if (!nested || nested.length !== 2) return errors;

      const [parentKey, childKey] = nested;

      errors[parentKey] = { [childKey]: false };

      const value = formData[parentKey];
      if (!value) errors[parentKey][childKey] = 'required';
      else if (value && !value[childKey]) errors[parentKey][childKey] = 'required';
    });

    return errors;
  };
};

export const useForm = ({
  afterCancel,
  afterSubmit,
  callback,
  defaults = {},
  documentsFieldName = 'documents',
  resource = {},
  validate,
} = {}) => {
  const [formData, setFormData] = useState(defaults);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialState, setInitialState] = useState(resource);
  const [errors, setErrors] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  // Sets initial state of formData and saves
  // a reference to the original resource (if any)
  // to reset original state on cancel
  // FIX HOOK
  useEffect(() => {
    setInitialState(resource);
    setFormData({ ...defaults, ...resource });
    setTimeout(function () {
      setIsLoaded(true);
    }, 50);
  }, []); // eslint-disable-line

  const handleCallback = async () => {
    if (
      callback &&
      isSubmitting &&
      Object.values(errors).every((value) => value !== true && typeof value !== 'string')
    ) {
      const success = await callback(formData);

      if (success === true) {
        setInitialState(formData);
      }

      if (afterSubmit) afterSubmit();
    }
    setIsSubmitting(false);
  };

  // FIX HOOK
  useEffect(() => {
    handleCallback();
  }, [errors, isSubmitting]); // eslint-disable-line

  // Rest formData values to initial state
  const handleCancel = (event) => {
    if (event) event.preventDefault();

    setFormData(initialState);
    setErrors({});
    if (!afterCancel) return;
    afterCancel();
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();

    if (validate) {
      setErrors(validate(formData));
    }

    setIsSubmitting(true);
  };

  const handleSwitch = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.checked });
  };

  const handleChange = (event, value) => {
    if (event?.persist) event.persist();
    setFormData((formData) => ({ ...formData, [event.target.name]: event.target.value }));
  };

  // Convenience methods for passing props for various elements
  // I think the real value of hooks is abstracting props rather than
  // creating libraries of specialized components
  const getSwitchHandlerProps = (name) => {
    return { color: 'primary', onChange: handleSwitch, name, checked: formData[name] };
  };

  const getTextFieldHandlerProps = (name) => {
    const value = formData[name] || '';
    const helperText = typeof errors[name] == 'string' ? errors[name] : '';
    return { onChange: handleChange, name, value, helperText };
  };

  const getNestedSelectHandlerProps = (name) => {
    const nested = name.split('.');
    if (!nested || nested.length !== 2) return {};

    const [parentKey, childKey] = nested;

    const payload = {};

    return {
      name,
      onChange: (event, value) => {
        if (formData[parentKey]) {
          payload[parentKey] = { ...formData[parentKey], [childKey]: value };
        } else {
          payload[parentKey] = { [childKey]: value };
        }

        setFormData({ ...formData, ...payload });
      },
    };
  };

  // Set select handler for Autocomplete derived components
  const getSelectHandlerProps = (name) => {
    return {
      name,
      onChange: (event, value) => {
        setFormData({ ...formData, [name]: value });
      },
    };
  };

  const getCustomFieldSelectHandlerProps = ({ name, type }) => {
    const baseProps = getSelectHandlerProps(name);
    let value = formData[name] || null;

    // this will also be called for a new value being set and the
    // value will be an object so we can just return it
    if (!value || value.label) return { ...baseProps, value };

    if (type === 'general_select') {
      value = { label: formData[name], title: formData[name] };
    } else if (type === 'user_select') {
      const user = getUserDetails(value);
      value = { label: value, title: user && user.full_name };
    } else if (type === 'facility_select') {
      const facility = getFacilityDetails(value);
      value = { label: value, title: facility && facility.attributes.display_name };
    }

    return { ...baseProps, value };
  };

  const setUploadedDocument = ({ name, id, url }) => {
    let data = { ...formData };
    data[documentsFieldName] = [
      ...(formData[documentsFieldName] || []),
      { title: name, label: id, url },
    ];
    setFormData(data);
  };

  const getInlineCreateSelectHandlerProps = (name) => {
    const filterOptions = (options, params) => {
      const filtered = createFilterOptions()(options, params);
      const exists = options.map((obj) => (obj && obj.title ? obj.title.toLowerCase() : obj.title));
      const createCondition =
        params.inputValue !== '' && !exists.includes(params.inputValue.toLowerCase());

      if (createCondition) {
        filtered.push({
          label: params.inputValue,
          title: `Add "${params.inputValue}"`,
          created: true,
        });
      }

      return filtered;
    };

    return {
      name,
      placeHolder: 'Type to add new options',
      noOptionsText: 'Type to add new options',
      selectOnFocus: true,
      clearOnBlur: true,
      filterOptions: filterOptions,
      onChange: (event, value) => {
        const updatedValues =
          value && value.filter ? value.filter((v) => typeof v !== 'string') : value;

        // This handles to multi false case where we just want the value to appear in the dropdown
        if (updatedValues && updatedValues.label) {
          updatedValues.title = updatedValues.label;
        }

        setFormData({ ...formData, [name]: updatedValues });
      },
    };
  };

  const getJsonSchemaProps = ({ name, type }) => {
    switch (type) {
      case 'checkbox':
        return { ...getSwitchHandlerProps(name) };
      case 'general_select':
      case 'user_select':
      case 'facility_select':
        return { ...getCustomFieldSelectHandlerProps({ name, type }) };
      case 'datetime':
      case 'string':
      case 'text':
      case 'integer':
      case 'time':
      default:
        return { ...getTextFieldHandlerProps(name) };
    }
  };

  const getRequiredTextProps = (name, label) => {
    // This just maps 1:1 to the expected props for MUI text field
    //https://material-ui.com/api/text-field//

    // We set formData based on the event.target.name, so that is what we pass into the form.
    // This corresponds to the key of an http request body. "i.e. id === user_id" or "id === company_id"
    return { error: errors[name], required: true };
  };

  // Necessary for triggers on formData changes
  // You cannot use an object as a dependency in a useEffect() hook, it will cause an infinite loop.
  // If you need trigger updates based on changes to the form data state you must use
  // a hashed value as the dependency.
  const stateId = hash({ formData });
  const errorStateId = hash({ errors });

  const setCustomErrors = (obj) => {
    setErrors({ errors, ...obj });
  };

  return {
    handleChange,
    handleSubmit,
    handleCancel,
    handleSwitch,
    getSelectHandlerProps,
    getNestedSelectHandlerProps,
    getJsonSchemaProps,
    getInlineCreateSelectHandlerProps,
    getSwitchHandlerProps,
    getTextFieldHandlerProps,
    getRequiredTextProps,
    setUploadedDocument,
    formData,
    setFormData,
    setInitialState,
    isLoaded,
    stateId,
    errorStateId,
    errors,
    setCustomErrors,
  };
};

export const useDetailForm = ({ callback, afterCancel, ...useFormProps } = {}) => {
  const [formEditMode, setFormEditMode] = useState(false);
  const [currentStateId, setCurrentStateId] = useState();

  const onCancel = () => {
    setCurrentStateId(null);
    setFormEditMode(false);
    afterCancel && afterCancel();
  };

  const onSubmit = async (formData) => {
    if (!callback) return;
    const success = await callback(formData);
    setFormEditMode(false);
    return success;
  };

  const { stateId, isLoaded, ...rest } = useForm({
    afterCancel: onCancel,
    callback: onSubmit,
    ...useFormProps,
  });

  // FIX HOOK
  useEffect(() => {
    if (!currentStateId) {
      if (isLoaded) setCurrentStateId(stateId);
    } else {
      if (isLoaded && !formEditMode) setFormEditMode(true);
    }
  }, [stateId, isLoaded]); // eslint-disable-line

  return {
    formEditMode,
    setFormEditMode,
    ...rest,
  };
};

const Index = ({ variant, children, ...rest }) => {
  const { v2 } = useAppContext();

  const classes = useStyles();
  // Default to variant, in v2 default ix max, in v1 it is constrained
  const className = variant ? variant : v2 ? 'max' : 'constrained';

  return (
    <form noValidate autoComplete="off" className={classes[className]} {...rest}>
      {children}
    </form>
  );
};

export default Index;
