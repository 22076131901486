import React from 'react';
import PropTypes from 'prop-types';

import { lightGray, charcoal, lighterGray } from 'lib/v2/colors';

// material-core
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from './Button';

// matches form width from: components/v2/FormElements/index.js
const MAX_WIDTH = '45rem';

const useStyles = makeStyles({
  spacer: {
    height: '3.5rem',
  },
  container: {
    display: 'flex',
    position: 'fixed',
    justifyContent: 'space-between',
    bottom: 0,
    left: '18rem',
    right: '6rem',
    maxWidth: MAX_WIDTH,
    padding: '1rem',
    background: lighterGray,
    border: '1px solid',
    borderBottom: 'none',
    borderColor: lightGray,
    borderRadius: '5px 5px 0 0',
    boxShadow: '0px 10px 8px rgba(0, 0, 0, 0.25)',
    zIndex: 999,
  },
  message: {
    color: charcoal,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: '1rem',
    },
  },
  submitButton: {
    padding: '0 2rem',
  },
  cancelButton: {
    color: charcoal,
  },
});

const SavePrompt = ({
  disabled = false,
  onCancel,
  onClick,
  cancelText = 'Cancel',
  loading = false,
  clickText = 'Save',
  sidebar = false,
  ...rest
}) => {
  const classes = useStyles(rest);

  return (
    <>
      {!sidebar && <Box className={classes.spacer} />}
      <Box className={classes.container}>
        <Typography variant="body1" className={classes.message}>
          You have unsaved changes
        </Typography>

        <Box className={classes.buttons}>
          <Button
            type="button"
            variant="text"
            onClick={onCancel || onClick}
            className={classes.cancelButton}
          >
            {cancelText}
          </Button>

          <Button
            disabled={disabled || loading}
            variant="contained"
            color="primary"
            loading={loading}
            onClick={onClick}
            className={classes.submitButton}
          >
            {clickText}
          </Button>
        </Box>
      </Box>
    </>
  );
};

SavePrompt.propTypes = {
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  clickText: PropTypes.string,
  cancelText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SavePrompt;
