import React from 'react';
import passwordStrength from 'zxcvbn';

import { makeStyles } from '@mui/styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';

import ListItem from 'components/v2/FormElements/ListItem';

const scoreLabels = ['Weak', 'Average', 'Good', 'Strong', 'Very Strong'];

const useStyles = makeStyles((theme) => ({
  passwordHint: {
    top: '1.25rem',
    right: 75,
    position: 'absolute',
    zIndex: 1,
  },
  tooshort: {
    color: 'red',
  },
  weak: {
    color: 'orange',
  },
  average: {
    color: '#555',
  },
  good: {
    color: 'green',
  },
  strong: {
    color: 'green',
  },
  verystrong: {
    color: 'green',
  },
}));

const helpItems = [
  'Longer than 8 characters',
  'Unique to Sign In Compliance',
  'Hard for a stranger to guess',
];

const renderHelpListItem = (item) => {
  return (
    <ListItem dense key={`rule-${item}`} style={{ display: 'flex' }}>
      <ListItemIcon sx={{ minWidth: 0 }}>
        <ArrowRightIcon />
      </ListItemIcon>
      <ListItemText secondary={item} />
    </ListItem>
  );
};

const Index = ({ password, password_confirmation }) => {
  const [score, setScore] = React.useState();

  const classes = useStyles();

  const passwordsCheck = () => {
    if (password && password.length <= 8) {
      return { password: 'password must be longer than 8 characters' };
    }

    if (score < 2) {
      return { password: 'password must be above average strength.' };
    }

    if (password && password !== password_confirmation) {
      return { password_confirmation: 'passwords must match' };
    }
  };

  const passwordHelperText = () => {
    if (password && password.length <= 8) {
      return 'Too Short';
    }

    return scoreLabels[score];
  };

  const passwordClass = () => {
    const helperText = passwordHelperText();
    const adaptedText = helperText && helperText.toLowerCase().replace(/\s+/g, '');
    return `${classes.passwordHint} ${classes[adaptedText]}`;
  };

  const PasswordHelper = () => {
    return (
      <Typography variant="caption" className={passwordClass()}>
        {passwordHelperText()}
      </Typography>
    );
  };

  const PasswordRules = () => {
    return (
      <List>
        <ListItem>
          <ListItemText primary="Your password should be:" />
        </ListItem>
        {helpItems.map(renderHelpListItem)}
      </List>
    );
  };

  React.useEffect(() => {
    if (password && password.length > 8) {
      setScore(passwordStrength(password).score);
    } else {
      setScore(null);
    }
  }, [password]);

  return {
    PasswordHelper,
    PasswordRules,
    passwordsCheck,
    classes,
  };
};

export default Index;
