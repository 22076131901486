import React from 'react';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';

import ConfirmModal from 'components/v2/FormElements/ConfirmModal';
import { useDialog } from 'components/v2/FormElements/Dialog';

const StyledGridToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '55px',
  right: 0,
  height: '55px',
  backgroundColor: theme.palette.appbar.border,
  zIndex: 2,
  padding: 0,
}));

export const BatchActionsContainer = ({ children }) => {
  return <StyledGridToolbarContainer>{children}</StyledGridToolbarContainer>;
};

export const DeleteAction = ({ bulkDestroy, list, clearSelection, selectedIds, type }) => {
  const { onClick, open, onClose } = useDialog();

  const onConfirm = async () => {
    if (!selectedIds || !selectedIds.length) return;
    if (!bulkDestroy) return;

    const payload = { data: { type, ids: selectedIds } };
    await bulkDestroy(payload);
    await list();
    clearSelection && clearSelection();
  };

  const modalProps = {
    title: 'Delete Types',
    isOpen: open,
    onConfirm,
    onClose,
    message: `Are you sure you want to delete the ${selectedIds.length} selected items. This action cannot be undone.`,
  };

  return (
    <>
      <ConfirmModal {...modalProps} />
      <Button size="medium" startIcon={<DeleteIcon />} color="primary" onClick={onClick}>
        Delete
      </Button>
    </>
  );
};

export const BatchActionItemFactory = ({ title, message, icon, onConfirm }) => {
  const { onClick, open, onClose } = useDialog();

  const modalProps = {
    title,
    isOpen: open,
    onConfirm,
    onClose,
    message,
  };

  return (
    <>
      <ConfirmModal {...modalProps} />
      <Button size="medium" startIcon={icon} color="primary" onClick={onClick}>
        {title}
      </Button>
    </>
  );
};
