import React from 'react';
import moment from 'moment';

// material ui
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import TextField from 'components/v2/FormElements/TextField';

const Index = ({
  value = null,
  onChange,
  name,
  disabled,
  error,
  helperText,
  required,
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = React.useState();

  // TODO: make sure this is best way to do this, basically otherwise the initial load
  // of the component with an empty value persists.
  if (value && !selectedDate) setSelectedDate(moment(value));

  const handleDateChange = (date, dateString) => {
    setSelectedDate(date);
    if (onChange) {
      // don't love this... can't seem to get an normal input change event from this component
      const event = {
        persist: () => {},
        target: {
          name,
          value: date,
        },
      };
      onChange(event);
    }
  };

  const datePickerProps = {
    autoOk: true,
    name,
    value: selectedDate,
    onChange: handleDateChange,
    slots: {
      textField: TextField,
    },
    slotProps: {
      textField: {
        error,
        helperText,
        required,
      },
    },
    disabled,
  };

  if (disabled) datePickerProps.keyboardIcon = null;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        disableToolbar
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        {...datePickerProps}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default Index;
