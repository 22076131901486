import CONST from './constants';

const initialState = {
  data: {},
  loaded: false,
  loading: false,
};

const index = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case CONST.GET_VERSION_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.GET_VERSION_SUCCESS:
      return {
        ...state,
        data: payload,
        loading: false,
        loaded: true,
      };
    case CONST.GET_VERSION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default index;
