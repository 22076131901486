import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

// material-core
import Button from './Button';
import { StyledDeleteButton } from 'components/v2/FormElements/Dialog/Buttons';

// mui icons
import CheckIcon from '@mui/icons-material/Check';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from '@mui/material/CircularProgress';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

export const SubmitButton = ({
  onClick,
  disabled,
  loading,
  children,
  Icon = CheckIcon,
  ...rest
}) => {
  const history = useHistory();
  const location = useLocation();

  const clearCreateQueryParam = () => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('create')) {
      queryParams.delete('create');
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  const handleClick = () => {
    clearCreateQueryParam();
    onClick && onClick();
  };

  return (
    <Button
      data-submit-button="true"
      color="primary"
      disabled={disabled || loading}
      variant="contained"
      onClick={handleClick}
      startIcon={loading ? <CircularProgress size="1.25rem" /> : <Icon />}
      {...rest}
    >
      {children}
    </Button>
  );
};

export const CancelButton = ({ disabled, loading, children, onClick, ...rest }) => {
  const history = useHistory();
  const location = useLocation();

  const clearCreateQueryParam = () => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('create')) {
      queryParams.delete('create');
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  const handleClick = () => {
    clearCreateQueryParam();
    onClick && onClick();
  };

  return (
    <Button
      data-cancel-button="true"
      color="secondary"
      disabled={loading}
      onClick={handleClick}
      // startIcon={<HighlightOffIcon />}
      {...rest}
    >
      {children}
    </Button>
  );
};

export const ApprovalButtons = ({
  disabled = false,
  loading = false,
  approveText = 'Approve',
  onApprove,
  denyText = 'Deny',
  onDeny,
  cancelText = 'Cancel',
  onCancel,
}) => {
  return (
    <>
      {onCancel && (
        <StyledDeleteButton
          type="button"
          loading={loading}
          disabled={disabled}
          onClick={onCancel}
          variant="text"
        >
          {cancelText}
        </StyledDeleteButton>
      )}
      <SubmitButton
        disabled={disabled}
        loading={loading}
        onClick={onDeny}
        Icon={DoDisturbIcon}
        color={'error'}
        variant="text"
      >
        {denyText}
      </SubmitButton>
      <SubmitButton
        disabled={disabled}
        loading={loading}
        onClick={onApprove}
        color={'success'}
        variant="text"
      >
        {approveText}
      </SubmitButton>
    </>
  );
};

const Buttons = ({
  disabled = false,
  onCancel,
  onClick,
  cancelText = 'Cancel',
  loading = false,
  clickText = 'Save',
  Icon,
}) => {
  return (
    <>
      {onCancel && (
        <CancelButton
          type="button"
          loading={loading}
          disabled={disabled}
          onClick={onCancel}
          variant="text"
        >
          {cancelText}
        </CancelButton>
      )}
      <SubmitButton disabled={disabled} loading={loading} onClick={onClick} Icon={Icon}>
        {clickText}
      </SubmitButton>
    </>
  );
};

Buttons.propTypes = {
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
  clickText: PropTypes.string,
  cancelText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Buttons;
