import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

const BasicAccordion = ({ title, children, description, ...rest }) => {
  return (
    <Accordion sx={{ boxShadow: 'none' }} {...rest}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="subtitle2">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2" display="block" gutterBottom>
          {description || children}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default BasicAccordion;
