import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import { cellAdapterFactory, cellLinkAdapterFactory } from 'adapters/v2/factories';
import { adaptToJsonApiRequest, adaptJsonApiToDetail } from 'adapters/v1/json_api';
import { adaptSingleSelectFieldsToRequest } from 'adapters/v1';

const dateFields = ['updated_at', 'created_at'];
const singleSelectFields = [];

export const jsonApiType = 'tag_name';

export const adaptToRequest = (formData) => {
  const adapted = {
    ...adaptToIso(formData, dateFields),
    ...adaptSingleSelectFieldsToRequest({ formData, singleSelectFields }),
  };

  const attributes = { ...adapted };

  return adaptToJsonApiRequest({
    attributes,
    type: jsonApiType,
  });
};

export const adaptToBulkCreateRequest = (tags) => {
  const data = tags.map((tag) => {
    return {
      attributes: { name: tag },
      type: 'tag_name',
    };
  });

  return { data };
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  return adaptToDisplay(adaptJsonApiToDetail({ ...resource }), dateFields);
};

export const adaptToForm = (resource) => {
  if (!resource) return {};
  return { ...resource };
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
