import { isAnyAdmin } from 'permissions/v1/roles';
import { canView, canCreate, canUpdate, canDestroy } from 'permissions/v1/index';

const permission = 'tag_names';

const view = (profile) => isAnyAdmin(profile) || canView(permission, profile);
const create = (profile) => isAnyAdmin(profile) || canCreate(permission, profile);
const update = (profile) => isAnyAdmin(profile) || canUpdate(permission, profile);
const destroy = (profile) => isAnyAdmin(profile) || canDestroy(permission, profile);

const permissions = (profile) => {
  return {
    list: true,
    view: view(profile),
    create: create(profile),
    update: update(profile),
    destroy: destroy(profile),
  };
};

export default permissions;
