import { hasClientCredentials } from 'permissions/v1/preferences';
import { isAdmin } from 'permissions/v1/roles';
import { canList, canView, canCreate, canUpdate, canDestroy } from 'permissions/v1/index';

const permission = 'SecretKey';

const list = (profile) =>
  hasClientCredentials(profile) && (isAdmin(profile) || canList(permission, profile));
const view = (profile) =>
  hasClientCredentials(profile) && (isAdmin(profile) || canView(permission, profile));
const create = (profile) =>
  hasClientCredentials(profile) && (isAdmin(profile) || canCreate(permission, profile));
const update = (profile) =>
  hasClientCredentials(profile) && (isAdmin(profile) || canUpdate(permission, profile));
const destroy = (profile) =>
  hasClientCredentials(profile) && (isAdmin(profile) || canDestroy(permission, profile));

const permissions = (profile) => {
  return {
    list: list(profile),
    view: view(profile),
    create: create(profile),
    update: update(profile),
    destroy: destroy(profile),
  };
};

export default permissions;
