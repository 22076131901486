import React from 'react';
import { connect } from 'react-redux';

import usePasswordChecks from 'lib/v1/passwords';

// Actions
import { requestCodeByEmail, resendCode, verifyCode } from 'store/v1/security/actions';
import { update } from 'store/v1/authentication/actions';
import { add_message } from 'store/v1/app/actions';

// mui
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { useForm } from 'components/v2/FormElements';
import TextField from 'components/v2/FormElements/TextField';
import { SubmitButton, CancelButton } from 'components/v2/FormElements/Buttons';

const WellContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.backgroundBasic.main,
  padding: '0 1rem',
  marginBottom: '1rem',
}));

const Form = ({
  accountEmail,
  add_message,
  resetPasswordMode,
  authConfig,
  handleCancel,
  setLoading,
  loading,
  updatePassword,
}) => {
  const [otpRequired, setOtpRequired] = React.useState(authConfig.onboarding || resetPasswordMode);
  const { getTextFieldHandlerProps, getRequiredTextProps, formData, setCustomErrors } = useForm({
    resource: { email: accountEmail, verified: false },
  });

  const { PasswordHelper, passwordsCheck, PasswordRules } = usePasswordChecks(formData);

  const showPasswordFields = !loading && !otpRequired;

  const rateLimitErrorMessage = () =>
    add_message('warning', ['Too many attempts, please try again later.']);

  const handleResend = async () => {
    resendCode(accountEmail)
      .then(() => {
        add_message('success', [`Confirmation code was resent to ${accountEmail}.`]);
      })
      .catch((error) => {
        rateLimitErrorMessage();
      });
  };

  const submitOtp = async () => {
    const { validation_code } = formData;

    verifyCode(validation_code, accountEmail)
      .then(() => setOtpRequired(false))
      .catch((e) => {
        const isRateLimit = e.response?.status === 429;
        if (isRateLimit) {
          rateLimitErrorMessage();
          setCustomErrors({ validation_code: null });
        } else setCustomErrors({ validation_code: 'Incorrect code. Please try again' });
      });
  };

  const submitNewPassword = async () => {
    const { password, password_confirmation } = formData;

    const errors = passwordsCheck({ password, password_confirmation });
    if (errors) return setCustomErrors(errors);

    await updatePassword({ password, password_confirmation });
  };

  // if otp required, send code to entered email
  // FIX HOOK
  React.useEffect(() => {
    if (!otpRequired) return;

    setLoading(true);

    requestCodeByEmail(accountEmail)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        rateLimitErrorMessage();
      });
  }, []); // eslint-disable-line

  return (
    <>
      <Typography variant="h5" align="center" style={{ marginBottom: '3rem', padding: 0 }}>
        {otpRequired
          ? 'Check your email for a confirmation code'
          : 'Create and confirm your new password'}
      </Typography>
      <>
        <WellContainer>{showPasswordFields && <PasswordRules />}</WellContainer>

        {!loading && otpRequired && (
          <TextField
            {...getTextFieldHandlerProps('validation_code')}
            {...getRequiredTextProps('validation_code')}
            label="Confirmation Code"
            autoComplete="one-time-code"
          />
        )}
        {showPasswordFields && (
          <>
            <div style={{ position: 'relative' }}>
              <PasswordHelper />
              <TextField
                password
                {...getTextFieldHandlerProps('password')}
                {...getRequiredTextProps('password')}
                label="New Password"
                autoComplete="new-password"
              />
            </div>
            <TextField
              password
              {...getTextFieldHandlerProps('password_confirmation')}
              {...getRequiredTextProps('password_confirmation')}
              label="Verify New Password"
              autoComplete="new-password"
              style={{ marginTop: '1rem' }}
            />
          </>
        )}
        {showPasswordFields && (
          <SubmitButton
            onClick={submitNewPassword}
            fullWidth
            startIcon={null}
            style={{ fontSize: 20, marginTop: '1rem' }}
          >
            Set Password
          </SubmitButton>
        )}

        {!loading && otpRequired && (
          <SubmitButton
            onClick={submitOtp}
            fullWidth
            startIcon={null}
            style={{ fontSize: 20, marginTop: '1rem' }}
          >
            Submit Code
          </SubmitButton>
        )}
        {!loading && (
          <>
            {/* {showPasswordFields && (
              <CancelButton fullWidth onClick={() => setPasswordMode(false)} variant="text">
                Cancel
              </CancelButton>
            )} */}
            <Stack direction="row" sx={{ mt: 2 }}>
              {otpRequired && (
                <CancelButton fullWidth onClick={handleResend} variant="text">
                  Resend Code
                </CancelButton>
              )}

              <CancelButton fullWidth onClick={handleCancel} variant="text">
                {resetPasswordMode ? 'Cancel' : 'Change account'}
              </CancelButton>
            </Stack>
          </>
        )}
      </>
    </>
  );
};

export default connect(null, { updatePassword: update, add_message })(Form);
