import React from 'react';
import Avatar from '@mui/material/Avatar';

import FileIcon from 'assets/images/file.svg';

const styles = ({ size = 36, xl, lg, md, sm, xs, evenMargins, rightAlign }) => {
  const margin = evenMargins ? -2 : 8;

  if (xl) size = 44;
  if (lg) size = 40;
  if (md) size = 36;
  if (sm) size = 30;
  if (xs) size = 24;

  let defaultAvatarStyles = {
    marginRight: margin,
    width: size,
    height: size,
  };

  if (rightAlign) {
    defaultAvatarStyles = {
      ...defaultAvatarStyles,
      marginRight: 0,
      marginLeft: margin,
    };
  }

  if (sm) {
    defaultAvatarStyles = {
      ...defaultAvatarStyles,
      fontSize: '1rem',
    };
  }

  if (xs) {
    defaultAvatarStyles = {
      ...defaultAvatarStyles,
      fontSize: '0.8rem',
    };
  }

  return { defaultAvatarStyles };
};

const getInitials = (name) => {
  if (!name) return '';

  const names = name.split(' ');
  const initials = names.map((n) => n.charAt(0)).join('');

  return initials;
};

export const ProfileAvatar = ({ profile = {}, style, ...styleOptions } = {}) => {
  const { defaultAvatarStyles } = styles(styleOptions);

  const customAvatar =
    (profile.attributes && profile.attributes.avatar_url) ||
    profile.avatar_url ||
    (profile.avatar && profile.avatar.url);

  const fullName = (profile.attributes && profile.attributes.name) || profile.name || '';
  const initials = getInitials(fullName);

  return (
    <Avatar alt={fullName} src={customAvatar} style={{ ...defaultAvatarStyles, ...style }}>
      {initials}
    </Avatar>
  );
};

export const DocumentAvatar = ({ document = {}, style, ...styleOptions } = {}) => {
  const { defaultAvatarStyles } = styles(styleOptions);

  return <Avatar src={FileIcon} alt="file icon" style={{ ...defaultAvatarStyles, ...style }} />;
};

export default ProfileAvatar;
