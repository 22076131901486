import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Box)(({ theme }) => ({
  // background: `${theme.palette.backgroundBasic.main} url(${Bg}) no-repeat 89% 4% fixed`,
  background: `${theme.palette.backgroundBasic.main} no-repeat 50% 0% fixed`,
  backgroundSize: '85%',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
}));

const BasicLayout = ({ children }) => {
  return (
    <>
      <StyledContainer />
      {children}
    </>
  );
};

export default BasicLayout;
