import React from 'react';

// mappers
import { withTableState } from './state';

import { useAppContext } from 'components/v2/AppContext';

// FlexTable API
import Search from 'components/v2/FlexTable/Search';
import TableAPI from 'components/v2/FlexTable/Table';
import Pagination from 'components/v2/FlexTable/Pagination';
import AddFilter from 'components/v2/Filters';
import ActiveFilters from 'components/v2/Filters/ActiveFilters';

import filters from './Filters';
import { HistoryItem, ExpandableHistoryItem } from 'components/v2/PageElements/History';

const HistoryCell = ({ adapted }) => {
  const historyItemProps = {
    action: adapted.event,
    date: adapted.created_at,
    object_changes: adapted.object_changes,
  };

  if (adapted.user) {
    historyItemProps.user = adapted.user;
    historyItemProps.name = adapted.user.name;
  }

  const Component =
    Object.entries(adapted.object_changes).length === 0 ? HistoryItem : ExpandableHistoryItem;

  return <Component {...historyItemProps} />;
};

// Generic mechanism for handling link throughs for Audit Log events
const useContextPaths = () => {
  const { v2, paths } = useAppContext();
  if (!v2) return null;

  const { my } = paths || {};
  if (!my) return null;

  return ({ item_type, item_id }) => {
    switch (item_type) {
      case 'Visitor':
        return my.visits.resource.detail(item_id);
      default:
        return null;
    }
  };
};

export const Table = ({
  list,
  children,
  permissions,
  updateQuery,
  paths,
  cellAdapter,
  cellLinkAdapter,
  resourceName = 'Audit',
  ...rest
}) => {
  const contextPaths = useContextPaths();

  const filterOptions = () => ({ filterOptions: filters.map((option) => option({ updateQuery })) });

  const rowActions = () => {
    if (!permissions) return [];

    const actions = [];

    return {
      rowActions: actions.filter((action) => action.permission).map((action) => action.action),
    };
  };

  const columns = () => {
    if (!permissions) return [];

    const data = [
      {
        Header: 'Record',
        accessor: 'item_type',
        Cell: contextPaths ? cellLinkAdapter('item_type', contextPaths) : cellAdapter('item_type'),
        permission: true,
      },
      {
        Header: 'Event ',
        accessor: 'event',
        Cell: cellAdapter('event', HistoryCell),
        permission: true,
      },
      {
        Header: 'Event Time',
        accessor: 'created_at',
        Cell: cellAdapter('created_at'),
        permission: true,
      },
    ];

    return { columns: data.filter((column) => column.permission) };
  };

  const listActionProps = () => {
    return { list };
  };

  return (
    <>
      <Search {...rest} />
      <AddFilter {...rest} {...filterOptions()} />
      <ActiveFilters {...rest} />
      <TableAPI {...columns()} {...rowActions()} {...listActionProps()} {...rest} />
      <Pagination {...rest} {...listActionProps()} />
    </>
  );
};

export default withTableState(Table);
