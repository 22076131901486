import { ByUser, ByMeta, ByTag } from 'components/v2/Filters/MultiSelect';
import { BuildDateRangeFilter } from 'components/v2/Filters/DateRange';
import MultiSelectFilter from 'components/v2/Filters/MultiSelect';

export const ByStatus = ({ fieldName = 'status', updateQuery }) => {
  return ByMeta({
    fieldName,
    displayName: 'Status',
    updateQuery,
    storeKey: 'digitalFormRequests',
    metaKey: 'status',
  });
};

export const ByTemplate = ({
  fieldName = 'template_id',
  displayName = 'Template',
  updateQuery,
}) => {
  const mapStateToSelect = ({ digitalFormTemplates }) => {
    const { loading, dropdown } = digitalFormTemplates;

    return {
      options: Object.values(dropdown).map((obj) => {
        const { attributes } = obj;
        return { value: attributes.template_id, label: attributes.display_name };
      }),
      isLoading: loading,
      disabled: loading,
    };
  };

  const multiSelectProps = {
    name: fieldName,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelectFilter {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
  };
};

export const ByDueDate = BuildDateRangeFilter({ name: 'due_date', displayName: 'Due Date' });

const filters = [ByUser, ByDueDate, ByTag, ByStatus, ByTemplate];

export default filters;
