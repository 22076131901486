import React from 'react';

// mappers
import { withTableState } from './state';

// FlexTable API
import TableAPI from 'components/v2/FlexTable/Table';
import Pagination from 'components/v2/FlexTable/Pagination';
import SearchLayout from 'components/v2/FlexTable/SearchLayout';

import filters from './Filters';
import {
  TagDigitalFormTemplate as TagRowAction,
  ConnectedDelete as Delete,
  ConnectedRefresh as Refresh,
} from './RowActions';

import { Tag as TagBatchAction } from './BatchActions';

import { chipCellAdapter } from 'adapters/v1/digitalFormTemplates/formData';

export const Table = ({
  list,
  children,
  permissions,
  updateQuery,
  paths,
  cellAdapter,
  cellLinkAdapter,
  resourceName = 'Digital Form Template',
  ...rest
}) => {
  const filterOptions = () => ({ filterOptions: filters.map((option) => option({ updateQuery })) });

  const rowActions = () => {
    if (!permissions) return [];

    const actions = [
      { action: TagRowAction, permission: permissions.digitalFormTemplates.update },
      { action: Refresh, permission: permissions.digitalFormTemplates.view },
      { action: Delete, permission: permissions.digitalFormTemplates.destroy },
    ];

    return {
      rowActions: actions.filter((action) => action.permission).map((action) => action.action),
    };
  };

  const batchActions = () => {
    if (!permissions) return [];

    const actions = [
      { action: TagBatchAction, permission: permissions.digitalFormTemplates.update },
    ];

    return {
      batchActions: actions.filter((action) => action.permission).map((action) => action.action),
    };
  };

  const columns = () => {
    if (!permissions) return [];

    const data = [
      {
        Header: 'Name',
        accessor: 'title',
        Cell: cellLinkAdapter('title', ({ id, status }) => {
          if (status !== 'Available') return null;
          return paths.resource.detail(id);
        }),
        permission: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: cellAdapter('status'),
        permission: true,
      },
      {
        Header: 'Date Created',
        accessor: 'created_at',
        Cell: cellAdapter('created_at'),
        permission: true,
      },
      {
        Header: 'Tags',
        accessor: 'tag_array',
        Cell: chipCellAdapter('tag_array'),
        disableSortBy: true,
        permission: true,
      },
    ];

    return { columns: data.filter((column) => column.permission) };
  };

  const listActionProps = () => {
    return { list };
  };

  return (
    <>
      <SearchLayout filters={filterOptions()} {...rest} />
      <TableAPI
        {...columns()}
        {...rowActions()}
        {...batchActions()}
        {...listActionProps()}
        {...rest}
      />
      <Pagination {...rest} {...listActionProps()} />
    </>
  );
};

export default withTableState(Table);
