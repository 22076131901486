import React from 'react';

// TEMPLATE TODO: update header icon
import HeaderIcon from 'assets/images/action_request.svg';

// Layouts
import HeaderLayout from 'layouts/v2/HeaderLayout';

// factories
import { withFeatureState } from './state';

// Components
import Table from './Table';

export const Index = ({ children, ...rest }) => {
  const headerProps = {
    title: 'Audit Log',
    icon: HeaderIcon,
    allowCreate: false,
  };

  return (
    <HeaderLayout {...headerProps} {...rest}>
      {children}
    </HeaderLayout>
  );
};

const WrappedIndex = withFeatureState(Index);

const WithTable = (props) => {
  return (
    <WrappedIndex {...props}>
      <Table />
    </WrappedIndex>
  );
};

export default WithTable;
