import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch, useRoutingFilters } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths from 'config/paths/travels';

// Components
const Index = lazy(() => import('./index'));
const Table = lazy(() => import('./Table'));
const Detail = lazy(() => import('./Detail'));
const Settings = lazy(() => import('./Settings'));

// in user context, the profile paths will be passed in and override the default paths
export const Routes = ({ paths, permissions, location, updateQuery }) => {
  useRoutingFilters({ location, updateQuery });

  const reduxData = [
    'peopleDropdown',
    'countries',
    'travelsDropdown',
    'digitalFormTemplatesDropdown',
  ];

  return (
    <ReduxManager data={reduxData}>
      <Suspense fallback={<></>}>
        <Switch>
          <Route exact permission={permissions.travels.list} path={paths.root}>
            <Index {...{ permissions, location }}>
              <Table />
            </Index>
          </Route>
          <Route exact permission={permissions.travels.list} path={paths.resource.detail(':id')}>
            <Detail />
          </Route>

          <Route exact permission={permissions.travels.manage} path={paths.settings}>
            <Settings />
          </Route>
        </Switch>
      </Suspense>
    </ReduxManager>
  );
};

export default connect(mapPermissionsToProps, null)((props) => <Routes paths={paths} {...props} />);
