import React from 'react';
import { Link, Text } from 'components/v2/FlexTable/Cells';
import Tooltip from 'components/v2/FormElements/Tooltip';
import ProgressBar from 'components/v2/PageElements/ProgressBar';
import Chip from 'components/v2/FormElements/Chip';

// MUI
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// paths
import facilityPaths from 'config/paths/facilities';
import peoplePaths from 'config/paths/people';
import containersPaths from 'config/paths/containerControls';
import { trainingCoursePaths } from 'config/paths/training';
import contractsPaths from 'config/paths/contracts';
import { IconCell } from 'components/v2/FlexTable/Cells';

const useStyles = makeStyles((theme) => ({
  comboCell: {
    fontSize: '0.7rem',
    lineHeight: '0.7rem',
    fontWeight: '350',
    marginTop: '-0.1rem',
    paddingBottom: '0.3rem',
  },
}));

const UnviewedLayout = ({ children, adapted = {} }) => {
  const showIcon = adapted && adapted.viewed !== undefined ? !adapted.viewed : false;
  const tooltipText = showIcon ? 'This record has not been viewed by the Security Team.' : '';

  return <IconCell {...{ showIcon, tooltipText }}>{children}</IconCell>;
};

// Note: The cellAdapterFactory defines a generic API
// that will pass the object and value to any Component
// that is passed in, allowing easy customization of any given
// cell with full access to the display adapted values and object metadata.
export const cellAdapterFactory = (adaptToDetail) => {
  return (key, Component, { truncate } = {}) => {
    return ({ row }) => {
      const obj = row ? row.original : {};
      const adapted = adaptToDetail(obj);
      let value = adapted[key];
      if (truncate && value?.length > truncate) {
        value = value.substring(0, truncate) + '...';
      }
      return Component ? Component({ obj, value, adapted }) : Text(value);
    };
  };
};

export const detailLinkPathFactory = (paths, id = 'id') => {
  return (resource) => {
    if (!paths || !resource || !id || !resource[id]) return;
    return paths.resource.detail(resource[id]);
  };
};

export const chipCellAdapterFactory = (adaptToDetail) => {
  return (key) => {
    return ({ row }) => {
      const obj = row ? row.original : {};
      const adapted = adaptToDetail(obj);
      const value = adapted[key];

      if (!value) return <></>;
      if (!Array.isArray(value)) return <></>;

      return (
        <>
          {value.map((val, i) => (
            <Chip key={`chip-${val}-${i}`} label={val} />
          ))}
        </>
      );
    };
  };
};

export const tagsCellAdapterFactory = (adaptToDetail) => {
  return ({ key = 'tag_array', updateQuery }) => {
    return ({ row }) => {
      const obj = row ? row.original : {};
      const adapted = adaptToDetail(obj);
      const tags = adapted[key];

      if (!tags) return <></>;
      if (!Array.isArray(tags)) return <></>;

      const handleClick = ({ id, name }) => {
        updateQuery({
          filters: {
            tag_id: { displayName: `Tag: ${name}`, values: [id] },
          },
        });
      };

      return (
        <>
          {tags.map(({ id, name }, i) => (
            <Chip
              key={`chip-tag-${id}-${i}`}
              variant="outlined"
              label={name}
              onClick={() => handleClick({ id, name })}
              icon={<LocalOfferIcon />}
            />
          ))}
        </>
      );
    };
  };
};

export const cellLinkAdapterFactory = (adaptToDetail) => {
  return (key, pathFunc, { Layout, id = 'id', checkViewed = false } = {}) => {
    const mappings = {
      facilityDetail: detailLinkPathFactory(facilityPaths, id),
      userDetail: detailLinkPathFactory(peoplePaths, id),
      trainingDetail: detailLinkPathFactory(trainingCoursePaths, id),
      containerDetail: detailLinkPathFactory(containersPaths, id),
      contractDetail: detailLinkPathFactory(contractsPaths, id),
    };

    return ({ row }) => {
      const obj = row ? row.original : {};
      const adapted = adaptToDetail(obj);
      const value = adapted[key];
      const viewed = checkViewed ? (adapted.viewed !== undefined ? adapted.viewed : true) : true;
      if (!value) return null;

      const knownPathFunc = mappings[pathFunc];
      const pathString = knownPathFunc ? knownPathFunc(adapted) : pathFunc(adapted);
      const Child = pathString
        ? Link(value, pathString, viewed ? 'FlexTable-link' : 'FlexTable-row--unread')
        : value;

      if (checkViewed) return <UnviewedLayout {...{ adapted }}>{Child}</UnviewedLayout>;

      if (!Layout) return Child;

      return (
        <Layout obj={obj} adapted={adapted}>
          {Child}
        </Layout>
      );
    };
  };
};

export const comboCellAdapterFactory = () => {
  return (cellAdapters = []) => {
    return ({ row }) => {
      return cellAdapters.reduce((prevValue, currAdapter, i) => {
        const classes = useStyles();
        return (
          <>
            {prevValue}
            <Box className={i > 0 ? classes.comboCell : ''}>{currAdapter({ row })}</Box>
          </>
        );
      }, '');
    };
  };
};

// There's nothing being passed in so...
export const comboCellAdapter = comboCellAdapterFactory();

export const arrayCountCellAdapterFactory = (adaptToDetail) => {
  return (key, prop, pathFunc) => {
    return ({ row }) => {
      const obj = row ? row.original : {};
      const arr = adaptToDetail(obj)[key] || [];
      const firstValue = arr[0];
      const rest = arr.slice(1);

      if (!firstValue) return Text('');

      const firstValueText = prop ? firstValue[prop] : firstValue;

      const firstCell = pathFunc
        ? Link(firstValueText, pathFunc(firstValue))
        : Text(firstValueText);

      const listItemStyle = {
        marginLeft: '1rem',
        listStyle: 'disc',
      };

      const tooltipText = (
        <ul>
          {rest.map((item, i) => {
            return (
              <li style={listItemStyle} key={`item-${i}`}>
                {prop ? item[prop] : item}
              </li>
            );
          })}
        </ul>
      );

      return (
        <>
          {firstCell}
          {arr.length > 1 && (
            <>
              <Tooltip title={tooltipText}>
                <span>{Text(`+${rest.length} more`)}</span>
              </Tooltip>
            </>
          )}
        </>
      );
    };
  };
};

export const progressBarCellAdapterFactory = (adaptToDetail) => {
  return (key) => {
    return ({ row }) => {
      const obj = row ? row.original : {};
      const value = adaptToDetail(obj)[key];
      return <ProgressBar withLabel value={value} />;
    };
  };
};
