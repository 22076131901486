import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import { cellAdapterFactory, cellLinkAdapterFactory } from 'adapters/v2/factories';
import { adaptToJsonApiRequest, adaptJsonApiToDetail } from 'adapters/v1/json_api';
import { adaptSingleSelectFieldsToRequest } from 'adapters/v1';

const dateFields = [
  'updated_at',
  'created_at',
  'trip_departure_date',
  'trip_return_date',
  'departure_date',
  'arrival_date',
];
export const countryFields = ['destination_country', 'departure_country', 'arrival_country'];
const singleSelectFields = [...countryFields, 'owner_type'];

export const adaptToCountries = (obj) => {
  countryFields.forEach((fieldName) => {
    const label = obj[fieldName];
    const title = obj[`${fieldName}_name`];
    if (!label || !title) return;

    // Skip if country has already been converted
    if (!!label?.label) return;

    obj[fieldName] = { label, title };
  });

  return obj;
};

export const jsonApiType = 'transportation';

export const adaptToTravelRequest = (formData) => {
  const adapted = {
    ...adaptToIso(formData, dateFields),
    ...adaptSingleSelectFieldsToRequest({ formData, singleSelectFields }),
  };

  delete adapted.ports;

  return adapted;
};

export const adaptPortsToTransportations = (formData) =>
  formData?.ports?.map((port) => ({
    arrival_country: port.port_country,
    arrival_date: port.arrival_date,
    arrival_port: port.port_name,
    departure_country: port.port_country,
    departure_date: port.departure_date,
    departure_port: port.port_name,
    id: port.id,
    transit_company_name: formData.transit_company_name,
    transit_number: formData.transit_number,
    travel_id: formData.travel_id,
    type: formData.type,
  }));

export const adaptTransportationToPort = (t) =>
  adaptToDisplay(
    {
      arrival_date: t.arrival_date,
      departure_date: t.departure_date,
      id: t.id,
      port_country: t.arrival_country,
      port_name: t.arrival_port,
    },
    dateFields
  );

export const adaptToRequest = (formData) => {
  const adapted = {
    ...adaptToIso(formData, dateFields),
    ...adaptSingleSelectFieldsToRequest({ formData, singleSelectFields }),
  };

  delete adapted.owner_type;
  delete adapted.same_return;

  const attributes = { ...adapted };

  return adaptToJsonApiRequest({
    attributes,
    type: jsonApiType,
  });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const adapted = adaptToDisplay(adaptJsonApiToDetail(resource), dateFields);

  return adapted;
};

export const adaptToForm = (resource) => {
  if (!resource) return {};

  const adapted = {
    ...resource,
    ...adaptToCountries(resource),
  };

  adapted.owner_type = {
    label: adapted.transit_company_name === 'Personal' ? 'Personal' : 'Rental',
    title: adapted.transit_company_name === 'Personal' ? 'Personal' : 'Rental',
  };

  adapted.same_return =
    adapted.arrival_address === adapted.departure_address &&
    adapted.arrival_city === adapted.departure_city &&
    adapted.arrival_country?.label === adapted.departure_country?.label;

  return adapted;
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
