import { displayDateFormat, displayDateTimeFormat, defaultMoment as moment } from 'config';

export const adaptChangesToDisplayFactory = (mapping) => {
  return (activity) => {
    if (!activity && !activity.attributes) return activity;

    Object.entries(mapping).forEach(([key, value]) => {
      const updated = mapping[key];
      if (!updated) return;
      if (!activity.attributes.object_changes[key]) return;

      activity.attributes.object_changes[updated] = activity.attributes.object_changes[key];
      delete activity.attributes.object_changes[key];
    });

    return activity;
  };
};

export const adaptToCalendarDate = (date) => {
  return moment(date, moment.ISO_8601).local().format(displayDateTimeFormat);
};

const blackList = ['company_id', 'created_at', 'id', 'updated_at'];
const noTimezone = ['hired_at', 'terminated_at', 'date_of_birth'];

const filterDelta = (object_changes = {}) => {
  const out = {};
  Object.entries(object_changes).forEach(([key, value]) => {
    if (blackList.includes(key)) return;
    // Since this is feature flagged it's ok if we show these values
    //if (key.endsWith('_id')) return;
    if (!Array.isArray(value)) return;

    if (noTimezone.includes(key)) {
      out[key] = value.map((val) => (val ? moment(val).format(displayDateFormat) : val));
    } else if (key.endsWith('_at') || key.endsWith('_date')) {
      out[key] = value.map((val) =>
        val ? moment(val, moment.ISO_8601).local().format(displayDateFormat) : val
      );
    } else {
      out[key] = value.map((val) => (val === true ? 'true' : val === false ? 'false' : val));
    }
  });

  return out;
};

export const adaptDataToHistoryFactory = (adaptActivityToHistory) => {
  return ({ data = [], kwargs = {} } = {}) => {
    if (!Array.isArray(data)) return [];
    if (data.length === 0) return data;

    return data
      .sort((a, b) => Number(b.id) - Number(a.id))
      .map((obj) => {
        const { object_changes, user_id, whodunnit } = obj.attributes;
        const user = obj?.relationships?.user?.data?.attributes;
        let name = user?.name || 'Unknown User';

        if (user_id === 0) {
          name = whodunnit || 'System';
        }

        return {
          user,
          name,
          ...adaptActivityToHistory(obj, kwargs),
          object_changes: filterDelta(object_changes),
        };
      })
      .filter((obj) => obj && obj.action);
  };
};
