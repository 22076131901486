import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Index = ({ label, ...rest }) => {
  // I define the Control component here because I need it to rerender
  // every time to keep up with formData state changes
  const Control = (props) => <Checkbox {...props} {...rest} />;
  return <FormControlLabel control={<Control />} {...{ label }} />;
};

export default Index;
