const constants = {
  REFRESH_PROFILE_SUCCESS: 'REFRESH_PROFILE_SUCCESS',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_PENDING: 'LOGIN_PENDING',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_PENDING: 'LOGOUT_PENDING',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
};

export default constants;
