import actionBuilder, { update } from 'store/v2/actions';
import actionType, { bulkActionType, apiPath } from './constants';

import { createActions } from 'redux-actions';
import request from 'lib/v1/request';
import CONST from 'store/v1/users/constants';
import { add_message } from 'store/v1/app/actions';
import handleError from 'lib/v1/error-handling';

const password = (id, payload, silent = false) => {
  return update(`${actionType}_PASSWORD`, `${apiPath}/${id}/password`, { ...payload, silent });
};

const disableTwoFactor = (id, payload, silent = false) => {
  return update(actionType, `${apiPath}/${id}/disable_two_factor`, { ...payload, silent });
};

const enableTwoFactor = (id, payload, silent = false) => {
  return update(actionType, `${apiPath}/${id}/enable_two_factor`, { ...payload, silent });
};

// this many arguments is annoying, the last two are showAll and isBulk
const bulkEnableTwoFactor = (payload, silent = false) => {
  return update(actionType, `${apiPath}/bulk/two_factor`, { ...payload, silent }, false, true);
};

const bulkAssignForm = (payload, silent = false) => {
  return update(actionType, `${apiPath}/bulk/digital_forms`, { ...payload, silent }, false, true);
};

const bulkAssignTraining = (payload, silent = false) => {
  return update(
    actionType,
    `${apiPath}/bulk/training_assignments`,
    { ...payload, silent },
    false,
    true
  );
};

const actions = {
  ...actionBuilder({ apiPath, bulkActionType, actionType }),
  bulkAssignForm,
  bulkAssignTraining,
  bulkEnableTwoFactor,
  disableTwoFactor,
  enableTwoFactor,
  password,
};

const { sendWelcomeEmailPending, sendWelcomeEmailSuccess } = createActions(
  CONST.SEND_WELCOME_EMAIL_PENDING,
  CONST.SEND_WELCOME_EMAIL_SUCCESS,
  CONST.SEND_WELCOME_EMAIL_FAILURE
);

export const sendWelcome = (payload, { filters, search } = {}) => {
  return (dispatch) => {
    const params = { ids: payload.ids && Array.isArray(payload.ids) ? payload.ids : [payload.ids] };
    const filterJSON = filters ? JSON.stringify(filters) : '';

    dispatch(sendWelcomeEmailPending());
    return request
      .post(`/users/send_welcome`)
      .query({ search, filters: filterJSON })
      .send(payload)
      .then((res) => {
        dispatch(sendWelcomeEmailSuccess(res.body.data));
        dispatch(
          add_message('success', [
            `Welcome email${params.ids.length > 1 ? 's' : ''} sent successfully.`,
          ])
        );
      })
      .catch(handleError);
  };
};

export default actions;
