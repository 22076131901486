import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths from 'config/paths/library';

// Components
const Index = lazy(() => import('./index'));

export const Routes = ({ paths, permissions }) => {
  // Redux Manager can handle loading any data necessary for the feature to function
  // see the component definition for available data/actions
  return (
    <ReduxManager data={['cardTemplatesDropdown']}>
      <Suspense fallback={<></>}>
        <Switch>
          <Route permission={permissions.library.list} exact path={paths.root}>
            <Index />
          </Route>
        </Switch>
      </Suspense>
    </ReduxManager>
  );
};

export default connect(mapPermissionsToProps, null)((props) => <Routes paths={paths} {...props} />);
