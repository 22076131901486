import { adaptToIso, adaptToDisplay, formatDate } from 'adapters/v1/dates';
import { cellAdapterFactory, cellLinkAdapterFactory } from 'adapters/v2/factories';
import { adaptToJsonApiRequest, adaptJsonApiToDetail } from 'adapters/v1/json_api';
import { adaptSelectToRequest } from 'adapters/v1/index';

const dateFields = [
  'investigation_opened_at',
  'investigation_closed_at',
  'reinvestigation_at',
  'granted_at',
  'continuous_evaluation_grant_date',
  'nda_date',
];

const singleSelectFields = [
  'granted_by_id',
  'investigated_by_id',
  'investigation_status',
  'type_eligibility_id',
  'type_investigation_id',
];

export const adaptToRequest = (formData) => {
  const adapted = adaptToIso(formData, dateFields);

  singleSelectFields.forEach((key) => {
    adapted[key] = adaptSelectToRequest(adapted ? adapted[key] : {});
  });

  return adaptToJsonApiRequest({ attributes: adapted, type: 'user_eligibility' });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const adapted = adaptJsonApiToDetail(resource);

  adapted.code = adapted.type_eligibility?.code;
  adapted.name = adapted.type_eligibility?.display_name;
  adapted.investigation = adapted.type_investigation?.display_name;
  adapted.grantingAgency = adapted.granted_by?.display_name;
  adapted.investigationDates = [adapted.investigation_opened_at, adapted.investigation_closed_at]
    .filter((date) => date)
    .map((date) => formatDate(date))
    .join(' - ');
  adapted.ce = adapted.continuous_evaluation ? 'Enabled' : '';
  adapted.ceGrantDate = adapted.continuous_evaluation
    ? adapted.continuous_evaluation_grant_date
    : '';

  adapted.reinvestigationDate = adapted.reinvestigation_at && new Date(adapted.reinvestigation_at);
  adapted.investigationDetails = [adapted.investigation, adapted.investigationDates];
  adapted.grantingDetails = [adapted.grantingAgency, formatDate(adapted.granted_at)];
  adapted.ceDetails = [adapted.ce, formatDate(adapted.ceGrantDate)];

  return adaptToDisplay(adapted, dateFields);
};

export const adaptToForm = (resource) => {
  if (!resource) return {};
  const out = { ...resource };

  const {
    type_eligibility,
    type_investigation,
    investigated_by,
    investigation_status,
    granted_by,
  } = resource;

  if (type_eligibility) {
    out.type_eligibility_id = {
      title: type_eligibility.display_name,
      label: parseInt(type_eligibility.id),
    };
  }

  if (type_investigation) {
    out.type_investigation_id = {
      title: `${type_investigation.code} - ${type_investigation.display_name}`,
      label: parseInt(type_investigation.id),
    };
  }

  if (investigated_by) {
    out.investigated_by_id = {
      title: `${investigated_by.code} - ${investigated_by.display_name}`,
      label: parseInt(investigated_by.id),
    };
  }

  if (investigation_status) {
    out.investigation_status = { label: investigation_status, title: investigation_status };
  }

  if (granted_by) {
    out.granted_by_id = {
      title: `${granted_by.code} - ${granted_by.display_name}`,
      label: parseInt(granted_by.id),
    };
  }

  return out;
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
