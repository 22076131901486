import React from 'react';

import { twilight } from 'lib/v2/colors';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';

const baseStyles = {
  root: {
    height: 10,
    borderRadius: 5,
  },
  bar: {
    borderRadius: 5,
  },
};

const getColor = (value) => {
  // const hue = (value / 440) * 100;
  const hue = Math.floor(value);
  const lightness = Math.floor((value / 100) * -15) + 55;
  return `hsl(${hue}, 85%, ${lightness}%)`;
};

const ProgressBar = ({ value, withLabel, color, staticColor }) => {
  if (!color) {
    color = staticColor ? twilight : getColor(value);
  }

  const useStyles = makeStyles({
    ...baseStyles,
    barColorPrimary: {
      background: color,
    },
  });

  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={value} classes={classes} color="primary" />
      </Box>
      {withLabel && (
        <Box minWidth={35}>
          <Typography variant="body2" color="secondary">{`${Math.round(value)}%`}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ProgressBar;
