import React from 'react';

// MUI
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// Components
import Button from 'components/v2/FormElements/Button';
import MyAccesses from 'components/v2/Profile/Accesses/DataTable';
import MyBadges from 'components/v2/InboxV2/MyBadges';
import MyEligibilities from 'components/v2/Profile/Eligibilities/DataTable';

import peoplePaths from 'config/paths/people';
import { withFeatureState } from 'components/v2/InboxV2/state';

const ModuleSelector = styled(Box)(({ theme }) => ({
  marginTop: '1rem',
  display: 'flex',
}));

const ProfileButton = styled(Button)(({ theme }) => ({
  marginLeft: 'auto',
}));

const MyStuff = ({ history, profile }) => {
  const [module, setModule] = React.useState('accesses');

  const handleModuleChange = (event, newModule) => {
    setModule(newModule);
  };

  const handleProfileClick = () => {
    history.push(peoplePaths.resource.detail(profile.id));
  };

  return (
    <>
      <ModuleSelector>
        <ToggleButtonGroup value={module} exclusive onChange={handleModuleChange}>
          <ToggleButton value="accesses">My Accesses</ToggleButton>
          <ToggleButton value="eligibilities">My Eligibilities</ToggleButton>
          <ToggleButton value="badges">My Badges</ToggleButton>
        </ToggleButtonGroup>

        <ProfileButton variant="text" color="primary" onClick={handleProfileClick}>
          View My Profile
        </ProfileButton>
      </ModuleSelector>

      {module === 'accesses' && <MyAccesses userId={profile.id} />}
      {module === 'eligibilities' && <MyEligibilities userId={profile.id} />}
      {module === 'badges' && <MyBadges />}
    </>
  );
};

export default withFeatureState(MyStuff);
