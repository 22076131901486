import React from 'react';

// MUI
import List from '@mui/material/List';

export const TswList = (props) => {
  return <List disablePadding dense {...props} style={{ width: '100%' }} />;
};

export default TswList;
