import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { useProfileContext } from 'components/v2/Profile/Context';

import { Route, withRouter } from 'react-router-dom';
// PageElements
import { DigitalFormsReduxManager } from 'components/v2/DigitalForms/Routes';

import { Switch, useRoutingFilters } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

// actions
import actions from 'store/v2/digitalFormRequests/actions';

// Components
import { Index } from 'components/v2/DigitalForms/index';
const Table = lazy(() => import('components/v2/DigitalForms/Requests/Table'));
const Detail = lazy(() => import('components/v2/DigitalForms/Requests/Detail'));

export const Routes = ({ paths, permissions, preferences, location, updateQuery }) => {
  useRoutingFilters({ location, updateQuery });

  const { isSelf } = useProfileContext();

  return (
    <DigitalFormsReduxManager>
      <Suspense fallback={<></>}>
        <Switch>
          {preferences.forms && (isSelf || permissions.digitalFormRequests.list) && (
            <Route exact path={paths.root}>
              <Index {...{ permissions, location, paths }}>
                <Table {...{ paths }} />
              </Index>
            </Route>
          )}
          {preferences.forms && (isSelf || permissions.digitalFormRequests.view) && (
            <Route exact path={paths.resource.detail(':id')}>
              <Detail {...{ paths }} />
            </Route>
          )}
        </Switch>
      </Suspense>
    </DigitalFormsReduxManager>
  );
};

export default withRouter(
  connect(
    ({ profile }) => ({
      ...mapPermissionsToProps({ profile }),
      ...mapPreferencesToProps({ profile }),
    }),
    actions
  )(Routes)
);
