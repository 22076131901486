import React from 'react';

const CohortCount = ({ cohort_count = 0, total_count = 0 }) => {
  return (
    <h3 className="FlexTable__filteredCount">
      {total_count} of {cohort_count} entries
    </h3>
  );
};

export default CohortCount;
