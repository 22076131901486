import React, { useState } from 'react';
import { connect } from 'react-redux';

import Menu from '../Menu';

// MUI
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Dialog from './dialog';
import { useDialog } from 'components/v2/FormElements/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: 'auto',
  marginLeft: '2rem',
}));

const StyledMenuBox = styled(Box)(({ theme }) => ({
  padding: '10px 25px',
  minWidth: '310px',
}));

const SecurityContact = ({ profile }) => {
  const { onClick, onClose, open } = useDialog();

  const officerCount = profile?.security_officers?.length;
  const hasMultipleOfficers = officerCount > 1;
  const additionalText = officerCount === 2 ? 'other point of contact' : 'other points of contact';
  const [securityOfficer] = profile?.security_officers || [];

  const [anchorEl, setAnchorEl] = useState(null);
  const show = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonProps = {
    onClick: handleClick,
    variant: 'outlined',
    sx: { height: 'fit-content' },
  };

  if (!securityOfficer || !securityOfficer?.name?.trim()?.length) return;

  return (
    <>
      <StyledBox>
        <div>
          <Typography variant="caption">Security Point of Contact</Typography>
          <div>{securityOfficer?.name}</div>
        </div>
        <IconButton {...buttonProps}>
          <ExpandMoreIcon />
        </IconButton>
      </StyledBox>

      <Menu anchorEl={anchorEl} onClick={handleClose} onClose={handleClose} open={show}>
        <StyledMenuBox>
          {securityOfficer?.email && (
            <>
              <Typography variant="caption">Email</Typography>
              <div>
                <a href={`mailto:${securityOfficer?.email}`}>{securityOfficer?.email}</a>
              </div>
            </>
          )}
          {securityOfficer?.work_phone && (
            <div style={{ marginTop: '5px' }}>
              <Typography variant="caption">Phone Number</Typography>
              <div>{securityOfficer?.work_phone}</div>
            </div>
          )}
          {hasMultipleOfficers && (
            <>
              <Divider sx={{ my: 2 }} />
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="caption">
                  You have {officerCount - 1} {additionalText}
                </Typography>
                <Button size="small" onClick={onClick} sx={{ mt: -0.75 }}>
                  View All
                </Button>
              </Stack>
            </>
          )}
        </StyledMenuBox>
      </Menu>
      <Dialog
        onClose={onClose}
        open={open}
        securityOfficers={profile?.security_officers}
        title="Security Point of Contacts"
      />
    </>
  );
};

const mapStateToProps = ({ profile }) => ({ profile });

export default connect(mapStateToProps)(SecurityContact);
