// Assets
import { iconComponents } from 'lib/v2/icons';

// Layouts
import HeaderLayout from 'layouts/v2/HeaderLayout';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';

// FormElements
import { useDialog } from 'components/v2/FormElements/Dialog';

// factories
import { withFeatureState } from './state';

// Components
import Table from './Table';
import Create from './Create';

export const Index = ({ children, ...rest }) => {
  const { onClick, onClose, open } = useDialog();

  const headerProps = {
    title: 'Files',
    icon: iconComponents('files'),
    allowCreate: rest && rest.permissions ? rest.permissions.documents.create : false,
    buttonText: 'Add a File',
    onClick,
  };

  const modalProps = {
    onClose,
    open,
  };

  return (
    <>
      <Create {...modalProps} />
      <HeaderLayout {...headerProps} {...rest}>
        {children}
      </HeaderLayout>
    </>
  );
};

const WrappedIndex = withFeatureState(Index);

const WithTable = (props) => {
  // FIXME: Filters should be able to manage this on their own, but currently they don't
  const data = ['tagNamesDropdown'];

  return (
    <ReduxManager data={data}>
      <WrappedIndex {...props}>
        <Table />
      </WrappedIndex>
    </ReduxManager>
  );
};

export default WithTable;
