import containersPaths from 'config/paths/containerControls';
export const adminRootPath = '/admin';

export const makeAdminPath = (path) => `${adminRootPath}/${path}`;

// FIXME: This should probably get reworked
// It's a first pass at variabilizing these values
const paths = {
  contracts: makeAdminPath('contracts'),
  facilities: makeAdminPath('facilities'),
  assets: makeAdminPath('assets'),
  forms: makeAdminPath('forms/templates'),
  documents: makeAdminPath('documents'),
  tags: makeAdminPath('tags'),
  tasks: makeAdminPath('tasks'),
  badges: makeAdminPath('badges'),
  roles: makeAdminPath('roles'),
  foreignTravel: makeAdminPath('foreign-travel'),
  companies: makeAdminPath('companies'),
  containers: containersPaths.root,
};

export default paths;
