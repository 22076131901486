import React from 'react';

import { formatDate } from 'adapters/v1/dates';
import { useDebounce } from 'lib/v1/hooks';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';

import { BatchActionsContainer } from './BatchActions';
import CustomPagination from './Pagination';
import { deleteActionItem } from './RowActionItem';

export const TABLE_WIDTH = 960;

const TableBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(5),
}));

export const dateFieldProps = {
  type: 'dateTime',
  width: 150,
  valueFormatter: ({ value }) => value && formatDate(value),
};

function toggleSelectAll({ apiRef, isSelected }) {
  const allRowIds = apiRef.current.getAllRowIds();
  apiRef.current.selectRows(allRowIds, isSelected);
}

const SelectAllAlert = ({ setSlots, showApplyState }) => {
  const selectAllMessage = showApplyState
    ? 'The action will be applied to all filtered records across all pages. Note, it may take a few minutes to complete with large selections.'
    : 'Would you like to select all filtered records across all pages?';
  const buttonText = showApplyState ? 'Cancel' : 'Select All';

  return (
    <GridToolbarContainer>
      <Alert severity="info">
        {selectAllMessage}
        <Button onClick={setSlots}>{buttonText}</Button>
      </Alert>
    </GridToolbarContainer>
  );
};

const DataTable = ({
  applyAll = false,
  batchActions,
  checkboxSelection,
  columns = [],
  defaultSort,
  defaultSorts,
  destroy,
  destroyProps,
  disableColumnFilter = false,
  hideFooter = false,
  list,
  forcedFilters = {},
  handleRowSelectionChange,
  loading,
  pageSizeOptions = [20, 50, 100, 250],
  query = {},
  rows = [],
  selectedIds,
  showViewedStatus,
  ZeroStateComponent,
  ...rest
}) => {
  const [loaded, setLoaded] = React.useState(false);
  // getting inconsistent results with loading state coming in from props
  const [listLoading, setListLoading] = React.useState(false);
  const hasRows = rows?.length > 0;
  const zeroStateHeight = loaded && ZeroStateComponent ? '600px' : '200px';
  const defaultPageSize = pageSizeOptions[0];
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: defaultPageSize,
  });

  const getData = React.useCallback(
    async (props) => {
      if (list && !listLoading) {
        setListLoading(true);
        await list({
          per_page: paginationModel.pageSize,
          page: paginationModel.page + 1,
          showAll: true,
          forcedFilters,
          ...props,
        });
        setListLoading(false);
        setLoaded(true);
      } else {
        console.log('skipping datatable list', { listLoading }); // eslint-disable-line
      }
    },
    [setLoaded] // eslint-disable-line
  );

  const search = useDebounce(query.search, 200);
  const filters = useDebounce(query.filters, 200);

  React.useEffect(() => {
    getData({ filters, search });
  }, [search, getData, filters]);

  const getRowClassName = (params) => {
    const attrs = Object.keys(params.row);
    const isUnViewed = attrs.includes('viewed') && !params.row.viewed;
    const stripedClass =
      params.indexRelativeToCurrentPage % 2 === 0 ? 'datagrid_row_even' : 'datagrid_row_odd';
    const viewedClass = isUnViewed ? 'datagrid_row_unviewed' : 'datagrid_row_viewed';
    return stripedClass + (showViewedStatus ? ' ' + viewedClass : '');
  };

  const [showApplyState, setShowApplyState] = React.useState(false);
  const hasAllSelected = selectedIds?.length >= paginationModel.pageSize;

  async function setSlots({ apiRef }) {
    await setShowApplyState((prev) => !prev);
    await toggleSelectAll({ apiRef, isSelected: !showApplyState });
  }

  const CustomToolbar = () => {
    const apiRef = useGridApiContext();

    return (
      <>
        {batchActions && <BatchActionsContainer>{batchActions}</BatchActionsContainer>}
        {applyAll && hasAllSelected && (
          <SelectAllAlert setSlots={() => setSlots({ apiRef })} showApplyState={showApplyState} />
        )}
      </>
    );
  };

  const slots = {
    pagination: CustomPagination,
    toolbar: CustomToolbar,
  };

  if (loaded) {
    if (ZeroStateComponent) slots.noRowsOverlay = ZeroStateComponent;
    if (!hasRows && ZeroStateComponent) slots.columnHeaders = () => null;
  }

  if (destroy && destroyProps) {
    const afterDelete = () => getData({ filters, search });
    columns.push(deleteActionItem({ destroy, afterDelete, query, ...destroyProps }));
  }

  let sortModel = [];
  if (defaultSort) {
    sortModel = [defaultSort];
  } else if (defaultSorts) {
    sortModel = defaultSorts;
  }

  const tableProps = {
    autoHeight: hasRows,
    checkboxSelection: checkboxSelection && !showApplyState,
    checkboxSelectionVisibleOnly: true,
    columns,
    disableColumnFilter,
    disableRowSelectionOnClick: true,
    density: 'standard',
    getRowClassName,
    hideFooter: hideFooter || !hasRows,
    initialState: {
      sorting: { sortModel },
    },
    loading,
    onPaginationModelChange: setPaginationModel,
    onRowSelectionModelChange: handleRowSelectionChange,
    paginationModel,
    pageSizeOptions,
    pagination: true,
    rows,
    slots,
  };

  return (
    <TableBox sx={{ height: hasRows ? 'auto' : zeroStateHeight }}>
      <DataGridPro {...tableProps} />
    </TableBox>
  );
};

export default DataTable;
