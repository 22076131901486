import { isAnyAdmin } from 'permissions/v1/roles';

import { noPermissions } from 'permissions/v1/index';

import { hasWorkflows, hasTasks } from 'permissions/v1/preferences';

const create = (profile) => isAnyAdmin(profile);
const update = (profile) => isAnyAdmin(profile);
const destroy = (profile) => isAnyAdmin(profile);

const permissions = (profile) => {
  if (!hasWorkflows(profile) && !hasTasks(profile)) return noPermissions;

  return {
    list: true,
    view: true,
    create: create(profile),
    update: update(profile),
    destroy: destroy(profile),
  };
};

export default permissions;
