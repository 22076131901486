/*
 * These are useful for dealing with data coming from flextable v2 rows.  They have a specific schema
 * and whenever we are doing batch actions/row actions we will need to parse data.
 */
export const adaptToOriginal = (row) => {
  if (!row) return {};
  return row.original;
};

export const adaptToOriginalRows = (rows) => {
  if (!rows) return [];
  return rows.map((row) => adaptToOriginal(row));
};

export const adaptToOriginalRowIds = (rows) => {
  return adaptToOriginalRows(rows).map((row) => row.id);
};

export const adaptToOriginalFromRowItem = (rowItem = {}) => {
  if (!rowItem || !rowItem.row) return {};

  const { original } = rowItem.row || {};
  return original || {};
};
