export const INBOX_ACTIONS = {
  INBOX_FETCHED: 'INBOX_FETCHED',
  UPDATE_INBOX: 'UPDATE_INBOX',
  INBOX_FETCHED_PENDING: 'INBOX_FETCHED_PENDING',
  INBOX_FETCHED_SUCCESS: 'INBOX_FETCHED_SUCCESS',
  INBOX_FETCHED_FAILURE: 'INBOX_FETCHED_FAILURE',
  UPDATE_INBOX_PENDING: 'UPDATE_INBOX_PENDING',
  UPDATE_INBOX_SUCCESS: 'UPDATE_INBOX_SUCCESS',
  UPDATE_INBOX_FAILURE: 'UPDATE_INBOX_FAILURE',
};
