const initialState = {
  mode: 'original',
};

const themesReducer = (state, { type, payload }) => {
  if (!state) {
    state = initialState;
    const savedTheme = window.localStorage.getItem('theme');
    if (savedTheme) state.mode = savedTheme;
  }

  if (type === 'SET_THEME_MODE') {
    window.localStorage.setItem('theme', payload.mode);
    state = {
      ...state,
      ...payload,
    };
  }

  return state;
};

export default themesReducer;
