import React from 'react';

const UserAdd = ({ width = '64px', height = '64px', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 64 64" {...rest}>
    <path
      d="M64.001,59.993c0,0-0.005-0.567-0.099-1.47C62.852,59.432,61.499,60,60,60c0,1.098-0.316,2.114-0.832,3H61
      c1.657,0,3-1.343,3-3c0-0.003-0.001-0.005-0.001-0.007H64.001z M42,54c0-3.314,2.686-6,6-6c0-1.952,0.945-3.668,2.389-4.764
      c-0.797-0.209-1.897-0.571-3.688-1.314c-6.205-2.57-7.675-3.408-7.675-3.408l-0.055-5.894c0,0,2.324-1.764,3.049-7.337
      c1.451,0.42,1.942-1.701,2.021-3.056c0.086-1.309,0.858-5.389-0.917-5.025c0.363-2.724,0.648-5.187,0.52-6.491
      c-0.444-4.576-3.608-9.355-11.595-9.704c-6.79,0.349-11.198,5.131-11.643,9.707c-0.128,1.304,0.133,3.765,0.496,6.494
      c-1.774-0.369-1.01,3.719-0.932,5.027c0.086,1.355,0.566,3.482,2.02,3.062c0.722,5.574,3.046,7.352,3.046,7.352l-0.058,5.924
      c0,0-1.47,0.896-7.675,3.466c-6.205,2.573-4.259,0.532-9.314,3.047C0,49.061,0,59.992,0,59.992h0.002C0.001,59.995,0,59.998,0,60
      c0,1.657,1.343,3,3,3h45.832C48.316,62.115,48,61.1,48,60C44.686,60,42,57.314,42,54z M63,54c0-1.657-1.343-3-3-3h-3v-3
      c0-1.657-1.343-3-3-3s-3,1.343-3,3v3h-3c-1.657,0-3,1.343-3,3s1.343,3,3,3h3v3c0,1.657,1.343,3,3,3s3-1.343,3-3v-3h3
      C61.657,57,63,55.657,63,54z"
    />
  </svg>
);

export default UserAdd;
