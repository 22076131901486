import { ERROR_ACTIONS } from './constants';
const error = (state = false, action) => {
  switch (action.type) {
    case ERROR_ACTIONS.SHOW_ERROR_MODAL:
      return true;
    case ERROR_ACTIONS.HIDE_ERROR_MODAL:
      return false;

    default:
      return state;
  }
};

export default error;
