import React from 'react';
import { withSummaryStyles } from './state';

// MUI
import ExpansionPanel from '@mui/material/Accordion'; // Note this is updated to Accordion in current MUI
import ExpansionPanelDetails from '@mui/material/AccordionDetails';
import ExpansionPanelSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Box from '@mui/material/Box';
import List from './List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// icons
import BusinessIcon from '@mui/icons-material/Business';
import { withStyles } from '@mui/styles';

const MyExpansionPanel = withStyles({
  expanded: {
    background: '#f3f3f3',
    margin: '4px 0 !important',
    borderTop: '1px solid #aaa',
    borderBottom: '1px solid #aaa',
    boxShadow: 'inset 0px 10px 7px -10px #aaa, inset 0px -10px 7px -10px #aaa',
    '& .MuiExpansionPanelSummary-content': {
      margin: '4px 0 0',
    },
  },
})(ExpansionPanel);

const MyExpansionPanelDetails = withStyles({
  root: {
    padding: '0 0 10px 10px',
  },
})(ExpansionPanelDetails);

export const NavExpansionPanel = ({
  onClick,
  expanded,
  selected,
  classes,
  Icon = <BusinessIcon />,
  title = 'Title',
  subtitle,
  children,
  SummaryChildren,
}) => {
  return (
    <MyExpansionPanel {...{ expanded }}>
      <List>
        <Box display="flex" alignItems="center">
          <ListItem button {...{ onClick, selected }}>
            <ExpansionPanelSummary classes={classes} expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                {Icon && <ListItemIcon>{Icon}</ListItemIcon>}
                <ListItemText primary={title} secondary={subtitle} />
              </Box>
            </ExpansionPanelSummary>
          </ListItem>
          {SummaryChildren}
        </Box>
      </List>
      <MyExpansionPanelDetails>{children}</MyExpansionPanelDetails>
    </MyExpansionPanel>
  );
};

export default withSummaryStyles(NavExpansionPanel);
