import React from 'react';

// MUI
// import { styled } from '@mui/material/styles';
// import InputBase from '@mui/material/InputBase';

// ICONS
// import SearchIcon from '@mui/icons-material/Search';

import Search from 'components/v2/FlexTable/Search';

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

// const SearchInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//   },
// }));

const Index = ({ ...props }) => {
  // <SearchInputBase
  //   placeholder="Search…"
  //   inputProps={{ 'aria-label': 'search' }}
  // />
  // <SearchIconWrapper>
  //   <SearchIcon />
  // </SearchIconWrapper>

  return <Search {...props} />;
};

export default Index;
