// Mappers
import { chipCellAdapter } from 'adapters/v1/contracts/formData';
import { withTableState } from './state';

// FlexTable API
import CohortCount from 'components/v2/FlexTable/CohortCount';
import TableAPI from 'components/v2/FlexTable/Table';
import Pagination from 'components/v2/FlexTable/Pagination';
import SearchLayout from 'components/v2/FlexTable/SearchLayout';

import filters from './Filters';
import {
  ConnectedDelete as Delete,
  ConnectedEdit as Edit,
  ConnectedTags as Tags,
} from './RowActions';
import batchActions from './BatchActions';

export const Table = ({
  list,
  children,
  permissions,
  updateQuery,
  paths,
  cellAdapter,
  cellLinkAdapter,
  cellDownloadLinkAdapter,
  resourceName = 'Files',
  ...rest
}) => {
  const filterOptions = () => ({ filterOptions: filters.map((option) => option({ updateQuery })) });

  const rowActions = () => {
    if (!permissions) return [];

    const actions = [
      { action: Edit, permission: permissions.documents.update },
      { action: Tags, permission: permissions.documents.update },
      { action: Delete, permission: permissions.documents.destroy },
    ];

    return {
      rowActions: actions.filter((action) => action.permission).map((action) => action.action),
    };
  };

  const columns = () => {
    if (!permissions) return [];

    const data = [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: cellDownloadLinkAdapter(),
        permission: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: cellAdapter('description'),
        permission: true,
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: cellAdapter('relativeCreatedAt'),
        permission: true,
      },
      {
        Header: 'Updated',
        accessor: 'updated_at',
        Cell: cellAdapter('relativeUpdatedAt'),
        permission: true,
      },
      {
        Header: 'Tags',
        accessor: 'tag_array',
        Cell: chipCellAdapter('tag_array'),
        disableSortBy: true,
        permission: true,
      },
    ];

    return { columns: data.filter((column) => column.permission) };
  };

  const listActionProps = () => {
    return { list };
  };

  return (
    <>
      <CohortCount {...rest} />
      <SearchLayout filters={permissions.documents.view && filterOptions()} {...rest} />
      <TableAPI
        {...columns()}
        {...batchActions({ permissions })}
        {...rowActions()}
        {...listActionProps()}
        {...rest}
      />
      <Pagination {...rest} {...listActionProps()} />
    </>
  );
};

export default withTableState(Table);
