import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';

// Config
import paths from 'config/paths/contracts';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch, useRoutingFilters } from 'components/v2/PageElements/Routes';
import { withIdentityManager } from 'components/v2/PageElements/IdentityManager';
import { withRouter } from 'react-router-dom';

// State
import actions from 'store/v2/contracts/actions';
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Components
const Index = lazy(() => import('./index'));
const ResourceLayout = lazy(() => import('./ResourceLayout'));

export const Routes = ({ paths, permissions, updateQuery, location, isSelf }) => {
  useRoutingFilters({ location, updateQuery });

  return (
    <ReduxManager
      data={['tagNamesDropdown', 'contractsDropdown', 'facilitiesDropdown', 'peopleDropdown']}
    >
      <Suspense fallback={<></>}>
        <Switch>
          <Route permission={isSelf || permissions.contracts.list} exact path={paths.root}>
            <Index paths={paths} />
          </Route>
          <Route permission={permissions.contracts.list} path={paths.resource.root}>
            <ResourceLayout paths={paths} />
          </Route>
        </Switch>
      </Suspense>
    </ReduxManager>
  );
};

export default withIdentityManager(
  connect(
    mapPermissionsToProps,
    actions
  )(withRouter((props) => <Routes paths={paths} {...props} />))
);
