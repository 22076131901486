import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  // background: theme.palette.background.default,
  // color: theme.palette.text.primary,
  padding: '1.25rem 1.75rem 1.25rem 6.25rem',
  height: '100%',
  flex: '1 1',
}));

const AppContent = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

export default AppContent;
