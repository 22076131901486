import React from 'react';

const Suitcase = ({ width = '64px', height = '64px', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 64 64" {...rest}>
    <path
      d="M0,21v37c0,1.657,1.343,3,3,3h5V18H3C1.343,18,0,19.343,0,21z M32,3c-8.284,0-15,6.716-15,15h-6v43h42V18h-6
      C47,9.716,40.284,3,32,3z M23,18c0-4.971,4.029-9,9-9c4.971,0,9,4.029,9,9H23z M61,18h-5v43h5c1.657,0,3-1.343,3-3V21
      C64,19.343,62.657,18,61,18z"
    />
  </svg>
);

export default Suitcase;
