import { isProduction } from 'config';

export const checkPermission = (policy, action, profile) => {
  if (!profile) return false;

  const { custom_role, permissions = [] } = profile;

  if (!custom_role) return false;

  return !!permissions.find((p) => p.policy === policy && p.action === action);
};

export const canUpdate = (policy, profile) => {
  return checkPermission(policy, 'update', profile);
};

export const canCreate = (policy, profile) => {
  return checkPermission(policy, 'create', profile);
};

export const canList = (policy, profile) => {
  return checkPermission(policy, 'index', profile);
};

export const canView = (policy, profile) => {
  return checkPermission(policy, 'show', profile);
};

export const canDestroy = (policy, profile) => {
  return checkPermission(policy, 'destroy', profile);
};

export const featureFlag = (value) => {
  return (condition) => {
    if (value) {
      return !isProduction && condition;
    }

    return condition;
  };
};

export const noPermissions = {
  create: false,
  update: false,
  view: false,
  destroy: false,
  list: false,
};

export const makePermission = (policy) => {
  return {
    create: canCreate(policy),
    update: canUpdate(policy),
    list: canList(policy),
    view: canView(policy),
    destroy: canDestroy(policy),
  };
};
