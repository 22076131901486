import React from 'react';

import history from 'config/history';

// MUI
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const BackButton = ({ to, text = 'Back to List', ...rest }) => {
  const onClick = () => history.push(to);

  return (
    <Button variant="text" startIcon={<ArrowBackIosNewIcon />} onClick={onClick} {...rest}>
      {text}
    </Button>
  );
};

export default BackButton;
