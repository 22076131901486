import React from 'react';

const Unread = ({ width = '64px', height = '64px', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 64 64" {...rest}>
    <path
      id="a"
      d="M19 8c0 7.18 5.82 13 13 13s13-5.82 13-13h16c.773 0 1.471.3 2.002.78L32 33 .998 8.78A2.977 2.977 0 0 1 3 8h16zM.894 8.866A2.99 2.99 0 0 0 0 11v38c0 .789.311 1.501.809 2.037l19.569-23.296L.894 8.866zM32 39l-8.136-7.881L.943 51.175A2.982 2.982 0 0 0 3 52h58a2.98 2.98 0 0 0 2.057-.825l-22.92-20.056L32 39zM63.105 8.866C63.657 9.41 64 10.165 64 11v38a2.98 2.98 0 0 1-.809 2.037L43.622 27.741 63.105 8.866zm-29.87 1.769h-2.343L30.56.78h3.007l-.332 9.854zm-1.172 1.543c.476 0 .858.14 1.148.42.29.28.435.638.435 1.074 0 .43-.145.784-.435 1.064-.29.28-.672.42-1.148.42-.468 0-.848-.14-1.137-.42-.29-.28-.435-.634-.435-1.064 0-.43.145-.786.435-1.07.29-.283.669-.424 1.137-.424z"
    />
  </svg>
);

export default Unread;
