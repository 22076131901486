import { pathBuilder } from 'config/paths/index';

export const root = '/users';

const paths = {
  dissImport: `${root}/import`,
  ...pathBuilder(root),
};

export default paths;
