export const adminRoot = '/admin';
export const peopleRoot = '/users';
export const trainingRoot = '/training';
export const employeeTravelRoot = '/employee/travel';

export const defaultPath = '/inbox';

export const basePathV2 = '/v2';
export const appBasePath = `${basePathV2}/home`;
export const adminBasePath = `${basePathV2}/admin`;
export const logoutPath = '/logout';
export const defaultPathV2 = appBasePath;

export const pathBuilder = (root, paramId = ':id') => {
  const resourceRoot = (id) => {
    return `${root}/${id}`;
  };

  const edit = (id) => {
    return `${resourceRoot(id)}/edit`;
  };

  const detail = (id) => {
    return `${resourceRoot(id)}/details`;
  };

  const visits = (id) => {
    return `${resourceRoot(id)}/visits`;
  };

  const audits = (id) => {
    return `${resourceRoot(id)}/audits`;
  };

  const receipts = (id) => {
    return `${resourceRoot(id)}/receipts`;
  };

  const documents = (id) => {
    return `${resourceRoot(id)}/documents`;
  };

  const personnel = (id) => {
    return `${resourceRoot(id)}/personnel`;
  };

  const contracts = (id) => {
    return `${resourceRoot(id)}/contracts`;
  };

  const requirements = (id) => {
    return `${resourceRoot(id)}/requirements`;
  };

  const assets = (id) => {
    return `${resourceRoot(id)}/assets`;
  };

  const actionRequests = (id) => {
    return `${resourceRoot(id)}/action-requests`;
  };

  const facilities = (id) => {
    return `${resourceRoot(id)}/facilities`;
  };

  const training = (id) => {
    return `${resourceRoot(id)}/training`;
  };

  const forms = (id) => {
    return `${resourceRoot(id)}/signatures`;
  };

  const files = (id) => {
    return `${resourceRoot(id)}/files`;
  };

  const eligibility = (id) => {
    return `${resourceRoot(id)}/eligibility`;
  };

  const accesses = (id) => {
    return `${resourceRoot(id)}/accesses`;
  };

  const accessesEligibilities = (id) => {
    return `${resourceRoot(id)}/accesses-eligibilities`;
  };

  const badges = (id) => {
    return `${resourceRoot(id)}/badges`;
  };

  const jobs = (id) => {
    return `${resourceRoot(id)}/jobs`;
  };

  const polygraphsV2 = (id) => {
    return `${resourceRoot(id)}/polygraphsV2`;
  };

  const risk = (id) => {
    return `${resourceRoot(id)}/risk`;
  };

  const reportables = (id) => {
    return `${resourceRoot(id)}/reportable-info`;
  };

  const reportableIncidents = (id) => {
    return `${resourceRoot(id)}/reportable-incidents`;
  };

  const tags = (id) => {
    return `${resourceRoot(id)}/tags`;
  };

  const tasks = (id) => {
    return `${resourceRoot(id)}/tasks`;
  };

  const tickets = (id) => {
    return `${resourceRoot(id)}/tickets`;
  };

  const travels = (id) => {
    return `${resourceRoot(id)}/travels`;
  };

  const create = `${root}/create`;

  return {
    root: root,
    list: root,
    create,
    resource: {
      root: resourceRoot(paramId),
      accesses,
      accessesEligibilities,
      actionRequests,
      assets,
      badges,
      audits,
      contracts,
      detail,
      documents,
      edit,
      eligibility,
      facilities,
      forms,
      files,
      jobs,
      personnel,
      polygraphsV2,
      receipts,
      reportables,
      reportableIncidents,
      requirements,
      risk,
      tags,
      tasks,
      tickets,
      training,
      travels,
      visits,
    },
  };
};

export const sharedRoots = [
  'details',
  'action-requests',
  'accesses',
  'accesses-eligibilities',
  'assets',
  'badges',
  'containers',
  'contracts',
  'eligibility',
  'facilities',
  'files',
  'foreign-travel',
  'forms',
  'reportable-info',
  'reportable-incidents',
  'people',
  'polygraphs',
  'polygraphsV2',
  'preferences',
  'security',
  'settings',
  'risk',
  'tags',
  'tasks',
  'tickets',
  'training',
  'travels',
  'visits',
  'actions',
];

const adminRoots = [
  'people',
  'issue-types',
  'insights',
  'organization',
  'settings',
  { path: 'roles', basePath: 'settings' },
  { path: 'custom-fields', basePath: 'settings' },
  { path: 'integrations', basePath: 'settings' },
  { path: 'account', basePath: 'settings' },
  { path: 'accesses', basePath: 'settings/account' },
  { path: 'eligibility', basePath: 'settings/account' },
  { path: 'polygraphs', basePath: 'settings/account' },
  { path: 'visits', basePath: 'organization' },
  { path: 'training', basePath: 'organization' },
  { path: 'forms', basePath: 'organization' },
  { path: 'files', basePath: 'organization' },
  { path: 'action-requests', basePath: 'organization' },
  { path: 'activity', basePath: 'insights' },
  { path: 'analytics', basePath: 'insights' },
];

const myRoots = [
  'profile',
  'actions',
  'assignments',
  { path: 'visits', basePath: 'actions' },
  { path: 'action-requests', basePath: 'actions' },
  { path: 'foreign-travel', basePath: 'actions' },
  { path: 'reportable-info', basePath: 'actions' },
  { path: 'training', basePath: 'assignments' },
  { path: 'forms', basePath: 'assignments' },
];

export const buildPaths = (basePath, roots) => {
  const paths = {};
  roots.forEach((val) => {
    if (val.path) {
      paths[val.name ? val.name : val.path] = pathBuilder(
        [basePath, val.basePath, val.path].filter((obj) => obj).join('/')
      );
    } else {
      paths[val] = pathBuilder(`${basePath}/${val}`);
    }
  });

  return paths;
};

export const usePaths = ({ adminBasePath, basePath, isAdminContext, profile }) => {
  const admin = buildPaths(adminBasePath, sharedRoots.concat(adminRoots));
  const my = buildPaths(basePathV2, sharedRoots.concat(myRoots));

  // Context is intended as a dynamic path definition.  I.e. for components/nav elements that
  // are shared between contexts but are mounted in different paths.
  return {
    labsDisable: '/v2/disable',
    labsEnable: '/v2/enable',
    manageHomePath: adminBasePath,
    myHomePath: appBasePath,
    logoutPath,
    admin,
    my,
    context: isAdminContext ? admin : my,
  };
};

export default defaultPath;
