import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import { Switch } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths, {
  trainingCoursePaths,
  trainingAssignmentPaths,
  employeeTrainingAssignmentPaths,
} from 'config/paths/training';

import AppContent from 'layouts/v2/AppContent';

// Components
const Index = lazy(() => import('./index'));
const CourseDetail = lazy(() => import('./Courses/Detail'));
const AssignmentDetail = lazy(() => import('./Assignments/Detail'));

/*
 * Important thing to note about Resourceful layouts.
 *
 * If you want sublayouts such as those defined in "Detail" to successfully render
 * you cannot use exact paths, you are partial matching on the parent to ensure
 * the top level layout always gets loaded so that any child pages also get loaded.
 */

export const TrainingReduxManager = ({ children }) => {
  const data = [
    'contractsDropdown',
    'customRolesDropdown',
    'facilitiesDropdown',
    'tagNamesDropdown',
    'trainingsDropdown',
    'typesDropdown',
  ];
  return <ReduxManager data={data}>{children}</ReduxManager>;
};

export const Routes = ({ paths, permissions, profile }) => {
  // Redux Manager can handle loading any data necessary for the feature to function
  // see the component definition for available data/actions
  const rootRedirect = permissions.training.view
    ? trainingCoursePaths.root
    : employeeTrainingAssignmentPaths.list(profile.id);

  return (
    <AppContent>
      <TrainingReduxManager>
        <Route exact path={paths.root}>
          <Redirect to={rootRedirect} />
        </Route>
        <Suspense fallback={<></>}>
          <Switch>
            {permissions.training.view && (
              <Route path={trainingCoursePaths.resource.root}>
                <CourseDetail />
              </Route>
            )}
            <Route path={trainingAssignmentPaths.resource.root}>
              <AssignmentDetail />
            </Route>
            <Route path={paths.root}>
              <Index />
            </Route>
          </Switch>
        </Suspense>
      </TrainingReduxManager>
    </AppContent>
  );
};

export default connect(
  ({ profile }) => ({
    ...mapPermissionsToProps({ profile }),
    profile,
  }),
  null
)((props) => <Routes paths={paths} {...props} />);
