import React from 'react';

// Icons
// import AddAlertIcon from '@mui/icons-material/AddAlert';
// import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

// MUI
// import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import { styled } from '@mui/material/styles';

// Components
// import BasicForm from './Basic';
// import CardButton from 'components/v2/FormElements/CardButton';
// import DialogButtons from 'components/v2/FormElements/DialogV2/Buttons';
import NotifyGrid from 'components/v2/Reportables/Notify';
// import TicketForm from './Ticket';
import TswDialog from 'components/v2/FormElements/DialogV2';

import { withFeatureState } from '../state';

// const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
//   display: 'grid',
//   gridAutoColumns: '1fr',
//   gap: '0',
// }));

export const Dialog = ({ onClose, open, profile, ...rest }) => {
  // const [type, setType] = React.useState('reportable');

  // const isEmployee = profile?.role === 'employee';

  // const handleChange = (event, type) => {
  //   if (type !== null) {
  //     setType(type);
  //   }
  // };

  // const handleClick = () => {
  //   console.log('handleClick'); // eslint-disable-line
  //   onClose();
  // };

  // const modalActions = (
  //   <DialogButtons
  //     onCancel={onClose}
  //     onClick={handleClick}
  //     // disabled={disabled}
  //     clickText={'Next'}
  //   />
  // );

  const modalProps = {
    onClose,
    title: 'Select Notification Type',
    open,
    maxWidth: 'xl',
    fullWidth: true,
    // actions: type === 'ticket' && modalActions,
  };

  return (
    <TswDialog {...modalProps}>
      {/* {isEmployee && <BasicForm onClose={onClose} />}

      {!isEmployee && (
        <>
          <StyledToggleButtonGroup value={type} exclusive onChange={handleChange}>
            <CardButton icon={<AddAlertIcon />} label="New Report" value="reportable" />
            <CardButton icon={<ConfirmationNumberIcon />} label="New Ticket" value="ticket" />
          </StyledToggleButtonGroup>

          {type === 'reportable' && <NotifyGrid onClose={onClose} />}
          {type === 'ticket' && <TicketForm />}
        </>
      )} */}

      <NotifyGrid onClose={onClose} />
    </TswDialog>
  );
};

export default withFeatureState(Dialog);
