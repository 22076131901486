import actionBuilder, { create } from 'store/v2/actions';
import actionType, { bulkActionType, apiPath } from './constants';

const transfer = (id, payload, silent = false) => {
  return create(`${actionType}_TRANSFER`, `${apiPath}/${id}/transfer`, { ...payload, silent });
};

const actions = {
  ...actionBuilder({ apiPath, bulkActionType, actionType }),
  transfer,
};

export default actions;
