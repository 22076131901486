import React from 'react';
import { connect } from 'react-redux';

/// FormElements
import TswForm, { useForm, useRequiredFields } from 'components/v2/FormElements';
import DialogButtons from 'components/v2/FormElements/Dialog/Buttons';
import TextField from 'components/v2/FormElements/TextField';
import { FacilitiesAutocomplete } from 'components/v2/FormElements/Autocomplete';
import Switch from 'components/v2/FormElements/Switch';
import ListItem from 'components/v2/FormElements/ListItem';

import { adaptToForm } from 'adapters/v1/documents/formData';
import FileDrop from 'components/v1/FileDrop';

// material
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';

// styles
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  orItem: {
    fontSize: '1.25rem',
    textAlign: 'center',
  },
  switch: {
    marginLeft: 0,
    '& span': {
      fontSize: '0.9rem',
      fontWeight: '300',
    },
  },
}));

const Form = ({
  callback,
  loading = false,
  disabled = false,
  resource = {},
  debug = false,
  afterCancel,
}) => {
  const classes = useStyles();
  const validate = useRequiredFields(['name']);
  const initialData = { ...adaptToForm(resource) };

  // The useForm hook abstracts out all of the data management logic
  // from the form implementation.  This allows us to focus on
  // display and business logic in the form.
  const {
    handleSubmit,
    handleCancel,
    getRequiredTextProps,
    getSelectHandlerProps,
    getTextFieldHandlerProps,
    getSwitchHandlerProps,
    formData,
    setFormData,
  } = useForm({
    validate,
    resource: initialData,
    afterCancel,
    callback,
  });

  const handleFileChange = (file) => {
    const { content, type, name, size } = file[0];

    setFormData({
      ...formData,
      name,
      file: {
        content,
        content_type: type,
        filename: name,
        size: (size / 1000000).toFixed(2) + 'MB',
      },
    });
  };

  const isLink = formData && formData.link;
  const isFile = formData && formData.file;
  const formStart = !isLink && !isFile;
  const formReady = isLink || isFile;

  const FormSwitch = (props) => (
    <Switch {...getSwitchHandlerProps('employee_viewable')} disabled={disabled} {...props} />
  );

  const prependFacilitiesOptions = [{ title: 'All CAGE codes', label: 'all' }];

  return (
    <TswForm>
      {debug && JSON.stringify(formData)}
      <List>
        {!isLink && (
          <ListItem>
            <FileDrop onChange={handleFileChange} />
          </ListItem>
        )}

        {formStart && (
          <ListItem>
            <Box className={classes.orItem}>OR</Box>
          </ListItem>
        )}

        {!isFile && (
          <ListItem>
            <TextField
              {...getTextFieldHandlerProps('link')}
              label="Paste URL for File"
              disabled={disabled}
              placeholder="Paste URL for File"
            />
          </ListItem>
        )}

        {formReady && (
          <ListItem>
            <TextField
              {...getTextFieldHandlerProps('name')}
              {...getRequiredTextProps('name')}
              label="Name"
              disabled={disabled}
              placeholder="File Name"
            />
          </ListItem>
        )}

        {formReady && (
          <ListItem>
            <FacilitiesAutocomplete
              disabled={disabled}
              {...getSelectHandlerProps('facility')}
              value={formData.facility || prependFacilitiesOptions[0]}
              multiple={false}
              label="Facility CAGE Code"
              prependOptions={prependFacilitiesOptions}
            />
          </ListItem>
        )}

        {formReady && (
          <ListItem>
            <TextField
              {...getTextFieldHandlerProps('description')}
              multiline
              label="File Description"
              disabled={disabled}
              placeholder="File description"
            />
          </ListItem>
        )}

        {formReady && (
          <ListItem>
            <Alert severity="info">
              <a
                href="https://help.threatswitch.com/en/articles/3179366-how-do-i-make-a-company-or-industry-standard-document-available-to-employees"
                rel="noopener noreferrer"
                target="_blank"
              >
                Toggles visibility in employee's Files list (subject to Facility CAGE Code setting)
              </a>
            </Alert>
            <FormControlLabel
              control={<FormSwitch />}
              className={classes.switch}
              label={"Allow users with 'employee' role to view this document"}
            />
          </ListItem>
        )}
      </List>

      {formReady && (
        <DialogButtons
          onCancel={handleCancel}
          loading={loading}
          onClick={handleSubmit}
          disabled={disabled}
        />
      )}
    </TswForm>
  );
};

export default connect()(Form);
