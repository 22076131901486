import { pathBuilder } from 'config/paths/index';

export const root = '/training';

export const employeeRoot = (userId) => `/users/${userId}/training`;

const employeeAssignmentDetail = (id, userId) => {
  return `${employeeRoot(userId)}/${id}/details`;
};

export const trainingCoursePaths = pathBuilder(`${root}/courses`);
export const trainingAssignmentPaths = pathBuilder(`${root}/assignments`);
export const employeeTrainingAssignmentPaths = {
  list: employeeRoot,
  resource: {
    detail: employeeAssignmentDetail,
  },
};
export default pathBuilder(root);
