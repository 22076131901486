import React from 'react';
import IndeterminateCheckbox, { IndeterminateTooltipCheckbox } from './Checkbox';

export const useDataFilter = ({ data, filterKey, filterValue, refresh, jsonApi = false }) => {
  if (!filterValue || !filterKey) return data;
  if (!Array.isArray(data)) return [];

  if (jsonApi) {
    return data.filter((obj) => obj.attributes[filterKey] === filterValue);
  }

  return data.filter((obj) => obj[filterKey] === filterValue);
};

export const useStaticQuery = ({ query, queryKey, resourceId, refresh = true }) => {
  React.useState(() => {
    if (!query || !refresh || !query.filters) return;
    delete query.filters[queryKey];
  }, []);

  if (query) {
    let resourceFilter = {};

    if (resourceId) {
      resourceFilter = { ...resourceFilter, [queryKey]: [resourceId] };
    }

    if (query.filters) {
      query.filters = { ...query.filters, ...resourceFilter };
    } else {
      query.filters = resourceFilter;
    }
  }

  return query;
};

// Build on a column using a custom hook
const useSelectionColumn = (instance) => {
  instance.visibleColumns.push((columns) => {
    const selectionColumns = [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        ),
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
    ];
    return [...selectionColumns, ...columns];
  });
};

export const DisabledTooltipSelectionColumn = (instance, disabled = false, tooltip) => {
  instance.visibleColumns.push((columns) => {
    const selectionColumns = [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => {
          const checkProps = getToggleAllRowsSelectedProps();

          const handleOnChange = (props) => {
            return checkProps.onChange(props);
          };

          checkProps.disabled = disabled;
          checkProps.tooltip = tooltip;

          return <IndeterminateTooltipCheckbox {...checkProps} onChange={handleOnChange} />;
        },
        Cell: ({ row }) => (
          <div>
            <IndeterminateTooltipCheckbox
              {...row.getToggleRowSelectedProps()}
              disabled={disabled}
              tooltip={tooltip}
            />
          </div>
        ),
      },
    ];
    return [...selectionColumns, ...columns];
  });
};

export default useSelectionColumn;
