import React from 'react';
import { connect } from 'react-redux';

/// FormElements
import TswForm, { useDetailForm, useRequiredFields } from 'components/v2/FormElements';
import SavePrompt from 'components/v2/FormElements/SavePrompt';
import DialogButtons from 'components/v2/FormElements/Dialog/Buttons';
import TextField from 'components/v2/FormElements/TextField';
import AddDocument from 'components/v2/FormElements/AddDocument';
import SortableList from 'components/v2/FormElements/SortableList';
import Section from 'components/v2/FormElements/Section';
import AttachmentItem from 'components/v2/PageElements/Attachments/Item';

import { mapPreferencesToProps } from 'mappers/v1/preferences';

// material
import List from '@mui/material/List';

//components
import ListItem from 'components/v2/FormElements/ListItem';

// adapter
import { adaptToForm } from 'adapters/v1/digitalFormTemplates/formData';

// ikons
import InfoIcon from '@mui/icons-material/Info';
import SettingsIcon from '@mui/icons-material/Settings';

const Form = ({
  preferences,
  callback,
  loading = false,
  disabled = false,
  resource = {},
  debug = false,
  isDialog = false,
  afterCancel,
}) => {
  const validate = useRequiredFields(['title', 'signer_roles']);
  const signer_roles = [{ label: 'Name', title: null }];

  const {
    handleSubmit,
    handleCancel,
    getTextFieldHandlerProps,
    getRequiredTextProps,
    formData,
    formEditMode,
    setFormData,
  } = useDetailForm({
    validate,
    resource: adaptToForm(resource),
    afterCancel,
    callback,
    defaults: { signer_roles, files: {} },
  });

  const hasUpload = formData && formData.files && formData.files.filename;

  const onUpload = (props) => {
    setFormData({
      ...formData,
      files: { ...props },
    });
  };

  const onUploadDelete = () => {
    setFormData({
      ...formData,
      files: {},
    });
  };

  const addDocumentProps = {
    description: 'Upload Document',
    onUpload,
  };

  const handleSorted = (values) => {
    setFormData({
      ...formData,
      signer_roles: [...values],
    });
  };

  return (
    <TswForm>
      {debug && JSON.stringify(formData)}
      <List>
        <Section title="Details" Icon={InfoIcon}>
          <ListItem>
            <TextField
              {...getTextFieldHandlerProps('title')}
              {...getRequiredTextProps('title')}
              label="Title"
              disabled={disabled}
            />
          </ListItem>
          <ListItem>
            {!hasUpload && <AddDocument {...addDocumentProps} />}
            {hasUpload && (
              <>
                <AttachmentItem
                  attachment={{
                    ...formData.files,
                    name: formData.files.filename,
                    text: formData.files.filename,
                  }} // legacy certificate uploads all use 'data' as the name
                  destroy={onUploadDelete}
                  permissions={{ destroy: true, skipId: true }}
                />
              </>
            )}
          </ListItem>
        </Section>
        <Section title="Signature Configuration" Icon={SettingsIcon}>
          <ListItem>
            <SortableList
              disabled={disabled}
              allowEmpty={false}
              value={formData.signer_roles || []}
              itemLabel="Signing Roles"
              addLabel="Add Roles for Additional Signers"
              onChange={handleSorted}
              textFieldProps={{ ...getRequiredTextProps('signer_roles') }}
            />
          </ListItem>
        </Section>
      </List>

      {!isDialog && !disabled && formEditMode && (
        <SavePrompt onCancel={handleCancel} loading={loading} onClick={handleSubmit} />
      )}

      {isDialog && (
        <DialogButtons
          onCancel={handleCancel}
          loading={loading}
          onClick={handleSubmit}
          disabled={disabled}
        />
      )}
    </TswForm>
  );
};

export default connect(mapPreferencesToProps)(Form);
