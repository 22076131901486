import CONST from './constants';

const initialState = {
  data: {},
  meta: {},
  query: {},
  filters: {},
  result: [],
  loading: false,
  initialState: true,
};

export function analyticsReports(state = initialState, { type, payload, error }) {
  switch (type) {
    case CONST.LIST_ANALYTICS_REPORTS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.LIST_ANALYTICS_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        initialState: false,
        data: payload.reduce((all, row) => {
          const id = row.id;
          all[id] = row;

          return all;
        }, {}),
        result: payload.map((d) => d.id),
      };
    case CONST.LIST_ANALYTICS_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        initialState: false,
      };
    default:
      return state;
  }
}

export function analyticsDashboards(state = initialState, { type, payload, error }) {
  switch (type) {
    case CONST.LIST_ANALYTICS_DASHBOARDS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.LIST_ANALYTICS_DASHBOARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        initialState: false,
        data: payload.reduce((all, row) => {
          const id = row.id;
          all[id] = row;

          return all;
        }, {}),
        result: payload.map((d) => d.id),
      };
    case CONST.LIST_ANALYTICS_DASHBOARDS_FAILURE:
      return {
        ...state,
        loading: false,
        initialState: false,
      };
    default:
      return state;
  }
}

export function analyticsExplores(state = initialState, { type, payload, error }) {
  switch (type) {
    case CONST.LIST_ANALYTICS_EXPLORES_PENDING:
      return {
        ...state,
        loading: false,
        initialState: false,
      };
    case CONST.LIST_ANALYTICS_EXPLORES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload.reduce((all, row) => {
          const id = row.name;
          all[id] = row;

          return all;
        }, {}),
        result: payload.map((d) => d.name),
      };
    case CONST.LIST_ANALYTICS_EXPLORES_FAILURE:
      return {
        ...state,
        loading: false,
        initialState: false,
      };
    default:
      return state;
  }
}
