import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

// actions
import badgeActions from 'store/v2/badgeControls/actions';

// adapters
import { adaptToDetail as adaptBadgeToDetail } from 'adapters/v1/badgeControls/formData';

const stateBuilder = () => {
  const mapStateToProps = ({ badgeControls, profile, userAccesses, userEligibilities }) => {
    return {
      badges: Object.values(badgeControls.data || {}).map(adaptBadgeToDetail),
      profile,
      loading: badgeControls.loading || userAccesses.loading || userEligibilities.loading,
      ...mapPermissionsToProps({ profile }),
      ...mapPreferencesToProps({ profile }),
    };
  };

  const mapDispatchToProps = {
    listBadges: (queryParams) =>
      badgeActions.list({
        ...queryParams,
        include: 'user,facility,contract',
        showAll: true,
      }),
  };

  return {
    withFeatureState: (Component) => {
      return withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));
    },
  };
};

export const { withFeatureState } = stateBuilder();
