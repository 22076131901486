import React from 'react';

const Ce = ({ height = '36px', width = '36px', ...rest }) => (
  <svg height={height} width={width} viewBox="0 0 17 17" {...rest}>
    <g transform="translate(-34.886 -128.58)">
      <path
        d="m37.253 131.44a8.283 8.283 0 0 0 -2.1835 5.6039 8.283 8.283 0 0 0 8.283 8.283 8.283 8.283 0 0 0 8.283 -8.283 8.283 8.283 0 0 0 -8.283 -8.283 8.283 8.283 0 0 1 -0.35046 7e-3"
        fill="none"
        stroke="#28648a"
        stroke-width=".8173"
      />
      <g>
        <ellipse
          cx="43.352"
          cy="137.04"
          rx="5.7842"
          ry="5.7842"
          fill="#28648a"
          stroke="#28648a"
          stroke-width=".029066"
        />
        <path
          d="m36.079 130.62 2.762-0.49132-1.0543 2.5228z"
          fill="#28648a"
          stroke="#28648a"
          stroke-linejoin="round"
          stroke-width=".97646"
        />
        <g
          transform="matrix(.83719 0 0 .83719 7.0583 22.312)"
          fill="#fff"
          stroke-width=".17032"
          aria-label="CE"
        >
          <path d="m43.233 134.94v0.70857q-0.33932-0.31603-0.72521-0.47238-0.38256-0.15635-0.81503-0.15635-0.85162 0-1.304 0.52228-0.45242 0.51896-0.45242 1.5036 0 0.98136 0.45242 1.5036 0.45242 0.51896 1.304 0.51896 0.43246 0 0.81503-0.15635 0.38589-0.15636 0.72521-0.47239v0.70193q-0.35262 0.23951-0.7485 0.35927-0.39254 0.11976-0.83166 0.11976-1.1277 0-1.7764-0.68861-0.6487-0.69195-0.6487-1.8862 0-1.1976 0.6487-1.8862 0.6487-0.69195 1.7764-0.69195 0.44577 0 0.83832 0.11976 0.39587 0.11644 0.74184 0.35263z" />
          <path d="m44.277 134.56h3.1404v0.56553h-2.4684v1.4704h2.3652v0.56553h-2.3652v1.7997h2.5283v0.56553h-3.2002z" />
        </g>
      </g>
    </g>
  </svg>
);

export default Ce;
