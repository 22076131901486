import { isAnyAdmin } from 'permissions/v1/roles';
import { canList, canCreate, canUpdate, canDestroy, noPermissions } from 'permissions/v1/index';

import { hasCustomFields } from 'permissions/v1/preferences';

const permission = 'CustomField';

// TEMPLATE TODO: Update permissions as necessary.
const list = (profile) => isAnyAdmin(profile) || canList(permission, profile);
const view = (profile) => true;
const create = (profile) => isAnyAdmin(profile) || canCreate(permission, profile);
const update = (profile) => isAnyAdmin(profile) || canUpdate(permission, profile);
const destroy = (profile) => isAnyAdmin(profile) || canDestroy(permission, profile);

const permissions = (profile) => {
  if (!hasCustomFields(profile)) return noPermissions;

  return {
    list: list(profile),
    view: view(profile),
    create: create(profile),
    update: update(profile),
    destroy: destroy(profile),
  };
};

export default permissions;
