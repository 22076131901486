import React from 'react';
import { withFeatureState } from './state';
import Dialog from './Dialog';
import { adaptToRequest } from 'adapters/v1/digitalFormTemplates/formData';

export const Create = ({ onClose, create, history, paths, ...rest }) => {
  const callback = async (formData) => {
    if (!formData || !create) return;
    const adapted = adaptToRequest(formData);
    const resp = await create(adapted);

    if (!resp.success) return;
    history.push(paths.resource.detail(resp.id));
    onClose();
  };

  const ownProps = {
    title: 'Create',
    onClose,
    callback,
  };
  return <Dialog {...ownProps} {...rest} />;
};

export default withFeatureState(Create);
