import { BuildSearchFilter } from './Search';
import { BuildBasicSelectFilter } from './MultiSelect';

// import { adaptCustomFieldsToLabels } from 'adapters/v1';
// import { JsonSchemaComponent, schemaParser } from 'components/v2/FormElements/JsonSchema';

export const mapCustomFilterType = ({ field = '', schema = {} }) => {
  const cf = schema?.properties?.[field?.value];
  // const widgetType = cf?.widget?.type;
  const type = cf?.type;

  if (type === 'general_select') {
    // Instance fields options are top level
    // Model fields are nested under json_fields
    const field_options = cf.options
      ? cf.options.map(({ label }) => label)
      : cf?.json_fields?.options;
    const mapped_options = field_options?.map((o) => ({ label: o, value: o }));

    return BuildBasicSelectFilter({
      name: field?.value,
      displayName: field?.label,
      options: mapped_options,
    });
  } else {
    return BuildSearchFilter({ name: field?.value, displayName: field?.label });
  }
};
