import { isAdmin } from 'permissions/v1/roles';
import { noPermissions } from 'permissions/v1';
import { isProduction } from 'config';
import { hasCustomRole } from 'permissions/v1/preferences';

const condition = (profile) => isAdmin(profile) && hasCustomRole(profile);

const list = (profile) => condition(profile);
const view = (profile) => condition(profile);
const create = (profile) => condition(profile);
const update = (profile) => condition(profile);
const destroy = (profile) => condition(profile);

const permissions = (profile) => {
  if (isProduction) return noPermissions;

  return {
    list: list(profile),
    view: view(profile),
    create: create(profile),
    update: update(profile),
    destroy: destroy(profile),
  };
};

export default permissions;
