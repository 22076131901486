import { createTheme } from '@mui/material/styles';

import baseline, { baselinePalette } from '../baseline';
import { blueLight, blueDark, blueMed, blueShade, alert, success } from 'lib/v2/colors';

export const primary = blueDark;
export const secondary = blueMed;
const backgroundDefault = '#fff';

const defaultTheme = createTheme();
export const theme = createTheme({
  ...baseline,
  palette: {
    ...baselinePalette,
    primary: { main: primary },
    secondary: { main: secondary },
    info: { main: secondary, light: blueShade, contrastText: '#fff' },
    alert: { main: alert },
    success: { main: success },
    // text: { primary: charcoal, secondary: ash, disabled: buttonDisabled },
    background: { default: blueLight, paper: '#fff' },
    appbar: defaultTheme.palette.augmentColor({
      color: { main: backgroundDefault, border: blueLight },
      name: 'navbar',
    }),
    mode: 'light',
  },
  components: {
    ...baseline.components,
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          textFillColor: secondary,
          color: secondary,
          '&.Mui-disabled': {
            backgroundColor: '#EAF0F5',
            '& button': {
              display: 'none',
            },
          },
        },
      },
    },
  },
});

export default theme;
