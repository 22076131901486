import React from 'react';
import store from 'store';

import Slider from '@mui/material/Slider';
import FilterFormFactory from 'components/v2/Filters/Factories';

import Box from '@mui/material/Box';

export const Container = ({ children, width = 500 }) => (
  <Box style={{ padding: '20px 20px 0 20px', maxWidth: width, margin: '0 auto' }}>{children}</Box>
);

const RangeSlider = ({ callback, max = 50, min = 0, ...rest }) => {
  const [value, setValue] = React.useState([min, max]);

  const handleChange = (e, value) => {
    setValue(value);

    if (!callback) return;
    callback(value);
  };

  const sliderProps = { ...rest, callback, max, min, value };

  return <Slider disableSwap size="small" onChange={handleChange} {...sliderProps} />;
};

const riskMap = { 0: [0, 0], 1: [1, 3], 2: [4, 5], 3: [6, 9], 4: [10, 10] };
const risk_label = ['No Information', 'Low', 'Caution', 'Needs Attention', 'Urgent'];

export const RiskSlider = ({ max = 4, min = 0, ...rest }) => {
  const marksFromCount = (count) => {
    const array = [];
    for (let i = 0; i <= count; i++) {
      array.push({ value: (max / count) * i });
    }
    return array;
  };
  const marks = marksFromCount(max);

  const format = (x) => risk_label[x];

  const riskProps = {
    ...rest,
    displayName: 'Risk Score',
    disableSwap: true,
    marks,
    valueLabelDisplay: 'on',
    valueLabelFormat: format,
    max,
    min,
  };

  return (
    <Container>
      <RangeSlider {...riskProps} />
    </Container>
  );
};

export const RiskSliderDialog = ({ displayName, name, updateQuery, ...rest }) => {
  const onSubmit = (range) => {
    const labels = range.map((i) => risk_label[i]).join(' - ');
    const _displayName = `${displayName}: ${labels}`;
    const mappedRange = range.map((num, i) => riskMap[num][i]);

    store.dispatch(
      updateQuery({
        filters: { [name]: { values: mappedRange, displayName: _displayName } },
      })
    );
  };

  const FilterForm = FilterFormFactory(RiskSlider);

  const formProps = { ...rest, onSubmit, displayName };
  return <FilterForm {...formProps} />;
};

export default RangeSlider;
