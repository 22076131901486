import React from 'react';

import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';

const navPadding = '1.75rem';
export const navWidth = '9.2rem';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  height: 'calc(100% - 66px)',
  marginRight: `-${navPadding}`,
  position: 'relative',
  left: '-1.5rem',
}));

export const Content = styled('article')(({ theme }) => ({
  padding: '0rem 1rem 5.25rem',
  flexGrow: 1,
  // maxWidth: `calc(100% - ${navWidth})`,
}));

export const StyledMenuList = styled(MenuList)(({ theme }) => ({
  width: navWidth,
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: 0,
}));

const StyledLink = styled(NavLink)(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  fontSize: '0.875rem',
  height: '2.1rem',
  paddingLeft: '0.85rem',
  paddingRight: '0.85rem',
  width: '100%',
  '&.activeLink': {
    background: theme.palette.navbar.main,
    fontWeight: 'bold',
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledSidebar = styled('aside')(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const NavItem = ({ endpoint, label }) => {
  return (
    <StyledMenuItem>
      <StyledLink key={label} to={endpoint} activeClassName="activeLink">
        {label}
      </StyledLink>
    </StyledMenuItem>
  );
};

const NavGroup = ({ items, lastGroup }) => {
  return (
    <>
      <StyledMenuList>
        {items
          .filter(({ allowed }) => allowed)
          .map((item) => (
            <NavItem key={item.label} {...item} />
          ))}
      </StyledMenuList>
      {!lastGroup && <Divider />}
    </>
  );
};

const SecondaryNav = ({ children, groupedItems }) => {
  const groups = Object.keys(groupedItems);
  const lastGroup = groups[groups.length - 1];

  return (
    <Wrapper>
      {groups.length > 0 && (
        <StyledSidebar>
          {groups.map((group) => (
            <NavGroup key={group} items={groupedItems[group]} lastGroup={group === lastGroup} />
          ))}
        </StyledSidebar>
      )}
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default SecondaryNav;
