import cx from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { styled } from '@mui/material/styles';

export const StyledDivider = styled('div')({
  paddingBottom: '10px',
  height: '100%',
});

const TabView = (props) => {
  const pages = props.pages || [];
  const { length } = pages;
  const currentPath = props.location.pathname;
  const onClick = length === 1 ? (e) => e.preventDefault() : null;
  const filterPath = (path) => {
    if (path) {
      return path
        .split('/')
        .filter((val) => val !== ':id')
        .filter((val) => isNaN(val))
        .join('/');
    }

    return null;
  };

  const active = (obj) => filterPath(currentPath).match(filterPath(obj.path)) || length === 1;

  const exactMatch = (obj) => currentPath === obj.path;

  return (
    <>
      <section className="Manage-navigation">
        <nav className="Manage-navList">
          {pages.map((object, i) => (
            <Link
              className={cx('Manage-route', {
                'is-active': object.exact ? exactMatch(object) : active(object),
              })}
              onClick={onClick}
              key={object.title}
              to={object.path}
            >
              {object.title}
            </Link>
          ))}
        </nav>
        <StyledDivider />
      </section>
      {props.children}
    </>
  );
};

export default withRouter(TabView);
