import CONST from './constants';

const initialState = {
  loading: false,
  new: [],
  updates: [],
  errors: [],
  data: [],
  imported: 0,
  result: [],
  users_not_found: [],
  meta: { total_count: 0 },
};

export const arrayToObj = (arr) => {
  return arr.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});
};

const index = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case CONST.JPAS_UPDATE_USERS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.JPAS_UPDATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        imported: payload.data,
      };

    case CONST.JPAS_USERS_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.JPAS_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: arrayToObj(payload.data),
        meta: { ...payload.meta },
        result: payload.data.map((d) => d.id),
      };
    case CONST.REFRESH_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.REFRESH_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CONST.DIFF_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.DIFF_SUCCESS:
      return {
        ...state,
        loading: false,
        diff: payload,
      };
    case CONST.UPLOAD_PENDING:
      return {
        ...state,
        loading: true,
      };
    case CONST.UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        users_not_found: payload.users_not_found,
      };
    case CONST.IMPORT_PENDING:
      return {
        ...state,
      };
    case CONST.IMPORT_SUCCESS:
      return {
        ...state,
        updates: (payload.updates || [])
          .filter((user) => Object.keys(user.field_deltas).length)
          .map((user) => ({
            ...user,
            selected: true,
          })),
        new: payload.new.map((user) => ({
          ...user,
          selected: true,
        })),
      };
    case CONST.CHANGE_UPDATES:
      return {
        ...state,
        updates: state.updates.map((u, i) => {
          if (payload.indexes.has(i)) {
            return {
              ...u,
              ...payload.update,
            };
          }
          return u;
        }),
      };
    case CONST.CHANGE_NEW:
      return {
        ...state,
        new: state.new.map((n, i) => {
          if (payload.indexes.has(i)) {
            return {
              ...n,
              ...payload.update,
            };
          }
          return n;
        }),
      };
    case CONST.IMPORT_FAILURE:
      return {
        ...state,
      };
    case CONST.RESET_IMPORT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default index;
