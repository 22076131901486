import { createTheme } from '@mui/material/styles';

import baseline, { baselinePalette } from '../baseline';
import { blueLight, alert, ash, success, blueMed, blueShade, charcoal } from 'lib/v2/colors';

export const primary = blueShade;
export const secondary = blueShade;
const backgroundDefault = charcoal;

const defaultTheme = createTheme();
export const theme = createTheme({
  ...baseline,
  palette: {
    ...baselinePalette,
    primary: { main: primary },
    secondary: { main: secondary },
    info: { main: blueMed, dark: blueMed, contrastText: '#fff' },
    alert: { main: alert },
    success: { main: success },
    // text: { primary: '#fefefe', secondary: '#eee', disabled: buttonDisabled },
    background: { default: backgroundDefault, paper: ash },
    appbar: defaultTheme.palette.augmentColor({
      color: { main: backgroundDefault, border: blueLight },
      name: 'navbar',
    }),
    mode: 'dark',
  },
  components: {
    ...baseline.components,
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: ash,
          '&.Mui-disabled': {
            backgroundColor: 'inherit',
            textFillColor: secondary,
            color: secondary,
            '& button': {
              display: 'none',
            },
          },
        },
      },
    },
  },
});

export default theme;
