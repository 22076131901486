import { isAnyAdmin } from 'permissions/v1/roles';
import { hasForms } from 'permissions/v1/preferences';
import {
  noPermissions,
  canList,
  canView,
  canCreate,
  canUpdate,
  canDestroy,
} from 'permissions/v1/index';

const permission = 'DigitalForm';

const list = (profile) => isAnyAdmin(profile) || canList(permission, profile);
const view = (profile) => isAnyAdmin(profile) || canView(permission, profile);
const create = (profile) => isAnyAdmin(profile) || canCreate(permission, profile);
const update = (profile) => isAnyAdmin(profile) || canUpdate(permission, profile);
const destroy = (profile) => isAnyAdmin(profile) || canDestroy(permission, profile);

const permissions = (profile) => {
  if (!hasForms(profile)) return noPermissions;

  return {
    list: list(profile),
    view: view(profile),
    create: create(profile),
    update: update(profile),
    destroy: destroy(profile),
  };
};

export default permissions;
