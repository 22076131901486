import React from 'react';
import withFeatureState from './Navigation/state';
import { useAppContext } from 'components/v2/AppContext';

// Redux State Mappers

import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';

// mui
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';

// icons
import MenuIcon from '@mui/icons-material/Menu';

// Components
import CompanyLogo from 'components/v2/PageElements/CompanyLogo';
import Navigation from './Navigation';

import AccountMenu from './AccountMenu';

// lib
import { charcoal } from 'lib/v2/colors';

// styles
const drawerWidth = 240;
const appbarHeight = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 'auto !important',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    paddingTop: appbarHeight + 5,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.appBar,
    height: appbarHeight,
  },
  appBarAdmin: {
    backgroundColor: charcoal,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawer: {
    zIndex: `${theme.zIndex.appBar - 1} !important`,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    zIndex: `${theme.zIndex.appBar - 1} !important`,
  },
  content: {
    boxSizing: 'border-box',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(10),
    paddingTop: appbarHeight + 10,
    background: '#fff',
    flex: 1,
    height: '100%',
  },
  headerTitle: {
    padding: '0 0 0 2rem',
  },

  headerAction: {
    marginLeft: 'auto',
  },
  headerText: {
    paddingTop: '0',
    paddingBottom: '0',
  },
  headerSubtitle: { paddingTop: '0' },
  headerContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  actionText: {
    '@media (max-width: 900px)': {
      display: 'none !important',
    },
  },
  icon: { paddingRight: theme.spacing(1) },
  logo: { marginTop: 10 },
}));

const Component = ({ history, title = 'ThreatSwitch', children }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { isAdminContext } = useAppContext();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className={classes.toolbar}>
      <Navigation />
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarAdmin]: isAdminContext,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Box className={classes.headerContainer}>
            <Box className={classes.logo}>
              <CompanyLogo />
            </Box>

            <Box className={classes.headerTitle}>
              <Typography variant="h6" noWrap className={classes.headerText}>
                {title}
              </Typography>
              {!isAdminContext && (
                <Typography variant="subtitle1" noWrap className={classes.headerSubtitle}>
                  {'My ThreatSwitch'}
                </Typography>
              )}
              {isAdminContext && (
                <Typography variant="subtitle1" noWrap className={classes.headerSubtitle}>
                  {'Management Console'}
                </Typography>
              )}{' '}
            </Box>
            <Box className={classes.headerAction}>
              <AccountMenu />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawerContainer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              root: classes.drawer,
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              root: classes.drawer,
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>{children}</main>
    </div>
  );
};

export default withFeatureState(Component);
