import { Link, Text } from 'components/v2/FlexTable/Cells';

import facilityPaths from 'config/paths/facilities';
import peoplePaths from 'config/paths/people';

export const cellAdapterFactory = (adaptToDetail) => {
  return (key) => {
    return ({ original }) => Text(adaptToDetail(original)[key]);
  };
};

export const detailLinkPathFactory = (paths, id = 'id') => {
  return (resource) => {
    if (!paths || !resource || !id) return;
    return paths.resource.detail(resource[id]);
  };
};

export const cellLinkAdapterFactory = (adaptToDetail) => {
  return (key, pathFunc, id = 'id') => {
    const mappings = {
      facilityDetail: detailLinkPathFactory(facilityPaths, id),
      userDetail: detailLinkPathFactory(peoplePaths, id),
    };

    return ({ original }) => {
      const text = adaptToDetail(original)[key];
      if (!text) return;

      const knownPathFunc = mappings[pathFunc];

      return Link(text, knownPathFunc ? knownPathFunc(original) : pathFunc(original));
    };
  };
};
