import React from 'react';
import PropTypes from 'prop-types';

// Mui
import { styled } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';

const Pagination = styled(TablePagination)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const PaginationContainer = ({
  resourceName = '',
  list,
  loading,
  current_page = 0,
  total_count = 0,
  total_pages = 0,
  per_page = 10,
  rowsPerPageOptions = [10, 25, 50, 100, 250],
}) => {
  const [page, setPage] = React.useState(current_page - 1);

  // This is because we have a non-zero index on our paging
  React.useEffect(() => {
    const newPage = current_page > 0 ? current_page - 1 : 0;
    setPage(newPage);
  }, [current_page]);

  const handleChangePage = async (event, newPage) => {
    if (loading) return;
    await list({ page: newPage + 1, per_page: per_page });
  };

  const handleChangeRowsPerPage = async (event) => {
    if (loading) return;
    await list({ page: 0, per_page: parseInt(event.target.value, 10) });
  };

  const paginationProps = {
    // labelRowsPerPage: `${resourceName} per page:`,
    onPageChange: handleChangePage,
    onRowsPerPageChange: handleChangeRowsPerPage,
    count: total_count,
    page,
    rowsPerPage: per_page,
    rowsPerPageOptions,
    // TODO: We can add first and last buttons, after we fix the multiple actions issue
    // There is no global disable on TablePagination, disabling each button for now
    SelectProps: {
      disabled: loading,
    },
    backIconButtonProps: { disabled: loading || page === 0 },
    nextIconButtonProps: {
      disabled:
        total_count !== -1 ? loading || page >= Math.ceil(total_count / per_page) - 1 : loading,
    },
  };

  const maxCountOnPage = per_page * page;
  if (page > 0 && total_count < maxCountOnPage) {
    handleChangePage(null, 0);
    setPage(0);
  }

  // const showPagination = total_pages > 1 || current_page > 1 || total_count > 10 || null;
  const showPagination = total_count > 0;

  return <>{showPagination && <Pagination component="div" {...paginationProps} />}</>;
};

PaginationContainer.propTypes = {
  list: PropTypes.func.isRequired,
  resourceName: PropTypes.string,
  total_count: PropTypes.number,
  total_pages: PropTypes.number,
  current_page: PropTypes.number,
  per_page: PropTypes.number,
};

export default PaginationContainer;
