import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import Button from 'components/v1/Button';

const StyledWrapper = styled('div')(({ noPadding }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 0,
  marginLeft: 0,
  padding: noPadding ? '0' : '2.5rem 1.25rem 0rem',
}));

const StyledButton = styled(Button)(({ noPadding }) => ({
  marginRight: '0.9rem',
  ...(noPadding && { marginLeft: '0' }),
}));

const ConfirmButtons = ({
  cancelText,
  disabled,
  loading,
  noPadding,
  onClose,
  onSubmit,
  submitType = 'submit',
  text,
  ...rest
}) => (
  <StyledWrapper noPadding={noPadding} {...rest}>
    <StyledButton
      type="button"
      color="grey"
      icon="Close"
      disabled={disabled}
      onClick={onClose}
      text={cancelText || 'Cancel'}
    />
    <Button
      color="twilight"
      disabled={disabled || loading}
      icon="Check"
      loading={loading}
      text={text || 'Ok'}
      type={submitType}
      onClick={onSubmit}
    />
  </StyledWrapper>
);

ConfirmButtons.propTypes = {
  loading: PropTypes.bool,
  noPadding: PropTypes.bool,
  text: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  submitType: PropTypes.string,
  cancelText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ConfirmButtons;
