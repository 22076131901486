import React from 'react';

import DataTable, { dateFieldProps } from 'components/v2/PageElements/DataTable';

import { withFeatureState } from 'components/v2/InboxV2/state';

const Badges = ({ badges, listBadges, profile, loading }) => {
  const columns = [
    { field: 'badge_number', headerName: 'Number' },
    { field: 'badge_type', headerName: 'Type', flex: 1 },
    { field: 'agency_other', headerName: 'Agency', flex: 1 },
    { field: 'issuedDate', headerName: 'Issued', ...dateFieldProps },
  ];

  const tableProps = {
    columns,
    defaultSort: { field: 'issuedDate', sort: 'desc' },
    forcedFilters: { user_id: profile.id },
    hideFooter: true,
    list: listBadges,
    loading,
    pageSizeOptions: [500],
    rows: badges,
  };

  return (
    <>
      <DataTable {...tableProps} />
    </>
  );
};

export default withFeatureState(Badges);
