/* An invalid value should return true for these functions */
export function empty(value) {
  return value === null || value === '' ? true : false;
}

export function invalidEmail(value) {
  return value === null || value.indexOf('@') === -1 ? true : false;
}

export function noMatch(value1, value2) {
  return value1 !== value2;
}

export function tooShort(value, length) {
  return value === null || value.length < length;
}

export const validURL = (str) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};
