import React from 'react';
import { connect } from 'react-redux';

// MUI
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

// MUI Icons
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SendIcon from '@mui/icons-material/Send';

// FormElements
import { useForm } from 'components/v2/FormElements';
import TextField from 'components/v2/FormElements/TextField';
import Tooltip from 'components/v2/FormElements/Tooltip';

// PageElements
import SidebarListItem from 'components/v2/PageElements/Sidebar/ListItem';

import { ash, lightGray, lighterGray, charcoal, red } from 'lib/v2/colors';

const useStyles = makeStyles({
  suggestions: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    marginTop: -10,
    background: 'white',
    boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.75)',
    border: `1px solid ${charcoal}`,
    borderRadius: '5px',
    zIndex: 1,
    padding: '5px 0',
  },
  suggestion: {
    color: ash,
    borderBottom: `1px solid ${lightGray}`,
    marginLeft: 0,
    padding: '10px',
    fontSize: '0.75rem',
    '&:first-child': {
      // borderRadius: '10px 10px 0 0',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      background: lighterGray,
      cursor: 'pointer',
    },
    '&.active': {
      background: lightGray,
    },
  },
  container: {
    display: 'block',
    border: `2px solid ${lightGray}`,
    padding: 1,
    borderRadius: 5,
    marginBottom: 20,
    transition: 'all 0.25s',
    '&.adminOnly': {
      border: `2px solid ${red}`,
    },
  },
  controls: {
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
  },
  lock: {
    cursor: 'pointer',
  },
  buttons: {
    marginLeft: 'auto',
    display: 'block',
  },
  button: {
    // padding: '0 10px'
  },
});

const Composer = ({ defaultAdminOnlyNewComments = false, callback, users, loading, profile }) => {
  const classes = useStyles();
  const [suggestions, setSuggestions] = React.useState([]);
  const [activeSuggestion, setActiveSuggestion] = React.useState(0);
  const [selectionStart, setSelectionStart] = React.useState(0);
  const [showSuggestions, setShowSuggestions] = React.useState(false);
  const inputRef = React.createRef();

  const hideAdminOnly = !profile || profile.role === 'employee';
  const showAdminOnly = !hideAdminOnly;
  const defaultAdminOnly = hideAdminOnly ? false : defaultAdminOnlyNewComments;

  const { setFormData, handleCancel, formData, getTextFieldHandlerProps } = useForm({
    callback,
    defaults: { admin_only: defaultAdminOnly },
  });

  const { description = '', admin_only = defaultAdminOnly } = formData;

  const clearSuggester = () => {
    setShowSuggestions(false);
    setSuggestions([]);
    setActiveSuggestion(0);
  };

  const applySuggestion = (user) => {
    if (!user) return;

    const str = description || '';
    const insert = user.email;
    let existingText = str.slice(0, selectionStart - 1);
    const newText = `${existingText.trimEnd()} ${insert}${str.slice(
      selectionStart + insert.length,
      str.length
    )} `;
    setFormData({
      ...formData,
      description: newText.trim(),
    });
    clearSuggester();
    inputRef.current.focus();
  };

  const onCancel = () => {
    setFormData({
      ...formData,
      description: '',
    });
    handleCancel();
    clearSuggester();
  };

  const handleSubmit = async () => {
    if (!formData.description) return;
    const words = description.split(' ');
    // I think this parsing is better off on the back end but will keep it here until the backend is updated
    // someone could technically hack this request and insert any user id's into the mentions
    const possibleMentions = words.filter((word) => word.includes('@'));
    const mentions = users
      .filter(({ email }) => possibleMentions.includes(email))
      .map(({ id }) => id);
    await callback({
      ...formData,
      mentions,
    });
    onCancel();
  };

  const handleFilteringSuggestions = () => {
    const str = description || '';
    const search = str.slice(selectionStart);
    const matchingUsers = users.filter((u) => {
      const nameMatch = u.name.includes(search) || u.email.includes(search);
      if (admin_only) return nameMatch && u.any_admin;
      return nameMatch;
    });
    setSuggestions(matchingUsers.slice(0, 6));
    if (suggestions.length > activeSuggestion - 1) setActiveSuggestion(0);
  };

  const startMention = () => {
    inputRef.current.focus();
    setSelectionStart(inputRef.current.selectionStart + 1);
    setFormData({
      ...formData,
      description: description + '@',
    });
  };

  // using keydown events was very unreliable for the @ sign
  // so we're also checking if it's the last char entered
  // FIX HOOK
  React.useEffect(
    (prop) => {
      if (!description) return clearSuggester();
      const lastChar = description.slice(-1);

      if (showSuggestions) {
        handleFilteringSuggestions();
      } else if (lastChar === '@') {
        setShowSuggestions(true);
        handleFilteringSuggestions();
      }
    },
    [description] // eslint-disable-line
  );

  // still need key events for special keys
  const handleKeyDown = (event) => {
    const { key } = event;

    if (!showSuggestions) {
      // if (key === "@") {
      //   setShowSuggestions(true);
      //   handleFilteringSuggestions();
      // }
      return setSelectionStart(inputRef.current.selectionStart + 1);
    }

    // don't want to enter line breaks or move the cursor right now
    if (['Enter', 'ArrowUp', 'ArrowDown'].includes(key)) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (key === 'Escape' || key === ' ') {
      clearSuggester();
    } else if (key === 'Enter') {
      applySuggestion(suggestions[activeSuggestion]);
    } else if (key === 'ArrowDown') {
      setActiveSuggestion((activeSuggestion + 1) % suggestions.length);
    } else if (key === 'ArrowUp' && activeSuggestion > 0) {
      setActiveSuggestion((activeSuggestion - 1) % suggestions.length);
    }
  };

  const inputProps = {
    label: 'New Comment',
    placeholder: "Type @ to mention a team member and they'll be notified via email",
    disabled: loading,
    inputRef,
    onKeyDown: handleKeyDown,
  };

  const lockToolTip = admin_only
    ? 'Only Administrators and Security Managers will be able to see this comment'
    : 'All users with access to this page will be able to see this comment';

  const toggleAdminOnly = () => {
    setFormData({ ...formData, admin_only: !admin_only });
  };

  return (
    <ListItem className={`${classes.container} ${admin_only ? 'adminOnly' : ''}`}>
      <Box sx={{ position: 'relative' }}>
        <TextField multiline {...getTextFieldHandlerProps('description')} {...inputProps} />
        {showSuggestions && suggestions.length > 0 && (
          <List className={classes.suggestions}>
            {suggestions.map((s, i) => (
              <SidebarListItem
                key={`suggestion-${i}`}
                onClick={() => applySuggestion(s)}
                className={`${classes.suggestion} ${i === activeSuggestion ? 'active' : ''}`}
              >
                {s.email}
              </SidebarListItem>
            ))}
          </List>
        )}
      </Box>

      <Box className={classes.controls}>
        {showAdminOnly && (
          <Tooltip title={lockToolTip}>
            <IconButton onClick={toggleAdminOnly} className={classes.lock}>
              {admin_only && <LockIcon sx={{ color: red }} />}
              {!admin_only && <LockOpenIcon />}
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title={'mention user'}>
          <IconButton onClick={startMention}>
            <AlternateEmailIcon />
          </IconButton>
        </Tooltip>

        <Box className={classes.buttons}>
          <IconButton onClick={handleSubmit} disabled={!description}>
            <SendIcon color="primary" />
          </IconButton>
        </Box>
      </Box>
    </ListItem>
  );
};

// currently grabbing users store, will want to be updated when get around to fixing people v2 dropdown scope
const mapStateToProps = ({ users = {} }) => ({ users: Object.values(users.data || {}) });
export default connect(mapStateToProps)(Composer);
