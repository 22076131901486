import React from 'react';

// material-core
import Button from '@mui/material/Button';

const Index = ({
  children,
  className,
  color,
  component,
  disabled,
  endIcon,
  fullWidth,
  href,
  onClick,
  size,
  startIcon,
  style,
  sx,
  target,
  to,
  type,
  variant = 'contained',
}) => (
  <Button
    disableElevation
    {...{
      children,
      className,
      color,
      component,
      disabled,
      endIcon,
      fullWidth,
      href,
      onClick,
      size,
      startIcon,
      style,
      sx,
      target,
      to,
      type,
      variant,
    }}
  />
);

export default Index;
