export const isAdmin = (profile) => {
  if (!profile) return false;
  if (!profile.role) return false;

  const { role } = profile;
  return role === 'admin';
};

export const isSecurityManager = (profile) => {
  if (!profile) return false;
  if (!profile.role) return false;

  const { role } = profile;
  return role === 'sec-mngr';
};

export const isAsstSecurityManager = (profile) => {
  if (!profile) return false;
  if (!profile.role) return false;

  const { role } = profile;
  return role === 'asst-sec-mngr';
};

export const isAnyAdmin = (profile) => {
  return isAdmin(profile) || isSecurityManager(profile) || isAsstSecurityManager(profile);
};

export const isCustomRole = (profile) => {
  if (!profile) return false;
  if (!profile.role) return false;
  if (profile.custom_role) return true;

  return false;
};

export const isEmployee = (profile) => {
  if (!profile) return false;
  if (!profile.role) return false;
  if (profile.custom_role) return false;

  const { role } = profile;

  return role === 'employee';
};

export const isThreatSwitch = (profile) => {
  if (!profile) return false;
  if (!profile.email) return false;

  return profile.email.includes('@threatswitch.com');
};
