// Dependencies
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import store from 'store';
import moment from 'moment';
import { displayDateFormat } from 'config';
import { ticketObjectName } from 'components/v2/ActionRequests/constants';
import { hasWorkflows } from 'permissions/v1/preferences';

import { styled } from '@mui/material/styles';

// Components
import FeedItem from './FeedItem';

// UI Assets
import VisitIcon from 'assets/images/visits.svg';
import TravelIcon from 'assets/images/airplane.svg';
import HeaderIcon from 'assets/images/certificate.svg';
import CheckBoxIcon from 'assets/images/checkbox.svg';

// new designer made icons
import MenuInbox from 'assets/images/icons/menu_inbox.svg';
import MenuForms from 'assets/images/icons/menu_forms.svg';
import MenuTrainings from 'assets/images/icons/menu_trainings.svg';
import WorkflowIcon from 'assets/images/icons/workflow.svg';

import BusinessIcon from '@mui/icons-material/Business';
import CelebrationIcon from '@mui/icons-material/Celebration';

import { icons } from 'lib/v2/icons';

// Utilities
import format from 'lib/v1/format';
import pluralator from 'lib/v1/pluralator';

// Transformers
import { visitorUsers } from 'adapters/v1/Inbox/notifications';

// Actions
import { updateInbox } from 'store/v2/inbox/actions';
import actionRequestActions from 'store/v2/actionRequests/actions';
import assetsActions from 'store/v2/assetControls/actions';

// Paths
import { userPathsBuilder as userDigitalFormsPathsBuilder } from 'config/paths/userDigitalForms';

// Styles
import { StyledList, StyledListItem } from 'styles/Inbox/styles';

import Button from 'components/v2/FormElements/Button';

// paths
import travelPaths from 'config/paths/travels';
import visitRequestsPaths from 'config/paths/visitRequests';
import { userPathsBuilder as userTasksPathsBuilder } from 'config/paths/tasks';
import ticketPaths from 'config/paths/tickets';
import { userPathsBuilder as userTravelsPathsBuilder } from 'config/paths/userTravels';
import reportablesPaths from 'config/paths/reportablesV2';
import actionRequestsPaths from 'config/paths/actionRequests';
import { userPathsBuilder as userAssetControlsPath } from 'config/paths/userAssetControls';
import { employeeTrainingAssignmentPaths } from 'config/paths/training';
import workflowControlPaths from 'config/paths/workflowControls';

import { sisGreen5, red, ticketsColor } from 'lib/v2/colors';

const StyledButton = styled(Button)(({ theme }) => ({
  float: 'right',
}));

function ItemList({ items, max = 5, link }) {
  const itemSubList = items.slice(0, max);
  return (
    <StyledList>
      {itemSubList.map((item, index) => (
        <StyledListItem key={index}>{item}</StyledListItem>
      ))}
      {items.length > max && (
        <StyledListItem>
          <Link to={link}>+{items.length - max} more - View all</Link>
        </StyledListItem>
      )}
    </StyledList>
  );
}

function Message({ active, data, onToggleCard, onRefresh }) {
  const { type = {} } = data || {};

  const types = {
    'Message::Company': { src: BusinessIcon, color: red },
    'Message::Welcome': { src: CelebrationIcon, color: sisGreen5 },
  };

  const icon = {
    src: MenuInbox,
    alt: type,
    updated: true,
  };

  const onClick = async (e) => {
    await store.dispatch(updateInbox(data));
    await onRefresh();
  };

  return (
    <FeedItem
      active={active}
      onToggleCard={onToggleCard}
      title={data?.subject}
      icon={icon}
      Icon={types[type]}
    >
      <p>{data?.body}</p>
      <StyledButton color="secondary" onClick={onClick}>
        Roger that
      </StyledButton>
    </FeedItem>
  );
}

function Incident({ active, data, incidentType, onToggleCard }) {
  const PLURAL = data.count > 1;
  const USER_PLURAL = data.users.length > 1;
  const icon = { src: incidentType.icon, alt: data.type };

  const title = `You have ${data.count} unviewed ${incidentType.text} ${
    PLURAL ? 'reports' : 'report'
  }`;

  return (
    <FeedItem active={active} onToggleCard={onToggleCard} title={title} icon={icon}>
      <p>
        {USER_PLURAL
          ? `The following users have unviewed ${incidentType.text} Reports`
          : `The following user has unviewed ${incidentType.text} Reports`}
      </p>
      <ItemList items={data.users} max={3} link={reportablesPaths.root} />
      <StyledButton
        to={{
          pathname: reportablesPaths.root,
          state: {
            activeFilters: {
              kind: [incidentType.filterValue],
              viewed: [false],
            },
          },
        }}
        onClick={(e) => e.stopPropagation()}
        component={Link}
      >
        View {PLURAL ? 'Reports' : 'Report'}
      </StyledButton>
    </FeedItem>
  );
}

function TasksDue({ active, data, onToggleCard, profileId }) {
  const PLURAL = data.count > 1;
  const icon = { src: CheckBoxIcon, alt: data.type };
  const items = data.tasks.map((task) => {
    return `${task.name} that is due on ${moment(task.due_at, moment.ISO_8601)
      .local()
      .format(displayDateFormat)}`;
  });
  const title = `You have ${data.count} incomplete ${PLURAL ? 'tasks' : 'task'}`;

  return (
    <FeedItem active={active} onToggleCard={onToggleCard} title={title} icon={icon}>
      <ItemList items={items} max={3} link={userTasksPathsBuilder(profileId).root} />
      <StyledButton
        to={{
          pathname: userTasksPathsBuilder(profileId).root,
        }}
        onClick={(e) => e.stopPropagation()}
        component={Link}
      >
        View {PLURAL ? 'Tasks' : 'Task'}
      </StyledButton>
    </FeedItem>
  );
}

function ActionRequests({ active, data, requestType, onToggleCard, updateQuery, profile }) {
  const isWorkflows = hasWorkflows(profile);
  const PLURAL = data.count > 1;
  const icon = { src: WorkflowIcon, alt: requestType, updated: true };
  const query = { initialState: true };

  const items = data.cards.map((card) => {
    return `${card.submitted ? 'Submitted' : 'Unsubmitted'} ${ticketObjectName('object')} for ${
      card.subject
    } created on ${moment(card.created_at).format(displayDateFormat)}`;
  });

  const title = `You have ${data.count} open ${
    PLURAL ? 'items' : 'item'
  } related to ${requestType}`;

  const filters = {
    active: { values: [true], displayName: 'Active - Active' },
    card_template_id: {
      values: [data.card_template_id],
      displayName: `Request Type - ${requestType}`,
    },
    unreviewed: { values: [true], displayName: 'Unreviewed' },
  };

  const onClick = (e) => {
    if (e) e.stopPropagation(); // may not be necessary
    updateQuery({ filters, query });
  };

  const link = isWorkflows
    ? workflowControlPaths.resource.detail(data.card_template_id)
    : actionRequestsPaths.root;

  return (
    <FeedItem active={active} onToggleCard={onToggleCard} title={title} icon={icon}>
      <ItemList items={items} link={link} />
      <StyledButton to={{ pathname: link }} onClick={onClick} component={Link} color="tickets">
        View {ticketObjectName(PLURAL ? 'Objects' : 'Object')}
      </StyledButton>
    </FeedItem>
  );
}
const ConnectedActionRequests = connect(({ profile }) => ({ profile }), {
  updateQuery: actionRequestActions.updateQuery,
})(ActionRequests);

function DraftTickets({ active, data, onToggleCard }) {
  const count = data.length;
  const plural = count > 1;

  const Icon = { src: icons.tickets, color: ticketsColor };
  const title = `You have ${count} draft ${plural ? 'tickets' : 'ticket'} to complete`;
  const buttonLink = ticketPaths.root;

  const message = ({ data }) => {
    return `${data.attributes?.name} (Id: ${data.id})`;
  };

  return (
    <FeedItem active={active} Icon={Icon} onToggleCard={onToggleCard} title={title}>
      <p>The following tickets need you to finish the submission process:</p>
      <StyledList>
        {data.map((d, index) => (
          <StyledListItem key={index}>
            <Link to={{ pathname: ticketPaths.resource.detail(d.data?.id) }}>{message(d)}</Link>
          </StyledListItem>
        ))}
      </StyledList>
      <StyledButton
        to={{
          pathname: buttonLink,
        }}
        onClick={(e) => e.stopPropagation()}
        component={Link}
      >
        View My Tickets
      </StyledButton>
    </FeedItem>
  );
}

function TrainingAssignments({ active, data, onToggleCard, profile }) {
  const icon = {
    src: MenuTrainings,
    alt: 'Training Assignments',
    updated: true,
  };
  const PLURAL = data.length > 1;
  const title = `You have ${data.length} incomplete training ${
    PLURAL ? 'assignments' : 'assignment'
  }`;

  return (
    <FeedItem active={active} icon={icon} onToggleCard={onToggleCard} title={title}>
      <p>You have been assigned to complete:</p>
      <StyledList>
        {data.map((assignment, index) => (
          <StyledListItem key={index}>{assignment.name}</StyledListItem>
        ))}
      </StyledList>
      <StyledButton
        to={{
          pathname: employeeTrainingAssignmentPaths.list(profile.id),
          state: { activeFilters: { status: [0] } },
        }}
        onClick={(e) => e.stopPropagation()}
        component={Link}
      >
        View Training {PLURAL ? 'Assignments' : 'Assignment'}
      </StyledButton>
    </FeedItem>
  );
}
const ConnectedTrainings = connect(({ profile }) => ({ profile }))(TrainingAssignments);

const FormRequests = ({ active, data, isEmployee, onToggleCard, profile }) => {
  const icon = {
    src: MenuForms,
    alt: 'Form Requests',
    updated: true,
  };
  const PLURAL = data.length > 1;
  const title = `You have ${data.length} incomplete form ${PLURAL ? 'requests' : 'request'}`;

  return (
    <FeedItem active={active} icon={icon} onToggleCard={onToggleCard} title={title}>
      <p>You have been assigned to complete:</p>
      <StyledList>
        {data.map((formRequest, index) => (
          <StyledListItem key={index}>{formRequest.title}</StyledListItem>
        ))}
      </StyledList>
      <StyledButton
        to={{
          pathname: userDigitalFormsPathsBuilder(profile.id).root,
          state: {
            activeFilters: [
              {
                type: 'multi_select',
                name: 'status',
                displayName: 'Status',
                values: ['Incomplete'],
              },
            ],
          },
        }}
        onClick={(e) => e.stopPropagation()}
        component={Link}
      >
        View Form {PLURAL ? 'Requests' : 'Request'}
      </StyledButton>
    </FeedItem>
  );
};

const ConnectedFormRequests = connect(({ profile }) => ({ profile }))(FormRequests);

function VisitRequests({ active, data, isEmployee, onToggleCard }) {
  const icon = {
    src: VisitIcon,
    alt: 'Visit Requests',
  };
  const PLURAL = data.length > 1;
  const title = `You have ${data.length} unviewed ${
    PLURAL ? 'VARs' : 'VAR'
  } submitted by employees`;

  const activeFilters = [
    {
      type: 'multi_select',
      options: [{ label: 'No', value: false }],
      name: 'viewed',
      displayName: 'Viewed',
    },
  ];

  const basePath = visitRequestsPaths;

  return (
    <FeedItem active={active} icon={icon} onToggleCard={onToggleCard} title={title}>
      <p>The following {PLURAL ? 'people have' : 'person has'} an unviewed VAR:</p>
      <StyledList>
        {data.map((d, index) => (
          <StyledListItem key={index}>
            <Link to={{ pathname: basePath.resource.detail(d.id) }}>
              {format.name(visitorUsers(d) || {})}
            </Link>
          </StyledListItem>
        ))}
      </StyledList>
      <StyledButton
        to={{
          pathname: basePath.root,
          state: { activeFilters },
        }}
        onClick={(e) => e.stopPropagation()}
        component={Link}
      >
        View {PLURAL ? 'Reports' : 'Report'}
      </StyledButton>
    </FeedItem>
  );
}

function AssetAudits({ active, data, updateQuery, onToggleCard, profileId }) {
  const icon = {
    src: HeaderIcon,
    alt: 'Asset Audits',
  };

  const PLURAL = data.length > 1;
  const title = `You have ${data.length} ${pluralator(
    data.length,
    'asset',
    'assets'
  )} that ${pluralator(data.length, 'needs', 'need')} attention`;

  const filters = {
    needs_attention: {
      values: [true],
      displayName: `Audit Needs Attention - Yes`,
    },
  };
  const query = { initialState: true };

  const onClick = (e) => {
    if (e) e.stopPropagation(); // may not be necessary
    updateQuery({ filters, query });
  };

  return (
    <FeedItem active={active} icon={icon} onToggleCard={onToggleCard} title={title}>
      <p>You have been assigned to complete:</p>
      <StyledList>
        {data.map((asset, index) => (
          <StyledListItem key={index}>
            {`${asset.document_name} - check due on ${moment(
              asset.audit_due_at,
              moment.ISO_8601
            ).format(displayDateFormat)}`}
          </StyledListItem>
        ))}
      </StyledList>
      <StyledButton
        to={{
          pathname: userAssetControlsPath(profileId).list,
        }}
        onClick={onClick}
        component={Link}
      >
        View {PLURAL ? 'Assets' : 'Asset'}
      </StyledButton>
    </FeedItem>
  );
}

const ConnectedAssetAudits = connect(null, { updateQuery: assetsActions.updateQuery })(AssetAudits);

function ForeignTravels({ active, data, isEmployee, onToggleCard, profile }) {
  const icon = {
    src: TravelIcon,
    alt: 'Travel',
  };

  const ctaLink = userTravelsPathsBuilder(profile.id).root;
  const PLURAL = data.length === 1;
  const title = `You have ${data.length} incomplete ${PLURAL ? 'travel' : 'travels'}`;

  const message = (data) => {
    return `${format.full_date(data.departure_date)} - ${format.full_date(data.return_date)}, ${
      data.travel_type
    }`;
  };

  return (
    <FeedItem active={active} icon={icon} onToggleCard={onToggleCard} title={title}>
      <p>The following travels are incomplete or need you to complete a debriefing:</p>
      <StyledList>
        {data.map((d, index) => (
          <StyledListItem key={index}>
            <Link to={{ pathname: travelPaths.resource.detail(d.id) }}>{message(d)}</Link>
          </StyledListItem>
        ))}
      </StyledList>
      <StyledButton
        to={{
          pathname: ctaLink,
        }}
        onClick={(e) => e.stopPropagation()}
        component={Link}
      >
        View My Travels
      </StyledButton>
    </FeedItem>
  );
}
const ConnectedForeignTravels = connect(({ profile }) => ({ profile }))(ForeignTravels);

export {
  ConnectedFormRequests,
  Incident,
  Message,
  ConnectedTrainings,
  VisitRequests,
  ConnectedForeignTravels,
  TasksDue,
  ConnectedAssetAudits,
  ConnectedActionRequests,
  DraftTickets,
};
