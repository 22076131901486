import React from 'react';
import { connect } from 'react-redux';

// Hooks
import { useDialog } from 'components/v2/FormElements/Dialog';
import { useForm } from 'components/v2/FormElements';

// components
import ConfirmModal from 'components/v2/FormElements/ConfirmModal';
import { TagNamesAutocomplete } from 'components/v2/FormElements/Autocomplete';
import BatchAction from './BatchAction';
import { useBulkCreateTags, useExistingTags } from './TagRowAction';

// Actions
import tagNameActions from 'store/v2/tagNames/actions';
import tagActions from 'store/v2/tags/actions';

// Adapters
import { adaptToOriginalRowIds } from 'adapters/v1/table/rows';

const adaptToBulkCreateTagAssociationRequest = ({ tags = [], selected = [], taggableType }) => {
  if (!tags || !selected || !taggableType) return [];

  const data = adaptToOriginalRowIds(selected)
    .map((id) =>
      tags.map((obj) => ({
        attributes: { taggable_type: taggableType, taggable_id: id, tag_name_id: obj.label },
        type: 'tag',
      }))
    )
    .flat();

  return { data };
};

export const TagBatchAction = ({
  action = 'Tags',
  taggableType,
  bulkCreateTagNames,
  bulkCreateTags,
  selected,
  updateQuery,
  toggleAllRowsSelected,
  formDataTagKey = 'tags',
  triggerState,
  debug = false,
  ...rest
}) => {
  const { onClick, onClose, open } = useDialog();

  // Fail hard, should be development only
  if (!taggableType) throw 'taggableType must be defined'; /* eslint-disable-line */
  if (debug) console.log({ rest, selected }); /* eslint-disable-line */

  const { getInlineCreateSelectHandlerProps, formData, setFormData } = useForm({ resource: {} });
  const selectedTags = formData[formDataTagKey];

  const useOnConfirm = async () => {
    // await is pretty important here
    const created = await useBulkCreateTags({ bulkCreateTags: bulkCreateTagNames, selectedTags });
    const tags = useExistingTags(selectedTags).concat(created);
    const payload = adaptToBulkCreateTagAssociationRequest({ tags, selected, taggableType });

    if (debug) console.log({ payload }); /* eslint-disable-line */

    if (!debug) {
      const resp = await bulkCreateTags(payload);
      if (!resp.success) return;
      if (triggerState) triggerState();
      setFormData({ tags: [] });
      onClose();
    }
  };

  const modalProps = {
    title: 'Bulk Assign Tags',
    isOpen: open,
    onConfirm: useOnConfirm,
    confirmText: 'Save',
    onClose,
  };

  const actionProps = { onClick, icon: 'Tag', title: 'Tag' };

  React.useEffect(() => {
    if (!open) toggleAllRowsSelected(false);
  }, [open, toggleAllRowsSelected]);

  return (
    <>
      <BatchAction {...rest} {...actionProps} />
      <ConfirmModal {...modalProps}>
        <TagNamesAutocomplete
          {...getInlineCreateSelectHandlerProps(formDataTagKey)}
          value={selectedTags || []}
        />
      </ConfirmModal>
    </>
  );
};

export default connect(null, {
  bulkCreateTagNames: tagNameActions.bulkCreate,
  bulkCreateTags: tagActions.bulkCreate,
})(TagBatchAction);
