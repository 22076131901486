import React from 'react';

import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '0 2px 2px',
    border: `1px solid ${theme.palette.divider}`,
    minWidth: '5rem',
    boxShadow: 'none !important',
    marginTop: '-1px',
    padding: '0 0 0 0',
    '& ul': {
      padding: '5px 0',
    },
    '& li': {
      fontSize: '12px',
      fontWeight: '300',
    },
  },
}));

// the built in Menu component for Material UI captures tab keyup events
// https://github.com/mui-org/material-ui/issues/20173
const Menu = ({ open, children, anchorEl, onClose }) => {
  const classes = useStyles();

  return (
    <>
      <Popover
        keepMounted
        classes={classes}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={onClose}
      >
        <Paper className={classes.paper}>{children}</Paper>
      </Popover>
    </>
  );
};

export default Menu;
