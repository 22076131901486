import { isAnyAdmin } from 'permissions/v1/roles';
import { canList, canView, canCreate, canUpdate, canDestroy } from 'permissions/v1/index';

const permission = 'User';

const list = (profile) => isAnyAdmin(profile) || canList(permission, profile);
const view = (profile) => isAnyAdmin(profile) || canView(permission, profile);
const create = (profile) => isAnyAdmin(profile) || canCreate(permission, profile);
const update = (profile) => isAnyAdmin(profile) || canUpdate(permission, profile);
const destroy = (profile) => isAnyAdmin(profile) || canDestroy(permission, profile);

const piiAuthorized = (profile) =>
  isAnyAdmin(profile) ||
  (canView(permission) && profile.custom_role ? profile.custom_role.secure_info_authorized : false);

const permissions = (profile) => {
  return {
    list: list(profile),
    view: view(profile),
    create: create(profile),
    update: update(profile),
    destroy: destroy(profile),
    isCustomRole: !!profile.custom_role,
    isAnyAdmin: isAnyAdmin(profile),
    pii: piiAuthorized(profile),
  };
};

export default permissions;
