import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, Redirect } from 'react-router-dom';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { useRoutingFilters } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

import actions from 'store/v2/digitalFormRequests/actions';

// Config
import paths from 'config/paths/digitalForms';
import digitalFormTemplatesPaths from 'config/paths/digitalFormTemplates';
import digitalFormRequestsPaths from 'config/paths/digitalFormRequests';

// Components
const Index = lazy(() => import('./index'));
const TemplatesDetail = lazy(() => import('./Templates/Detail'));
const RequestsDetail = lazy(() => import('./Requests/Detail'));

export const DigitalFormsReduxManager = ({ children }) => {
  return (
    <ReduxManager
      data={[
        'peopleDropdown',
        'digitalFormTemplatesDropdown',
        'digitalFormRequestsDropdown',
        'tagNamesDropdown',
      ]}
    >
      {children}
    </ReduxManager>
  );
};

export const Routes = ({ paths, permissions, updateQuery, location }) => {
  useRoutingFilters({ location, updateQuery });
  // Redux Manager can handle loading any data necessary for the feature to function
  // see the component definition for available data/actions
  return (
    <DigitalFormsReduxManager>
      <Suspense fallback={<></>}>
        <Switch>
          <Route
            permission={permissions.digitalFormTemplates.view}
            exact
            path={digitalFormTemplatesPaths.resource.detail(':id')}
            component={TemplatesDetail}
          />
          <Route
            permission={permissions.digitalFormRequests.view}
            exact
            path={digitalFormRequestsPaths.resource.detail(':id')}
            component={RequestsDetail}
          />
          <Route
            exact
            permission={true}
            path={paths.root}
            render={(props) => <Redirect {...props} to={digitalFormTemplatesPaths.root} />}
          />
          <Route
            permission={permissions.digitalFormTemplates.list}
            path={paths.root}
            component={Index}
          />
        </Switch>
      </Suspense>
    </DigitalFormsReduxManager>
  );
};

export default connect(
  mapPermissionsToProps,
  actions
)(withRouter((props) => <Routes paths={paths} {...props} />));
