import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapTableToPropsFactory, mergePropsToTable } from 'mappers/v1/table';

// actions
import actions from 'store/v2/documents/actions';

// adapters
import {
  cellLinkAdapter,
  cellAdapter,
  cellDownloadLinkAdapter,
} from 'adapters/v1/documents/formData';

/* TEMPLATE NOTES:
 *
 * Make sure to import actions and update the featureReduxKey or define the explicit mapper in mappers/v1/<feature>
 *
 * stateBuilder is my current best effort and making components/features fully portable across
 * different parts of the app.  Along with the routeBuilder feature in Routes.js I believe I've
 * abstracted things down to path configuration only.
 *
 * paramId refers to the value in a given route that we need to pull for a resource.
 *
 * in actionRequests the :id is an action request id, whereas in Users/ActionRequests :id refers to the user id,
 * and it is :request_id (in the route definition) that is used to retrieve the appropriate value for returning the resource.
 *
 * One of the keys here was to abandon the effort to seperate action request data into different store values.  listByUser turns out to be a core action for any given feature and once I unified those two concepts it become much simpler to just reuse nearly every part of the action requests feature to support Users/ActionRequests.
 */

const adaptToDetail = (obj) => obj;

export const stateBuilder = (paramId = 'id', featureReduxKey = 'documentsV2') => {
  const mapDataToTable = mapTableToPropsFactory(featureReduxKey);

  const mapResourceToProps = (store, ownProps) => {
    const reduxKey = store[featureReduxKey];
    // Map resource from store, we've wrapped the component
    // in withRouter so match is Present.
    const { match, resource } = ownProps;
    const resourceId =
      match && match.params ? Number(match.params[paramId]) : resource && resource.id;
    return { resourceId, resource: adaptToDetail(reduxKey.data[resourceId]) };
  };

  const mapStateToProps = ({ profile, documentsV2 }) => {
    return {
      profile,
      loading: documentsV2.loading,
      company: profile && profile.company,
      ...mapPermissionsToProps({ profile }),
    };
  };

  const mapDispatchToProps = {
    ...actions,
    list: (queryParams) =>
      actions.list({
        sort: 'created_at',
        order: 'desc',
        ...queryParams,
      }),
  };

  return {
    withTableState: (Component) => {
      return connect(
        (store) => ({ ...mapPermissionsToProps(store), ...mapDataToTable(store) }),
        mapDispatchToProps,
        mergePropsToTable
      )((props) => (
        <Component
          {...props}
          cellLinkAdapter={cellLinkAdapter}
          cellAdapter={cellAdapter}
          cellDownloadLinkAdapter={cellDownloadLinkAdapter}
        />
      ));
    },
    withFeatureState: (Component) => {
      return connect(mapStateToProps, mapDispatchToProps)(withRouter(Component));
    },
    withResourceState: (Component) => {
      return connect(
        (store, ownProps) => ({
          ...mapStateToProps(store),
          ...mapResourceToProps(store, ownProps),
        }),
        mapDispatchToProps
      )(withRouter(Component));
    },
  };
};

// by encapsulating all of our HOCs into a single function definition, I can cleanly swap out minimal state to reuse components across routes.
// This is literally a function, that returns an object, that contains 3 higher order components composed of 1 or more higher order components..
export const { withFeatureState, withResourceState, withTableState } = stateBuilder();
