import request from 'lib/v1/request';

export const getCodes = (code) => {
  return request.get('/users/otps');
};

export const verifyCode = (code, email) => {
  const payload = { code };
  if (email) payload.email = email;
  return request.post('/users/otps/verify').send(payload);
};

export const resendCode = (email) => {
  const url = email
    ? `/users/otps/resend?email=${encodeURIComponent(email)}`
    : '/users/otps/resend';
  return request.get(url);
};

export const requestCode = () => {
  return request.post('/users/otps');
};

export const requestCodeByEmail = (email) => {
  return request.post('/users/otps').send({ email });
};

export const disableTwoFactor = (id) => {
  return request.delete(`/users/otps/${id}`);
};
