import React from 'react';
import { withFeatureState } from './state';

import TswDialog from 'components/v2/FormElements/Dialog'; // TODO: Come up with something here.  Form elements/modals are merged together here and it creates a dom validation error due to nestted forms.
import Form from './CopyForm';

export const Dialog = ({ onClose, open, title, ...rest }) => {
  return (
    <TswDialog onClose={onClose} open={open} title={title}>
      <Form afterCancel={onClose} {...rest} isDialog={true} />
    </TswDialog>
  );
};

export const Copy = ({
  copyTemplate,
  digitalFormTemplates: { dropdown },
  history,
  list,
  onClose,
  paths,
  ...rest
}) => {
  const callback = async (formData) => {
    if (!formData || !copyTemplate) return;

    const selection = Object.values(dropdown).find(
      (i) => i.attributes.template_id === formData.template_id.label
    );

    if (!selection) return;

    const payload = { data: { id: selection?.id, type: 'digital_form_template' } };
    const resp = await copyTemplate(payload);

    if (!resp.success) return;

    await list();
    onClose();
  };

  const ownProps = {
    title: 'Copy',
    onClose,
    callback,
  };
  return <Dialog {...ownProps} {...rest} />;
};

export default withFeatureState(Copy);
