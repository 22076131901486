import { Component } from 'react';
import { styled } from '@mui/material/styles';

import CloudUpload from 'assets/images/cloud-upload.svg';

import { fileSizeError } from 'components/v2/FormElements/AddDocument';

const StyledWrapper = styled('label')(({ css, error, hovering }) => ({
  alignItems: 'center',
  backgroundColor: 'rgba(242, 242, 242, 0.2)',
  boxSizing: 'border-box',
  border: 'dotted 3px #d5d2d2',
  borderRadius: '10px',
  display: 'flex',
  cursor: 'pointer',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '200px',
  padding: '45px',
  margin: '0 100px',
  ...css,
  ...(hovering && { backgroundColor: 'rgba(242, 242, 242, 0.8)' }),
  ...(error && {
    border: 'dotted 1px #d0011b',
    backgroundColor: 'rgba(208, 2, 27, 0.06)',
  }),
}));

const StyledIcon = styled('img')({
  minHeight: '1px',
  marginBottom: '15px',
  width: '40px',
});

const StyledInstructions = styled('strong')({
  marginTop: '14px',
  display: 'flex',
  textAlign: 'center',
  p: {
    color: '#205077',
    font: 'normal 20px Roboto, Arial',
    padding: 0,
    span: {
      fontSize: '14px',
      color: '#9b9b9b',
    },
  },
});

class FileDrop extends Component {
  state = {
    files: [],
    isDraggedOver: false,
  };

  onSelectFiles = (e) => {
    this.onAddFiles(e.target.files);
    if (this.props.onChangeEvent) {
      this.props.onChangeEvent(e);
    }
  };

  onAddFiles = async (files) => {
    if (files.length) {
      let filesArray = Array.from(files).map((file) => {
        file.file_name = file.name;
        return file;
      });

      if (fileSizeError(filesArray)) return;

      const contents = await Promise.all(filesArray.map(this.read));

      filesArray = filesArray.map((file, i) => {
        file.content = (contents[i] || '').substr((contents[i] || '').indexOf(',') + 1);
        return file;
      });

      if (this.props.onChange) {
        this.props.onChange(filesArray);
      }
    }
  };

  onDropFiles = (e) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    if (files) {
      const obj = {
        preventDefault: () => {},
        target: { value: true, files },
      };
      this.onSelectFiles(obj);
    }
  };

  read = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => resolve(reader.result);
    });

  onDragEnter = (e) => {
    e.preventDefault();
    this.setState({ isDraggedOver: true });
  };

  onDragLeave = (e) => {
    e.preventDefault();
    this.setState({ isDraggedOver: false });
  };

  onDragOver = (e) => e.preventDefault();

  render() {
    const { accept, className, error, multiple, hidden, noMargin, css } = this.props;

    const subText = multiple ? 'files' : 'file';

    return (
      <StyledWrapper
        className={className}
        draggable={true}
        error={error}
        hidden={hidden}
        noMargin={noMargin}
        css={css}
        hovering={this.state.isDraggedOver}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        onDrop={this.onDropFiles}
        htmlFor="single"
      >
        <StyledIcon src={CloudUpload} alt="Cloud-drop-icon" />
        <div>
          <StyledInstructions>
            <p>
              Click to browse
              <br />
              <span>(Or drag {subText} here)</span>{' '}
            </p>
          </StyledInstructions>
          <input
            multiple={multiple}
            accept={accept || 'accept'}
            style={{ display: 'none' }}
            onChange={this.onSelectFiles}
            type="file"
            name="single"
            id="single"
          />
        </div>
      </StyledWrapper>
    );
  }
}

export default FileDrop;
